import type { UploadFile } from "antd/es/upload/interface";
import { PICTURE_ACTIONS } from "../../../constants/ActionTypes/ReducerActionTypes/reducer-action-types";
import { PictureT } from "../../../constants/ActionTypes/picture-action-types";
interface InitialState {
  file: UploadFile | any;
  name: string;
  selectedId: number;
  imageUrl: string;
  bookId: number;
  bookSearch: string;
  filteredPictures: PictureT[];
  bookTargetKeys: string[];
  bookSelectedKeys: string[];
  taskSequenceId: string;
}

export const initialState: InitialState = {
  file: null,
  name: "",
  selectedId: null!,
  imageUrl: "",
  bookId: null!,
  bookSearch: "",
  filteredPictures: [],
  bookTargetKeys: [],
  bookSelectedKeys: [],
  taskSequenceId: "",
};

type Action =
  | { type: typeof PICTURE_ACTIONS.SET_FILE; file: UploadFile | any }
  | { type: typeof PICTURE_ACTIONS.SET_NAME; name: string }
  | { type: typeof PICTURE_ACTIONS.SET_SELECTED_ID; selectedId: number }
  | { type: typeof PICTURE_ACTIONS.SET_IMAGE_URL; imageUrl: string }
  | { type: typeof PICTURE_ACTIONS.SET_BOOK_ID; bookId: number }
  | { type: typeof PICTURE_ACTIONS.SET_BOOK_SEARCH; bookSearch: string }
  | {
      type: typeof PICTURE_ACTIONS.SET_FILETED_PICTURES;
      filteredPictures: PictureT[];
    }
  | {
      type: typeof PICTURE_ACTIONS.SET_BOOK_TARGET_KEYS;
      bookTargetKeys: string[];
    }
  | {
      type: typeof PICTURE_ACTIONS.SET_BOOK_SELECTED_KEYS;
      bookSelectedKeys: string[];
    }
  | {
      type: typeof PICTURE_ACTIONS.SET_TASK_SESQUENCE_ID;
      taskSequenceId: string;
    };

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case PICTURE_ACTIONS.SET_FILE:
      return { ...state, file: action.file };
    case PICTURE_ACTIONS.SET_NAME:
      return { ...state, name: action.name };
    case PICTURE_ACTIONS.SET_SELECTED_ID:
      return { ...state, selectedId: action.selectedId };
    case PICTURE_ACTIONS.SET_IMAGE_URL:
      return { ...state, imageUrl: action.imageUrl };
    case PICTURE_ACTIONS.SET_BOOK_ID:
      return { ...state, bookId: action.bookId };
    case PICTURE_ACTIONS.SET_BOOK_SEARCH:
      return { ...state, bookSearch: action.bookSearch };
    case PICTURE_ACTIONS.SET_FILETED_PICTURES:
      return { ...state, filteredPictures: action.filteredPictures };
    case PICTURE_ACTIONS.SET_BOOK_TARGET_KEYS:
      return { ...state, bookTargetKeys: action.bookTargetKeys };
    case PICTURE_ACTIONS.SET_BOOK_SELECTED_KEYS:
      return { ...state, bookSelectedKeys: action.bookSelectedKeys };
    case PICTURE_ACTIONS.SET_TASK_SESQUENCE_ID:
      return { ...state, taskSequenceId: action.taskSequenceId };
    default:
      return state;
  }
};

export default reducer;
