import {
  BookTypeDispatchTypes,
  BookTypeT,
} from "../constants/ActionTypes/book-type-action-types";

export interface initialBookTypeState {
  booktype: BookTypeT[];
  status: boolean;
  message: string;
  saveStatus: boolean;
}

const defaultState = {
  booktype: [],
  status: false,
  message: "",
  saveStatus: false,
};

const booktypeReducer = (
  state: initialBookTypeState = defaultState,
  action: BookTypeDispatchTypes
): initialBookTypeState => {
  switch (action.type) {
    case "BOOKTYPES_LOADING":
      return Object.assign({}, state, {
        booktype: [],
        status: false,
        message: "",
      });
    case "BOOKTYPES_LOADED":
      return Object.assign({}, state, {
        booktype: action.payload.bookTypes,
        status: true,
        message: "",
      });
    case "BOOKTYPES_ERROR":
      return Object.assign({}, state, {
        booktype: [],
        status: false,
        message: action.payload,
      });
    case "BOOKTYPE_REGISTER_START":
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case "BOOKTYPE_REGISTER_SUCCESS":
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case "BOOKTYPE_REGISTER_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default booktypeReducer;
