import { useCallback } from "react";
import { NOTIFICATION_ACTIONS } from "../../constants/ActionTypes/ReducerActionTypes/reducer-action-types";
import { initialState } from "./reducer";
import type { UploadFile } from "antd/es/upload/interface";
export type AdminState = typeof initialState;

export const useBookChange = (dispatch: any) => {
  const bookChange = useCallback(
    (value: number) => {
      dispatch({
        type: NOTIFICATION_ACTIONS.SET_BOOK_ID,
        bookId: value,
      });
    },
    [dispatch]
  );

  return bookChange;
};

export const useTitleChange = (dispatch: any) => {
  const titleChange = useCallback(
    (value: string) => {
      dispatch({
        type: NOTIFICATION_ACTIONS.SET_TITLE,
        title: value,
      });
    },
    [dispatch]
  );

  return titleChange;
};

export const useDescriptionChange = (dispatch: any) => {
  const descriptionChange = useCallback(
    (value: string) => {
      dispatch({
        type: NOTIFICATION_ACTIONS.SET_DESCRIPTION,
        description: value,
      });
    },
    [dispatch]
  );

  return descriptionChange;
};

export const useImageUrlChange = (dispatch: any) => {
  const imageUrlChange = useCallback(
    (value: string) => {
      dispatch({
        type: NOTIFICATION_ACTIONS.SET_IMAGE_URL,
        imageUrl: value,
      });
    },
    [dispatch]
  );

  return imageUrlChange;
};

export const useFileChange = (dispatch: any) => {
  const fileChange = useCallback(
    (value: UploadFile | any) => {
      dispatch({
        type: NOTIFICATION_ACTIONS.SET_FILE,
        file: value,
      });
    },
    [dispatch]
  );

  return fileChange;
};
