import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, Table } from "antd";
import { UserT } from "../../constants/ActionTypes/login-action-types";

export default function AdminTable({
  propAdmins,
  loaded,
  functionDeleteAdmin,
}: any) {
  const columns = [
    {
      title: "Név",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "E-mail cím",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Törlés",
      key: "delete",
      render: (text: string, record: UserT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan törölni akarod ezt az admint?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => functionDeleteAdmin(record)}
            >
              <Button type="primary" danger>
                <DeleteOutlined key="delete" />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={propAdmins}
      loading={!loaded && !propAdmins}
      pagination={{
        position: ["bottomCenter"],
        pageSize: 10,
        showSizeChanger: false,
      }}
    />
  );
}
