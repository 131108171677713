import { Row, Col, Button, Form, Modal, Space } from "antd";
import { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RestOutlined } from "@ant-design/icons";

import BookActions from "../../actions/book";
import KeywordActions from "../../actions/keyword";
import BookTypeActions from "../../actions/booktype";
import BookType from "../BookType/booktype";
import Keywords from "../Keywords/keywords";
import { RootStore } from "../../store/store";
import { BookT } from "../../constants/ActionTypes/book-action-types";
import { useHistory } from "react-router-dom";
import "../formating/content.css";
import DeletedBooks from "./deletedBooks";
import useWindowDimensions from "../../constants/sizecheck";

import reducer, { initialState } from "./reducer";
import BookTable from "./book/bookTable";

export default function Book() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const effectRan = useRef(false);
  const propBook = useSelector((state: RootStore) => state.bookReducer.book);
  const status = useSelector((state: RootStore) => state.bookReducer.status);
  const [state] = useReducer(reducer, initialState);
  const { searchedColumn } = state;
  const [modalVisible, setModalVisible] = useState<boolean>(() => false);
  const [modify, setModify] = useState<boolean>(() => false);
  const [loaded, setLoaded] = useState<boolean>(() => false);
  const [bookTypeModalVisible, setBookTypeModalVisible] = useState<boolean>(
    () => false
  );
  const [keywordModalVisible, setKeywordModalVisible] = useState<boolean>(
    () => false
  );

  const [showDeletedModal, setShowDeletedModal] = useState<boolean>(
    () => false
  );
  const { width } = useWindowDimensions();
  useEffect(() => {
    const fetchData = async () => {
      if (effectRan.current === false) {
        try {
          await dispatch(BookActions.getAllBook());
          await dispatch(KeywordActions.getAllKeyword());
          await dispatch(BookTypeActions.getAllBookType());
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetchData();
    if (status) {
      setLoaded(true);
    }
    return () => {
      effectRan.current = true;
    };
  }, [dispatch, status]);

  const getBooks = async () => {
    setLoaded(false);
    try {
      await dispatch(BookActions.getAllBook());
      if (status) {
        if (propBook!.length > 0) {
          setLoaded(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const functionDeleteBook = async (record: BookT) => {
    await dispatch(BookActions.deleteBook(record.id));
    await getBooks();
  };

  const showPageModal = (record: BookT) => {
    history.push({
      pathname: "/raabe-klett/page",
      state: { bookId: record.id },
    });
  };

  const closeDeletedBooksModal = async () => {
    setShowDeletedModal(false);
    await getBooks();
  };

  return (
    <div className="content">
      <Row>
        <Col span={24} className="colPadding">
          <h1 className="title">
            <strong>Könyvek</strong>
          </h1>
          <Row>
            <Row>
              <Col span={30} className="colPadding">
                <Space size="middle">
                  <Button type="primary" onClick={() => setModalVisible(true)}>
                    Létrehozás
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => setBookTypeModalVisible(true)}
                  >
                    Könyvtípusok
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => setKeywordModalVisible(true)}
                  >
                    Kulcsszavak
                  </Button>
                </Space>
              </Col>
            </Row>
            <div className="recicleBin">
              <Button onClick={() => setShowDeletedModal(true)}>
                <RestOutlined />
              </Button>
            </div>
          </Row>
          <Col span={24}>
            <BookTable
              searchedColumn={searchedColumn}
              width={width}
              showPageModal={showPageModal}
              functionDeleteBook={functionDeleteBook}
              propBook={propBook}
              loaded={loaded}
              form={form}
              setModalVisible={setModalVisible}
              setModify={setModify}
              getBooks={getBooks}
              modalVisible={modalVisible}
              modify={modify}
            />
          </Col>
        </Col>
      </Row>
      <Modal
        visible={bookTypeModalVisible}
        onCancel={() => setBookTypeModalVisible(false)}
        maskClosable={false}
        forceRender
        centered
        footer={false}
        width={"70em"}
      >
        <BookType />
      </Modal>
      <Modal
        visible={keywordModalVisible}
        onCancel={() => setKeywordModalVisible(false)}
        maskClosable={false}
        forceRender
        centered
        footer={false}
        width={"70em"}
      >
        <Keywords />
      </Modal>
      <Modal
        visible={showDeletedModal}
        onCancel={closeDeletedBooksModal}
        maskClosable={true}
        forceRender
        footer={false}
        width={"87.5vw"}
        bodyStyle={{ height: "89.5vh" }}
        style={{
          position: "absolute",
          right: 0,
          bottom: "0em",
          marginTop: "0em",
        }}
      >
        <DeletedBooks showDeletedModal={showDeletedModal} />
      </Modal>
    </div>
  );
}
