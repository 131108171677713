import {
  QuestionDispatchTypes,
  QuestionT,
} from "../constants/ActionTypes/question-action-types";

export interface initialQuestionState {
  questions: QuestionT[];
  status: boolean;
  message: string;
  saveStatus: boolean;
}

const defaultState = {
  questions: [],
  status: false,
  message: "",
  saveStatus: false,
};
const questionReducer = (
  state: initialQuestionState = defaultState,
  action: QuestionDispatchTypes
): initialQuestionState => {
  switch (action.type) {
    case "QUESTIONS_LOADING":
      return Object.assign({}, state, {
        questions: [],
        status: false,
        message: "",
      });
    case "QUESTIONS_LOADED":
      return Object.assign({}, state, {
        questions: action.payload.questions,
        status: true,
        message: "",
      });
    case "QUESTIONS_ERROR":
      return Object.assign({}, state, {
        questions: [],
        status: false,
        message: action.payload,
      });
    case "QUESTION_REGISTER_START":
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case "QUESTION_REGISTER_SUCCESS":
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case "QUESTION_REGISTER_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default questionReducer;
