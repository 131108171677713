import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Popconfirm, Space, Table } from "antd";
import { useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import DashBoardActions from "../../actions/dashboard";
import { BookT } from "../../constants/ActionTypes/book-action-types";
import { DashBoardT } from "../../constants/ActionTypes/dashboard-action-types";
import {
  useDashboardSelectedKeysChange,
  useDashboardTargetKeysChange,
  useNameChange,
  useSelectedIdChange,
} from "./dashboardUtils";
import DashboardModal from "./modal";
import reducer, { initialState } from "./reducer";

const DashboardTable = ({
  modalVisible,
  setModalVisible,
  form,
  propDashBoard,
  loaded,
  setRefetch,
}: any) => {
  const dispatch = useDispatch();
  const [state, reducerDispatch] = useReducer(reducer, initialState);
  const {
    categoryTitle,
    selectedId,
    dashboardSelectedKeys,
    dashboardTargetKeys,
  } = state;

  const titleChange = useNameChange(reducerDispatch);
  const selectedIdChange = useSelectedIdChange(reducerDispatch);
  const dashboardSelectedKeysChange =
    useDashboardSelectedKeysChange(reducerDispatch);
  const dashboardTargetKeysChange =
    useDashboardTargetKeysChange(reducerDispatch);

  const [checked, setChecked] = useState<boolean>(() => false);
  const [modify, setModify] = useState<boolean>(() => false);
  const positionUp = async (record: DashBoardT) => {
    await dispatch(DashBoardActions.positionUpCategory(record.id));
    setChecked(true);
  };

  const positionDown = async (record: DashBoardT) => {
    await dispatch(DashBoardActions.positionDownCategory(record.id));
    setChecked(true);
  };

  const showModifyModal = (record: DashBoardT) => {
    form.setFieldsValue({
      categoryTitle: record.categoryTitle,
      books: record?.books,
    });
    setModalVisible(true);
    setModify(true);
    titleChange(record.categoryTitle);
    selectedIdChange(record.id);
    dashboardTargetKeysChange(
      record.books?.map((book: BookT) => book.id.toString())
    );
  };

  const functionDeletePage = async (record: DashBoardT) => {
    setRefetch(true);
    await dispatch(DashBoardActions.deleteCategory(record.id));
  };

  const closeModal = () => {
    setModalVisible(false);
    setModify(false);
    selectedIdChange(null!);
    titleChange("");
    dashboardTargetKeysChange([]);
    form.resetFields();
  };

  const maxPos = propDashBoard.reduce((max: number, p: any) => max > p.position ? max : p.position, 0)

  const columns = [
    {
      title: "Kategória név",
      key: "categoryTitle",
      dataIndex: "categoryTitle",
    },
    {
      title: "",
      key: "positioning",
      render: (text: string, record: DashBoardT) => {
        return (
          <Space size="middle">
            {record.position !== 1 && (
              <Button onClick={() => positionUp(record)}>
                <ArrowUpOutlined />
              </Button>
            )}
            {record.position !== maxPos && (
              <Button onClick={() => positionDown(record)}>
                <ArrowDownOutlined />
              </Button>
            )}
          </Space>
        );
      },
    },
    {
      title: "Módosítás",
      key: "modify",
      render: (text: string, record: DashBoardT) => {
        return (
          <Space size="middle">
            <Button onClick={() => showModifyModal(record)}>
              <EditOutlined />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Törlés",
      key: "delete",
      render: (text: string, record: DashBoardT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan törölni akarod ezt a kategóriát?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => functionDeletePage(record)}
            >
              <Button type="primary" danger>
                <DeleteOutlined key="delete" />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={propDashBoard}
        loading={!loaded && !propDashBoard}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          showSizeChanger: false,
        }}
      />
      <DashboardModal
        form={form}
        modalVisible={modalVisible}
        modify={modify}
        closeModal={closeModal}
        categoryTitle={categoryTitle}
        setChecked={setChecked}
        selectedId={selectedId}
        checked={checked}
        setRefetch={setRefetch}
        nameChange={titleChange}
        bookTargetKeys={dashboardTargetKeys}
        bookSelectedKeys={dashboardSelectedKeys}
        handleChangeTargetBooks={dashboardTargetKeysChange}
        handleSelectChangeBook={dashboardSelectedKeysChange}
      />
    </div>
  );
};
export default DashboardTable;
