import { useState, useEffect, useRef } from "react";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import type { UploadChangeParam } from "antd/es/upload";
import { Button, Form, message, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store/store";
import QuestionActions from "../../../actions/question";
import PictureSequenceSchema from "./schemas/pictureSequenceSchema";
import { handleChange } from "../../../constants/pictureUpload";

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export default function PictureSequence({
  question,
  modify,
  setModify,
  setModalVisible,
  modalVisible,
  checked,
  setChecked,
}: any) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propAnswers = useSelector(
    (state: RootStore) => state.answerReducer.answers
  );
  const propSaveStatus = useSelector(
    (state: RootStore) => state.answerReducer.saveStatus
  );

  const [arr, setArray] = useState<number[]>(() => []);
  const [picture1afile, setPicture1afile] = useState<UploadFile | any>(
    () => null!
  );
  const [loadingPicture1afile, setLoadingPicture1afile] = useState<boolean>(
    () => false
  );
  const [imageUrlPicture1afile, setImageUrlPicture1afile] = useState<string>(
    () => ""
  );
  const [picture2afile, setPicture2afile] = useState<UploadFile | any>(
    () => null!
  );
  const [loadingPicture2afile, setLoadingPicture2afile] = useState<boolean>(
    () => false
  );
  const [imageUrlPicture2afile, setImageUrlPicture2afile] = useState<string>(
    () => ""
  );
  const [picture3afile, setPicture3afile] = useState<UploadFile | any>(
    () => null!
  );
  const [loadingPicture3afile, setLoadingPicture3afile] = useState<boolean>(
    () => false
  );
  const [imageUrlPicture3afile, setImageUrlPicture3afile] = useState<string>(
    () => ""
  );
  const [picture4afile, setPicture4afile] = useState<UploadFile | any>(
    () => null!
  );
  const [loadingPicture4afile, setLoadingPicture4afile] = useState<boolean>(
    () => false
  );
  const [imageUrlPicture4afile, setImageUrlPicture4afile] = useState<string>(
    () => ""
  );

  const [firstSequnce, setFirstSequence] = useState<number>(() => null!);
  const [secoundSequnce, setSecoundSequence] = useState<number>(() => null!);
  const [thirdSequnce, setThirdSequence] = useState<number>(() => null!);
  const [fourthSequnce, setFourthSequence] = useState<number>(() => null!);

  const [disabledButton, setDisabledButton] = useState<boolean>(() => true);

  const [selectedSequences, setSelectedSequences] = useState<number[]>(
    () => []
  );
  const firstPrevCount = usePrevious(firstSequnce);
  const secoundPrevCount = usePrevious(secoundSequnce);
  const thirdPrevCount = usePrevious(thirdSequnce);
  const fourthPrevCount = usePrevious(fourthSequnce);

  const [forceRefresh, setForceRefresh] = useState<boolean>(() => false);

  const [pictureDescription1, setPictureDescription1] = useState<string>(
    () => ""
  );
  const [pictureDescription2, setPictureDescription2] = useState<string>(
    () => ""
  );
  const [pictureDescription3, setPictureDescription3] = useState<string>(
    () => ""
  );
  const [pictureDescription4, setPictureDescription4] = useState<string>(
    () => ""
  );

  useEffect(() => {
    setArray(Array.from({ length: question?.answerCount }, (_, i) => i + 1));
  }, [question?.answerCount]);

  const beforeUpload1 = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setPicture1afile(null!);
      setImageUrlPicture1afile("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setImageUrlPicture1afile("");
            setPicture1afile(null!);
          }
        };
      };
    }
    return false;
  };

  const beforeUpload2 = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setPicture2afile(null!);
      setImageUrlPicture2afile("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setImageUrlPicture2afile("");
            setPicture2afile(null!);
          }
        };
      };
    }
    return false;
  };

  const beforeUpload3 = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setPicture3afile(null!);
      setImageUrlPicture3afile("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setPicture3afile(null!);
            setImageUrlPicture3afile("");
          }
        };
      };
    }
    return false;
  };

  const beforeUpload4 = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setPicture4afile(null!);
      setImageUrlPicture4afile("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setPicture4afile(null!);
            setImageUrlPicture4afile("");
          }
        };
      };
    }
    return false;
  };

  const handleChangeImage1: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(
      info,
      setLoadingPicture1afile,
      setPicture1afile,
      setImageUrlPicture1afile
    );
  };

  const handleChangeImage2: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(
      info,
      setLoadingPicture2afile,
      setPicture2afile,
      setImageUrlPicture2afile
    );
  };

  const handleChangeImage3: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(
      info,
      setLoadingPicture3afile,
      setPicture3afile,
      setImageUrlPicture3afile
    );
  };

  const handleChangeImage4: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(
      info,
      setLoadingPicture4afile,
      setPicture4afile,
      setImageUrlPicture4afile
    );
  };

  useEffect(() => {
    const fetch = () => {
      if (propSaveStatus && checked) {
        message.success("Sikeres mentés");
        setChecked(false);
        setModalVisible(false);
      } else if (!propSaveStatus && checked) {
        setChecked(false);
        message.error("Hiba az feladat mentése közben!");
      }
    };
    fetch();
  }, [checked]);

  const removeElements = (value: any) => {
    for (let i = 0; i < selectedSequences.length; i++) {
      if (selectedSequences[i] === value) {
        selectedSequences.splice(i, 1);
        setSelectedSequences(selectedSequences);
      }
    }
  };

  useEffect(() => {
    if (firstSequnce !== null) {
      setSelectedSequences((pre) => [firstSequnce, ...pre]);
      if (
        firstSequnce === secoundSequnce ||
        firstSequnce === thirdSequnce ||
        firstSequnce === fourthSequnce
      ) {
        removeElements(firstPrevCount);
        setForceRefresh(true);
      }
    }
  }, [firstSequnce]);

  useEffect(() => {
    if (secoundSequnce !== null) {
      setSelectedSequences((pre) => [secoundSequnce, ...pre]);
      if (
        secoundSequnce === firstSequnce ||
        secoundSequnce === thirdSequnce ||
        secoundSequnce === fourthSequnce
      ) {
        removeElements(secoundPrevCount);
        setForceRefresh(true);
      }
    }
  }, [secoundSequnce]);

  useEffect(() => {
    if (thirdSequnce !== null) {
      setSelectedSequences((pre) => [thirdSequnce, ...pre]);
      if (
        thirdSequnce === firstSequnce ||
        thirdSequnce === secoundSequnce ||
        thirdSequnce === fourthSequnce
      ) {
        removeElements(thirdPrevCount);
        setForceRefresh(true);
      }
    }
  }, [thirdSequnce]);

  useEffect(() => {
    if (fourthSequnce !== null) {
      setSelectedSequences((pre) => [fourthSequnce, ...pre]);
      if (
        fourthSequnce === firstSequnce ||
        fourthSequnce === secoundSequnce ||
        fourthSequnce === thirdSequnce
      ) {
        removeElements(fourthPrevCount);
        setForceRefresh(true);
      }
    }
  }, [fourthSequnce]);

  const functionCreateQuestionWithAnswer = async () => {
    const formData = new FormData();
    const convertedFile1 = picture1afile as RcFile;
    const convertedFile2 = picture2afile as RcFile;
    const convertedFile3 = picture3afile as RcFile;
    const convertedFile4 = picture4afile as RcFile;

    formData.append("questionId", question.id);

    const answers = [
      {
        image: convertedFile1,
        sequenceId: firstSequnce,
        pictureDescription: pictureDescription1,
      },
      {
        image: convertedFile2,
        sequenceId: secoundSequnce,
        pictureDescription: pictureDescription2,
      },
      {
        image: convertedFile3,
        sequenceId: thirdSequnce,
        pictureDescription: pictureDescription3,
      },
      {
        image: convertedFile4,
        sequenceId: fourthSequnce,
        pictureDescription: pictureDescription4,
      },
    ];
    formData.append("pictureAfile", convertedFile1);
    formData.append("pictureAfile", convertedFile2);
    formData.append("pictureAfile", convertedFile3);
    formData.append("pictureAfile", convertedFile4);

    formData.append("answers", JSON.stringify(answers));
    await dispatch(QuestionActions.createQuestionAnswers(formData));
    setChecked(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setPicture1afile(null!);
    setPicture2afile(null!);
    setPicture3afile(null!);
    setPicture4afile(null!);

    setImageUrlPicture1afile("");
    setImageUrlPicture2afile("");
    setImageUrlPicture3afile("");
    setImageUrlPicture4afile("");

    setFirstSequence(null!);
    setSecoundSequence(null!);
    setThirdSequence(null!);
    setFourthSequence(null!);

    setPictureDescription1("");
    setPictureDescription2("");
    setPictureDescription3("");
    setPictureDescription4("");

    setModify(false);
    form.resetFields();
  };

  const functionModifyQuestionWithAnswer = async () => {
    const formData = new FormData();
    const convertedFile1 = picture1afile as RcFile;
    const convertedFile2 = picture2afile as RcFile;
    const convertedFile3 = picture3afile as RcFile;
    const convertedFile4 = picture4afile as RcFile;

    formData.append("questionId", question.id);
    const answers = [
      {
        id: propAnswers[0]?.id,
        image: convertedFile1,
        sequenceId: firstSequnce,
        pictureDescription: pictureDescription1,
        changed: convertedFile1?.type === undefined ? false : true,
      },
      {
        id: propAnswers[1]?.id,
        image: convertedFile2,
        sequenceId: secoundSequnce,
        pictureDescription: pictureDescription2,
        changed: convertedFile2?.type === undefined ? false : true,
      },
      {
        id: propAnswers[2]?.id,
        image: convertedFile3,
        sequenceId: thirdSequnce,
        pictureDescription: pictureDescription3,
        changed: convertedFile3?.type === undefined ? false : true,
      },
      {
        id: propAnswers[3]?.id,
        image: convertedFile4,
        sequenceId: fourthSequnce,
        pictureDescription: pictureDescription4,
        changed: convertedFile4?.type === undefined ? false : true,
      },
    ];
    formData.append("pictureAfile", convertedFile1);
    formData.append("pictureAfile", convertedFile2);
    formData.append("pictureAfile", convertedFile3);
    formData.append("pictureAfile", convertedFile4);

    formData.append("answers", JSON.stringify(answers));
    await dispatch(QuestionActions.modifyQuestionAnswers(formData));
    setChecked(true);
  };

  useEffect(() => {
    if (modify) {
      setImageUrlPicture1afile(
        process.env.REACT_APP_BASE_URL +
          "/files/content/pictures/" +
          propAnswers[0]?.imageUrl?.substring(
            `${process.env.REACT_APP_BASE_URL}/files/content/pictures/`.length
          )
      );
      setImageUrlPicture2afile(
        process.env.REACT_APP_BASE_URL +
          "/files/content/pictures/" +
          propAnswers[1]?.imageUrl?.substring(
            `${process.env.REACT_APP_BASE_URL}/files/content/pictures/`.length
          )
      );
      setImageUrlPicture3afile(
        process.env.REACT_APP_BASE_URL +
          "/files/content/pictures/" +
          propAnswers[2]?.imageUrl?.substring(
            `${process.env.REACT_APP_BASE_URL}/files/content/pictures/`.length
          )
      );
      setImageUrlPicture4afile(
        process.env.REACT_APP_BASE_URL +
          "/files/content/pictures/" +
          propAnswers[3]?.imageUrl?.substring(
            `${process.env.REACT_APP_BASE_URL}/files/content/pictures/`.length
          )
      );

      setFirstSequence(propAnswers[0]?.sequenceId);
      setSecoundSequence(propAnswers[1]?.sequenceId);
      setThirdSequence(propAnswers[2]?.sequenceId);
      setFourthSequence(propAnswers[3]?.sequenceId);
      setPicture1afile(propAnswers[0]?.fileName);
      setPicture2afile(propAnswers[0]?.fileName);
      setPicture3afile(propAnswers[0]?.fileName);
      setPicture4afile(propAnswers[0]?.fileName);
      setPictureDescription1(propAnswers[0]?.pictureDescription);
      setPictureDescription2(propAnswers[1]?.pictureDescription);
      setPictureDescription3(propAnswers[2]?.pictureDescription);
      setPictureDescription4(propAnswers[3]?.pictureDescription);
      form.setFieldsValue({
        sequence1: propAnswers[0]?.sequenceId,
        sequence2: propAnswers[1]?.sequenceId,
        sequence3: propAnswers[2]?.sequenceId,
        sequence4: propAnswers[3]?.sequenceId,
        description1: propAnswers[0]?.pictureDescription,
        description2: propAnswers[1]?.pictureDescription,
        description3: propAnswers[2]?.pictureDescription,
        description4: propAnswers[3]?.pictureDescription,
      });
    }
  }, [modify]);

  useEffect(() => {
    if (question?.answerCount === 4) {
      pictureDescription1 !== "" &&
      pictureDescription2 !== "" &&
      pictureDescription3 !== "" &&
      pictureDescription4 !== "" &&
      picture1afile !== null &&
      picture2afile !== null &&
      picture3afile !== null &&
      picture4afile !== null &&
      selectedSequences.includes(1) &&
      selectedSequences.includes(2) &&
      selectedSequences.includes(3) &&
      selectedSequences.includes(4)
        ? setDisabledButton(false)
        : setDisabledButton(true);
    } else if (question?.answerCount === 3) {
      pictureDescription1 !== "" &&
      pictureDescription2 !== "" &&
      pictureDescription3 !== "" &&
      picture1afile !== null &&
      picture2afile !== null &&
      picture3afile !== null &&
      selectedSequences.includes(1) &&
      selectedSequences.includes(2) &&
      selectedSequences.includes(3)
        ? setDisabledButton(false)
        : setDisabledButton(true);
    }
  }, [
    question?.questionType,
    question?.answerCount,
    picture1afile,
    picture2afile,
    picture3afile,
    picture4afile,

    firstSequnce,
    secoundSequnce,
    thirdSequnce,
    fourthSequnce,

    pictureDescription1,
    pictureDescription2,
    pictureDescription3,
    pictureDescription4,

    selectedSequences,
  ]);

  return (
    <div>
      <Modal
        visible={modalVisible}
        onCancel={() => closeModal()}
        maskClosable={false}
        title={modify ? "Módosítás" : "Létrehozás"}
        forceRender
        centered
        footer={[
          <Button
            key="createPictureSequence"
            type="primary"
            disabled={disabledButton}
            onClick={() =>
              modify
                ? functionModifyQuestionWithAnswer()
                : functionCreateQuestionWithAnswer()
            }
          >
            {modify ? "Módosítás" : "Létrehozás"}
          </Button>,
          <Button key="cancel" onClick={() => closeModal()}>
            Mégsem
          </Button>,
        ]}
        width={"70em"}
      >
        <Form
          form={form}
          name="register"
          scrollToFirstError
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 20 }}
        >
          <PictureSequenceSchema
            id={"file1"}
            sequenceId={"sequence1"}
            beforeUpload={beforeUpload1}
            handleChangeImage={handleChangeImage1}
            imageUrlPictureFile={imageUrlPicture1afile}
            loadingPictureFile={loadingPicture1afile}
            sequnce={firstSequnce}
            setSequence={setFirstSequence}
            arr={arr}
            title={"Első kép"}
            selectedSequences={selectedSequences}
            forceRefresh={forceRefresh}
            setForceRefresh={setForceRefresh}
            descriptionId={"description1"}
            pictureDescription={pictureDescription1}
            setPictureDescription={setPictureDescription1}
            setPictureFile={setPicture1afile}
            setPictureImageUrl={setImageUrlPicture1afile}
          />

          <PictureSequenceSchema
            id={"file2"}
            sequenceId={"sequence2"}
            beforeUpload={beforeUpload2}
            handleChangeImage={handleChangeImage2}
            imageUrlPictureFile={imageUrlPicture2afile}
            loadingPictureFile={loadingPicture2afile}
            sequnce={secoundSequnce}
            setSequence={setSecoundSequence}
            arr={arr}
            title={"Második kép"}
            selectedSequences={selectedSequences}
            forceRefresh={forceRefresh}
            setForceRefresh={setForceRefresh}
            descriptionId={"description2"}
            pictureDescription={pictureDescription2}
            setPictureDescription={setPictureDescription2}
            setPictureFile={setPicture2afile}
            setPictureImageUrl={setImageUrlPicture2afile}
          />

          {question?.answerCount >= 3 && (
            <PictureSequenceSchema
              id={"file3"}
              sequenceId={"sequence3"}
              beforeUpload={beforeUpload3}
              handleChangeImage={handleChangeImage3}
              imageUrlPictureFile={imageUrlPicture3afile}
              loadingPictureFile={loadingPicture3afile}
              sequnce={thirdSequnce}
              setSequence={setThirdSequence}
              arr={arr}
              title={"Harmadik kép"}
              selectedSequences={selectedSequences}
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              descriptionId={"description3"}
              pictureDescription={pictureDescription3}
              setPictureDescription={setPictureDescription3}
              setPictureFile={setPicture3afile}
              setPictureImageUrl={setImageUrlPicture3afile}
            />
          )}

          {question?.answerCount === 4 && (
            <PictureSequenceSchema
              id={"file4"}
              sequenceId={"sequence4"}
              beforeUpload={beforeUpload4}
              handleChangeImage={handleChangeImage4}
              imageUrlPictureFile={imageUrlPicture4afile}
              loadingPictureFile={loadingPicture4afile}
              sequnce={fourthSequnce}
              setSequence={setFourthSequence}
              arr={arr}
              title={"Negyedik kép"}
              selectedSequences={selectedSequences}
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              descriptionId={"description4"}
              pictureDescription={pictureDescription4}
              setPictureDescription={setPictureDescription4}
              setPictureFile={setPicture4afile}
              setPictureImageUrl={setImageUrlPicture4afile}
            />
          )}
        </Form>
      </Modal>
    </div>
  );
}
