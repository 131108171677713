import {
  message,
  Row,
  Col,
  Button,
  Popconfirm,
  Form,
  Modal,
  Input,
  Table,
  Space,
  Select,
} from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  RestOutlined,
  RetweetOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import TaskActions from "../../actions/task";
import { RootStore } from "../../store/store";
import { TaskT } from "../../constants/ActionTypes/task-action-types";
import Question from "../Question/question";
import TextArea from "antd/lib/input/TextArea";
import BookActions from "../../actions/book";
import { BookT } from "../../constants/ActionTypes/book-action-types";
import { layout } from "../../constants/layout";
import "../formating/content.css";
import DeletedTasks from "./deletedTasks";
import type { InputRef } from "antd";
import type { ColumnType } from "antd/es/table";
import CharacterCounter from "../../constants/characterCounter";

export default function Task() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const effectRan = useRef(false);
  const propTask = useSelector((state: RootStore) => state.taskReducer.task);
  const status = useSelector((state: RootStore) => state.taskReducer.status);
  const questions = useSelector((state: RootStore) => state.questionReducer.questions);
  const saveStatus = useSelector(
    (state: RootStore) => state.taskReducer.saveStatus
  );
  const propBooks = useSelector((state: RootStore) => state.bookReducer.book);
  const [title, setTitle] = useState<string>(() => "");
  const [description, setDescription] = useState<string>(() => "");
  const [modalVisible, setModalVisible] = useState<boolean>(() => false);
  const [modify, setModify] = useState<boolean>(() => false);
  const [loaded, setLoaded] = useState<boolean>(() => false);
  const [selectedId, setSelectedId] = useState<number>(() => null!);
  const [optionsModalVisible, setOptionsModalVisible] = useState<boolean>(
    () => false
  );
  const [checked, SetChecked] = useState<boolean>(() => false);
  const [selectedTask, setSelectedTask] = useState<TaskT>();
  const [bookId, setBookId] = useState<number>(() => null!);
  const [bookSearch, setBookSearch] = useState<string>(() => "");
  const [filteredTasks, setFilteredTasks] = useState<TaskT[]>(() => []);
  const [onSearch, setOnSerach] = useState<boolean>(() => false);
  const [disabled, setDisabled] = useState<boolean>(() => true);
  const [showDeletedModal, setShowDeletedModal] = useState<boolean>(
    () => false
  );
  const [showCloseModal, setShowCloseModal] = useState<boolean>(
      () => false
  );
  const [searchedColumn, setSearchedColumn] = useState(() => "");
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (effectRan.current === false) {
        try {
          await dispatch(TaskActions.getAllTask());
          await dispatch(BookActions.getAllBook());
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetchData();
    if (status) {
      setLoaded(true);
    }
    return () => {
      effectRan.current = true;
    };
  }, [dispatch, status]);

  const getTasks = useCallback(() => {
    setLoaded(false);
    try {
      dispatch(TaskActions.getAllTask());
      setLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);

  const onReset = useCallback(() => {
    form.resetFields();
  }, [form]);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setModify(false);
    setTitle("");
    setDescription("");
    setBookId(null!);
    onReset();
  }, [onReset]);

  useEffect(() => {
    const fetch = () => {
      if (saveStatus && checked) {
        message.success("Sikeres mentés");
        SetChecked(false);
        getTasks();
        closeModal();
      } else if (!saveStatus && checked) {
        SetChecked(false);
        message.error("Hiba az feladat mentése közben!");
      }
    };
    fetch();
  }, [saveStatus, checked, closeModal, getTasks]);

  const closeOptionModal = () => {
    if (questions.filter(q => q.answers.length < q.answerCount).length > 0) {
      setShowCloseModal(true);
    } else {
      onCloseModal()
    }
  };

  const onCloseModal = () => {
    setShowCloseModal(false);
    setOptionsModalVisible(false);
    setSelectedTask(null!);
  }

  const functionCreateTask = async () => {
    const toSend = {
      title: title,
      question: description,
      bookId: bookId,
    };
    await dispatch(TaskActions.createTask(toSend));
    SetChecked(true);
  };

  const functionModifyTask = async () => {
    const toSend = {
      id: selectedId,
      title,
      question: description,
      bookId: bookId,
    };
    await dispatch(TaskActions.modifyTask(toSend));
    SetChecked(true);
  };

  const functionDeleteTask = async (record: TaskT) => {
    await dispatch(TaskActions.deleteTask(record.id));
    getTasks();
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const showOptionModal = (record: TaskT) => {
    setOptionsModalVisible(true);
    setSelectedTask(record);
  };

  type DataIndex = keyof TaskT;

  const handleSearch = (
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    title: string
  ): ColumnType<TaskT> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="searchPadding">
        <Input
          ref={searchInput}
          placeholder={`Keress ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm, dataIndex)}
          className="searchInput"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            className="searchButton"
          >
            Keresés
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              handleSearch(confirm, dataIndex);
            }}
            size="small"
            className="searchButton"
          >
            Visszaállítás
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const showModifyModal = (record: TaskT) => {
    form.setFieldsValue({
      title: record?.title,
      description: record?.question,
      book: record.book?.title,
    });
    setModalVisible(true);
    setModify(true);
    setSelectedId(record.id);
    setTitle(record.title);
    setDescription(record.question);
    setBookId(record.book.id);
  };

  const searchTasks = () => {
    if (bookSearch.length > 0) {
      setOnSerach(true);
      const filter = propTask.filter((task) =>
        task.book?.title.toLowerCase().includes(bookSearch.toLocaleLowerCase())
      );
      setFilteredTasks(filter);
    }
  };

  const resetFilter = () => {
    setOnSerach(false);
    setBookSearch("");
    setFilteredTasks([]);
  };

  const closeDeletedTasksModal = async () => {
    setShowDeletedModal(false);
    await getTasks();
  };

  const duplicate = async (record: TaskT) => {
    await dispatch(TaskActions.duplicateTask(record.id));
    await getTasks();
  };

  const columns = [
    {
      title: "Feladat megnevezés",
      key: "taskTitle",
      dataIndex: "title",
      ...getColumnSearchProps("title", "megnevezést"),
      sorter: (a: TaskT, b: TaskT) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Leírás",
      key: "taskDescription",
      dataIndex: "question",
      ...getColumnSearchProps("question", "leírást"),
    },
    {
      title: "Könyv",
      key: "book",
      render: (text: string, record: TaskT) => {
        return record.book?.title;
      },
    },
    {
      title: "Kérdések és válaszok",
      key: "toptions",
      render: (text: string, record: TaskT) => {
        return (
          <Space size="middle">
            <Button onClick={() => showOptionModal(record)}>
              <QuestionCircleOutlined />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Duplikálás",
      key: "duplicate",
      render: (text: string, record: TaskT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan duplikálni akarod ezt a könyvet?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => duplicate(record)}
            >
              <Button>
                <CopyOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
    {
      title: "Módosítás",
      key: "modify",
      render: (text: string, record: TaskT) => {
        return (
          <Space size="middle">
            <Button onClick={() => showModifyModal(record)}>
              <EditOutlined />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Törlés",
      key: "delete",
      render: (text: string, record: TaskT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan törölni akarod ezt a feladatot?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => functionDeleteTask(record)}
            >
              <Button type="primary" danger>
                <DeleteOutlined key="delete" />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    if (!title || !description || !bookId) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [title, description, bookId]);

  return (
    <div className="content">
      <Row>
        <Col span={24} className="colPadding">
          <h1 className="title">
            <strong>Tesztsorok</strong>
          </h1>
          <Row>
            <Row>
              <Col span={30} className="colPadding">
                <Space size="middle">
                  <Button type="primary" onClick={showModal}>
                    Létrehozás
                  </Button>
                </Space>
              </Col>
            </Row>
            <div className="recicleBin">
              <Button onClick={() => setShowDeletedModal(true)}>
                <RestOutlined />
              </Button>
            </div>
          </Row>
          <Space className="colPadding">
            Könyv alapú keresés:
            <Input
              name="booksearch"
              value={bookSearch}
              onChange={(e) => setBookSearch(e.target.value)}
            />
            {!onSearch ? (
              <Button type="primary" size="small" onClick={searchTasks}>
                <SearchOutlined />
              </Button>
            ) : (
              <Button type="primary" size="small" onClick={resetFilter}>
                <RetweetOutlined />
              </Button>
            )}
          </Space>
          <Col span={24}>
            <Table
              columns={columns}
              rowKey="id"
              dataSource={onSearch ? filteredTasks : propTask}
              loading={!loaded && !propTask}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
                showSizeChanger: false,
              }}
            />
          </Col>
        </Col>
      </Row>
      <Modal
        visible={modalVisible}
        onCancel={closeModal}
        title={modify ? "Módosítás" : "Létrehozás"}
        maskClosable={false}
        forceRender
        centered
        footer={[
          <Button
            key="createTask"
            type="primary"
            disabled={disabled}
            onClick={modify ? functionModifyTask : functionCreateTask}
          >
            {modify ? "Módosítás" : "Létrehozás"}
          </Button>,
          <Button key="cancel" onClick={closeModal}>
            Mégsem
          </Button>,
        ]}
        width={"50em"}
      >
        <Row>
          <Col span={24} className="colPadding">
            <Form
              form={form}
              {...layout}
              name="register"
              onFinish={modify ? functionModifyTask : functionCreateTask}
              scrollToFirstError
            >
              <Form.Item
                name="title"
                label="Megnevezés"
                rules={[
                  {
                    required: true,
                    message: "Mező kitöltése kötelező!",
                  },
                ]}
              >
                <Input
                  name="title"
                  value={title}
                  maxLength={50}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <CharacterCounter max={50} taken={title.length} />
              </Form.Item>
              <Form.Item
                name="description"
                label="Leírás"
                rules={[
                  {
                    required: true,
                    message: "Mező kitöltése kötelező!",
                  },
                ]}
              >
                <TextArea
                  name="description"
                  value={description}
                  maxLength={100}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <CharacterCounter max={100} taken={description.length} />
              </Form.Item>
              <Form.Item
                name="book"
                label="Könyv"
                rules={[
                  {
                    required: true,
                    message: "Mező kitöltése kötelező!",
                  },
                ]}
              >
                <Select
                  style={{ width: "20em" }}
                  value={bookId}
                  onChange={(event) => setBookId(event)}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={propBooks.map((book: BookT) => ({
                    value: book.id,
                    label: book.title,
                  }))}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={optionsModalVisible}
        onCancel={closeOptionModal}
        maskClosable={true}
        forceRender
        centered
        footer={false}
        width={"88vw"}
        bodyStyle={{ height: "100vh" }}
        style={{ position: "absolute", right: 0, top: 0, bottom: 0 }}
      >
        <Question taskQuestions={selectedTask} />
      </Modal>
      <Modal
        visible={showDeletedModal}
        onCancel={closeDeletedTasksModal}
        maskClosable={true}
        forceRender
        footer={false}
        width={"87.5vw"}
        bodyStyle={{ height: "89.5vh" }}
        style={{
          position: "absolute",
          right: 0,
          bottom: "0em",
          marginTop: "0em",
        }}
      >
        <DeletedTasks showDeletedModal={showDeletedModal} />
      </Modal>
      <Modal
      title="Biztosan bezárja a szerkesztő felületet?"
      onCancel={() => setShowCloseModal(false)}
      cancelText="Mégsem"
      onOk={ onCloseModal }
      okText="Igen"
      visible={showCloseModal}>
        Egy vagy több kérdéshez még nem tartozik válasz.
      </Modal>
    </div>
  );
}
