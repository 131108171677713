import { Button, Form, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QuestionActions from "../../../actions/question";
import { RootStore } from "../../../store/store";
import TextTextPairSchema from "./schemas/textTextPairSchema";

export default function TextTextPair({
  question,
  modify,
  setModify,
  setModalVisible,
  modalVisible,
  checked,
  setChecked,
}: any) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propAnswers = useSelector(
    (state: RootStore) => state.answerReducer.answers
  );
  const propSaveStatus = useSelector(
    (state: RootStore) => state.answerReducer.saveStatus
  );
  const [pairAnswer1, setPairAnswer1] = useState<string>(() => "");
  const [pairAnswer2, setPairAnswer2] = useState<string>(() => "");
  const [pairAnswer3, setPairAnswer3] = useState<string>(() => "");
  const [pairAnswer4, setPairAnswer4] = useState<string>(() => "");
  const [pairAnswer5, setPairAnswer5] = useState<string>(() => "");
  const [pairAnswer6, setPairAnswer6] = useState<string>(() => "");
  const [pairAnswer7, setPairAnswer7] = useState<string>(() => "");
  const [pairAnswer8, setPairAnswer8] = useState<string>(() => "");

  const [pairAnswerPair1, setPairAnswerPair1] = useState<string>(() => "");
  const [pairAnswerPair2, setPairAnswerPair2] = useState<string>(() => "");
  const [pairAnswerPair3, setPairAnswerPair3] = useState<string>(() => "");
  const [pairAnswerPair4, setPairAnswerPair4] = useState<string>(() => "");
  const [pairAnswerPair5, setPairAnswerPair5] = useState<string>(() => "");
  const [pairAnswerPair6, setPairAnswerPair6] = useState<string>(() => "");
  const [pairAnswerPair7, setPairAnswerPair7] = useState<string>(() => "");
  const [pairAnswerPair8, setPairAnswerPair8] = useState<string>(() => "");

  const [disabledButton, setDisabledButton] = useState<boolean>(() => true);

  const functionCreateQuestionWithAnswer = async () => {
    const formData = new FormData();

    formData.append("questionId", question.id);
    const answers = [
      {
        pairAnswer: pairAnswer1,
        pairAnswerPair: pairAnswerPair1,
      },
      {
        pairAnswer: pairAnswer2,
        pairAnswerPair: pairAnswerPair2,
      },
      {
        pairAnswer: pairAnswer3,
        pairAnswerPair: pairAnswerPair3,
      },
      {
        pairAnswer: pairAnswer4,
        pairAnswerPair: pairAnswerPair4,
      },
      {
        pairAnswer: pairAnswer5,
        pairAnswerPair: pairAnswerPair5,
      },
      {
        pairAnswer: pairAnswer6,
        pairAnswerPair: pairAnswerPair6,
      },
      {
        pairAnswer: pairAnswer7,
        pairAnswerPair: pairAnswerPair7,
      },
      {
        pairAnswer: pairAnswer8,
        pairAnswerPair: pairAnswerPair8,
      },
    ];
    formData.append("answers", JSON.stringify(answers));
    await dispatch(QuestionActions.createQuestionAnswers(formData));
    setChecked(true);
  };

  useEffect(() => {
    const fetch = () => {
      if (propSaveStatus && checked) {
        message.success("Sikeres mentés");
        setChecked(false);
        setModalVisible(false);
      } else if (!propSaveStatus && checked) {
        setChecked(false);
        message.error("Hiba az feladat mentése közben!");
      }
    };
    fetch();
  }, [checked, propSaveStatus, setChecked, setModalVisible]);

  useEffect(() => {
    if (question.answerCount === 8) {
      pairAnswer1 !== "" &&
      pairAnswer2 !== "" &&
      pairAnswer3 !== "" &&
      pairAnswer4 !== "" &&
      pairAnswer5 !== "" &&
      pairAnswer6 !== "" &&
      pairAnswer7 !== "" &&
      pairAnswer8 !== "" &&
      pairAnswerPair1 !== "" &&
      pairAnswerPair2 !== "" &&
      pairAnswerPair3 !== "" &&
      pairAnswerPair4 !== "" &&
      pairAnswerPair5 !== "" &&
      pairAnswerPair6 !== "" &&
      pairAnswerPair7 !== "" &&
      pairAnswerPair8 !== ""
        ? setDisabledButton(false)
        : setDisabledButton(true);
    } else if (question.answerCount === 7) {
      pairAnswer1 !== "" &&
      pairAnswer2 !== "" &&
      pairAnswer3 !== "" &&
      pairAnswer4 !== "" &&
      pairAnswer5 !== "" &&
      pairAnswer6 !== "" &&
      pairAnswer7 !== "" &&
      pairAnswerPair1 !== "" &&
      pairAnswerPair2 !== "" &&
      pairAnswerPair3 !== "" &&
      pairAnswerPair4 !== "" &&
      pairAnswerPair5 !== "" &&
      pairAnswerPair6 !== "" &&
      pairAnswerPair7 !== ""
        ? setDisabledButton(false)
        : setDisabledButton(true);
    } else if (question.answerCount === 6) {
      pairAnswer1 !== "" &&
      pairAnswer2 !== "" &&
      pairAnswer3 !== "" &&
      pairAnswer4 !== "" &&
      pairAnswer5 !== "" &&
      pairAnswer6 !== "" &&
      pairAnswerPair1 !== "" &&
      pairAnswerPair2 !== "" &&
      pairAnswerPair3 !== "" &&
      pairAnswerPair4 !== "" &&
      pairAnswerPair5 !== "" &&
      pairAnswerPair6 !== ""
        ? setDisabledButton(false)
        : setDisabledButton(true);
    } else if (question.answerCount === 5) {
      pairAnswer1 !== "" &&
      pairAnswer2 !== "" &&
      pairAnswer3 !== "" &&
      pairAnswer4 !== "" &&
      pairAnswer5 !== "" &&
      pairAnswerPair1 !== "" &&
      pairAnswerPair2 !== "" &&
      pairAnswerPair3 !== "" &&
      pairAnswerPair4 !== "" &&
      pairAnswerPair5 !== ""
        ? setDisabledButton(false)
        : setDisabledButton(true);
    } else if (question.answerCount === 4) {
      pairAnswer1 !== "" &&
      pairAnswer2 !== "" &&
      pairAnswer3 !== "" &&
      pairAnswer4 !== "" &&
      pairAnswerPair1 !== "" &&
      pairAnswerPair2 !== "" &&
      pairAnswerPair3 !== "" &&
      pairAnswerPair4 !== ""
        ? setDisabledButton(false)
        : setDisabledButton(true);
    } else if (question.answerCount >= 3) {
      pairAnswer1 !== "" &&
      pairAnswer2 !== "" &&
      pairAnswer3 !== "" &&
      pairAnswerPair1 !== "" &&
      pairAnswerPair2 !== "" &&
      pairAnswerPair3 !== ""
        ? setDisabledButton(false)
        : setDisabledButton(true);
    } else if (question.answerCount >= 2) {
      pairAnswer1 !== "" &&
      pairAnswer2 !== "" &&
      pairAnswerPair1 !== "" &&
      pairAnswerPair2 !== ""
        ? setDisabledButton(false)
        : setDisabledButton(true);
    }
  }, [
    question?.answerCount,
    question?.questionType,
    pairAnswerPair1,
    pairAnswerPair2,
    pairAnswerPair3,
    pairAnswerPair4,
    pairAnswerPair5,
    pairAnswerPair6,
    pairAnswerPair7,
    pairAnswerPair8,
    pairAnswer1,
    pairAnswer2,
    pairAnswer3,
    pairAnswer4,
    pairAnswer5,
    pairAnswer6,
    pairAnswer7,
    pairAnswer8,
  ]);

  const closeModal = () => {
    setModalVisible(false);
    setPairAnswer1("");
    setPairAnswer2("");
    setPairAnswer3("");
    setPairAnswer4("");
    setPairAnswer5("");
    setPairAnswer6("");
    setPairAnswer7("");
    setPairAnswer8("");
    setPairAnswerPair1("");
    setPairAnswerPair2("");
    setPairAnswerPair3("");
    setPairAnswerPair4("");
    setPairAnswerPair5("");
    setPairAnswerPair6("");
    setPairAnswerPair7("");
    setPairAnswerPair8("");
    setModify(false);
    form.resetFields();
  };

  const functionModifyQuestionWithAnswer = async () => {
    const formData = new FormData();
    formData.append("questionId", question.id);
    const answers = [
      {
        id: propAnswers[0]?.id,
        pairId: propAnswers[1]?.id,
        pairAnswer: pairAnswer1,
        pairAnswerPair: pairAnswerPair1,
      },
      {
        id: propAnswers[2]?.id,
        pairId: propAnswers[3]?.id,
        pairAnswer: pairAnswer2,
        pairAnswerPair: pairAnswerPair2,
      },
      {
        id: propAnswers[4]?.id,
        pairId: propAnswers[5]?.id,
        pairAnswer: pairAnswer3,
        pairAnswerPair: pairAnswerPair3,
      },
      {
        id: propAnswers[6]?.id,
        pairId: propAnswers[7]?.id,
        pairAnswer: pairAnswer4,
        pairAnswerPair: pairAnswerPair4,
      },
      {
        id: propAnswers[8]?.id,
        pairId: propAnswers[9]?.id,
        pairAnswer: pairAnswer5,
        pairAnswerPair: pairAnswerPair5,
      },
      {
        id: propAnswers[10]?.id,
        pairId: propAnswers[11]?.id,
        pairAnswer: pairAnswer6,
        pairAnswerPair: pairAnswerPair6,
      },
      {
        id: propAnswers[12]?.id,
        pairId: propAnswers[13]?.id,
        pairAnswer: pairAnswer7,
        pairAnswerPair: pairAnswerPair7,
      },
      {
        id: propAnswers[14]?.id,
        pairId: propAnswers[15]?.id,
        pairAnswer: pairAnswer8,
        pairAnswerPair: pairAnswerPair8,
      },
    ];
    formData.append("answers", JSON.stringify(answers));

    await dispatch(QuestionActions.modifyQuestionAnswers(formData));
    setChecked(true);
  };

  useEffect(() => {
    if (modify) {
      setPairAnswer1(propAnswers[0]?.description);
      setPairAnswerPair1(propAnswers[1]?.description);
      setPairAnswer2(propAnswers[2]?.description);
      setPairAnswerPair2(propAnswers[3]?.description);
      setPairAnswer3(propAnswers[4]?.description);
      setPairAnswerPair3(propAnswers[5]?.description);
      setPairAnswer4(propAnswers[6]?.description);
      setPairAnswerPair4(propAnswers[7]?.description);
      setPairAnswer5(propAnswers[8]?.description);
      setPairAnswerPair5(propAnswers[9]?.description);
      setPairAnswer6(propAnswers[10]?.description);
      setPairAnswerPair6(propAnswers[11]?.description);
      setPairAnswer7(propAnswers[12]?.description);
      setPairAnswerPair7(propAnswers[13]?.description);
      setPairAnswer8(propAnswers[14]?.description);
      setPairAnswerPair8(propAnswers[15]?.description);
      form.setFieldsValue({
        pair1: propAnswers[0]?.description,
        answer1: propAnswers[1]?.description,
        pair2: propAnswers[2]?.description,
        answer2: propAnswers[3]?.description,
        pair3: propAnswers[4]?.description,
        answer3: propAnswers[5]?.description,
        pair4: propAnswers[6]?.description,
        answer4: propAnswers[7]?.description,
        pair5: propAnswers[8]?.description,
        answer5: propAnswers[9]?.description,
        pair6: propAnswers[10]?.description,
        answer6: propAnswers[11]?.description,
        pair7: propAnswers[12]?.description,
        answer7: propAnswers[13]?.description,
        pair8: propAnswers[14]?.description,
        answer8: propAnswers[15]?.description,
      });
    }
  }, [modify, propAnswers, form]);

  return (
    <div>
      <Modal
        visible={modalVisible}
        onCancel={() => closeModal()}
        maskClosable={false}
        title={modify ? "Módosítás" : "Létrehozás"}
        forceRender
        centered
        footer={[
          <Button
            key="createTextTextPair"
            type="primary"
            disabled={disabledButton}
            onClick={() =>
              modify
                ? functionModifyQuestionWithAnswer()
                : functionCreateQuestionWithAnswer()
            }
          >
            {modify ? "Módosítás" : "Létrehozás"}
          </Button>,
          <Button key="cancel" onClick={() => closeModal()}>
            Mégsem
          </Button>,
        ]}
        width={"70em"}
      >
        <Form
          form={form}
          name="register"
          scrollToFirstError
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 20 }}
        >
          <TextTextPairSchema
            id={"answer1"}
            pairId={"pair1"}
            pairAnswerPair={pairAnswerPair1}
            setPairAnswerPair={setPairAnswerPair1}
            pairAnswer={pairAnswer1}
            setPairAnswer={setPairAnswer1}
            title={"Első kérdés-válasz pár"}
          />
          <TextTextPairSchema
            id={"answer2"}
            pairId={"pair2"}
            pairAnswerPair={pairAnswerPair2}
            setPairAnswerPair={setPairAnswerPair2}
            pairAnswer={pairAnswer2}
            setPairAnswer={setPairAnswer2}
            title={"Második kérdés-válasz pár"}
          />
          {question?.answerCount >= 3 && (
            <TextTextPairSchema
              id={"answer3"}
              pairId={"pair3"}
              pairAnswerPair={pairAnswerPair3}
              setPairAnswerPair={setPairAnswerPair3}
              pairAnswer={pairAnswer3}
              setPairAnswer={setPairAnswer3}
              title={"Harmadik kérdés-válasz pár"}
            />
          )}
          {question?.answerCount >= 4 && (
            <TextTextPairSchema
              id={"answer4"}
              pairId={"pair4"}
              pairAnswerPair={pairAnswerPair4}
              setPairAnswerPair={setPairAnswerPair4}
              pairAnswer={pairAnswer4}
              setPairAnswer={setPairAnswer4}
              title={"Negyedik kérdés-válasz pár"}
            />
          )}
          {question?.answerCount >= 5 && (
            <TextTextPairSchema
              id={"answer5"}
              pairId={"pair5"}
              pairAnswerPair={pairAnswerPair5}
              setPairAnswerPair={setPairAnswerPair5}
              pairAnswer={pairAnswer5}
              setPairAnswer={setPairAnswer5}
              title={"Ötödik kérdés-válasz pár"}
            />
          )}
          {question?.answerCount >= 6 && (
            <TextTextPairSchema
              id={"answer6"}
              pairId={"pair6"}
              pairAnswerPair={pairAnswerPair6}
              setPairAnswerPair={setPairAnswerPair6}
              pairAnswer={pairAnswer6}
              setPairAnswer={setPairAnswer6}
              title={"Hatodik kérdés-válasz pár"}
            />
          )}
          {question?.answerCount >= 7 && (
            <TextTextPairSchema
              id={"answer7"}
              pairId={"pair7"}
              pairAnswerPair={pairAnswerPair7}
              setPairAnswerPair={setPairAnswerPair7}
              pairAnswer={pairAnswer7}
              setPairAnswer={setPairAnswer7}
              title={"Hetedik kérdés-válasz pár"}
            />
          )}
          {question?.answerCount === 8 && (
            <TextTextPairSchema
              id={"answer8"}
              pairId={"pair8"}
              pairAnswerPair={pairAnswerPair8}
              setPairAnswerPair={setPairAnswerPair8}
              pairAnswer={pairAnswer8}
              setPairAnswer={setPairAnswer8}
              title={"Nyolcadik kérdés-válasz pár"}
            />
          )}
        </Form>
      </Modal>
    </div>
  );
}
