import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Popconfirm, Space, Table } from "antd";
import { useDispatch } from "react-redux";
import AudioActions from "../../../actions/audio";
import { AudioT } from "../../../constants/ActionTypes/audio-action-types";
import type { ColumnType } from "antd/es/table";
import { downloadContent } from "./audio";
import { useReducer, useRef, useState } from "react";
import type { InputRef } from "antd";
import { BookT } from "../../../constants/ActionTypes/book-action-types";
import reducer, { initialState } from "./reducer";
import {
  useBookIdChange,
  useBookSelectedKeysChange,
  useBookTargetKeysChange,
  useFileChange,
  useImageUrlChange,
  useNameChange,
  useSearchedColumnChange,
  useSelectedIdChange,
} from "./auidoUtils";
import AudioModal from "./modal";

const AudioTable = ({
  form,
  getAudios,
  setModalVisible,
  propAudio,
  loaded,
  filteredAudios,
  onSearch,
  modalVisible,
}: any) => {
  const dispatch = useDispatch();
  const searchInput = useRef<InputRef>(null);
  const [modify, setModify] = useState<boolean>(() => false);

  const [state, reducerDisptatch] = useReducer(reducer, initialState);
  const selectedIdChange = useSelectedIdChange(reducerDisptatch);
  const nameChange = useNameChange(reducerDisptatch);
  const bookIdChange = useBookIdChange(reducerDisptatch);
  const bookTargetKeysChange = useBookTargetKeysChange(reducerDisptatch);
  const searchedColumnChange = useSearchedColumnChange(reducerDisptatch);
  const fileChange = useFileChange(reducerDisptatch);
  const imageUrlChange = useImageUrlChange(reducerDisptatch);
  const bookSelectedKeysChange = useBookSelectedKeysChange(reducerDisptatch);
  const {
    searchColumn,
    name,
    file,
    imageUrl,
    bookId,
    selectedId,
    bookTargetKeys,
    bookSelectedKeys,
  } = state;

  const functionDeleteAudio = async (record: AudioT) => {
    await dispatch(AudioActions.deleteAudio(record.id));
    getAudios();
  };

  const showModifyModal = async (record: AudioT) => {
    form.setFieldsValue({
      name: record?.name,
      book: record.book,
    });
    setModalVisible(true);
    setModify(true);
    selectedIdChange(record.id);
    nameChange(record?.name);
    bookIdChange(record.book[record.book.length - 1].id);
    bookTargetKeysChange(record.book?.map((b: BookT) => b.id.toString()));
  };

  type DataIndex = keyof AudioT;
  const handleSearch = (
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    searchedColumnChange(dataIndex);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    title: string
  ): ColumnType<AudioT> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="searchPadding">
        <Input
          ref={searchInput}
          placeholder={`Keress ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm, dataIndex)}
          className="searchInput"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            className="searchButton"
          >
            Keresés
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              handleSearch(confirm, dataIndex);
            }}
            size="small"
            className="searchButton"
          >
            Visszaállítás
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) => (searchColumn === dataIndex ? text : text),
  });

  const columns = [
    {
      title: "Audió neve",
      key: "name",
      dataIndex: "name",
      ...getColumnSearchProps("name", "nevet"),
      sorter: (a: AudioT, b: AudioT) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Eredeti név",
      key: "originalName",
      dataIndex: "originalName",
      ...getColumnSearchProps("originalName", "eredeti nevet"),
    },
    // NINCS ELDÖNTVE HOGY KELL E NEKIK
    // {
    //   title: "Audió mérete",
    //   key: "fileSize",
    //   render: (text: string, record: AudioT) => {
    //     return `${record.fileSize} KB`;
    //   },
    // },
    {
      title: "Könyv",
      key: "book",
      render: (text: string, record: AudioT) => {
        const arr = record.book
          ?.map((b) => {
            if (!b.deleted) return b.title + ", ";
          })
          .filter((e) => e !== undefined);

        if (arr.length > 4) {
          return arr.slice(0, 4) + "...";
        } else {
          return arr;
        }
      },
    },
    {
      title: "Létrehozva",
      key: "created",
      render: (text: string, record: AudioT) => {
        return new Date(record.created).toLocaleDateString();
      },
    },
    {
      title: "Letöltés",
      key: "download",
      render: (text: string, record: AudioT) => {
        return (
          <Space size="middle">
            <Button onClick={() => downloadContent(record)}>
              <DownloadOutlined />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Módosítás",
      key: "modify",
      render: (text: string, record: AudioT) => {
        return (
          <Space size="middle">
            <Button onClick={() => showModifyModal(record)}>
              <EditOutlined />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Törlés",
      key: "delete",
      render: (text: string, record: AudioT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan törölni akarod ezt a kontentet?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => functionDeleteAudio(record)}
            >
              <Button type="primary" danger>
                <DeleteOutlined key="delete" />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={onSearch ? filteredAudios : propAudio}
        loading={!loaded && !propAudio}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          showSizeChanger: false,
        }}
      />
      <AudioModal
        form={form}
        modalVisible={modalVisible}
        modify={modify}
        name={name}
        setName={nameChange}
        setFile={fileChange}
        setImageUrl={imageUrlChange}
        file={file}
        imageUrl={imageUrl}
        bookId={bookId}
        setModalVisible={setModalVisible}
        setModify={setModify}
        setSelectedId={selectedIdChange}
        setBookId={bookIdChange}
        setBooksTargetKeys={bookTargetKeysChange}
        selectedId={selectedId}
        booksTargetKeys={bookTargetKeys}
        getAudios={getAudios}
        setBooksSelectedKeys={bookSelectedKeysChange}
        booksSelectedKeys={bookSelectedKeys}
      />
    </div>
  );
};
export default AudioTable;
