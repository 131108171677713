import { useCallback } from "react";
import { KEYWORDS_CATEGORY_ACTIONS } from "../../../constants/ActionTypes/ReducerActionTypes/reducer-action-types";
import { initialState } from "./reducer";
export type AdminState = typeof initialState;

export const useNameChange = (dispatch: any) => {
  const nameChange = useCallback(
    (value: string) => {
      dispatch({ type: KEYWORDS_CATEGORY_ACTIONS.SET_NAME, name: value });
    },
    [dispatch]
  );

  return nameChange;
};

export const useSelectedIdChange = (dispatch: any) => {
  const selectedIdChange = useCallback(
    (value: number) => {
      dispatch({
        type: KEYWORDS_CATEGORY_ACTIONS.SET_SELECTED_ID,
        selectedId: value,
      });
    },
    [dispatch]
  );

  return selectedIdChange;
};
