import { routes } from "../constants/routes";
import ProtectedRoute from "../components/protectedRoute/protected-route";
import { Route, Switch } from "react-router";
import MissingRight from "./missing-right";
import { Socket } from "socket.io-client";

interface so {
  socket: Socket;
}
export default function Body(socket: so) {
  return (
    <div>
      <Switch>
        {routes.map((current) => {
          return (
            <ProtectedRoute
              socket={socket.socket}
              path={current.path}
              component={current.component}
              key={current.path}
            />
          );
        })}
        <Route path="/raabe-klett/missing-right" component={MissingRight} />
      </Switch>
    </div>
  );
}
