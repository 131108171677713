import { useEffect, useState } from "react";
import { Form, Input, Button, Col, Row, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import UserActions from "../../actions/users";
import HttpService from "../../services/http";
import LoginActions from "../../actions/login";
import { RootStore } from "../../store/store";
import "../formating/content.css"

const formItemLayout = {
  labelCol: {
    xs: {
      span: 4,
    },
    sm: {
      span: 4, 
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};
export default function Profile() {
  const dispatch = useDispatch();
  const token = useSelector((state: RootStore) => state.loginReducer.token);
  const user = useSelector((state: RootStore) => state.loginReducer.user);
  const saveStatus = useSelector(
    (state: RootStore) => state.usersReducer.saveStatus
  );

  const [email, setEmail] = useState<string>(() => "");
  const [password, setPassword] = useState<string>(() => "");
  const [name, setName] = useState<string>(() => "");

  useEffect(() => {
    setEmail(email);
  }, [email]);

  const refreshFormAndUser = async () => {
    await dispatch(LoginActions.refreshUser(user!.id));
    setEmail(user!.email);
    setPassword("");
    HttpService.setAxiosDefaults(token);
  };

  const submitForm = async () => {
    const toSend = {
      id: user!.id,
      email: email,
      password: password,
      name: name,
    };
    await dispatch(UserActions.modifyProfileUser(toSend));
    if (saveStatus) {
      message.success("Siekres mentés", 5);
      await refreshFormAndUser();
    } else {
      message.error("Sikertelen mentés", 5);
    }
  };

  const validateMessage = "Nem egyezik meg a két jelszó!";

  return (
    <div className="content">
      <Row>
        <Col span={18} className="colPadding">
          <h1 className="title">
            <strong>Profil</strong>
          </h1>
          <Form
            {...formItemLayout}
            name="register"
            onFinish={submitForm}
            scrollToFirstError
          >
            <Form.Item name="name" label="Név: ">
              <Input
                placeholder={user.name}
                style={{ width: "30em" }}
                name="name"
                value={name}
                maxLength={255}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="E-mail cím: "
              rules={[
                {
                  required: true,
                  message: "Mező kitöltése kötelező!",
                },
              ]}
            >
              <Input
                style={{ width: "30em" }}
                name="email"
                value={email}
                maxLength={255}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Jelszó: "
              rules={[
                {
                  required: false,
                  message: "Mező kitöltése kötelező!",
                },
                {
                  pattern: /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)).{8,}$/,
                  message:
                    "A jelszónak tartalmaznia kell kis- és nagybetűt és számot!",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                style={{ width: "30em" }}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Jelszó megerősítés: "
              name="confirm"
              hasFeedback
              dependencies={["password"]}
              rules={[
                {
                  required: password.length > 0,
                  message: "Mező kitöltése kötelező!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(validateMessage);
                  },
                }),
              ]}
            >
              <Input.Password
                style={{ width: "30em" }}
                name="confirm"
                type="password"
              />
            </Form.Item>
            <Form.Item>
              <Button
                style={{ position: "relative", left: "25em" }}
                htmlType="submit"
                type="primary"
              >
                Mentés
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
