import axios from "axios";
import { Dispatch } from "redux";
import { PictureDispatchTypes } from "../constants/ActionTypes/picture-action-types";

const getAllPicture = (): any => {
  return async (dispatch: Dispatch<PictureDispatchTypes>) => {
    dispatch({ type: "PICTURES_LOADING" });
    await axios
      .get("/1.0.0/picture", {})
      .then((response) => {
        dispatch({
          type: "PICTURES_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "PICTURES_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const getAllDeletedPicture = (): any => {
  return async (dispatch: Dispatch<PictureDispatchTypes>) => {
    dispatch({ type: "DELETED_PICTURES_LOADING" });
    await axios
      .get("/1.0.0/picture/trash", {})
      .then((response) => {
        dispatch({
          type: "DELETED_PICTURES_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DELETED_PICTURES_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const createPicture = (params: FormData): any => {
  return async (dispatch: Dispatch<PictureDispatchTypes>) => {
    dispatch({ type: "PICTURE_REGISTER_START" });
    await axios
      .post("/1.0.0/picture/create", params, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch({
          type: "PICTURE_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "PICTURE_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const modifyPictureName = (params: object): any => {
  return async (dispatch: Dispatch<PictureDispatchTypes>) => {
    dispatch({ type: "PICTURE_REGISTER_START" });
    await axios
      .put("/1.0.0/picture/modify/", params, {})
      .then((response) => {
        dispatch({
          type: "PICTURE_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "PICTURE_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const restorePicture = (id: string): any => {
  return async (dispatch: Dispatch<PictureDispatchTypes>) => {
    dispatch({ type: "PICTURE_REGISTER_START" });
    await axios
      .put("/1.0.0/picture/restore/" + id, {})
      .then((response) => {
        dispatch({
          type: "PICTURE_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "PICTURE_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const addPicture = (params: object): any => {
  return async (dispatch: Dispatch<PictureDispatchTypes>) => {
    dispatch({ type: "PICTURE_ADD_START" });
    await axios
      .post("/1.0.0/book/add-picture", params, {})
      .then((response) => {
        dispatch({
          type: "PICTURE_ADD_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "PICTURE_ADD_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const deletePicture = (id: number): any => {
  return async () => {
    await axios.delete("/1.0.0/picture/" + id).catch((err) => {
      console.log(err.message);
    });
  };
};

const PictureActions = {
  getAllPicture,
  createPicture,
  modifyPictureName,
  addPicture,
  deletePicture,
  getAllDeletedPicture,
  restorePicture,
};

export default PictureActions;
