import { Tabs } from "antd";
import Login from "./login";
import logo from "../../static/klett.png";
import "./login.scss";
import ForgotPassword from "./forgotpassword";
import "../formating/content.css";
const { TabPane } = Tabs;

export default function LoginMain() {
  return (
    <div className="loginPagePadding">
      <div className="loginPageLogoPositioning">
        <div className="login-page-icon-container">
          <img src={logo} alt="logo" className="login-page-icon" />
        </div>
        <Tabs defaultActiveKey="1">
          <TabPane key="1" tab={"Bejelentkezés"}>
            <Login />
          </TabPane>
          <TabPane key="2" tab={"Elfelejtett jelszó"}>
            <ForgotPassword />
          </TabPane>
        </Tabs>
      </div>

      <div className="allrightsreservedStyle">
        <p>Minden jog fenntartva! &#169; 2022 Raabe Klett</p>
      </div>
    </div>
  );
}
