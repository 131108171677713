import { Form, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect } from "react";
import CharacterCounter from "../../../../constants/characterCounter";
import DebounceFunc from "../../../../constants/debounce";

export default function TextSequenceSchema({
  id,
  pairAnswerPair,
  setPairAnswerPair,
  sequenceId,
  sequnce,
  setSequence,
  arr,
  title,
  selectedSequences,
  setForceRefresh,
  forceRefresh,
}: any) {
  useEffect(() => {
    if (forceRefresh) {
      setForceRefresh(false);
    }
  }, [forceRefresh]);

  return (
    <Form.Item
      name="answer1"
      label={title}
      style={{
        position: "relative",
        width: "45em",
        marginRight: "auto",
        marginLeft: "auto",
      }}
      rules={[
        {
          required: true,
          message: "",
        },
      ]}
    >
      <Form.Item
        name={id}
        style={{
          display: "inline-block",
          width: "calc(80% - 4em)",
        }}
        rules={[
          {
            required: true,
            message: "Mező kitöltése kötelező!",
          },
        ]}
      >
        <TextArea
          name={id}
          value={pairAnswerPair}
          maxLength={100}
          onChange={(e) => setPairAnswerPair(e.target.value)}
        />
        <CharacterCounter max={100} taken={pairAnswerPair.length} />
      </Form.Item>
      <Form.Item
        name={sequenceId}
        style={{
          display: "inline-block",
          width: "calc(20% - 4em)",
          margin: "0 4em",
        }}
      >
        <Select
          style={{ width: "5em", marginTop: "1em" }}
          value={sequnce}
          onChange={(event) => setSequence(event)}
        >
          {arr?.map((v: number) => {
            return (
              <Select.Option
                key={v}
                value={v}
                style={
                  selectedSequences?.includes(v)
                    ? { backgroundColor: "#e6e6e6" }
                    : { backgroundColor: "white" }
                }
              >
                {v}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </Form.Item>
  );
}
