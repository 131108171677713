import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import CharacterCounter from "../../../../constants/characterCounter";
import DebounceFunc from "../../../../constants/debounce";

export default function TextTextPairSchema({
  id,
  pairId,
  pairAnswerPair,
  setPairAnswerPair,
  pairAnswer,
  setPairAnswer,
  title,
}: any) {
  return (
    <Form.Item
      name="answer1"
      label={title}
      rules={[
        {
          required: true,
          message: "",
        },
      ]}
    >
      <Form.Item
        name={id}
        style={{
          display: "inline-block",
          width: "calc(50% - 4em)",
        }}
        rules={[
          {
            required: true,
            message: "Mező kitöltése kötelező!",
          },
        ]}
      >
        <TextArea
          name={id}
          value={pairAnswerPair}
          maxLength={100}
          onChange={(e) => setPairAnswerPair(e.target.value)}
        />
        <CharacterCounter max={100} taken={pairAnswerPair?.length} />
      </Form.Item>
      <Form.Item
        name={pairId}
        label=""
        style={{
          display: "inline-block",
          width: "calc(50% - 4em)",
          margin: "0 4em",
        }}
      >
        <TextArea
          name={pairId}
          value={pairAnswer}
          maxLength={100}
          onChange={(e) => setPairAnswer(e.target.value)}
        />
        <CharacterCounter max={100} taken={pairAnswer?.length} />
      </Form.Item>
    </Form.Item>
  );
}
