import axios from "axios";
import { Dispatch } from "redux";
import { VideoDispatchTypes } from "../constants/ActionTypes/video-action-types";

const getAllVideo = (): any => {
  return async (dispatch: Dispatch<VideoDispatchTypes>) => {
    dispatch({ type: "VIDEOS_LOADING" });
    await axios
      .get("/1.0.0/video", {})
      .then((response) => {
        dispatch({
          type: "VIDEOS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "VIDEOS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const getAllDeletedVideo = (): any => {
  return async (dispatch: Dispatch<VideoDispatchTypes>) => {
    dispatch({ type: "DELETED_VIDEOS_LOADING" });
    await axios
      .get("/1.0.0/video/trash", {})
      .then((response) => {
        dispatch({
          type: "DELETED_VIDEOS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DELETED_VIDEOS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const uploadVideo = (params: FormData): any => {
  return async (dispatch: Dispatch<VideoDispatchTypes>) => {
    dispatch({ type: "VIDEO_REGISTER_START" });
    await axios
      .post("/1.0.0/video/create", params, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch({
          type: "VIDEO_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "VIDEO_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const modifyVideoName = (params: object): any => {
  return async (dispatch: Dispatch<VideoDispatchTypes>) => {
    dispatch({ type: "VIDEO_REGISTER_START" });
    await axios
      .put("/1.0.0/video/modify", params, {})
      .then((response) => {
        dispatch({
          type: "VIDEO_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "VIDEO_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const restoreVideo = (id: string): any => {
  return async (dispatch: Dispatch<VideoDispatchTypes>) => {
    dispatch({ type: "VIDEO_REGISTER_START" });
    await axios
      .put("/1.0.0/video/restore/" + id, {})
      .then((response) => {
        dispatch({
          type: "VIDEO_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "VIDEO_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const deleteVideo = (id: number): any => {
  return async () => {
    await axios.delete("/1.0.0/video/" + id).catch((err) => {
      console.log(err.message);
    });
  };
};

const VideoActions = {
  getAllVideo,
  uploadVideo,
  modifyVideoName,
  deleteVideo,
  getAllDeletedVideo,
  restoreVideo,
};

export default VideoActions;
