import { Button, Form, message, Modal, Switch } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QuestionActions from "../../../actions/question";
import { RootStore } from "../../../store/store";

export default function TrueFalse({
  question,
  modify,
  setModify,
  setModalVisible,
  modalVisible,
  checked,
  setChecked,
}: any) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propAnswers = useSelector(
    (state: RootStore) => state.answerReducer.answers
  );
  const propSaveStatus = useSelector(
    (state: RootStore) => state.answerReducer.saveStatus
  );
  const [isTrue, setIsTrue] = useState<boolean>(() => false);

  const functionCreateQuestionWithAnswer = async () => {
    const formData = new FormData();
    formData.append("questionId", question.id);
    if (question.questionType === 3) {
      const answer = {
        isTrue: isTrue,
      };
      formData.append("answers", JSON.stringify(answer));
    }
    await dispatch(QuestionActions.createQuestionAnswers(formData));
    setChecked(true);
  };

  useEffect(() => {
    const fetch = () => {
      if (propSaveStatus && checked) {
        message.success("Sikeres mentés");
        setChecked(false);
        setModalVisible(false);
      } else if (!propSaveStatus && checked) {
        setChecked(false);
        message.error("Hiba az feladat mentése közben!");
      }
    };
    fetch();
  }, [checked, propSaveStatus, setChecked, setModalVisible]);

  const closeModal = () => {
    setModalVisible(false);
    setIsTrue(false);
    setModify(false);
    form.resetFields();
  };

  const functionModifyQuestionWithAnswer = async () => {
    const formData = new FormData();
    formData.append("questionId", question.id);
    const answer = {
      isTrue: isTrue,
      id: propAnswers[0].id,
    };
    formData.append("answers", JSON.stringify(answer));
    await dispatch(QuestionActions.modifyQuestionAnswers(formData));
    setChecked(true);
  };

  useEffect(() => {
    if (modify) {
      setIsTrue(propAnswers[0]?.isGood);
      form.setFieldsValue({
        checkbox1: propAnswers[0]?.isGood,
      });
    }
  }, [modify, propAnswers,form]);

  return (
    <Modal
      visible={modalVisible}
      onCancel={closeModal}
      maskClosable={false}
      title={modify ? "Módosítás" : "Létrehozás"}
      forceRender
      centered
      footer={[
        <Button
          key="createTrueFalse"
          type="primary"
          onClick={() =>
            modify
              ? functionModifyQuestionWithAnswer()
              : functionCreateQuestionWithAnswer()
          }
        >
          {modify ? "Módosítás" : "Létrehozás"}
        </Button>,
        <Button key="cancel" onClick={closeModal}>
          Mégsem
        </Button>,
      ]}
      width={"70em"}
    >
      <Form
        form={form}
        name="register"
        scrollToFirstError
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 20 }}
      >
        <Form.Item
          name="question"
          label="Kérdés"
          style={{ padding: "0.5em" }}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Form.Item
            name="question"
            style={{ display: "inline-block", width: "calc(70% - 4em)" }}
          >
            <div style={{ fontSize: "1.5em" }}>
              <strong>{question?.question}</strong>
            </div>
          </Form.Item>
          <Form.Item
            name="checkbox1"
            style={{
              display: "inline-block",
              width: "calc(30% - 4em)",
              margin: "0 4em",
            }}
          >
            <Switch onChange={(e) => setIsTrue(e)} checked={isTrue} />
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
}
