import axios from "axios";
import { Dispatch } from "redux";
import { NotificationDispatchTypes } from "../constants/ActionTypes/notification-action-types";

const getNotification = (): any => {
  return async (dispatch: Dispatch<NotificationDispatchTypes>) => {
    dispatch({ type: "NOTIFICATIONS_LOADING" });
    await axios
      .get("/1.0.0/notification", {})
      .then((response) => {
        dispatch({
          type: "NOTIFICATIONS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "NOTIFICATIONS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const createCategory = (params: FormData): any => {
  return async (dispatch: Dispatch<NotificationDispatchTypes>) => {
    dispatch({ type: "NOTIFICATION_REGISTER_START" });
    await axios
      .post("/1.0.0/notification/create", params, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch({
          type: "NOTIFICATION_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "NOTIFICATION_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const sendPushNotificationToAllUser = (params: FormData): any => {
  return async (dispatch: Dispatch<NotificationDispatchTypes>) => {
    dispatch({ type: "PUSH_NOTIFICATION_REGISTER_START" });
    await axios
      .post("/1.0.0/notification/push", params, {})
      .then((response) => {
        dispatch({
          type: "PUSH_NOTIFICATION_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "PUSH_NOTIFICATION_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const NotificationActions = {
  getNotification,
  createCategory,
  sendPushNotificationToAllUser,
};
export default NotificationActions;
