import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Transfer,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import BookActions from "../../../actions/book";
import { BookTypeT } from "../../../constants/ActionTypes/book-type-action-types";
import DebounceFunc from "../../../constants/debounce";
import { layout } from "../../../constants/layout";
import {
  handleChange,
  removePictureFile,
  UploadButton,
} from "../../../constants/pictureUpload";
import { RootStore } from "../../../store/store";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { useEffect, useState } from "react";
import type { UploadChangeParam } from "antd/es/upload";

const BookModal = ({
  modalVisible,
  modify,
  closeModal,
  getBooks,
  form,
  imageUrl,
  keywordsTargetKeys,
  keywordsTargetKeysChange,
  imageUrlChange,
  fileChange,
  file,
  name,
  description,
  creators,
  barcode,
  purchaseUrl,
  selectedId,
  booktypeId,
  keywordsSelectedKeys,
  keywordsSelectedKeysChange,
  nameChange,
  descriptionChange,
  creatorsChange,
  barcodeChange,
  purchaseUrlChange,
  booktypeIdChange,
}: any) => {
  const propKeyword = useSelector(
    (state: RootStore) => state.keywordReducer.keyword
  );
  const propBookType = useSelector(
    (state: RootStore) => state.booktypeReducer.booktype
  );
  const saveStatus = useSelector(
    (state: RootStore) => state.bookReducer.saveStatus
  );
  const dispatch = useDispatch();

  const [checked, setChecked] = useState<boolean>(() => false);
  const [loading, setLoading] = useState<boolean>(() => false);
  const [disabled, setDisabled] = useState<boolean>(() => true);

  const functionCreateBook = async () => {
    const formData = new FormData();
    if (file) {
      const convertedFile = file as RcFile;
      formData.append("file", convertedFile);
      formData.append("title", name);
      formData.append("description", description);
      formData.append("creators", creators);
      formData.append("barcode", barcode);
      formData.append("booktypeId", booktypeId!.toString());
      formData.append("keywordIds", JSON.stringify(keywordsTargetKeys));
      if (purchaseUrl !== "") {
        formData.append("purchaseUrl", purchaseUrl);
      }
    }
    await dispatch(BookActions.createBook(formData));
    setChecked(true);
  };

  const beforeUpload = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      fileChange(null!);
      imageUrlChange("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            fileChange(null!);
            imageUrlChange("");
          }
        };
      };
    }
    return false;
  };

  const functionModifyBook = async () => {
    const formData = new FormData();
    if (file) {
      const convertedFile = file as RcFile;
      formData.append("file", convertedFile);
    }
    formData.append("id", selectedId.toString());
    formData.append("title", name);
    formData.append("description", description);
    formData.append("creators", creators);
    formData.append("barcode", barcode);
    formData.append("booktypeId", booktypeId!.toString());
    formData.append("keywordIds", JSON.stringify(keywordsTargetKeys));
    if (purchaseUrl !== "") {
      formData.append("purchaseUrl", purchaseUrl);
    }
    await dispatch(BookActions.modifyBook(formData));
    setChecked(true);
  };

  const handleChangeImage: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(info, setLoading, fileChange, imageUrlChange);
  };

  useEffect(() => {
    if (saveStatus && checked) {
      message.success("Sikeres mentés");
    } else if (!saveStatus && checked) {
      message.error("Hiba az könyv mentése közben!");
    }
    return () => {
      if (saveStatus && checked) {
        getBooks();
        closeModal();
      }
      setChecked(false);
    };
  });

  useEffect(() => {
    if (
      !name ||
      !barcode ||
      !description ||
      !creators ||
      !booktypeId ||
      !file ||
      keywordsTargetKeys.length === 0
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    name,
    barcode,
    description,
    creators,
    booktypeId,
    file,
    keywordsTargetKeys,
  ]);

  return (
    <Modal
      visible={modalVisible}
      onCancel={closeModal}
      title={modify ? "Módosítás" : "Létrehozás"}
      maskClosable={false}
      forceRender
      centered
      footer={[
        <Button
          disabled={disabled}
          key="createBook"
          type="primary"
          onClick={modify ? functionModifyBook : functionCreateBook}
        >
          {modify ? "Módosítás" : "Létrehozás"}
        </Button>,
        <Button key="cancel" onClick={closeModal}>
          Mégsem
        </Button>,
      ]}
      width={"70em"}
    >
      <Row>
        <Col span={24} className="colPadding">
          <Form
            {...layout}
            name="register"
            onFinish={modify ? functionModifyBook : functionCreateBook}
            scrollToFirstError
            form={form}
            style={{ marginLeft: "-7em" }}
          >
            <Form.Item
              name="name"
              label="Könyv címe"
              rules={[
                {
                  required: true,
                  message: "Mező kitöltése kötelező!",
                },
              ]}
            >
              <Input
                name="name"
                value={name}
                maxLength={255}
                onChange={(e) => DebounceFunc(e.target.value, nameChange)}
              />
            </Form.Item>
            <Form.Item
              name="barcode"
              label="Vonalkód"
              rules={[
                {
                  required: true,
                  message: "Mező kitöltése kötelező!",
                },
              ]}
            >
              <Input
                name="barcode"
                value={barcode}
                maxLength={255}
                onChange={(e) => DebounceFunc(e.target.value, barcodeChange)}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label="Leírás"
              rules={[
                {
                  required: true,
                  message: "Mező kitöltése kötelező!",
                },
              ]}
            >
              <TextArea
                name="description"
                value={description}
                onChange={(e) =>
                  DebounceFunc(e.target.value, descriptionChange)
                }
                rows={5}
              />
            </Form.Item>
            <Form.Item
              name="creators"
              label="Készítők"
              rules={[
                {
                  required: true,
                  message: "Mező kitöltése kötelező!",
                },
              ]}
            >
              <TextArea
                name="creators"
                value={creators}
                maxLength={255}
                onChange={(e) => DebounceFunc(e.target.value, creatorsChange)}
                rows={3}
              />
            </Form.Item>
            <Form.Item name="purchaseUrl" label="Vásárlói link">
              <Input
                name="purchaseUrl"
                value={purchaseUrl}
                maxLength={255}
                onChange={(e) =>
                  DebounceFunc(e.target.value, purchaseUrlChange)
                }
              />
            </Form.Item>
            <Form.Item
              name={""}
              label="Típusok"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Form.Item
                name="booktypes"
                style={{
                  display: "inline-block",
                }}
                rules={[
                  {
                    required: true,
                    message: "Mező kitöltése kötelező!",
                  },
                ]}
              >
                <Select
                  style={{ width: "20em" }}
                  value={booktypeId}
                  onChange={(event) => booktypeIdChange(event)}
                >
                  {propBookType.map((bookType: BookTypeT) => {
                    return (
                      <Select.Option key={bookType.id} value={bookType.id}>
                        {bookType.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="file"
                label="Képfeltöltés"
                style={{
                  position: "relative",
                  left: "25em",
                  bottom: "4em",
                  marginBottom: "-5em",
                  marginLeft: "5em",
                }}
                rules={[
                  {
                    required: true,
                    message: "Kérem adjon meg egy fájlt!",
                  },
                ]}
              >
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={beforeUpload}
                  onChange={handleChangeImage}
                  accept=".png,.jpg,.jpeg,.gif"
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <UploadButton loading={loading} />
                  )}
                </Upload>
                <Button
                  style={{ position: "absolute", left: "9em", top: "2.5em" }}
                  size="small"
                  danger
                  type="primary"
                  disabled={!file}
                  onClick={() => removePictureFile(fileChange, imageUrlChange)}
                >
                  <DeleteOutlined />
                </Button>
              </Form.Item>
            </Form.Item>
            <Form.Item
              name="keywords"
              label="Kulcsszavak"
              style={{ marginBottom: "-1em" }}
              rules={[
                {
                  required: true,
                  message: "Mező kitöltése kötelező!",
                },
              ]}
            >
              <Transfer
                style={{ textAlign: "left" }}
                dataSource={propKeyword}
                render={(item) => `${item.name} ${item.type}`}
                targetKeys={keywordsTargetKeys}
                selectedKeys={keywordsSelectedKeys}
                showSearch
                onChange={(nextTargetKeys: string[]) =>
                  keywordsTargetKeysChange(nextTargetKeys)
                }
                onSelectChange={(
                  sourceSelectedKeys: string[],
                  targetSelectedKeys: string[]
                ) =>
                  keywordsSelectedKeysChange([
                    ...sourceSelectedKeys,
                    ...targetSelectedKeys,
                  ])
                }
                rowKey={(record) => record.id.toString()}
                selectAllLabels={[<div></div>, <div></div>]}
                filterOption={(inputValue, item) =>
                  item.name.toLowerCase()!.includes(inputValue.toLowerCase()) ||
                  item.type.toLowerCase().includes(inputValue.toLowerCase())
                }
                locale={{
                  notFoundContent: "Üres",
                  selectAll: "Összes kiválasztása",
                  selectInvert: "Kiválasztás törlése",
                }}
                listStyle={{
                  width: 350,
                  height: 300,
                }}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
export default BookModal;
