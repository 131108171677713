import { Row, Col, Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../store/store";
import BookActions from "../../actions/book";
import NotificationActions from "../../actions/notifications";
import "../formating/content.css";
import NotificationTable from "./table";
import NotificationModal from "./modal";

export default function Notification() {
  const dispatch = useDispatch();
  const effectRan = useRef(false);

  const propNotification = useSelector(
    (state: RootStore) => state.notificationReducer.notification
  );
  const propStatus = useSelector(
    (state: RootStore) => state.notificationReducer.status
  );

  const propBook = useSelector((state: RootStore) => state.bookReducer.book);

  const [modalVisible, setModalVisible] = useState<boolean>(() => false);
  const [loaded, setLoaded] = useState<boolean>(() => false);

  const [refetch, setRefetch] = useState<boolean>(() => false);

  useEffect(() => {
    const fetchData = async () => {
      if (effectRan.current === false) {
        await dispatch(NotificationActions.getNotification());
        await dispatch(BookActions.getAllBook(true));
      }
    };
    if (refetch) {
      dispatch(NotificationActions.getNotification());
    }
    fetchData();
    if (propStatus) {
      setLoaded(true);
      setRefetch(false);
    }
    return () => {
      effectRan.current = true;
    };
  }, [dispatch, propStatus, refetch]);

  const showModal = () => {
    setModalVisible(true);
  };

  return (
    <div className="content">
      <Row>
        <Col span={24} className="colPadding">
          <h1 className="title">
            <strong>Üzenetek</strong>
          </h1>
          <Row>
            <Row>
              <Col span={30} className="colPadding">
                <Button type="primary" onClick={showModal}>
                  Létrehozás
                </Button>
              </Col>
            </Row>
          </Row>
          <Col span={24}>
            <NotificationTable
              propBook={propBook}
              propNotification={propNotification}
              loaded={loaded}
            />
          </Col>
        </Col>
      </Row>
      <NotificationModal
        setModalVisible={setModalVisible}
        modalVisible={modalVisible}
        propBook={propBook}
        setRefetch={setRefetch}
      />
    </div>
  );
}
