import {
  PictureDispatchTypes,
  PictureT,
} from "../constants/ActionTypes/picture-action-types";

export interface initialPictureState {
  picture: PictureT[];
  deletedPictures: PictureT[];
  status: boolean;
  message: string;
  saveStatus: boolean;
  addStatus: boolean;
}

const defaultState = {
  picture: [],
  deletedPictures: [],
  status: false,
  message: "",
  saveStatus: false,
  addStatus: false,
};

const pictureReducer = (
  state: initialPictureState = defaultState,
  action: PictureDispatchTypes
): initialPictureState => {
  switch (action.type) {
    case "PICTURES_LOADING":
      return Object.assign({}, state, {
        picture: [],
        status: false,
        message: "",
      });
    case "PICTURES_LOADED":
      return Object.assign({}, state, {
        picture: action.payload.pictures,
        status: true,
        message: "",
      });
    case "PICTURES_ERROR":
      return Object.assign({}, state, {
        picture: [],
        status: false,
        message: action.payload,
      });
    case "PICTURE_REGISTER_START":
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case "PICTURE_REGISTER_SUCCESS":
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case "PICTURE_REGISTER_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case "PICTURE_ADD_START":
      return Object.assign({}, state, {
        addStatus: false,
      });
    case "PICTURE_ADD_SUCCESS":
      return Object.assign({}, state, {
        addStatus: true,
      });
    case "PICTURE_ADD_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        addStatus: action.payload,
      });
    case "DELETED_PICTURES_LOADING":
      return Object.assign({}, state, {
        deletedPictures: [],
        status: false,
        message: "",
      });
    case "DELETED_PICTURES_LOADED":
      return Object.assign({}, state, {
        deletedPictures: action.payload.pictures,
        status: true,
        message: "",
      });
    case "DELETED_PICTURES_ERROR":
      return Object.assign({}, state, {
        deletedPictures: [],
        status: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default pictureReducer;
