import { useCallback } from "react";
import { ADMIN_ACTIONS } from "../../constants/ActionTypes/ReducerActionTypes/reducer-action-types";
import { initialState } from "./reducer";
export type AdminState = typeof initialState;

export const useEmailChange = (dispatch: any) => {
  const emailChange = useCallback(
    (value: string) => {
      dispatch({ type: ADMIN_ACTIONS.SET_EMAIL, email: value });
    },
    [dispatch]
  );

  return emailChange;
};

export const usePasswordChange = (dispatch: any) => {
  const passwordChange = useCallback(
    (value: string) => {
      dispatch({ type: ADMIN_ACTIONS.SET_PASSWORD, password: value });
    },
    [dispatch]
  );

  return passwordChange;
};

export const useNameChange = (dispatch: any) => {
  const nameChange = useCallback(
    (value: string) => {
      dispatch({ type: ADMIN_ACTIONS.SET_NAME, name: value });
    },
    [dispatch]
  );

  return nameChange;
};

export const useLoadedChange = (dispatch: any) => {
  const loadedChange = useCallback(
    (value: boolean) => {
      dispatch({ type: ADMIN_ACTIONS.SET_LOADED, loaded: value });
    },
    [dispatch]
  );

  return loadedChange;
};

export const useCheckedChange = (dispatch: any) => {
  const checkedChange = useCallback(
    (value: boolean) => {
      dispatch({ type: ADMIN_ACTIONS.SET_CHECKED, checked: value });
    },
    [dispatch]
  );

  return checkedChange;
};

export const useIsDeleteChange = (dispatch: any) => {
  const isDeleteChange = useCallback(
    (value: boolean) => {
      dispatch({ type: ADMIN_ACTIONS.SET_IS_DELETE, isDelete: value });
    },
    [dispatch]
  );

  return isDeleteChange;
};
