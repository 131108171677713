import { Form, Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";
import CharacterCounter from "../../../../constants/characterCounter";
import DebounceFunc from "../../../../constants/debounce";

export default function CheckBoxSchema({
  id,
  pairAnswerPair,
  setPairAnswerPair,
  setCheckboxAnswerIsGood,
  checkboxAnswerIsGood,
  title,
}: any) {
  return (
    <Form.Item
      name={title}
      label="Válasz"
      style={{
        padding: "0.5em",
      }}
      rules={[
        {
          required: true,
          message: "",
        },
      ]}
    >
      <Form.Item
        name={id}
        style={{
          display: "inline-block",
          width: "calc(70% - 4em)",
        }}
        rules={[
          {
            required: true,
            message: "Mező kitöltése kötelező!",
          },
        ]}
      >
        <TextArea
          name={id}
          value={pairAnswerPair}
          maxLength={100}
          onChange={(e) => setPairAnswerPair(e.target.value)}
        />
        <CharacterCounter max={100} taken={pairAnswerPair?.length} />
      </Form.Item>
      <Form.Item
        name="checkbox"
        style={{
          display: "inline-block",
          width: "calc(30% - 4em)",
          margin: "0 4em",
        }}
      >
        <Switch
          onChange={(e) => setCheckboxAnswerIsGood(e)}
          checked={checkboxAnswerIsGood}
        />
      </Form.Item>
    </Form.Item>
  );
}
