import { Button, Form, Input, Modal } from "antd";
const formItemLayout = {
  labelCol: {
    xs: {
      span: 10,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};
export default function AdminModal({
  modalVisible,
  closeModal,
  createAdmin,
  form,
  name,
  nameChange,
  email,
  emailChange,
  password,
  passwordChange,
  validateMessage,
}: any) {
  return (
    <Modal
      visible={modalVisible}
      onCancel={closeModal}
      maskClosable={false}
      title={"Létrehozás"}
      forceRender
      centered
      footer={[
        <Button key="createTask" type="primary" onClick={createAdmin}>
          {"Létrehozás"}
        </Button>,
        <Button key="cancel" onClick={closeModal}>
          Mégsem
        </Button>,
      ]}
      width={"60em"}
    >
      <Form
        form={form}
        {...formItemLayout}
        name="register"
        onFinish={createAdmin}
        scrollToFirstError
        style={{ marginLeft: "7em", marginRight: "auto" }}
      >
        <Form.Item
          name="name"
          label="Név: "
          rules={[
            {
              required: true,
              message: "Mező kitöltése kötelező!",
            },
          ]}
        >
          <Input
            style={{ maxWidth: "30em" }}
            name="name"
            value={name}
            maxLength={255}
            onChange={(e) => nameChange(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail cím: "
          rules={[
            {
              required: true,
              message: "Mező kitöltése kötelező!",
            },
          ]}
        >
          <Input
            style={{ maxWidth: "30em" }}
            name="email"
            value={email}
            maxLength={255}
            onChange={(e) => emailChange(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Jelszó: "
          rules={[
            {
              required: true,
              message: "Mező kitöltése kötelező!",
            },
            {
              pattern: /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)).{8,}$/,
              message:
                "A jelszónak tartalmaznia kell kis- és nagybetűt és számot!",
            },
          ]}
          hasFeedback
        >
          <Input.Password
            autoComplete="new-password"
            style={{ maxWidth: "30em" }}
            name="password"
            value={password}
            onChange={(e) => passwordChange(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="passwordcheck"
          label="Jelszó megerősítés: "
          dependencies={["password"]}
          rules={[
            {
              required: password.length > 0,
              message: "Mező kitöltése kötelező!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(validateMessage);
              },
            }),
          ]}
        >
          <Input.Password
            autoComplete="new-password"
            style={{ maxWidth: "30em" }}
            name="passwordcheck"
            type="password"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
