import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  message,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Input,
  Table,
  Space,
  Select,
  Popconfirm,
  InputNumber,
  Upload,
} from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QuestionActions from "../../actions/question";
import { QuestionT } from "../../constants/ActionTypes/question-action-types";

import { layout } from "../../constants/layout";
import {
  allQuestionType,
  AllQuestionTypeType,
} from "../../constants/questionTypes";
import { RootStore } from "../../store/store";
import WizzardDownloadTemplate from "../Wizzard/wizzardDownloadTemplate";
import Answer from "./questionAnswer";
import axios from "axios";
import * as XLSX from "xlsx";
import useWindowDimensions from "../../constants/sizecheck";
import "../formating/content.css";
import type { InputRef } from "antd";
import type { ColumnType } from "antd/es/table";
import CharacterCounter from "../../constants/characterCounter";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import {
  handleChange,
  removePictureFile,
  UploadButton,
} from "../../constants/pictureUpload";
import type { UploadChangeParam } from "antd/es/upload";

const { TextArea } = Input;

export default function Question({ taskQuestions }: any) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propQuestions = useSelector(
    (state: RootStore) => state.questionReducer.questions
  );
  const propSaveStatus = useSelector(
    (state: RootStore) => state.questionReducer.saveStatus
  );
  const [modalVisible, setModalVisible] = useState<boolean>(() => false);
  const [loaded, setLoaded] = useState<boolean>(() => false);
  const [question, setQuestion] = useState<string>(() => "");
  const [checked, setChecked] = useState<boolean>(() => false);
  const [questionType, setQuestionType] = useState<number>(() => null!);
  const [answerCount, setAnswerCount] = useState<number>(() => null!);
  const [description, setDescription] = useState<string>(() => "");
  const [modify, setModify] = useState<boolean>(() => false);
  const [selectedId, setSelectedId] = useState<number>(() => null!);
  const [isDelete, setIsDelete] = useState<boolean>(() => false);
  const [answersModalVisible, setAnswersModalVisible] = useState<boolean>(
    () => false
  );
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionT>(
    () => null!
  );
  const [disabled, setDisabled] = useState<boolean>(() => true);
  const [downloadModalVisible, setDownloadModalVisible] = useState<boolean>(
    () => false
  );
  const [excelImportVisible, setExcelImportVisible] = useState<boolean>(
    () => false
  );

  const [file, setFile] = useState<any>(() => []);
  const [importQuestions, setImportQuestions] = useState(() => []);
  const [choosenFilename, setChoosenFilename] = useState(() => "0");
  const [importButtonVisible, setImportButtonVisible] = useState(() => false);
  const { height, width } = useWindowDimensions();
  const [searchedColumn, setSearchedColumn] = useState(() => "");
  const searchInput = useRef<InputRef>(null);
  const [imageUrl, setImageUrl] = useState<string>(() => "");
  const [picfile, setPicFile] = useState<UploadFile | any>(() => null!);
  const [loading, setLoading] = useState<boolean>(() => false);

  useEffect(() => {
    setLoaded(false);
    const fetch = () => {
      if (
        taskQuestions?.id ||
        (checked && propSaveStatus) ||
        (checked && isDelete)
      ) {
        dispatch(QuestionActions.getAllQuestionWithAnswer(taskQuestions.id));
        setLoaded(true);
      }
    };
    fetch();
  }, [taskQuestions, dispatch, checked, isDelete, propSaveStatus]);

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setModify(false);
    setQuestion("");
    setQuestionType(null!);
    setAnswerCount(null!);
    setDescription("");
    setSelectedId(null!);
    setPicFile(null!);
    setImageUrl("");
    form.resetFields();
  }, [form]);

  const functionCreateQuestion = async () => {
    const formData = new FormData();
    if (picfile) {
      const convertedFile = picfile as RcFile;
      formData.append("file", convertedFile);
    }
    formData.append("taskId", taskQuestions.id?.toString());
    formData.append("questionType", questionType!?.toString());
    formData.append("question", question);
    if (answerCount) {
      formData.append("answerCount", answerCount!.toString());
    }
    formData.append("description", description);
    await dispatch(QuestionActions.createQuestion(formData));
    setChecked(true);
  };

  useEffect(() => {
    const fetch = () => {
      if (propSaveStatus && checked) {
        message.success("Sikeres mentés");
        setChecked(false);
        closeModal();
      } else if (checked && isDelete) {
        setChecked(false);
        setIsDelete(false);
      } else if (!propSaveStatus && checked) {
        setChecked(false);
        message.error("Hiba az feladat mentése közben!");
      }
    };
    fetch();
  }, [checked, closeModal, isDelete, propSaveStatus]);

  const beforeUploadPic = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setPicFile(null!);
      setImageUrl("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setPicFile(null!);
            setImageUrl("");
          }
        };
      };
    }
    return false;
  };

  const showModifyModal = (record: QuestionT) => {
    setModify(true);
    setModalVisible(true);
    setSelectedId(record.id);
    setQuestion(record.question);
    setDescription(record.description);
    setQuestionType(record.questionType);
    form.setFieldsValue({
      question: record.question,
      description: record.description,
      answerCount: record.answerCount,
    });
    setImageUrl(
      process.env.REACT_APP_BASE_URL +
        "/files/content/pictures/" +
        record.url?.substring(
          `${process.env.REACT_APP_BASE_URL}/files/content/pictures/`?.length
        )
    );
    setPicFile(record?.url);
  };
  const handleChangeImage: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(info, setLoading, setPicFile, setImageUrl);
  };

  const functionModifyQuestion = async () => {
    const formData = new FormData();
    if (picfile) {
      const convertedFile = picfile as RcFile;
      formData.append("file", convertedFile);
    }
    formData.append("questionId", selectedId.toString());
    formData.append("question", question);
    formData.append("description", description);
    await dispatch(QuestionActions.modifyQuestion(formData));
    setChecked(true);
  };

  const functionDeleteQuestion = async (record: QuestionT) => {
    await dispatch(QuestionActions.deleteQuestion(record.id));
    setIsDelete(true);
    setChecked(true);
  };

  const showAnswerModal = (record: QuestionT) => {
    setAnswersModalVisible(true);
    setSelectedQuestion(record);
  };

  const closeAnswerModal = () => {
    setAnswersModalVisible(false);
    setSelectedQuestion(null!);
    dispatch(QuestionActions.getAllQuestionWithAnswer(taskQuestions.id));
  };

  const showDownloadModal = () => {
    setDownloadModalVisible(true);
  };

  const removeFile = () => {
    setFile([]);
  };

  const handleBackFromTemplate = () => {
    setDownloadModalVisible(false);
    setExcelImportVisible(false);
  };

  const switchToExcelImport = () => {
    setExcelImportVisible(true);
    setDownloadModalVisible(false);
  };

  const handleBackFromExcelImport = () => {
    setDownloadModalVisible(true);
    setExcelImportVisible(false);
  };

  const beforeUpload = async (file: any) => {
    const workbook = XLSX.read(await file?.arrayBuffer(), { type: "buffer" });
    const sheet_name_list = workbook.SheetNames;
    const result = XLSX.utils.sheet_to_json(
      workbook.Sheets[sheet_name_list[0]]
    );
    let error = false;
    let questionsToSave: any = [result];
    for (let i = 0; i < questionsToSave[0]?.length; i++) {
      if (
        questionsToSave[0][i].kerdestipus < 0 ||
        (questionsToSave[0][i].kerdestipus > 3 &&
          questionsToSave[0][i].kerdestipus < 6) ||
        questionsToSave[0][i].kerdestipus > 7
      ) {
        error = true;
        message.error(
          "Hiba lépett fel: Helytelen kérdéstípus! Ellenőrizd, hogy mindenhol a megadott adatok alapján töltötted e ki a táblázatot!",
          5
        );
        break;
      }
      if (
        ((questionsToSave[0][i].kerdestipus === 1 ||
          questionsToSave[0][i].kerdestipus === 2) &&
          (questionsToSave[0][i].valaszszam < 2 ||
            questionsToSave[0][i].valaszszam > 4)) ||
        (questionsToSave[0][i].kerdestipus === 6 &&
          questionsToSave[0][i].valaszszam < 2) ||
        (questionsToSave[0][i].kerdestipus === 6 &&
          questionsToSave[0][i].valaszszam > 8) ||
        (questionsToSave[0][i].kerdestipus === 7 &&
          questionsToSave[0][i].valaszszam < 3) ||
        (questionsToSave[0][i].kerdestipus === 7 &&
          questionsToSave[0][i].valaszszam > 8)
      ) {
        error = true;
        message.error(
          "Hiba lépett fel: Helytelen válaszszám! Ellenőrizd, hogy mindenhol a megadott adatok alapján töltötted e ki a táblázatot!",
          5
        );
        break;
      }
    }
    if (!error) {
      setImportQuestions(questionsToSave[0]);
      setFile([file]);
      return false;
    }
  };

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const saveQuestions = async () => {
    const saveObj = {
      questions: importQuestions,
      taskId: taskQuestions.id,
    };
    if (importQuestions.length === 0) {
      message.error("A file üres!");
    } else {
      await axios
        .post("/1.0.0/question/import", saveObj)
        .then((response) => {
          if (response.status === 201) {
            setExcelImportVisible(false);
            setImportButtonVisible(false);
            setChoosenFilename("0");
          }
        })
        .catch((err) => {
          message.error(
            "Hiba az importálás során! Ellenőrizd, hogy mindenhol a megadott adatok alapján töltötted e ki a táblázatot!"
          );
          console.log(err);
        });
    }
    setChecked(true);
    setIsDelete(true);
    removeFile();
  };

  type DataIndex = keyof QuestionT;

  const handleSearch = (
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    title: string
  ): ColumnType<QuestionT> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="searchPadding">
        <Input
          ref={searchInput}
          placeholder={`Keress ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm, dataIndex)}
          className="searchInput"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            className="searchButton"
          >
            Keresés
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              handleSearch(confirm, dataIndex);
            }}
            size="small"
            className="searchButton"
          >
            Visszaállítás
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const columns = [
    {
      title: "Kérdés",
      key: "question",
      // dataIndex: "question",
      ...getColumnSearchProps("question", "kérdést"),
      sorter: (a: QuestionT, b: QuestionT) => {
        if (a.question < b.question) {
          return -1;
        }
        if (a.question > b.question) {
          return 1;
        }
        return 0;
      },
      render: (text: string, record: QuestionT) => {
        if (width > 1645) {
          const partString = record.question?.substring(0, 150);
          let index = partString?.indexOf(" ");
          index = partString?.indexOf(" ", index + 90);
          const result = partString?.substring(0, index);
          return record.question.length > 150
            ? result + "..."
            : record.question;
        } else {
          const partString = record.question?.substring(0, 75);
          let index = partString?.indexOf(" ");
          index = partString?.indexOf(" ", index + 40);
          const result = partString?.substring(0, index);
          return record.question.length > 75 ? result + "..." : record.question;
        }
      },
    },
    {
      title: "Kép",
      key: "pic",
      render: (text: string, record: QuestionT) => {
        return (
          <Space size="middle">
            {record.url ? (
              <img
                src={`${
                  process.env.REACT_APP_BASE_URL +
                  "/api/1.0.0/picture/getall/" +
                  record.filename
                }`}
                alt="icon"
                className="coverPic"
              />
            ) : (
              []
            )}
          </Space>
        );
      },
    },
    {
      title: "Leírás",
      key: "description",
      // dataIndex: "description",
      ...getColumnSearchProps("description", "leírást"),
      render: (text: string, record: QuestionT) => {
        if (record.description !== null) {
          if (width > 1645) {
            const partString = record.description?.substring(0, 150);
            let index = partString?.indexOf(" ");
            index = partString?.indexOf(" ", index + 90);
            const result = partString?.substring(0, index);
            return record.description.length > 150
              ? result + "..."
              : record.description;
          } else {
            const partString = record.description?.substring(0, 75);
            let index = partString?.indexOf(" ");
            index = partString?.indexOf(" ", index + 40);
            const result = partString?.substring(0, index);
            return record.description.length > 75
              ? result + "..."
              : record.description;
          }
        } else {
          return "";
        }
      },
    },
    {
      title: "Lehetséges válaszok",
      key: "answerCount",
      dataIndex: "answerCount",
    },
    {
      title: "Típus",
      key: "questionType",
      render: (text: string, record: QuestionT) => {
        return (
          <div>
            {allQuestionType.map((qt) =>
              qt.id === record.questionType ? qt.name : ""
            )}
          </div>
        );
      },
    },
    {
      title: "Válaszok",
      key: "qanswers",
      render: (text: string, record: QuestionT) => {
        return (
          <Space size="middle">
            <Button onClick={() => showAnswerModal(record)}>
              <QuestionCircleOutlined />
            </Button>
            { record.answers.length < record.answerCount ? <WarningOutlined style={{ color: `firebrick` }} /> : null }
          </Space>
        );
      },
    },
    {
      title: "Módosítás",
      key: "modify",
      render: (text: string, record: QuestionT) => {
        return (
          <Space size="middle">
            <Button onClick={() => showModifyModal(record)}>
              <EditOutlined />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Törlés",
      key: "delete",
      render: (text: string, record: QuestionT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan törölni akarod ezt a kérdést?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => functionDeleteQuestion(record)}
            >
              <Button type="primary" danger>
                <DeleteOutlined key="delete" />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  useEffect(() => {
    if (questionType === 0 || questionType === 1 || questionType === 2) {
      if (question.length === 0 || description.length === 0) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      if (!questionType || question.length === 0) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [questionType, question, description]);
  return (
    <>
      <Row>
        <Col span={24} className="colPadding">
          <h1 className="title">
            <strong>Kérdések</strong>
          </h1>
          <Row>
            <Row>
              <Col span={30} className="colPadding">
                <Space size="middle">
                  <Button type="primary" onClick={showModal}>
                    Létrehozás
                  </Button>
                  <div>
                    Válasszon importálási formát: &ensp;
                    <Select
                      style={{ width: "10em" }}
                      onChange={(event) => {
                        setChoosenFilename(event);
                        if (parseInt(event) === 0) {
                          setImportButtonVisible(false);
                        } else {
                          setImportButtonVisible(true);
                        }
                      }}
                      value={choosenFilename}
                      defaultValue={choosenFilename}
                    >
                      <Select.Option key={0}>Egyik sem</Select.Option>
                      <Select.Option
                        key={1}
                        value={
                          "Raabe-Klett-Question-Answer-text-question-import.xlsx"
                        }
                      >
                        Szöveges
                      </Select.Option>
                      <Select.Option
                        key={2}
                        value={
                          "Raabe-Klett-Question-Answer-checkbox-import.xlsx"
                        }
                      >
                        Egyválasztós
                      </Select.Option>
                      <Select.Option
                        key={3}
                        value={
                          "Raabe-Klett-Question-Answer-multiselect-import.xlsx"
                        }
                      >
                        Többválasztós
                      </Select.Option>
                      <Select.Option
                        key={4}
                        value={
                          "Raabe-Klett-Question-Answer-true-false-import.xlsx"
                        }
                      >
                        Igaz-Hamis
                      </Select.Option>
                      <Select.Option
                        key={5}
                        value={
                          "Raabe-Klett-Question-Answer-pairing-question-import.xlsx"
                        }
                      >
                        Szöveg párosításos
                      </Select.Option>
                      <Select.Option
                        key={6}
                        value={
                          "Raabe-Klett-Question-Answer-sequence-question-import.xlsx"
                        }
                      >
                        Sorbarendezéses
                      </Select.Option>
                    </Select>
                  </div>

                  {importButtonVisible && choosenFilename !== "0" && (
                    <Button type="primary" onClick={showDownloadModal}>
                      Importálás
                    </Button>
                  )}
                </Space>
              </Col>
            </Row>
          </Row>
          <Table
            columns={columns}
            rowKey="id"
            dataSource={propQuestions}
            loading={!loaded && !propQuestions}
            pagination={{
              position: ["bottomCenter"],
              pageSize: 10,
              showSizeChanger: false,
            }}
            scroll={{ y: height / 2 }}
          />
        </Col>
      </Row>
      <Modal
        visible={modalVisible}
        onCancel={closeModal}
        maskClosable={false}
        title={modify ? "Módosítás" : "Létrehozás"}
        forceRender
        centered
        footer={[
          <Button
            disabled={disabled}
            key="createQuestion"
            type="primary"
            onClick={modify ? functionModifyQuestion : functionCreateQuestion}
          >
            {modify ? "Módosítás" : "Létrehozás"}
          </Button>,
          <Button key="cancel" onClick={closeModal}>
            Mégsem
          </Button>,
        ]}
        width={"50em"}
      >
        <Form
          form={form}
          {...layout}
          name="register"
          onFinish={modify ? functionModifyQuestion : functionCreateQuestion}
          scrollToFirstError
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 20 }}
        >
          {!modify && (
            <Form.Item
              name="questionType"
              label="Típus"
              rules={[
                {
                  required: true,
                  message: "Mező kitöltése kötelező!",
                },
              ]}
            >
              <Select
                style={{ width: "12.5em" }}
                value={questionType}
                onChange={(event) => setQuestionType(event)}
              >
                {allQuestionType.map((allQuestionType: AllQuestionTypeType) => {
                  return (
                    <Select.Option
                      key={allQuestionType.id}
                      value={allQuestionType.id}
                    >
                      {allQuestionType.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}

          {questionType !== null && questionType !== undefined && (
            <>
              <Form.Item
                name="question"
                label="Kérdés"
                rules={[
                  {
                    required: true,
                    message: "Mező kitöltése kötelező!",
                  },
                ]}
              >
                <TextArea
                  name="question"
                  value={question}
                  maxLength={100}
                  onChange={(e) => setQuestion(e.target.value)}
                  style={{ width: "28em" }}
                />
                <CharacterCounter max={100} taken={question.length} />
              </Form.Item>

              <Form.Item name="file" label="Képfeltöltés">
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={beforeUploadPic}
                  onChange={handleChangeImage}
                  accept=".png,.jpg,.jpeg,.gif"
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <UploadButton loading={loading} />
                  )}
                </Upload>
                <Button
                  style={{
                    position: "absolute",
                    left: "9em",
                    top: "2.5em",
                  }}
                  size="small"
                  danger
                  type="primary"
                  disabled={!picfile}
                  onClick={() => removePictureFile(setPicFile, setImageUrl)}
                >
                  <DeleteOutlined />
                </Button>
              </Form.Item>

              <Form.Item name="description" label="Rövid leírás" rules={[
                {
                  required: true,
                  message: "Mező kitöltése kötelező!",
                },
              ]}>
                <TextArea
                  name="description"
                  value={description}
                  maxLength={150}
                  onChange={(e) => setDescription(e.target.value)}
                  style={{ width: "28em" }}
                />
                <CharacterCounter max={150} taken={description.length} />
              </Form.Item>
              {questionType !== 0 && questionType !== 3 && !modify && (
                <Form.Item
                  name="answerCount"
                  label="Válaszok száma"
                  rules={[
                    {
                      message: "Kérjük csak számokat adjon meg!",
                      pattern: /^\d+$/,
                    },
                  ]}
                >
                  <InputNumber
                    name="answerCount"
                    placeholder="4"
                    value={answerCount}
                    min={questionType === 7 || questionType === 8 ? 3 : 2}
                    max={questionType === 7 || questionType === 6 ? 8 : 4}
                    onChange={(e) => setAnswerCount(e)}
                  />
                </Form.Item>
              )}
            </>
          )}
        </Form>
      </Modal>
      <Modal
        visible={answersModalVisible}
        onCancel={closeAnswerModal}
        maskClosable={true}
        forceRender
        footer={false}
        width={"88vw"}
        bodyStyle={{ height: "100vh" }}
        style={{ position: "absolute", right: 0, top: 0, bottom: 0 }}
      >
        <Answer question={selectedQuestion} />
      </Modal>
      <Modal
        maskClosable={false}
        closable={true}
        footer={null}
        visible={downloadModalVisible}
        centered
        onCancel={handleBackFromTemplate}
      >
        <WizzardDownloadTemplate
          choosenFilename={choosenFilename}
          next={switchToExcelImport}
          prev={handleBackFromTemplate}
        />
      </Modal>

      <Modal
        title={null}
        visible={excelImportVisible}
        footer={null}
        width={"35em"}
        onCancel={handleBackFromTemplate}
        centered
        forceRender
        bodyStyle={{ height: "300px" }}
      >
        <div className="xlsxImportText">
          <div style={{ margin: "1em", textAlign: "left" }}>
            <Upload.Dragger
              name="file"
              multiple={false}
              onRemove={removeFile}
              accept={".xlsx"}
              beforeUpload={beforeUpload}
              fileList={file}
              showUploadList={{
                showDownloadIcon: false,
                showPreviewIcon: false,
              }}
              customRequest={dummyRequest}
            >
              <p className="ant-upload-drag-icon">
                <PlusOutlined />
              </p>
              <p
                className="ant-upload-text"
                style={{ color: "#00b7ae", textAlign: "center" }}
              >
                Kattints, vagy húzd ide a file-t
              </p>
            </Upload.Dragger>
          </div>
          <div style={{ margin: "1em" }}>
            <Button
              onClick={saveQuestions}
              type="primary"
              disabled={file?.length === 0}
            >
              Importálás
            </Button>
          </div>
          <Col>
            <Button
              onClick={handleBackFromExcelImport}
              style={{ width: "30%" }}
            >
              Vissza
            </Button>
          </Col>
        </div>
      </Modal>
    </>
  );
}
