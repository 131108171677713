import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Switch,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DebounceFunc from "../../constants/debounce";
import { layout } from "../../constants/layout";
import {
  handleChange,
  removePictureFile,
  UploadButton,
} from "../../constants/pictureUpload";
import { RootStore } from "../../store/store";
import {
  useBookChange,
  useDescriptionChange,
  useFileChange,
  useImageUrlChange,
  useTitleChange,
} from "./notiUtils";
import reducer, { initialState } from "./reducer";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import NotificationActions from "../../actions/notifications";
import type { UploadChangeParam } from "antd/es/upload";
import { BookT } from "../../constants/ActionTypes/book-action-types";

const NotificationModal = ({
  setModalVisible,
  modalVisible,
  propBook,
  setRefetch,
}: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propSaveStatus = useSelector(
    (state: RootStore) => state.notificationReducer.saveStatus
  );
  const user = useSelector((state: RootStore) => state.loginReducer.user);
  const [pushNoti, setPushNoti] = useState<boolean>(() => true);
  const [disabled, setDisabled] = useState<boolean>(() => true);
  const [checked, setChecked] = useState<boolean>(() => false);
  const [loading, setLoading] = useState<boolean>(() => false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(() => false);

  const [state, reducerDispatch] = useReducer(reducer, initialState);
  const { title, file, bookId, description, imageUrl } = state;
  const bookIdChange = useBookChange(reducerDispatch);
  const titleChange = useTitleChange(reducerDispatch);
  const descriptionChange = useDescriptionChange(reducerDispatch);
  const imageUrlChange = useImageUrlChange(reducerDispatch);
  const fileChange = useFileChange(reducerDispatch);

  const closeModal = () => {
    setModalVisible(false);
    setSubmitLoading(false);
    bookIdChange(null!);
    titleChange("");
    descriptionChange("");
    removePictureFile(fileChange, imageUrlChange);
    setPushNoti(true);
    form.resetFields();
  };

  const functionCreateNotification = async () => {
    setChecked(true);
    setSubmitLoading(true);
    const formData = new FormData();
    if (file) {
      const convertedFile = file as RcFile;
      formData.append("file", convertedFile);
    }
    formData.append("title", title);
    formData.append("description", description);
    formData.append("creator", user.name);
    if (bookId) {
      formData.append("bookId", bookId!.toString());
    }
    if (pushNoti) {
      formData.append("isPush", pushNoti.toString());
    }
    await dispatch(NotificationActions.createCategory(formData));
  };

  useEffect(() => {
    const fetch = () => {
      if (propSaveStatus && checked) {
        message.success("Sikeres mentés");
        setChecked(false);
        closeModal();
      } else if (!submitLoading && !propSaveStatus && checked) {
        message.error("Hiba a mentés közben!");
        setChecked(false);
        setSubmitLoading(false);
      }
    };
    fetch();
    return () => {
      if (propSaveStatus && checked) {
        setRefetch(true);
      }
    };
  }, [propSaveStatus, checked, submitLoading]);

  const handleChangeImage: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(info, setLoading, fileChange, imageUrlChange);
  };

  const beforeUpload = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      fileChange(null!);
      imageUrlChange(null!);
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            fileChange(null!);
            imageUrlChange(null!);
          }
        };
      };
    }
    return false;
  };

  const handleChangeBook = (event: number) => {
    bookIdChange(event);
  };
  useEffect(() => {
    if (!title || !description) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [title, description]);
  return (
    <Modal
      visible={modalVisible}
      title={"Létrehozás"}
      onCancel={closeModal}
      maskClosable={false}
      forceRender
      centered
      footer={[
        <Button
          disabled={disabled}
          key="create"
          type="primary"
          loading={submitLoading}
          onClick={functionCreateNotification}
        >
          Létrehozás
        </Button>,
        <Button key="cancel" onClick={closeModal}>
          Mégsem
        </Button>,
      ]}
      width="55em"
    >
      <Form
        form={form}
        {...layout}
        name="register"
        onFinish={functionCreateNotification}
        scrollToFirstError
      >
        <Form.Item
          name="title"
          label="Cím"
          rules={[
            {
              required: true,
              message: "Mező kitöltése kötelező!",
            },
          ]}
        >
          <Input
            name="title"
            value={title}
            maxLength={255}
            onChange={(e) => DebounceFunc(e.target.value, titleChange)}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Leírás"
          rules={[
            {
              required: true,
              message: "Mező kitöltése kötelező!",
            },
          ]}
        >
          <TextArea
            name="description"
            value={description}
            maxLength={255}
            onChange={(e) => DebounceFunc(e.target.value, descriptionChange)}
            rows={4}
          />
        </Form.Item>
        <Form.Item name="file" label="Képfeltöltés">
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            beforeUpload={beforeUpload}
            onChange={handleChangeImage}
            accept=".png,.jpg,.jpeg,.gif"
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" className="coverPicPreview" />
            ) : (
              UploadButton(loading)
            )}
          </Upload>
          <Button
            style={{ position: "absolute", left: "9em", top: "2.5em" }}
            size="small"
            danger
            type="primary"
            disabled={!imageUrl}
            onClick={() => removePictureFile(fileChange, imageUrlChange)}
          >
            <DeleteOutlined />
          </Button>
        </Form.Item>
        <Form.Item name="bookId" label="Könyv">
          <Select
            style={{ width: "22.5em" }}
            value={bookId}
            onChange={(event) => handleChangeBook(event)}
            showSearch
            filterOption={(input, option) => {
              return (option?.children?.toLocaleString() ?? "")
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            <Select.Option key={null} value={null}>
              Egyik sem
            </Select.Option>
            {propBook?.map((book: BookT) => {
              return (
                <Select.Option key={book.id} value={book.id}>
                  {book.title}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="pushNoti" label="Push értesítés">
          <Switch onChange={(e) => setPushNoti(e)} checked={pushNoti} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default NotificationModal;
