import axios from "axios";
import { Dispatch } from "redux";
import { BookTypeDispatchTypes } from "../constants/ActionTypes/book-type-action-types";

const getAllBookType = (): any => {
  return async (dispatch: Dispatch<BookTypeDispatchTypes>) => {
    dispatch({ type: "BOOKTYPES_LOADING" });
    await axios
      .get("/1.0.0/book-type", {})
      .then((response) => {
        dispatch({
          type: "BOOKTYPES_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "BOOKTYPES_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const createBookType = (params: object): any => {
  return async (dispatch: Dispatch<BookTypeDispatchTypes>) => {
    dispatch({ type: "BOOKTYPE_REGISTER_START" });
    await axios
      .post("/1.0.0/book-type/create", params, {})
      .then((response) => {
        dispatch({
          type: "BOOKTYPE_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "BOOKTYPE_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const modifyBookType = (params: object): any => {
  return async (dispatch: Dispatch<BookTypeDispatchTypes>) => {
    dispatch({ type: "BOOKTYPE_REGISTER_START" });
    await axios
      .put("/1.0.0/book-type/modify/", params, {})
      .then((response) => {
        dispatch({
          type: "BOOKTYPE_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "BOOKTYPE_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const deleteBookType = (booktypeId: number): any => {
  return async () => {
    await axios.delete("/1.0.0/book-type/" + booktypeId).catch((err) => {
      console.log(err.message);
    });
  };
};

const BookTypeActions = {
  getAllBookType,
  createBookType,
  modifyBookType,
  deleteBookType,
};
export default BookTypeActions;
