import { Pagination } from "antd";

const PicturePaginator = ({
  onSearch,
  onPictureNameSearch,
  filteredPictures,
  propPictures,
  setCurrent,
  width,
}: any) => {
  return (
    <Pagination
      total={
        onSearch || onPictureNameSearch
          ? filteredPictures.length
          : propPictures.length
      }
      onChange={(e) => setCurrent(e)}
      showSizeChanger={false}
      pageSize={
        width <= 2560 && width > 2380
          ? 30
          : width <= 2380 && width > 2150
          ? 27
          : width <= 2150 && width > 1920
          ? 24
          : width <= 1920 && width > 1903
          ? 21
          : width <= 1903 && width >= 1673
          ? 18
          : width < 1673 && width >= 1445
          ? 15
          : width < 1445 && width >= 1213
          ? 12
          : width < 1213 && width >= 983
          ? 9
          : width < 983 && width >= 753
          ? 6
          : 3
      }
    />
  );
};
export default PicturePaginator;
