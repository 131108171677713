import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, Table } from "antd";
import { useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import KeywordCategoryActions from "../../../actions/keywordCategory";
import { KeywordCategoryT } from "../../../constants/ActionTypes/keyword-category-action-types";
import { useNameChange, useSelectedIdChange } from "./keywordCategoryUtils";
import KeywordCategoryModal from "./modal";
import reducer, { initialState } from "./reducer";

const KeywordCategoryTable = ({
  form,
  setModalVisible,
  getKeywordCategories,
  data,
  loaded,
  propKeywordCategories,
  modalVisible,
}: any) => {
  const dispatch = useDispatch();
  const [state, reducerDispatch] = useReducer(reducer, initialState);
  const { name, selectedId } = state;
  const nameChange = useNameChange(reducerDispatch);
  const selectedIdChange = useSelectedIdChange(reducerDispatch);
  const [modify, setModify] = useState<boolean>(() => false);

  const showModifyModal = (record: KeywordCategoryT) => {
    form.setFieldsValue({
      name: record.name,
    });
    setModalVisible(true);
    setModify(true);
    selectedIdChange(record.id);
    nameChange(record.name);
  };

  const functionDeleteKeywordCategory = async (record: KeywordCategoryT) => {
    await dispatch(KeywordCategoryActions.deleteKeywordCategory(record.id));
    getKeywordCategories();
  };

  const columns = [
    {
      title: "Kategória",
      key: "keywordCategory",
      dataIndex: "name",
    },
    {
      title: "Módosítás",
      key: "modify",
      render: (text: string, record: KeywordCategoryT) => {
        return (
          <Space size="middle">
            <Button onClick={() => showModifyModal(record)}>
              <EditOutlined />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Törlés",
      key: "delete",
      render: (text: string, record: KeywordCategoryT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan törölni akarod ezt a kategóriát?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => functionDeleteKeywordCategory(record)}
            >
              <Button type="primary" danger>
                <DeleteOutlined key="delete" />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={data}
        loading={!loaded && !propKeywordCategories}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          showSizeChanger: false,
        }}
      />
      <KeywordCategoryModal
        form={form}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setModify={setModify}
        nameChange={nameChange}
        name={name}
        selectedId={selectedId}
        getKeywordCategories={getKeywordCategories}
        modify={modify}
      />
    </div>
  );
};
export default KeywordCategoryTable;
