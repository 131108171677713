import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import type { UploadChangeParam } from "antd/es/upload";
import { message } from "antd";

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export function beforeUploadPicture(
  file: RcFile,
  setFile: any,
  setImageUrl: any
) {
  if (file.size / 1024 / 1024 > 5) {
    setFile(null!);
    setImageUrl("");
    message.error("Túl nagy file (max 5MB)");
  } else {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const image = new Image();
      image.src = reader.result as string;
      image.onload = () => {
        const height = image.naturalHeight;
        const width = image.naturalWidth;
        if (height > 1920 || width > 1920) {
          message.error("A kép felbontása túl nagy, max fullHD");
          setFile(null!);
          setImageUrl("");
        }
      };
    };
  }
  return false;
}

export function removePictureFile(
  setPicturefile: any,
  setImageUrlPicturefile?: any
) {
  setPicturefile(null!);
  if (setImageUrlPicturefile) {
    setImageUrlPicturefile("");
  }
}

export function handleChange(
  info: UploadChangeParam<UploadFile>,
  setLoadingPicturefile: any,
  setPicturefile: any,
  setImageUrlPicturefile?: any
) {
  getBase64(info.file as RcFile, (url) => {
    setLoadingPicturefile(false);
    setPicturefile(info.file);
    setImageUrlPicturefile(url);
  });
}

export function UploadButton({ loading }: any) {
  return (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
}
