import axios from "axios";
import { Dispatch } from "redux";
import { KeywordDispatchTypes } from "../constants/ActionTypes/keyword-action-types";

const getAllKeyword = (): any => {
  return async (dispatch: Dispatch<KeywordDispatchTypes>) => {
    dispatch({ type: "KEYWORDS_LOADING" });
    await axios
      .get("/1.0.0/keyword", {})
      .then((response) => {
        dispatch({
          type: "KEYWORDS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "KEYWORDS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const createKeyword = (params: object): any => {
  return async (dispatch: Dispatch<KeywordDispatchTypes>) => {
    dispatch({ type: "KEYWORD_REGISTER_START" });
    await axios
      .post("/1.0.0/keyword/create", params, {})
      .then((response) => {
        dispatch({
          type: "KEYWORD_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "KEYWORD_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const modifyKeyword = (params: object): any => {
  return async (dispatch: Dispatch<KeywordDispatchTypes>) => {
    dispatch({ type: "KEYWORD_REGISTER_START" });
    await axios
      .put("/1.0.0/keyword/modify/", params, {})
      .then((response) => {
        dispatch({
          type: "KEYWORD_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "KEYWORD_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const deleteKeyword = (id: number): any => {
  return async () => {
    await axios.delete("/1.0.0/keyword/" + id).catch((err) => {
      console.log(err.message);
    });
  };
};

const KeywordActions = {
  getAllKeyword,
  createKeyword,
  modifyKeyword,
  deleteKeyword,
};

export default KeywordActions;
