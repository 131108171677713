import { TagDispatchTypes } from "../constants/ActionTypes/tag-action-types";

export interface initialTagState {
  visible: boolean;
  message: string;
}

const defaultState = {
  visible: false,
  message: "",
};
const tagReducer = (
  state: initialTagState = defaultState,
  action: TagDispatchTypes
): initialTagState => {
  switch (action.type) {
    case "OPEN_WITH_MESSAGE":
      return Object.assign({}, state, {
        visible: true,
        message: action.payload,
      });
    case "CLOSE":
      return Object.assign({}, state, {
        visible: false,
        message: "",
      });

    default:
      return state;
  }
};

export default tagReducer;
