import { Button, Form, message, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QuestionActions from "../../../actions/question";
import CharacterCounter from "../../../constants/characterCounter";
import { RootStore } from "../../../store/store";

export default function Text({
  question,
  modify,
  setModify,
  setModalVisible,
  modalVisible,
  checked,
  setChecked,
}: any) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propAnswers = useSelector(
    (state: RootStore) => state.answerReducer.answers
  );
  const propSaveStatus = useSelector(
    (state: RootStore) => state.answerReducer.saveStatus
  );
  const [pairAnswerPair1, setPairAnswerPair1] = useState<string>(() => "");
  const [disabledButton, setDisabledButton] = useState<boolean>(() => true);

  const functionCreateQuestionWithAnswer = async () => {
    const formData = new FormData();
    formData.append("questionId", question.id);
    if (question.questionType === 0) {
      formData.append("description", pairAnswerPair1);
    }
    await dispatch(QuestionActions.createQuestionAnswers(formData));
    setChecked(true);
  };
  useEffect(() => {
    const fetch = () => {
      if (propSaveStatus && checked) {
        message.success("Sikeres mentés");
        setChecked(false);
        setModalVisible(false);
      } else if (!propSaveStatus && checked) {
        setChecked(false);
        message.error("Hiba az feladat mentése közben!");
      }
    };
    fetch();
  }, [checked]);

  useEffect(() => {
    if (question?.questionType === 0) {
      pairAnswerPair1 !== ""
        ? setDisabledButton(false)
        : setDisabledButton(true);
    }
  }, [question?.questionType, pairAnswerPair1]);

  const closeModal = () => {
    setModalVisible(false);
    setPairAnswerPair1("");
    setModify(false);
    form.resetFields();
  };

  const functionModifyQuestionWithAnswer = async () => {
    const formData = new FormData();
    formData.append("questionId", question.id);
    formData.append("id", propAnswers[0].id.toString());
    formData.append("description", pairAnswerPair1);
    await dispatch(QuestionActions.modifyQuestionAnswers(formData));
    setChecked(true);
  };

  useEffect(() => {
    if (modify) {
      setPairAnswerPair1(propAnswers[0].description);
      form.setFieldsValue({
        answer1: propAnswers[0].description,
      });
    }
  }, [modify]);

  return (
    <div>
      <Modal
        visible={modalVisible}
        onCancel={closeModal}
        maskClosable={false}
        title={modify ? "Módosítás" : "Létrehozás"}
        forceRender
        centered
        footer={[
          <Button
            key="createText"
            type="primary"
            disabled={disabledButton}
            onClick={() =>
              modify
                ? functionModifyQuestionWithAnswer()
                : functionCreateQuestionWithAnswer()
            }
          >
            {modify ? "Módosítás" : "Létrehozás"}
          </Button>,
          <Button key="cancel" onClick={closeModal}>
            Mégsem
          </Button>,
        ]}
        width={"70em"}
      >
        <Form
          form={form}
          name="register"
          scrollToFirstError
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 20 }}
        >
          <Form.Item
            name="question"
            label="Kérdés"
            style={{
              position: "relative",
              width: "45em",
              marginRight: "auto",
              marginLeft: "auto",
            }}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <div style={{ fontSize: "1.5em" }}>
              <strong>{question?.question}</strong>
            </div>
          </Form.Item>
          {question.url && (
            <Form.Item>
              <img
                src={`${
                  process.env.REACT_APP_BASE_URL +
                  "/api/1.0.0/picture/getall/" +
                  question.filename
                }`}
                alt="icon"
                className="textImage"
              />
            </Form.Item>
          )}
          <Form.Item
            name="answer1"
            label={"Válasz"}
            style={{
              position: "relative",
              width: "45em",
              marginRight: "auto",
              marginLeft: "auto",
            }}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Form.Item
              name="answer1"
              rules={[
                {
                  required: true,
                  message: "Mező kitöltése kötelező!",
                },
              ]}
            >
              <TextArea
                name="answer1"
                value={pairAnswerPair1}
                maxLength={150}
                onChange={(e) => setPairAnswerPair1(e.target.value)}
              />
              <CharacterCounter max={150} taken={pairAnswerPair1.length} />
            </Form.Item>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
