import { Button, Col, Form, Input, message, Modal, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import KeywordActions from "../../actions/keyword";
import { KeywordCategoryT } from "../../constants/ActionTypes/keyword-category-action-types";
import DebounceFunc from "../../constants/debounce";
import { layout } from "../../constants/layout";
import { RootStore } from "../../store/store";

const KeywordModal = ({
  form,
  modalVisible,
  getKeywords,
  setModalVisible,
  setModify,
  name,
  type,
  nameChange,
  typeChange,
  selectedId,
  modify,
}: any) => {
  const dispatch = useDispatch();
  const keywordSaveStatus = useSelector(
    (state: RootStore) => state.keywordReducer.saveStatus
  );

  const propKeywordCategory = useSelector(
    (state: RootStore) => state.keywordCategoryReducer.keywordCategory
  );
  const [disabled, setDisabled] = useState<boolean>(() => true);
  const [checked, setChecked] = useState<boolean>(() => false);

  const onReset = () => {
    form.resetFields();
  };

  const closeModal = () => {
    setModalVisible(false);
    setModify(false);
    nameChange("");
    typeChange("");
    onReset();
  };

  const functionCreateKeyword = async () => {
    const toSend = {
      name: name,
      type: type,
    };
    await dispatch(KeywordActions.createKeyword(toSend));
    setChecked(true);
  };

  const functionModifyKeyword = async () => {
    const toSend = {
      id: selectedId,
      name,
      type,
    };
    await dispatch(KeywordActions.modifyKeyword(toSend));
    setChecked(true);
  };

  useEffect(() => {
    const fetch = () => {
      if (keywordSaveStatus && checked) {
        setChecked(false);
        message.success("Sikeres mentés");
      } else if (!keywordSaveStatus && checked) {
        setChecked(false);
        message.error("Hiba az feladat mentése közben!");
      }
    };
    fetch();
    return () => {
      if (keywordSaveStatus && checked) {
        getKeywords();
        closeModal();
      }
    };
  });

  useEffect(() => {
    if (!name || !type) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [name, type]);

  return (
    <Modal
      visible={modalVisible}
      onCancel={closeModal}
      title={modify ? "Módosítás" : "Létrehozás"}
      maskClosable={false}
      forceRender
      centered
      footer={[
        <Button
          disabled={disabled}
          key="createTask"
          type="primary"
          onClick={modify ? functionModifyKeyword : functionCreateKeyword}
        >
          {modify ? "Módosítás" : "Létrehozás"}
        </Button>,
        <Button key="cancel" onClick={closeModal}>
          Mégsem
        </Button>,
      ]}
      width="45em"
    >
      <Row>
        <Col span={24} className="colPadding">
          <Form
            {...layout}
            name="register"
            onFinish={modify ? functionModifyKeyword : functionCreateKeyword}
            scrollToFirstError
            form={form}
          >
            <Form.Item
              name="name"
              label="Megnevezés"
              rules={[
                {
                  required: true,
                  message: "Mező kitöltése kötelező!",
                },
              ]}
            >
              <Input
                name="name"
                value={name}
                maxLength={255}
                onChange={(e) => DebounceFunc(e.target.value, nameChange)}
              />
            </Form.Item>
            <Form.Item
              name="type"
              label="Típus"
              rules={[
                {
                  required: true,
                  message: "Mező kitöltése kötelező!",
                },
              ]}
            >
              <Select
                style={{ width: "20em" }}
                value={type}
                onChange={(event) => typeChange(event)}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={propKeywordCategory.map(
                  (keywordCategory: KeywordCategoryT) => ({
                    value: keywordCategory.name,
                    label: keywordCategory.name,
                  })
                )}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default KeywordModal;
