import { Button, Input, Popconfirm, Space, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BookActions from "../../actions/book";
import { BookT } from "../../constants/ActionTypes/book-action-types";
import { RootStore } from "../../store/store";
import NoPicture from "../../static/259987.png";
import {DeleteOutlined, RedoOutlined, SearchOutlined} from "@ant-design/icons";
import "../formating/content.css";
import useWindowDimensions from "../../constants/sizecheck";
import type { InputRef } from "antd";
import type { ColumnType } from "antd/es/table";

export default function DeletedBooks({ showDeletedModal }: any) {
  const dispatch = useDispatch();
  const propDeletedBooks = useSelector(
    (state: RootStore) => state.bookReducer.deletedBooks
  );
  const propStatus = useSelector(
    (state: RootStore) => state.bookReducer.status
  );
  const [loaded, setLoaded] = useState<boolean>(() => false);
  const { width } = useWindowDimensions();
  const [searchedColumn, setSearchedColumn] = useState(() => "");
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    const fetch = async () => {
      if (showDeletedModal) {
        try {
          await dispatch(BookActions.getAllDeletedBook());
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetch();
    if (propStatus) {
      setLoaded(true);
    }
  }, [dispatch, showDeletedModal]);

  const getDeletedBooks = async () => {
    setLoaded(false);
    try {
      await dispatch(BookActions.getAllDeletedBook());
      if (propStatus) {
        setLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const restoreDeletedBook = async (record: BookT) => {
    await dispatch(BookActions.restoreBook(record.id.toString()));
    await getDeletedBooks();
  };

  const finalDeleteBook = async (record: BookT) => {
    await dispatch(BookActions.deleteBook(record.id));
    await getDeletedBooks();
  };

  type DataIndex = keyof BookT;

  const handleSearch = (
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    title: string
  ): ColumnType<BookT> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="searchPadding">
        <Input
          ref={searchInput}
          placeholder={`Keress ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm, dataIndex)}
          className="searchInput"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            className="searchButton"
          >
            Keresés
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              handleSearch(confirm, dataIndex);
            }}
            size="small"
            className="searchButton"
          >
            Visszaállítás
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const columns = [
    {
      title: "Ikon",
      key: "bookCoverPic",
      render: (text: string, record: BookT) => {
        return (
          <Space size="middle">
            {record.imageUrl ? (
              <img
                src={`${
                  process.env.REACT_APP_BASE_URL +
                  "/api/1.0.0/picture/getall/" +
                  record.imageUrl.substring(
                    `${process.env.REACT_APP_BASE_URL}/files/content/pictures/`
                      .length
                  )
                }`}
                alt="icon"
                className="coverPic"
              />
            ) : (
              <img src={NoPicture} alt="nopic" className="coverPic" />
            )}
          </Space>
        );
      },
    },
    {
      title: "Könyv címe",
      key: "bookName",
      dataIndex: "title",
      ...getColumnSearchProps("title", "címet"),
      sorter: (a: BookT, b: BookT) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Leírás",
      key: "bookDescription",
      ...getColumnSearchProps("description", "leírást"),
      render: (text: string, record: BookT) => {
        if (width > 1645) {
          const partString = record.description.substring(0, 200);
          let index = partString.indexOf(" ");
          index = partString.indexOf(" ", index + 180);
          const result = partString.substring(0, index);
          return record.description.length > 200
            ? result + "..."
            : record.description;
        } else {
          const partString = record.description.substring(0, 100);
          let index = partString.indexOf(" ");
          index = partString.indexOf(" ", index + 50);
          const result = partString.substring(0, index);
          return record.description.length > 100
            ? result + "..."
            : record.description;
        }
      },
      width: "30%",
    },
    {
      title: "Készítők",
      key: "bookCreators",
      dataIndex: "creators",
      ...getColumnSearchProps("creators", "készítőt"),
    },
    {
      title: "Vonalkód",
      key: "bookBarcode",
      dataIndex: "barcode",
    },
    {
      title: "Végleges törlés",
      key: "delete",
      render: (text: string, record: BookT) => {
        return (
            <Space size="middle">
              <Popconfirm
                  title="Biztosan véglegesen törölni akarod ezt a könyvet?"
                  okText="Igen"
                  cancelText="Mégsem"
                  onConfirm={() => finalDeleteBook(record)}
              >
                <Button type="primary" danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Space>
        );
      },
    },
    {
      title: "Visszaállítás",
      key: "restore",
      render: (text: string, record: BookT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan vissza akarod állítani ezt a könyvet?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => restoreDeletedBook(record)}
            >
              <Button type="primary">
                <RedoOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      <h1 className="title">
        <strong>Törölt könyvek</strong>
      </h1>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={propDeletedBooks}
        loading={!loaded && !propDeletedBooks}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          showSizeChanger: false,
        }}
        scroll={{ y: width / 2 }}
      />
    </div>
  );
}
