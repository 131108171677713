import { useState } from "react";
import { Button, Input, message, Form } from "antd";
import { UserOutlined } from "@ant-design/icons";
import "./login.scss";
import axios from "axios";
import "../formating/content.css";

export default function ForgotPassword() {
  const [form] = Form.useForm();

  const [email, setEmail] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);

  const handleFormSubmit = async () => {
    setButtonDisable(true);
    await axios
      .post("/1.0.0/users/forgot-password", {
        email: email,
      })
      .then((response) => {
        message.success("Kérem nézze meg az emailjét", 5);
        form.resetFields();
        setEmail("");
        setButtonDisable(false);
      })
      .catch((err) => {
        message.error("Hiba az email küldés közben", 5);
        form.resetFields();
        setButtonDisable(false);
      });
  };

  return (
    <div>
      <div className="login-page-container">
        <Form
          name="normal_login"
          className="login-form"
          onFinish={handleFormSubmit}
          form={form}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Mező kitöltése kötelező!",
              },
              {
                type: "email",
                message: "Kérem emailt adjon meg!",
              },
            ]}
          >
            <Input
              prefix={
                <UserOutlined
                  className="site-form-item-icon"
                  style={{ color: "#00b7ae" }}
                />
              }
              name="email"
              autoComplete="off"
              value={email || ""}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <div className="forgotpasswordEmailPositioning">
            Kérlek add meg az email címedet
          </div>
          <Form.Item style={{ width: "100%" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: "100%" }}
              disabled={buttonDisable}
            >
              Új jelszó igénylése
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
