import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Transfer,
  Upload,
} from "antd";
import { BookT } from "../../../constants/ActionTypes/book-action-types";
import DebounceFunc from "../../../constants/debounce";
import { layout } from "../../../constants/layout";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import type { UploadChangeParam } from "antd/es/upload";
import {
  handleChange,
  removePictureFile,
  UploadButton,
} from "../../../constants/pictureUpload";
import { useDispatch, useSelector } from "react-redux";
import AudioActions from "../../../actions/audio";
import { useEffect, useState } from "react";
import { RootStore } from "../../../store/store";
import pic from "../../../static/sound-png-icon-free-download-338507.png";

const AudioModal = ({
  form,
  modalVisible,
  modify,
  name,
  setName,
  setFile,
  setImageUrl,
  file,
  imageUrl,
  bookId,
  setModalVisible,
  setModify,
  setSelectedId,
  setBookId,
  setBooksTargetKeys,
  selectedId,
  booksTargetKeys,
  getAudios,
  setBooksSelectedKeys,
  booksSelectedKeys,
}: any) => {
  const dispatch = useDispatch();
  const saveStatus = useSelector(
    (state: RootStore) => state.audioReducer.saveStatus
  );
  const propBooks = useSelector((state: RootStore) => state.bookReducer.book);
  const [checked, setChecked] = useState<boolean>(() => false);
  const [disabled, setDisabled] = useState<boolean>(() => true);
  const [loading, setLoading] = useState<boolean>(() => false);

  const handleChangeImage: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(info, setLoading, setFile, setImageUrl);
  };
  const beforeUpload = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 100) {
      setFile(null!);
      setImageUrl("");
      message.error("Túl nagy file (max 100MB)");
    }

    return false;
  };

  const removeFile = () => {
    setFile(null!);
    setImageUrl(null!);
  };

  const onReset = () => {
    form.resetFields();
  };

  const functionCreateAudio = async () => {
    const formData = new FormData();
    if (file) {
      const convertedFile = file as RcFile;
      formData.append("file", convertedFile);
      formData.append("name", name);
      formData.append("bookId", bookId.toString());
    }
    await dispatch(AudioActions.createAudio(formData));
    setChecked(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setModify(false);
    setSelectedId(null!);
    setName("");
    removeFile();
    setBookId(null!);
    setBooksTargetKeys([]);
    onReset();
  };
  const modifyAudio = async () => {
    const modify = {
      id: selectedId,
      name: name,
      bookIds: booksTargetKeys,
    };
    await dispatch(AudioActions.modifyAudioName(modify));
    setChecked(true);
  };

  useEffect(() => {
    if (saveStatus && checked) {
      message.success("Sikeres mentés");
    } else if (!saveStatus && checked) {
      message.error("Hiba az audio mentése közben!");
    }
    return () => {
      if (saveStatus && checked) {
        closeModal();
        getAudios();
      }
      setChecked(false);
    };
  });

  useEffect(() => {
    if (!modify ? !file || !name || !bookId : !name || !booksTargetKeys) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [file, name, bookId]);

  return (
    <Modal
      visible={modalVisible}
      onCancel={closeModal}
      title={modify ? "Módosítás" : "Létrehozás"}
      maskClosable={false}
      forceRender
      centered
      footer={[
        <Button
          disabled={disabled}
          key="createAudio"
          type="primary"
          onClick={modify ? modifyAudio : functionCreateAudio}
        >
          {modify ? "Módosítás" : "Létrehozás"}
        </Button>,
        <Button key="cancel" onClick={closeModal}>
          Mégsem
        </Button>,
      ]}
      width={"55em"}
    >
      <Form
        {...layout}
        name="register"
        onFinish={modify ? modifyAudio : functionCreateAudio}
        scrollToFirstError
        form={form}
      >
        {!modify && (
          <Form.Item
            name="file"
            label="Audio feltöltés"
            rules={[
              {
                required: true,
                message: "Kérem adjon meg egy fájlt!",
              },
            ]}
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={beforeUpload}
              onChange={handleChangeImage}
              accept=".mp3,.mp3s,.m4a,.flac,.wav"
            >
              {imageUrl ? (
                <img src={pic} alt="avatar" className="coverPicPreview" />
              ) : (
                UploadButton(loading)
              )}
            </Upload>
            <Button
              className="audioRecicleBinButton"
              size="small"
              danger
              type="primary"
              disabled={!file}
              onClick={() => removePictureFile(setFile, setImageUrl)}
            >
              <DeleteOutlined />
            </Button>
          </Form.Item>
        )}

        <Form.Item
          name="name"
          label="Név"
          rules={[
            {
              required: true,
              message: "Mező kitöltése kötelező!",
            },
          ]}
        >
          <Input
            style={modify ? { width: "37em" } : { width: "30em" }}
            name="name"
            value={name}
            maxLength={255}
            onChange={(e) => DebounceFunc(e.target.value, setName)}
          />
        </Form.Item>
        <Form.Item
          name="book"
          label="Könyv"
          rules={[
            {
              required: true,
              message: "Mező kitöltése kötelező!",
            },
          ]}
        >
          {modify ? (
            <Transfer
              style={{ textAlign: "left" }}
              dataSource={propBooks}
              render={(item) => `${item.title}`}
              targetKeys={booksTargetKeys}
              selectedKeys={booksSelectedKeys}
              showSearch
              onChange={(nextTargetKeys: string[]) =>
                setBooksTargetKeys(nextTargetKeys)
              }
              onSelectChange={(
                sourceSelectedKeys: string[],
                targetSelectedKeys: string[]
              ) =>
                setBooksSelectedKeys([
                  ...sourceSelectedKeys,
                  ...targetSelectedKeys,
                ])
              }
              rowKey={(record) => record.id.toString()}
              selectAllLabels={[<div></div>, <div></div>]}
              filterOption={(inputValue, item) =>
                item?.title.toLowerCase()!.includes(inputValue.toLowerCase())
              }
              locale={{
                notFoundContent: "Üres",
                selectAll: "Összes kiválasztása",
                selectInvert: "Kiválasztás törlése",
              }}
              listStyle={{
                width: 240,
                height: 300,
              }}
            />
          ) : (
            <Select
              style={{ width: "30em" }}
              value={bookId}
              onChange={(event) => setBookId(event)}
              showSearch
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={propBooks?.map((book: BookT) => ({
                value: book.id,
                label: book.title,
              }))}
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default AudioModal;
