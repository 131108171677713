import { Button, Form, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QuestionActions from "../../../actions/question";
import { RootStore } from "../../../store/store";
import CheckBoxSchema from "./schemas/checkboxSchema";

export default function CheckBox({
  question,
  modify,
  setModify,
  setModalVisible,
  modalVisible,
  checked,
  setChecked,
}: any) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propAnswers = useSelector(
    (state: RootStore) => state.answerReducer.answers
  );
  const propSaveStatus = useSelector(
    (state: RootStore) => state.answerReducer.saveStatus
  );
  const [checkboxAnswer1IsGood, setCheckboxAnswer1IsGood] = useState<boolean>(
    () => false
  );
  const [checkboxAnswer2IsGood, setCheckboxAnswer2IsGood] = useState<boolean>(
    () => false
  );
  const [checkboxAnswer3IsGood, setCheckboxAnswer3IsGood] = useState<boolean>(
    () => false
  );
  const [checkboxAnswer4IsGood, setCheckboxAnswer4IsGood] = useState<boolean>(
    () => false
  );

  const [pairAnswerPair1, setPairAnswerPair1] = useState<string>(() => "");
  const [pairAnswerPair2, setPairAnswerPair2] = useState<string>(() => "");
  const [pairAnswerPair3, setPairAnswerPair3] = useState<string>(() => "");
  const [pairAnswerPair4, setPairAnswerPair4] = useState<string>(() => "");

  const [disabledButton, setDisabledButton] = useState<boolean>(() => true);

  useEffect(() => {
    if (question?.questionType === 1 || question?.questionType === 2) {
      if (question?.answerCount === 4) {
        pairAnswerPair1 !== "" &&
        pairAnswerPair2 !== "" &&
        pairAnswerPair3 !== "" &&
        pairAnswerPair4 !== "" &&
        (checkboxAnswer1IsGood !== false ||
          checkboxAnswer2IsGood !== false ||
          checkboxAnswer3IsGood !== false ||
          checkboxAnswer4IsGood !== false)
          ? setDisabledButton(false)
          : setDisabledButton(true);
      } else if (question?.answerCount >= 3) {
        pairAnswerPair1 !== "" &&
        pairAnswerPair2 !== "" &&
        pairAnswerPair3 !== "" &&
        (checkboxAnswer1IsGood !== false ||
          checkboxAnswer2IsGood !== false ||
          checkboxAnswer3IsGood !== false)
          ? setDisabledButton(false)
          : setDisabledButton(true);
      } else if (question?.answerCount >= 2) {
        pairAnswerPair1 !== "" &&
        pairAnswerPair2 !== "" &&
        (checkboxAnswer1IsGood !== false || checkboxAnswer2IsGood !== false)
          ? setDisabledButton(false)
          : setDisabledButton(true);
      }
    }
  }, [
    question?.questionType,
    pairAnswerPair1,
    pairAnswerPair2,
    pairAnswerPair3,
    pairAnswerPair4,
    checkboxAnswer1IsGood,
    checkboxAnswer2IsGood,
    checkboxAnswer3IsGood,
    checkboxAnswer4IsGood,
  ]);

  useEffect(() => {
    if (question?.questionType === 1) {
      if (checkboxAnswer1IsGood) {
        setCheckboxAnswer2IsGood(false);
        setCheckboxAnswer3IsGood(false);
        setCheckboxAnswer4IsGood(false);
      }
    }
  }, [question?.questionType, checkboxAnswer1IsGood]);

  useEffect(() => {
    if (question?.questionType === 1) {
      if (checkboxAnswer2IsGood) {
        setCheckboxAnswer1IsGood(false);
        setCheckboxAnswer3IsGood(false);
        setCheckboxAnswer4IsGood(false);
      }
    }
  }, [question?.questionType, checkboxAnswer2IsGood]);

  useEffect(() => {
    if (question?.questionType === 1) {
      if (checkboxAnswer3IsGood) {
        setCheckboxAnswer1IsGood(false);
        setCheckboxAnswer2IsGood(false);
        setCheckboxAnswer4IsGood(false);
      }
    }
  }, [question?.questionType, checkboxAnswer3IsGood]);

  useEffect(() => {
    if (question?.questionType === 1) {
      if (checkboxAnswer4IsGood) {
        setCheckboxAnswer1IsGood(false);
        setCheckboxAnswer2IsGood(false);
        setCheckboxAnswer3IsGood(false);
      }
    }
  }, [question?.questionType, checkboxAnswer4IsGood]);

  const functionCreateQuestionWithAnswer = async () => {
    const formData = new FormData();
    formData.append("questionId", question.id);
    if (question.questionType === 1 || question.questionType === 2) {
      const answers = [
        {
          // checkboxAnswerIsGood: checkboxData[0],
          // checkboxDescription: data[0],
          checkboxAnswerIsGood: checkboxAnswer1IsGood,
          checkboxDescription: pairAnswerPair1,
        },
        {
          // checkboxAnswerIsGood: checkboxData[1],
          // checkboxDescription: data[1],
          checkboxAnswerIsGood: checkboxAnswer2IsGood,
          checkboxDescription: pairAnswerPair2,
        },
        {
          // checkboxAnswerIsGood: checkboxData[2],
          // checkboxDescription: data[2],
          checkboxAnswerIsGood: checkboxAnswer3IsGood,
          checkboxDescription: pairAnswerPair3,
        },
        {
          // checkboxAnswerIsGood: checkboxData[3],
          // checkboxDescription: data[3],
          checkboxAnswerIsGood: checkboxAnswer4IsGood,
          checkboxDescription: pairAnswerPair4,
        },
      ];
      formData.append("answers", JSON.stringify(answers));
    }
    await dispatch(QuestionActions.createQuestionAnswers(formData));
    setChecked(true);
  };

  useEffect(() => {
    const fetch = () => {
      if (propSaveStatus && checked) {
        message.success("Sikeres mentés");
        setChecked(false);
        setModalVisible(false);
      } else if (!propSaveStatus && checked) {
        setChecked(false);
        message.error("Hiba az feladat mentése közben!");
      }
    };
    fetch();
  }, [checked]);

  const closeModal = () => {
    setModalVisible(false);
    setPairAnswerPair1("");
    setPairAnswerPair2("");
    setPairAnswerPair3("");
    setPairAnswerPair4("");

    setCheckboxAnswer1IsGood(false);
    setCheckboxAnswer2IsGood(false);
    setCheckboxAnswer3IsGood(false);
    setCheckboxAnswer4IsGood(false);

    setModify(false);
    form.resetFields();
  };

  const functionModifyQuestionWithAnswer = async () => {
    const formData = new FormData();
    formData.append("questionId", question.id);
    if (question.questionType === 1 || question.questionType === 2) {
      const answers = [
        {
          checkboxAnswerIsGood: checkboxAnswer1IsGood,
          id: propAnswers[0]?.id,
          checkboxDescription: pairAnswerPair1,
        },
        {
          checkboxAnswerIsGood: checkboxAnswer2IsGood,
          id: propAnswers[1]?.id,
          checkboxDescription: pairAnswerPair2,
        },
        {
          checkboxAnswerIsGood: checkboxAnswer3IsGood,
          id: propAnswers[2]?.id,
          checkboxDescription: pairAnswerPair3,
        },
        {
          checkboxAnswerIsGood: checkboxAnswer4IsGood,
          id: propAnswers[3]?.id,
          checkboxDescription: pairAnswerPair4,
        },
      ];
      formData.append("answers", JSON.stringify(answers));
    }
    await dispatch(QuestionActions.modifyQuestionAnswers(formData));
    setChecked(true);
  };

  useEffect(() => {
    if (modify) {
      setCheckboxAnswer1IsGood(propAnswers[0]?.isGood);
      setCheckboxAnswer2IsGood(propAnswers[1]?.isGood);
      setCheckboxAnswer3IsGood(propAnswers[2]?.isGood);
      setCheckboxAnswer4IsGood(propAnswers[3]?.isGood);

      setPairAnswerPair1(propAnswers[0]?.description);
      setPairAnswerPair2(propAnswers[1]?.description);
      setPairAnswerPair3(propAnswers[2]?.description);
      setPairAnswerPair4(propAnswers[3]?.description);

      form.setFieldsValue({
        answer1: propAnswers[0]?.description,
        answer2: propAnswers[1]?.description,
        answer3: propAnswers[2]?.description,
        answer4: propAnswers[3]?.description,

        checkbox1: propAnswers[0]?.isGood,
        checkbox2: propAnswers[1]?.isGood,
        checkbox3: propAnswers[2]?.isGood,
        checkbox4: propAnswers[3]?.isGood,
      });
    }
  }, [modify]);

  // const onChangeDyn = ()=>{
  //   const
  // }

  // const dynamicCheckbox = () => {
  //   let dynamicArray = [];
  //   for (let i = 0; i < question.answerCount; i++) {
  //     dynamicArray.push(
  //       <CheckBoxSchema
  //         id={i}
  //         textValue={data[i]}
  //         textOnChange={setData}
  //         checkboxValue={checkboxData[i]}
  //         checkboxOnChange={setCheckboxData}
  //       />
  //     );
  //   }
  //   return dynamicArray;
  // };

  return (
    <div>
      <Modal
        visible={modalVisible}
        onCancel={closeModal}
        maskClosable={false}
        title={modify ? "Módosítás" : "Létrehozás"}
        forceRender
        centered
        footer={[
          <Button
            key="createCheckbox"
            type="primary"
            disabled={disabledButton}
            onClick={() =>
              modify
                ? functionModifyQuestionWithAnswer()
                : functionCreateQuestionWithAnswer()
            }
          >
            {modify ? "Módosítás" : "Létrehozás"}
          </Button>,
          <Button key="cancel" onClick={closeModal}>
            Mégsem
          </Button>,
        ]}
        width={"70em"}
      >
        <Form
          form={form}
          name="register"
          scrollToFirstError
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 20 }}
        >
          {/* {dynamicCheckbox()} */}
          <CheckBoxSchema
            id={"answer1"}
            pairAnswerPair={pairAnswerPair1}
            setPairAnswerPair={setPairAnswerPair1}
            setCheckboxAnswerIsGood={setCheckboxAnswer1IsGood}
            checkboxAnswerIsGood={checkboxAnswer1IsGood}
            title={"Első válasz"}
          />
          {question?.answerCount >= 2 && (
            <CheckBoxSchema
              id={"answer2"}
              pairAnswerPair={pairAnswerPair2}
              setPairAnswerPair={setPairAnswerPair2}
              setCheckboxAnswerIsGood={setCheckboxAnswer2IsGood}
              checkboxAnswerIsGood={checkboxAnswer2IsGood}
              title={"Második válasz"}
            />
          )}
          {question?.answerCount >= 3 && (
            <CheckBoxSchema
              id={"answer3"}
              pairAnswerPair={pairAnswerPair3}
              setPairAnswerPair={setPairAnswerPair3}
              setCheckboxAnswerIsGood={setCheckboxAnswer3IsGood}
              checkboxAnswerIsGood={checkboxAnswer3IsGood}
              title={"Harmadik válasz"}
            />
          )}
          {question?.answerCount === 4 && (
            <CheckBoxSchema
              id={"answer4"}
              pairAnswerPair={pairAnswerPair4}
              setPairAnswerPair={setPairAnswerPair4}
              setCheckboxAnswerIsGood={setCheckboxAnswer4IsGood}
              checkboxAnswerIsGood={checkboxAnswer4IsGood}
              title={"Negyedik válasz"}
            />
          )}
        </Form>
      </Modal>
    </div>
  );
}
