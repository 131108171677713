import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { Route } from "react-router-dom";
import { RootStore } from "../../store/store";

export default function ProtectedRoute(component: any) {
  const token = useSelector((state: RootStore) => state.loginReducer.token);
  const location = useLocation();

  const isAuth = () => {
    if (token !== null) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      {isAuth() ? (
        <Route
          path={location.pathname}
          render={(props) =>
            React.createElement(component.component, {
              ...props,
              socket: component.socket,
            })
          }
        />
      ) : (
        <Redirect from="*" to="/login" />
      )}
    </React.Fragment>
  );
}
