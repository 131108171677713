import axios from "axios";
import { Dispatch } from "redux";
import { PageDispatchTypes } from "../constants/ActionTypes/page-action-types";

const getAllPage = (): any => {
  return async (dispatch: Dispatch<PageDispatchTypes>) => {
    dispatch({ type: "PAGES_LOADING" });
    await axios
      .get("/1.0.0/page", {})
      .then((response) => {
        dispatch({
          type: "PAGES_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "PAGES_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const getAllPageByBookId = (bookId: number): any => {
  return async (dispatch: Dispatch<PageDispatchTypes>) => {
    dispatch({ type: "PAGES_LOADING" });
    await axios
      .get(`/1.0.0/page/FE/${bookId}`, {})
      .then((response) => {
        dispatch({
          type: "PAGES_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "PAGES_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const getAllPageContent = (
  bookId: number,
  pageId: number,
  isTask: string,
  isAudio: string,
  isPicture: string,
  isVideo: string,
  isWebContent: string
): any => {
  return async (dispatch: Dispatch<PageDispatchTypes>) => {
    dispatch({ type: "PAGE_CONTENTS_LOADING" });
    await axios
      .get(`/1.0.0/page/content/${pageId}`, {
        params: { bookId, isTask, isAudio, isPicture, isVideo, isWebContent },
      })
      .then((response) => {
        dispatch({
          type: "PAGE_CONTENTS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "PAGE_CONTENTS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const getAllPageTask = (bookId: string): any => {
  return async (dispatch: Dispatch<PageDispatchTypes>) => {
    dispatch({ type: "PAGE_TASK_CONTENTS_LOADING" });
    await axios
      .get(`/1.0.0/page/count-task`, { params: { bookId } })
      .then((response) => {
        dispatch({
          type: "PAGE_TASK_CONTENTS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "PAGE_TASK_CONTENTS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const getAllPageAudio = (bookId: string): any => {
  return async (dispatch: Dispatch<PageDispatchTypes>) => {
    dispatch({ type: "PAGE_AUDIO_CONTENTS_LOADING" });
    await axios
      .get(`/1.0.0/page/count-audio`, { params: { bookId } })
      .then((response) => {
        dispatch({
          type: "PAGE_AUDIO_CONTENTS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "PAGE_AUDIO_CONTENTS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const getAllPagePicture = (bookId: string): any => {
  return async (dispatch: Dispatch<PageDispatchTypes>) => {
    dispatch({ type: "PAGE_PICTURE_CONTENTS_LOADING" });
    await axios
      .get(`/1.0.0/page/count-picture`, { params: { bookId } })
      .then((response) => {
        dispatch({
          type: "PAGE_PICTURE_CONTENTS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "PAGE_PICTURE_CONTENTS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const getAllPageVideo = (bookId: string): any => {
  return async (dispatch: Dispatch<PageDispatchTypes>) => {
    dispatch({ type: "PAGE_VIDEO_CONTENTS_LOADING" });
    await axios
      .get(`/1.0.0/page/count-video`, { params: { bookId } })
      .then((response) => {
        dispatch({
          type: "PAGE_VIDEO_CONTENTS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "PAGE_VIDEO_CONTENTS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const getAllPageWebContent = (bookId: string): any => {
  return async (dispatch: Dispatch<PageDispatchTypes>) => {
    dispatch({ type: "PAGE_WEB_CONTENTS_LOADING" });
    await axios
      .get(`/1.0.0/page/count-web-content`, { params: { bookId } })
      .then((response) => {
        dispatch({
          type: "PAGE_WEB_CONTENTS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "PAGE_WEB_CONTENTS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const createPage = (params: object): any => {
  return async (dispatch: Dispatch<PageDispatchTypes>) => {
    dispatch({ type: "PAGE_REGISTER_START" });
    await axios
      .post("/1.0.0/page/create", params, {})
      .then((response) => {
        dispatch({
          type: "PAGE_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "PAGE_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const modifyPage = (params: object): any => {
  return async (dispatch: Dispatch<PageDispatchTypes>) => {
    dispatch({ type: "PAGE_REGISTER_START" });
    await axios
      .put("/1.0.0/page/modify/", params, {})
      .then((response) => {
        dispatch({
          type: "PAGE_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "PAGE_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const deletePage = (id: number): any => {
  return async () => {
    await axios.delete("/1.0.0/page/" + id).catch((err) => {
      console.log(err.message);
    });
  };
};

const PageActions = {
  getAllPage,
  getAllPageByBookId,
  createPage,
  modifyPage,
  deletePage,
  getAllPageContent,
  getAllPageTask,
  getAllPageAudio,
  getAllPagePicture,
  getAllPageVideo,
  getAllPageWebContent,
};

export default PageActions;
