import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { Card, Popconfirm, Row } from "antd";
import { useReducer } from "react";
import { useDispatch } from "react-redux";
import PictureActions from "../../../actions/picture";
import { BookT } from "../../../constants/ActionTypes/book-action-types";
import { PictureT } from "../../../constants/ActionTypes/picture-action-types";
import PictureModal from "./modal";
import {
  useBookIdChange,
  useBookSelectedKeysChange,
  useBookTargetKeysChange,
  useFileChange,
  useImageUrlChange,
  useNameChange,
  useSelectedIdChange,
  useTaskSequenceIdChange,
} from "./pictureUtils";
import reducer, { initialState } from "./reducer";
const { Meta } = Card;
const PictureLoader = ({
  loaded,
  propPictures,
  onSearch,
  onPictureNameSearch,
  width,
  filteredPictures,
  current,
  getPictures,
  form,
  setModalVisible,
  setModify,
  setPreviewImageUrl,
  setPreviewModal,
  modalVisible,
  modify,
}: any) => {
  const dispatch = useDispatch();
  const [state, reducerDispatch] = useReducer(reducer, initialState);
  const {
    name,
    file,
    bookId,
    selectedId,
    bookTargetKeys,
    bookSelectedKeys,
    imageUrl,
    taskSequenceId,
  } = state;
  const selectedIdChange = useSelectedIdChange(reducerDispatch);
  const nameChange = useNameChange(reducerDispatch);
  const bookTargetKeysChange = useBookTargetKeysChange(reducerDispatch);
  const fileChange = useFileChange(reducerDispatch);
  const imageUrlChange = useImageUrlChange(reducerDispatch);
  const bookIdChange = useBookIdChange(reducerDispatch);
  const bookSelectedKeysChange = useBookSelectedKeysChange(reducerDispatch);
  const taskSequenceIdChange = useTaskSequenceIdChange(reducerDispatch);

  const functionDeletePicture = async (record: PictureT) => {
    await dispatch(PictureActions.deletePicture(record.id));
    getPictures();
  };

  const showModifyModal = async (record: PictureT) => {
    form.setFieldsValue({
      name: record?.name,
      book: record.book,
      originalName: record.originalName,
      fileSize: `${record.fileSize} KB`,
      taskSequenceId: record.taskSequenceNumber,
      created: new Date(record.created).toLocaleDateString(),
    });
    console.log(record);
    setModalVisible(true);
    setModify(true);
    selectedIdChange(record.id);
    nameChange(record.name);
    bookTargetKeysChange(record.book?.map((b: BookT) => b.id.toString()));
    taskSequenceIdChange(record.taskSequenceNumber);
  };

  const showPreviewModal = (picUrl: string) => {
    setPreviewImageUrl(picUrl);
    setPreviewModal(true);
  };

  const renderPhotos = (source: PictureT[]) => {
    return source.map((photo: PictureT) => {
      return (
        <div className="pictureCardStyle">
          <Card
            cover={
              <img
                className="pictureStyle"
                alt={photo.name}
                src={
                  process.env.REACT_APP_BASE_URL +
                  "/api/1.0.0/picture/getall/" +
                  photo.filename
                }
              />
            }
            bordered={false}
            actions={[
              <PictureOutlined
                key="pre"
                onClick={() => showPreviewModal(photo.filename)}
              />,
              <EditOutlined
                key="edit"
                onClick={() => showModifyModal(photo)}
              />,
              <Popconfirm
                title="Biztosan törölni akarod ezt a képet?"
                okText="Igen"
                cancelText="Mégsem"
                onConfirm={() => functionDeletePicture(photo)}
              >
                <DeleteOutlined key="delete" />,
              </Popconfirm>,
            ]}
          >
            <Meta title={photo.name} />
          </Card>
        </div>
      );
    });
  };

  return (
    <Row>
      {loaded && propPictures ? (
        renderPhotos(
          onSearch || onPictureNameSearch
            ? width <= 2560 && width > 2380
              ? filteredPictures.slice(
                  current === 1 ? 0 : 30 * current - 30,
                  30 * current
                )
              : width <= 2380 && width > 2150
              ? filteredPictures.slice(
                  current === 1 ? 0 : 27 * current - 27,
                  27 * current
                )
              : width <= 2150 && width > 1920
              ? filteredPictures.slice(
                  current === 1 ? 0 : 24 * current - 24,
                  24 * current
                )
              : width <= 1920 && width > 1903
              ? filteredPictures.slice(
                  current === 1 ? 0 : 21 * current - 21,
                  21 * current
                )
              : width <= 1903 && width >= 1673
              ? filteredPictures.slice(
                  current === 1 ? 0 : 18 * current - 18,
                  18 * current
                )
              : width < 1673 && width >= 1445
              ? filteredPictures.slice(
                  current === 1 ? 0 : 15 * current - 15,
                  15 * current
                )
              : width < 1445 && width >= 1213
              ? filteredPictures.slice(
                  current === 1 ? 0 : 12 * current - 12,
                  12 * current
                )
              : width < 1213 && width >= 983
              ? filteredPictures.slice(
                  current === 1 ? 0 : 9 * current - 9,
                  9 * current
                )
              : width < 983 && width >= 753
              ? filteredPictures.slice(
                  current === 1 ? 0 : 6 * current - 6,
                  6 * current
                )
              : filteredPictures.slice(
                  current === 1 ? 0 : 3 * current - 3,
                  3 * current
                )
            : width <= 2560 && width > 2380
            ? propPictures.slice(
                current === 1 ? 0 : 30 * current - 30,
                30 * current
              )
            : width <= 2380 && width > 2150
            ? propPictures.slice(
                current === 1 ? 0 : 27 * current - 27,
                27 * current
              )
            : width <= 2150 && width > 1920
            ? propPictures.slice(
                current === 1 ? 0 : 24 * current - 24,
                24 * current
              )
            : width <= 1920 && width > 1903
            ? propPictures.slice(
                current === 1 ? 0 : 21 * current - 21,
                21 * current
              )
            : width <= 1903 && width >= 1673
            ? propPictures.slice(
                current === 1 ? 0 : 18 * current - 18,
                18 * current
              )
            : width < 1673 && width >= 1445
            ? propPictures.slice(
                current === 1 ? 0 : 15 * current - 15,
                15 * current
              )
            : width < 1445 && width >= 1213
            ? propPictures.slice(
                current === 1 ? 0 : 12 * current - 12,
                12 * current
              )
            : width < 1213 && width >= 983
            ? propPictures.slice(
                current === 1 ? 0 : 9 * current - 9,
                9 * current
              )
            : width < 983 && width >= 753
            ? propPictures.slice(
                current === 1 ? 0 : 6 * current - 6,
                6 * current
              )
            : propPictures.slice(
                current === 1 ? 0 : 3 * current - 3,
                3 * current
              )
        )
      ) : (
        <div className="loadingButton">
          <LoadingOutlined style={{ fontSize: "10em" }} />
        </div>
      )}
      <PictureModal
        form={form}
        modalVisible={modalVisible}
        file={file}
        name={name}
        bookId={bookId}
        selectedId={selectedId}
        setFile={fileChange}
        setImageUrl={imageUrlChange}
        getPictures={getPictures}
        setModalVisible={setModalVisible}
        setModify={setModify}
        setSelectedId={selectedIdChange}
        setName={nameChange}
        setBookId={bookIdChange}
        modify={modify}
        booksTargetKeys={bookTargetKeys}
        setBooksTargetKeys={bookTargetKeysChange}
        booksSelectedKeys={bookSelectedKeys}
        setBooksSelectedKeys={bookSelectedKeysChange}
        imageUrl={imageUrl}
        taskSequenceId={taskSequenceId}
        setTaskSequenceId={taskSequenceIdChange}
      />
    </Row>
  );
};
export default PictureLoader;
