import { Row, Col, Button, Form, Modal, Space } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import KeywordActions from "../../actions/keyword";
import { RootStore } from "../../store/store";
import "../formating/content.css";
import KeywordCategories from "./keywords.category";
import KeywordTable from "./table";

export default function Keywords() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propKeyword = useSelector(
    (state: RootStore) => state.keywordReducer.keyword
  );
  const keywordStatus = useSelector(
    (state: RootStore) => state.keywordReducer.status
  );

  const [modalVisible, setModalVisible] = useState<boolean>(() => false);
  const [loaded, setLoaded] = useState<boolean>(() => false);
  const [categoryModal, showCategoryModal] = useState<boolean>(() => false);

  const getKeywords = async () => {
    setLoaded(false);
    await dispatch(KeywordActions.getAllKeyword());
    if (keywordStatus) {
      if (propKeyword.length > 0) {
        setLoaded(true);
      }
    }
  };

  return (
    <>
      <Row>
        <Col span={24} className="colPadding">
          <h1 className="title">
            <strong>Kulcsszavak</strong>
          </h1>
          <Row>
            <Row>
              <Col span={30} className="colPadding">
                <Space size="middle">
                  <Button type="primary" onClick={() => setModalVisible(true)}>
                    Létrehozás
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => showCategoryModal(true)}
                  >
                    Kategóriák
                  </Button>
                </Space>
              </Col>
            </Row>
          </Row>
          <Col span={24}>
            <KeywordTable
              form={form}
              setModalVisible={setModalVisible}
              modalVisible={modalVisible}
              getKeywords={getKeywords}
              propKeyword={propKeyword}
              loaded={loaded}
            />
          </Col>
        </Col>
      </Row>

      <Modal
        visible={categoryModal}
        onCancel={() => showCategoryModal(false)}
        maskClosable={false}
        forceRender
        centered
        footer={[false]}
        width="45em"
      >
        <KeywordCategories />
      </Modal>
    </>
  );
}
