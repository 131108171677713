import axios from "axios";
import { Dispatch } from "redux";
import { AudioDispatchTypes } from "../constants/ActionTypes/audio-action-types";

const getAllAudio = (): any => {
  return async (dispatch: Dispatch<AudioDispatchTypes>) => {
    dispatch({ type: "AUDIOS_LOADING" });
    await axios
      .get("/1.0.0/audio", {})
      .then((response) => {
        dispatch({
          type: "AUDIOS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "AUDIOS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const getAllDeletedAudio = (): any => {
  return async (dispatch: Dispatch<AudioDispatchTypes>) => {
    dispatch({ type: "DELETED_AUDIOS_LOADING" });
    await axios
      .get("/1.0.0/audio/trash", {})
      .then((response) => {
        dispatch({
          type: "DELETED_AUDIOS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DELETED_AUDIOS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const createAudio = (params: FormData): any => {
  return async (dispatch: Dispatch<AudioDispatchTypes>) => {
    dispatch({ type: "AUDIO_REGISTER_START" });
    await axios
      .post("/1.0.0/audio/create", params, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch({
          type: "AUDIO_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "AUDIO_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const modifyAudioName = (params: object): any => {
  return async (dispatch: Dispatch<AudioDispatchTypes>) => {
    dispatch({ type: "AUDIO_REGISTER_START" });
    await axios
      .put("/1.0.0/audio/modify/", params, {})
      .then((response) => {
        dispatch({
          type: "AUDIO_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "AUDIO_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const restoreAudio = (id: string): any => {
  return async (dispatch: Dispatch<AudioDispatchTypes>) => {
    dispatch({ type: "AUDIO_REGISTER_START" });
    await axios
      .put("/1.0.0/audio/restore/" + id, {})
      .then((response) => {
        dispatch({
          type: "AUDIO_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "AUDIO_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const addAudio = (params: object): any => {
  return async (dispatch: Dispatch<AudioDispatchTypes>) => {
    dispatch({ type: "AUDIO_ADD_START" });
    await axios
      .post("/1.0.0/book/add-audio", params, {})
      .then((response) => {
        dispatch({
          type: "AUDIO_ADD_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "AUDIO_ADD_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const deleteAudio = (id: number): any => {
  return async () => {
    await axios.delete("/1.0.0/audio/" + id).catch((err) => {
      console.log(err.message);
    });
  };
};

const AudioActions = {
  getAllAudio,
  createAudio,
  modifyAudioName,
  addAudio,
  deleteAudio,
  getAllDeletedAudio,
  restoreAudio,
};
export default AudioActions;
