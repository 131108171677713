import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Form, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import CharacterCounter from "../../../../constants/characterCounter";
import DebounceFunc from "../../../../constants/debounce";
import { removePictureFile } from "../../../../constants/pictureUpload";

export default function PictureTextSchema({
  id,
  pairId,
  pairAnswerPair,
  setPairAnswerPair,
  beforeUpload,
  handleChangeImage,
  imageUrlPictureFile,
  loadingPictureFile,
  title,
  descriptionId,
  pictureDescription,
  setPictureDescription,
  setPictureFile,
  setPictureImageUrl,
}: any) {
  return (
    <Form.Item
      name="answer1"
      // label={title}
      style={{
        position: "relative",
        marginTop: "2em",
        left: "15em",
      }}
      rules={[
        {
          required: true,
          message: "",
        },
      ]}
    >
      <Form.Item
        name={id}
        label={title}
        style={{
          display: "inline-block",
          width: "calc(40% - 4em)",
        }}
        rules={[
          {
            required: true,
            message: "Mező kitöltése kötelező!",
          },
        ]}
      >
        <TextArea
          name={id}
          value={pairAnswerPair}
          maxLength={100}
          onChange={(e) => setPairAnswerPair(e.target.value)}
        />
        <CharacterCounter max={100} taken={pairAnswerPair.length} />
      </Form.Item>
      <Form.Item
        name={pairId}
        // label={"Kép"}
        rules={[
          {
            required: true,
            message: "Kérem adjon meg egy fájlt!",
          },
        ]}
        style={{
          display: "inline-block",
          width: "calc(20% - 4em)",
          marginLeft: "4em",
          // marginTop: "-2em",
        }}
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          beforeUpload={beforeUpload}
          onChange={handleChangeImage}
          accept=".png,.jpg,.jpeg,.gif"
        >
          {imageUrlPictureFile ? (
            <img
              src={imageUrlPictureFile}
              alt="avatar"
              style={{ width: "100%" }}
            />
          ) : (
            <div>
              {loadingPictureFile ? <LoadingOutlined /> : <PlusOutlined />}
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
        <Button
          style={{
            position: "absolute",
            left: "9em",
            top: "2.5em",
          }}
          size="small"
          danger
          type="primary"
          disabled={!imageUrlPictureFile}
          onClick={() => removePictureFile(setPictureFile, setPictureImageUrl)}
        >
          <DeleteOutlined />
        </Button>
      </Form.Item>
      <Form.Item
        label={"Kép leírása"}
        name={descriptionId}
        style={{
          display: "inline-block",
          width: "calc(20% - 4em)",
          marginLeft: "6em",
        }}
        rules={[
          {
            required: true,
            message: "Mező kitöltése kötelező!",
          },
        ]}
      >
        <TextArea
          name={id}
          value={pictureDescription}
          maxLength={100}
          onChange={(e) => setPictureDescription(e.target.value)}
        />
        <CharacterCounter max={100} taken={pictureDescription.length} />
      </Form.Item>
    </Form.Item>
  );
}
