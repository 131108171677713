import { Dispatch } from "redux";
import { TagDispatchTypes } from "../constants/ActionTypes/tag-action-types";

const openTagWithMessage = (message: string): any => {
  return (dispatch: Dispatch<TagDispatchTypes>) => {
    dispatch({ type: "OPEN_WITH_MESSAGE", payload: message });
  };
};

const closeTag = (): any => {
  return (dispatch: Dispatch<TagDispatchTypes>) => {
    dispatch({ type: "CLOSE" });
  };
};

const TagActions = {
  openTagWithMessage,
  closeTag,
};

export default TagActions;
