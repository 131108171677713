export const allQuestionType = [
  {
    id: 0,
    name: "Szöveges",
  },
  {
    id: 1,
    name: "Egyválasztós",
  },
  {
    id: 2,
    name: "Többválasztós",
  },
  {
    id: 3,
    name: "Igaz-hamis",
  },
  {
    id: 4,
    name: "Kép-szöveg párosítás",
  },
  {
    id: 5,
    name: "Kép párosítás",
  },
  {
    id: 6,
    name: "Szöveg párosítás",
  },
  {
    id: 7,
    name: "Szöveg sorbarendezés",
  },
  {
    id: 8,
    name: "Kép sorbarendezés",
  },
];

export type AllQuestionTypeType = {
  id: number;
  name: string;
};
