import axios from "axios";
import { Dispatch } from "redux";
import { QuestionDispatchTypes } from "../constants/ActionTypes/question-action-types";

const getAllQuestionWithAnswer = (taskId: number): any => {
  return async (dispatch: Dispatch<QuestionDispatchTypes>) => {
    dispatch({ type: "QUESTIONS_LOADING" });
    await axios
      .get("/1.0.0/question", { params: { taskId: taskId } })
      .then((response) => {
        dispatch({
          type: "QUESTIONS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "QUESTIONS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const getAllAnswerForQuestion = (questionId: number): any => {
  return async (dispatch: Dispatch<QuestionDispatchTypes>) => {
    dispatch({ type: "QUESTION_ANSWES_LOADING" });
    await axios
      .get("/1.0.0/question/answers", { params: { questionId: questionId } })
      .then((response) => {
        dispatch({
          type: "QUESTION_ANSWES_LOADED",
          payload: response.data.questionAnswers.answers,
        });
      })
      .catch((err) => {
        dispatch({
          type: "QUESTION_ANSWES_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const createQuestion = (params: FormData): any => {
  return async (dispatch: Dispatch<QuestionDispatchTypes>) => {
    dispatch({ type: "QUESTION_REGISTER_START" });
    await axios
      .post("/1.0.0/question/create-empty", params, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch({
          type: "QUESTION_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "QUESTION_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const createQuestionAnswers = (params: FormData): any => {
  return async (dispatch: Dispatch<QuestionDispatchTypes>) => {
    dispatch({ type: "QUESTION_ANSWES_REGISTER_START" });
    await axios
      .post("/1.0.0/question/create", params, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch({
          type: "QUESTION_ANSWES_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "QUESTION_ANSWES_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const modifyQuestion = (params: FormData): any => {
  return async (dispatch: Dispatch<QuestionDispatchTypes>) => {
    dispatch({ type: "QUESTION_REGISTER_START" });
    await axios
      .put("/1.0.0/question/modify-empty", params, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch({
          type: "QUESTION_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "QUESTION_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const modifyQuestionAnswers = (params: FormData): any => {
  return async (dispatch: Dispatch<QuestionDispatchTypes>) => {
    dispatch({ type: "QUESTION_ANSWES_REGISTER_START" });
    await axios
      .put("/1.0.0/question/modify-answers", params, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch({
          type: "QUESTION_ANSWES_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "QUESTION_ANSWES_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const deleteQuestion = (id: number): any => {
  return async () => {
    await axios.delete("/1.0.0/question/" + id).catch((err) => {
      console.log(err.message);
    });
  };
};

const deleteQuestionAnswer = (id: number): any => {
  return async () => {
    await axios.delete("/1.0.0/question/answer" + id).catch((err) => {
      console.log(err.message);
    });
  };
};

const QuestionActions = {
  getAllQuestionWithAnswer,
  createQuestionAnswers,
  createQuestion,
  modifyQuestion,
  deleteQuestion,
  getAllAnswerForQuestion,
  modifyQuestionAnswers,
  deleteQuestionAnswer,
};

export default QuestionActions;
