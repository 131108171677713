import {
  BOOK_ACTIONS,
} from "../../constants/ActionTypes/ReducerActionTypes/reducer-action-types";
import type { UploadFile } from "antd/es/upload/interface";

interface InitialState {
  name: string;
  description: string;
  creators: string;
  selectedId: number;
  barcode: string;
  booktypeId: number;
  keywordsTargetKeys: string[];
  keywordsSelectedKeys: string[];
  file: UploadFile | any;
  imageUrl: string;
  searchedColumn: string;
  purchaseUrl: string;
}

export const initialState: InitialState = {
  name: "",
  description: "",
  creators: "",
  barcode: "",
  imageUrl: "",
  searchedColumn: "",
  purchaseUrl: "",
  selectedId: null!,
  booktypeId: null!,
  keywordsTargetKeys: [],
  keywordsSelectedKeys: [],
  file: null,
};

type Action =
  | { type: typeof BOOK_ACTIONS.SET_NAME; name: string }
  | { type: typeof BOOK_ACTIONS.SET_DESCRIPTION; description: string }
  | { type: typeof BOOK_ACTIONS.SET_CREATORS; creators: string }
  | { type: typeof BOOK_ACTIONS.SET_BARCODE; barcode: string }
  | { type: typeof BOOK_ACTIONS.SET_IMAGE_URL; imageUrl: string }
  | { type: typeof BOOK_ACTIONS.SET_SEARCHED_COLUMN; searchedColumn: string }
  | { type: typeof BOOK_ACTIONS.SET_PURCHASE_URL; purchaseUrl: string }
  | { type: typeof BOOK_ACTIONS.SET_SELECTED_ID; selectedId: number }
  | { type: typeof BOOK_ACTIONS.SET_BOOK_TYPE_ID; booktypeId: number }
  | {
      type: typeof BOOK_ACTIONS.SET_KEYWORDS_TARGET_KEYS;
      keywordsTargetKeys: string[];
    }
  | {
      type: typeof BOOK_ACTIONS.SET_KEYWORDS_SELECTED_KEYS;
      keywordsSelectedKeys: string[];
    }
  | { type: typeof BOOK_ACTIONS.SET_FILE; file: UploadFile | any };

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case BOOK_ACTIONS.SET_NAME:
      return { ...state, name: action.name };
    case BOOK_ACTIONS.SET_DESCRIPTION:
      return { ...state, description: action.description };
    case BOOK_ACTIONS.SET_CREATORS:
      return { ...state, creators: action.creators };
    case BOOK_ACTIONS.SET_BARCODE:
      return { ...state, barcode: action.barcode };
    case BOOK_ACTIONS.SET_IMAGE_URL:
      return { ...state, imageUrl: action.imageUrl };
    case BOOK_ACTIONS.SET_SEARCHED_COLUMN:
      return { ...state, searchedColumn: action.searchedColumn };
    case BOOK_ACTIONS.SET_PURCHASE_URL:
      return { ...state, purchaseUrl: action.purchaseUrl };
    case BOOK_ACTIONS.SET_SELECTED_ID:
      return { ...state, selectedId: action.selectedId };
    case BOOK_ACTIONS.SET_BOOK_TYPE_ID:
      return { ...state, booktypeId: action.booktypeId };
    case BOOK_ACTIONS.SET_KEYWORDS_TARGET_KEYS:
      return { ...state, keywordsTargetKeys: action.keywordsTargetKeys };
    case BOOK_ACTIONS.SET_KEYWORDS_SELECTED_KEYS:
      return { ...state, keywordsSelectedKeys: action.keywordsSelectedKeys };
    case BOOK_ACTIONS.SET_FILE:
      return { ...state, file: action.file };

    default:
      return state;
  }
};

export default reducer;
