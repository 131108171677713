import { BOOK_TYPE_ACTIONS } from "../../constants/ActionTypes/ReducerActionTypes/reducer-action-types";

interface InitialState {
  name: string;
  selectedId: number;
}

export const initialState: InitialState = {
  name: "",
  selectedId: null!,
};

type Action =
  | { type: typeof BOOK_TYPE_ACTIONS.SET_NAME; name: string }
  | { type: typeof BOOK_TYPE_ACTIONS.SET_SELECTED_ID; selectedId: number };

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case BOOK_TYPE_ACTIONS.SET_NAME:
      return { ...state, name: action.name };
    case BOOK_TYPE_ACTIONS.SET_SELECTED_ID:
      return { ...state, selectedId: action.selectedId };
    default:
      return state;
  }
};

export default reducer;
