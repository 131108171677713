import { useEffect, useState } from "react";
import { Input, Button, Form, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LoginActions from "../../actions/login";
import { RootStore } from "../../store/store";
import { userRoles } from "../../constants/roles";

export default function Login() {
  const dispatch = useDispatch();
  const status = useSelector((state: RootStore) => state.loginReducer.status);
  const lastTry = useSelector((state: RootStore) => state.loginReducer.lastTry);
  const user = useSelector((state: RootStore) => state.loginReducer.user);
  const history = useHistory();
  const [email, setEmail] = useState<string>(() => "");
  const [password, setPassword] = useState<string>(() => "");
  const [checked, setChecked] = useState<boolean>(() => false);

  useEffect(() => {
    const sign = () => {
      if (status) {
        const groupIds = user.groups?.map((group) => group.id);
        if (groupIds?.includes(userRoles.SUPER_ADMIN)) {
          history.push("/raabe-klett/admins");
        } else if (groupIds?.includes(userRoles.ADMIN)) {
          history.push("/raabe-klett/book");
        } else {
          message.info("Nincs joga a művelethez!");
        }
      } else if (!status && checked) {
        message.error("Nem megfelelő felhasználónév és jelszó páros!", 5);
      }
    };
    sign();
  }, [status, history, checked, lastTry]);

  const handleFormSubmit = async () => {
    await dispatch(LoginActions.login(email, password));
    setChecked(true);
  };

  return (
    <div className="login-page-container">
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: false }}
        onFinish={handleFormSubmit}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Mező kitöltése kötelező!",
            },
          ]}
        >
          <Input
            prefix={
              <UserOutlined
                className="site-form-item-icon"
                style={{ color: "#00b7ae" }}
              />
            }
            placeholder="Felhasználónév: "
            name="username"
            autoComplete="off"
            value={email || ""}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Mező kitöltése kötelező!" }]}
        >
          <Input
            prefix={
              <LockOutlined
                className="site-form-item-icon"
                style={{ color: "#00b7ae" }}
              />
            }
            type="password"
            placeholder="Jelszó: "
            name="password"
            value={password || ""}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>
        <Form.Item style={{ width: "100%" }}>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ width: "100%" }}
          >
            Bejelentkezés
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
