export enum ADMIN_ACTIONS {
  SET_EMAIL = "SET_EMAIL",
  SET_PASSWORD = "SET_PASSWORD",
  SET_NAME = "SET_NAME",
  SET_LOADED = "SET_LOADED",
  SET_MODAL_VISIBLE = "SET_MODAL_VISIBLE",
  SET_CHECKED = "SET_CHECKED",
  SET_IS_DELETE = "SET_IS_DELETE",
}

export enum BOOK_ACTIONS {
  SET_NAME = "SET_NAME",
  SET_DESCRIPTION = "SET_DESCRIPTION",
  SET_CREATORS = "SET_CREATORS",
  SET_SELECTED_ID = "SET_SELECTED_ID",
  SET_BARCODE = "SET_BARCODE",
  SET_BOOK_TYPE_ID = "SET_BOOK_TYPE_ID",
  SET_KEYWORDS_TARGET_KEYS = "SET_KEYWORDS_TARGET_KEYS",
  SET_KEYWORDS_SELECTED_KEYS = "SET_KEYWORDS_SELECTED_KEYS",
  SET_FILE = "SET_FILE",
  SET_IMAGE_URL = "SET_IMAGE_URL",
  SET_SEARCHED_COLUMN = "SET_SEARCHED_COLUMN",
  SET_PURCHASE_URL = "SET_PURCHASE_URL",
}

export enum BOOK_TYPE_ACTIONS {
  SET_NAME = "SET_NAME",
  SET_SELECTED_ID = "SET_SELECTED_ID",
}

export enum KEYWORDS_ACTIONS {
  SET_NAME = "SET_NAME",
  SET_TYPE = "SET_TYPE",
  SET_SELECTED_ID = "SET_SELECTED_ID",
}

export enum KEYWORDS_CATEGORY_ACTIONS {
  SET_NAME = "SET_NAME",
  SET_SELECTED_ID = "SET_SELECTED_ID",
}

export enum DASBOARD_ACTIONS {
  SET_CATEGORY_TITLE = "SET_CATEGORY_TITLE",
  SET_CATEGORY_TARGET_KEYS = "SET_CATEGORY_TARGET_KEYS",
  SET_CATEGORY_SELECTED_KEYS = "SET_CATEGORY_SELECTED_KEYS",
  SET_SELECTED_ID = "SET_SELECTED_ID",
}

export enum NOTIFICATION_ACTIONS {
  SET_BOOK_ID = "SET_BOOK_ID",
  SET_TITLE = "SET_TITLE",
  SET_DESCRIPTION = "SET_DESCRIPTION",
  SET_IMAGE_URL = "SET_IMAGE_URL",
  SET_FILE = "SET_FILE",
}

export enum AUDIO_ACTIONS {
  SET_FILE = "SET_FILE",
  SET_NAME = "SET_NAME",
  SET_SELECTED_ID = "SET_SELECTED_ID",
  SET_IMAGE_URL = "SET_IMAGE_URL",
  SET_BOOK_ID = "SET_BOOK_ID",
  SET_BOOK_SEARCH = "SET_BOOK_SEARCH",
  SET_FILETED_AUDIOS = "SET_FILETED_AUDIOS",
  SET_BOOK_TARGET_KEYS = "SET_BOOK_TARGET_KEYS",
  SET_BOOK_SELECTED_KEYS = "SET_BOOK_SELECTED_KEYS",
  SET_SEARCHED_COLUMN = "SET_SEARCHED_COLUMN",
}

export enum PICTURE_ACTIONS {
  SET_FILE = "SET_FILE",
  SET_NAME = "SET_NAME",
  SET_SELECTED_ID = "SET_SELECTED_ID",
  SET_IMAGE_URL = "SET_IMAGE_URL",
  SET_BOOK_ID = "SET_BOOK_ID",
  SET_BOOK_SEARCH = "SET_BOOK_SEARCH",
  SET_FILETED_PICTURES = "SET_FILETED_PICTURES",
  SET_BOOK_TARGET_KEYS = "SET_BOOK_TARGET_KEYS",
  SET_BOOK_SELECTED_KEYS = "SET_BOOK_SELECTED_KEYS",
  SET_TASK_SESQUENCE_ID = "SET_TASK_SESQUENCE_ID",
}
