import { useEffect, useState } from "react";

export default function CharacterCounter({ max, taken }: any) {
  const [count, setCount] = useState(() => max);
  useEffect(() => {
    if (taken) {
      const counter = max - taken;
      setCount(counter);
    }
    if (taken === 0) {
      setCount(max);
    }
  }, [taken, max]);
  return <div>Hátralévő karakterek száma: {count}</div>;
}