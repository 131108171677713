import {
  PageDispatchTypes,
  PageT,
} from "../constants/ActionTypes/page-action-types";

export interface initialPageState {
  page: PageT[];
  pageWithContent: PageT;
  pageWithTaskContent: PageT[];
  pageWithAudioContent: PageT[];
  pageWithPictureContent: PageT[];
  pageWithVideoContent: PageT[];
  pageWithWebContent: PageT[];
  pageTaskContentStatus: boolean;
  pageTaskContentMessage: string;
  pageAudioContentStatus: boolean;
  pageAudioContentMessage: string;
  pagePictureContentStatus: boolean;
  pagePictureContentMessage: string;
  pageVideoContentStatus: boolean;
  pageVideoContentMessage: string;
  pageWebContentStatus: boolean;
  pageWebContentMessage: string;
  pageContentStatus: boolean;
  pageContentMessage: string;
  status: boolean;
  message: string;
  saveStatus: boolean;
}

const defaultState = {
  page: [],
  pageWithTaskContent: [],
  pageWithAudioContent: [],
  pageWithPictureContent: [],
  pageWithVideoContent: [],
  pageWithWebContent: [],
  pageTaskContentStatus: false,
  pageTaskContentMessage: "",
  pageAudioContentStatus: false,
  pageAudioContentMessage: "",
  pagePictureContentStatus: false,
  pagePictureContentMessage: "",
  pageVideoContentStatus: false,
  pageVideoContentMessage: "",
  pageWebContentStatus: false,
  pageWebContentMessage: "",
  pageWithContent: null!,
  pageContentStatus: false,
  pageContentMessage: "",
  status: false,
  message: "",
  saveStatus: false,
};

const pageReducer = (
  state: initialPageState = defaultState,
  action: PageDispatchTypes
): initialPageState => {
  switch (action.type) {
    case "PAGES_LOADING":
      return Object.assign({}, state, {
        page: [],
        status: false,
        message: "",
      });
    case "PAGES_LOADED":
      return Object.assign({}, state, {
        page: action.payload.pages,
        status: true,
        message: "",
      });
    case "PAGES_ERROR":
      return Object.assign({}, state, {
        page: [],
        status: false,
        message: action.payload,
      });
    case "PAGE_CONTENTS_LOADING":
      return Object.assign({}, state, {
        pageWithContent: [],
        pageContentStatus: false,
        pageContentMessage: "",
      });
    case "PAGE_CONTENTS_LOADED":
      return Object.assign({}, state, {
        pageWithContent: action.payload.pages,
        pageContentStatus: true,
        pageContentMessage: "",
      });
    case "PAGE_CONTENTS_ERROR":
      return Object.assign({}, state, {
        pageWithContent: [],
        pageContentStatus: false,
        pageContentMessage: action.payload,
      });
    case "PAGE_TASK_CONTENTS_LOADING":
      return Object.assign({}, state, {
        pageWithTaskContent: [],
        pageTaskContentStatus: false,
        pageTaskContentMessage: "",
      });
    case "PAGE_TASK_CONTENTS_LOADED":
      return Object.assign({}, state, {
        pageWithTaskContent: action.payload.pages,
        pageTaskContentStatus: true,
        pageTaskContentMessage: "",
      });
    case "PAGE_TASK_CONTENTS_ERROR":
      return Object.assign({}, state, {
        pageWithTaskContent: [],
        pageTaskContentStatus: false,
        pageTaskContentMessage: action.payload,
      });
    case "PAGE_AUDIO_CONTENTS_LOADING":
      return Object.assign({}, state, {
        pageWithAudioContent: [],
        pageAudioContentStatus: false,
        pageAudioContentMessage: "",
      });
    case "PAGE_AUDIO_CONTENTS_LOADED":
      return Object.assign({}, state, {
        pageWithAudioContent: action.payload.pages,
        pageAudioContentStatus: true,
        pageAudioContentMessage: "",
      });
    case "PAGE_AUDIO_CONTENTS_ERROR":
      return Object.assign({}, state, {
        pageWithAudioContent: [],
        pageAudioContentStatus: false,
        pageAudioContentMessage: action.payload,
      });
    case "PAGE_PICTURE_CONTENTS_LOADING":
      return Object.assign({}, state, {
        pageWithPictureContent: [],
        pagePictureContentStatus: false,
        pagePictureContentMessage: "",
      });
    case "PAGE_PICTURE_CONTENTS_LOADED":
      return Object.assign({}, state, {
        pageWithPictureContent: action.payload.pages,
        pagePictureContentStatus: true,
        pagePictureContentMessage: "",
      });
    case "PAGE_PICTURE_CONTENTS_ERROR":
      return Object.assign({}, state, {
        pageWithPictureContent: [],
        pagePictureContentStatus: false,
        pagePictureContentMessage: action.payload,
      });
    case "PAGE_VIDEO_CONTENTS_LOADING":
      return Object.assign({}, state, {
        pageWithVideoContent: [],
        pageVideoContentStatus: false,
        pageVideoContentMessage: "",
      });
    case "PAGE_VIDEO_CONTENTS_LOADED":
      return Object.assign({}, state, {
        pageWithVideoContent: action.payload.pages,
        pageVideoContentStatus: true,
        pageVideoContentMessage: "",
      });
    case "PAGE_VIDEO_CONTENTS_ERROR":
      return Object.assign({}, state, {
        pageWithVideoContent: [],
        pageVideoContentStatus: false,
        pageVideoContentMessage: action.payload,
      });
    case "PAGE_WEB_CONTENTS_LOADING":
      return Object.assign({}, state, {
        pageWithWebContent: [],
        pageWebContentStatus: false,
        pageWebContentMessage: "",
      });
    case "PAGE_WEB_CONTENTS_LOADED":
      return Object.assign({}, state, {
        pageWithWebContent: action.payload.pages,
        pageWebContentStatus: true,
        pageWebContentMessage: "",
      });
    case "PAGE_WEB_CONTENTS_ERROR":
      return Object.assign({}, state, {
        pageWithWebContent: [],
        pageWebContentStatus: false,
        pageWebContentMessage: action.payload,
      });
    case "PAGE_REGISTER_START":
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case "PAGE_REGISTER_SUCCESS":
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case "PAGE_REGISTER_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default pageReducer;
