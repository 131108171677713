import axios from "axios";
import { Dispatch } from "redux";
import { KeywordCategoryDispatchTypes } from "../constants/ActionTypes/keyword-category-action-types";

const getAllKeywordCategory = (): any => {
  return async (dispatch: Dispatch<KeywordCategoryDispatchTypes>) => {
    dispatch({ type: "KEYWORD_CATEGORIES_LOADING" });
    await axios
      .get("/1.0.0/keyword-category", {})
      .then((response) => {
        dispatch({
          type: "KEYWORD_CATEGORIES_LOADED",
          payload: response.data.keywordCategories,
        });
      })
      .catch((err) => {
        dispatch({
          type: "KEYWORD_CATEGORIES_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const createKeywordCategory = (params: object): any => {
  return async (dispatch: Dispatch<KeywordCategoryDispatchTypes>) => {
    dispatch({ type: "KEYWORD_CATEGORIES_REGISTER_START" });
    await axios
      .post("/1.0.0/keyword-category/create", params, {})
      .then((response) => {
        dispatch({
          type: "KEYWORD_CATEGORIES_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "KEYWORD_CATEGORIES_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const modifyKeywordCategory = (params: object): any => {
  return async (dispatch: Dispatch<KeywordCategoryDispatchTypes>) => {
    dispatch({ type: "KEYWORD_CATEGORIES_REGISTER_START" });
    await axios
      .put("/1.0.0/keyword-category/modify/", params, {})
      .then((response) => {
        dispatch({
          type: "KEYWORD_CATEGORIES_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "KEYWORD_CATEGORIES_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const deleteKeywordCategory = (id: number): any => {
  return async () => {
    await axios.delete("/1.0.0/keyword-category/" + id).catch((err) => {
      console.log(err.message);
    });
  };
};

const KeywordCategoryActions = {
  getAllKeywordCategory,
  createKeywordCategory,
  modifyKeywordCategory,
  deleteKeywordCategory,
};

export default KeywordCategoryActions;
