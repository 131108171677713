import axios from "axios";
import { Dispatch } from "redux";
import { UserDispatchTypes } from "../constants/ActionTypes/user-action-types";

const getAdmins = (): any => {
  return async (dispatch: Dispatch<UserDispatchTypes>) => {
    dispatch({ type: "USER_ADMIN_LOADING" });
    await axios
      .get("/1.0.0/users/", {})
      .then((response) => {
        dispatch({ type: "USER_ADMIN_LOADED", payload: response.data });
      })
      .catch((err) => {
        dispatch({
          type: "USER_ADMIN_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const registerAdmin = (params: object): any => {
  return async (dispatch: Dispatch<UserDispatchTypes>) => {
    dispatch({ type: "USER_REGISTER_START" });
    await axios
      .post("/1.0.0/users/admin-registration", params, {})
      .then(() => {
        dispatch({ type: "USER_REGISTER_SUCCESS" });
      })
      .catch((err) => {
        dispatch({
          type: "USER_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const modifyProfileUser = (params: object): any => {
  return async (dispatch: Dispatch<UserDispatchTypes>) => {
    dispatch({ type: "USER_REGISTER_START" });
    await axios
      .put("/1.0.0/users/modify-user-profile", params, {})
      .then(() => {
        dispatch({ type: "USER_REGISTER_SUCCESS" });
      })
      .catch((err) => {
        dispatch({
          type: "USER_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const modifyUser = (params: object): any => {
  return async (dispatch: Dispatch<UserDispatchTypes>) => {
    dispatch({ type: "USER_REGISTER_START" });
    await axios
      .post("/1.0.0/users/modify-user", params, {})
      .then(() => {
        dispatch({ type: "USER_REGISTER_SUCCESS" });
      })
      .catch((err) => {
        dispatch({
          type: "USER_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const deleteAdmin = (userId: number): any => {
  return async () => {
    await axios.delete("/1.0.0/users/delete-admin/" + userId).catch((err) => {
      console.log(err.message);
    });
  };
};

const UserActions = {
  getAdmins,
  registerAdmin,
  modifyProfileUser,
  modifyUser,
  deleteAdmin,
};

export default UserActions;
