import { DASBOARD_ACTIONS } from "../../constants/ActionTypes/ReducerActionTypes/reducer-action-types";

interface InitialState {
  categoryTitle: string;
  selectedId: number;
  dashboardTargetKeys: string[];
  dashboardSelectedKeys: string[] | any;
}

export const initialState: InitialState = {
  categoryTitle: "",
  selectedId: null!,
  dashboardTargetKeys: [],
  dashboardSelectedKeys: [],
};

type Action =
  | { type: typeof DASBOARD_ACTIONS.SET_CATEGORY_TITLE; categoryTitle: string }
  | { type: typeof DASBOARD_ACTIONS.SET_SELECTED_ID; selectedId: number }
  | {
      type: typeof DASBOARD_ACTIONS.SET_CATEGORY_TARGET_KEYS;
      dashboardTargetKeys: string[] | any;
    }
  | {
      type: typeof DASBOARD_ACTIONS.SET_CATEGORY_SELECTED_KEYS;
      dashboardSelectedKeys: string[] | any;
    };

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case DASBOARD_ACTIONS.SET_CATEGORY_TITLE:
      return { ...state, categoryTitle: action.categoryTitle };
    case DASBOARD_ACTIONS.SET_SELECTED_ID:
      return { ...state, selectedId: action.selectedId };
    case DASBOARD_ACTIONS.SET_CATEGORY_TARGET_KEYS:
      return { ...state, dashboardTargetKeys: action.dashboardTargetKeys };
    case DASBOARD_ACTIONS.SET_CATEGORY_SELECTED_KEYS:
      return { ...state, dashboardSelectedKeys: action.dashboardSelectedKeys };
    default:
      return state;
  }
};

export default reducer;
