import { Space, Table } from "antd";
import { BookT } from "../../constants/ActionTypes/book-action-types";
import { NotificationT } from "../../constants/ActionTypes/notification-action-types";

const NotificationTable = ({ propBook, propNotification, loaded }: any) => {
  const columns = [
    {
      title: "Cím",
      key: "title",
      dataIndex: "title",
    },
    {
      title: "Leírás",
      key: "description",
      dataIndex: "description",
    },
    {
      title: "Kép",
      key: "notificationPic",
      render: (text: string, record: NotificationT) => {
        return (
          <Space size="middle">
            {record.imageUrl ? (
              <img
                src={`${
                  process.env.REACT_APP_BASE_URL +
                  "/api/1.0.0/picture/getall/" +
                  record.imageUrl.substring(
                    `${process.env.REACT_APP_BASE_URL}/files/content/pictures/`
                      .length
                  )
                }`}
                alt="icon"
                className="coverPic"
              />
            ) : (
              <></>
            )}
          </Space>
        );
      },
    },
    {
      title: "Könyv",
      key: "book",
      render: (text: string, record: NotificationT) => {
        return propBook.map((b: BookT) =>
          b.id === record.bookId ? b.title : ""
        );
      },
    },
    {
      title: "Létrehozó",
      key: "creator",
      dataIndex: "creator",
    },
    {
      title: "Kiküldve",
      key: "created",
      render: (text: string, record: NotificationT) => {
        return new Date(record.created).toLocaleDateString();
      },
    },
  ];

  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={propNotification}
      loading={!loaded && !propNotification}
      pagination={{
        position: ["bottomCenter"],
        pageSize: 10,
        showSizeChanger: false,
      }}
    />
  );
};

export default NotificationTable;
