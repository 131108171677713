import {
  LoginDispatchTypes,
  UserT,
} from "../constants/ActionTypes/login-action-types";

export interface initialLoginState {
  token: string;
  user: UserT;
  message: string;
  status: boolean;
  lastTry: number;
}

const defaultState = {
  token: "",
  message: "",
  status: false,
  user: null!,
  lastTry: 0
};

const loginReducer = (
  state: initialLoginState = defaultState,
  action: LoginDispatchTypes
): initialLoginState => {
  switch (action.type) {
    case "TOKEN_LOADING":
      return Object.assign({}, state, {
        token: null,
        user: {},
        message: "",
        status: false,
      });
    case "TOKEN_LOADED":
      return Object.assign({}, state, {
        token: action.payload.token,
        user: action.payload.user,
        message: "",
        status: true,
      });
    case "TOKEN_ERROR":
      console.log(action);
      return Object.assign({}, state, {
        token: null,
        user: {},
        message:
          action.payload !== undefined
            ? action.payload
            : { code: 1002, message: "Invalid username or password" },
        status: false,
        lastTry: new Date().getTime()
      });
    case "LOGOUT":
      return Object.assign({}, state, {
        token: null,
        user: {},
        message: "",
        status: false,
        apiKey: null,
      });
    default:
      return state;
  }
};

export default loginReducer;
