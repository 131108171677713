import { useEffect, useState } from "react";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import type { UploadChangeParam } from "antd/es/upload";
import { Button, Form, message, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store/store";
import QuestionActions from "../../../actions/question";
import PicturePictureSchema from "./schemas/picturePictureSchema";
import { handleChange } from "../../../constants/pictureUpload";
export default function PicturePicturePair({
  question,
  modify,
  setModify,
  setModalVisible,
  modalVisible,
  checked,
  setChecked,
}: any) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propAnswers = useSelector(
    (state: RootStore) => state.answerReducer.answers
  );
  const propSaveStatus = useSelector(
    (state: RootStore) => state.answerReducer.saveStatus
  );
  const [picture1afile, setPicture1afile] = useState<UploadFile | any>(
    () => null!
  );
  const [loadingPicture1afile, setLoadingPicture1afile] = useState<boolean>(
    () => false
  );
  const [imageUrlPicture1afile, setImageUrlPicture1afile] = useState<string>(
    () => ""
  );
  const [picture2afile, setPicture2afile] = useState<UploadFile | any>(
    () => null!
  );
  const [loadingPicture2afile, setLoadingPicture2afile] = useState<boolean>(
    () => false
  );
  const [imageUrlPicture2afile, setImageUrlPicture2afile] = useState<string>(
    () => ""
  );
  const [picture3afile, setPicture3afile] = useState<UploadFile | any>(
    () => null!
  );
  const [loadingPicture3afile, setLoadingPicture3afile] = useState<boolean>(
    () => false
  );
  const [imageUrlPicture3afile, setImageUrlPicture3afile] = useState<string>(
    () => ""
  );
  const [picture4afile, setPicture4afile] = useState<UploadFile | any>(
    () => null!
  );
  const [loadingPicture4afile, setLoadingPicture4afile] = useState<boolean>(
    () => false
  );
  const [imageUrlPicture4afile, setImageUrlPicture4afile] = useState<string>(
    () => ""
  );

  const [picture1bfile, setPicture1bfile] = useState<UploadFile | any>(
    () => null!
  );
  const [loadingPicture1bfile, setLoadingPicture1bfile] = useState<boolean>(
    () => false
  );
  const [imageUrlPicture1bfile, setImageUrlPicture1bfile] = useState<string>(
    () => ""
  );
  const [picture2bfile, setPicture2bfile] = useState<UploadFile | any>(
    () => null!
  );
  const [loadingPicture2bfile, setLoadingPicture2bfile] = useState<boolean>(
    () => false
  );
  const [imageUrlPicture2bfile, setImageUrlPicture2bfile] = useState<string>(
    () => ""
  );
  const [picture3bfile, setPicture3bfile] = useState<UploadFile | any>(
    () => null!
  );
  const [loadingPicture3bfile, setLoadingPicture3bfile] = useState<boolean>(
    () => false
  );
  const [imageUrlPicture3bfile, setImageUrlPicture3bfile] = useState<string>(
    () => ""
  );
  const [picture4bfile, setPicture4bfile] = useState<UploadFile | any>(
    () => null!
  );
  const [loadingPicture4bfile, setLoadingPicture4bfile] = useState<boolean>(
    () => false
  );
  const [imageUrlPicture4bfile, setImageUrlPicture4bfile] = useState<string>(
    () => ""
  );

  const [disabledButton, setDisabledButton] = useState<boolean>(() => true);

  const [pictureDescription1, setPictureDescription1] = useState<string>(
    () => ""
  );
  const [pictureDescription2, setPictureDescription2] = useState<string>(
    () => ""
  );
  const [pictureDescription3, setPictureDescription3] = useState<string>(
    () => ""
  );
  const [pictureDescription4, setPictureDescription4] = useState<string>(
    () => ""
  );

  const [pictureDescriptionPair1, setPictureDescriptionPair1] =
    useState<string>(() => "");
  const [pictureDescriptionPair2, setPictureDescriptionPair2] =
    useState<string>(() => "");
  const [pictureDescriptionPair3, setPictureDescriptionPair3] =
    useState<string>(() => "");
  const [pictureDescriptionPair4, setPictureDescriptionPair4] =
    useState<string>(() => "");

  useEffect(() => {
    const fetch = () => {
      if (propSaveStatus && checked) {
        message.success("Sikeres mentés");
        setChecked(false);
        setModalVisible(false);
      } else if (!propSaveStatus && checked) {
        setChecked(false);
        message.error("Hiba az feladat mentése közben!");
      }
    };
    fetch();
  }, [checked]);

  useEffect(() => {
    if (question?.questionType === 5) {
      if (question.answerCount === 4) {
        pictureDescription1 !== "" &&
        pictureDescription2 !== "" &&
        pictureDescription3 !== "" &&
        pictureDescription4 !== "" &&
        pictureDescriptionPair1 !== "" &&
        pictureDescriptionPair2 !== "" &&
        pictureDescriptionPair3 !== "" &&
        pictureDescriptionPair4 !== "" &&
        picture1afile !== null &&
        picture2afile !== null &&
        picture3afile !== null &&
        picture4afile !== null &&
        picture1bfile !== null &&
        picture2bfile !== null &&
        picture3bfile !== null &&
        picture4bfile !== null
          ? setDisabledButton(false)
          : setDisabledButton(true);
      } else if (question.answerCount >= 3) {
        pictureDescription1 !== "" &&
        pictureDescription2 !== "" &&
        pictureDescription3 !== "" &&
        pictureDescriptionPair1 !== "" &&
        pictureDescriptionPair2 !== "" &&
        pictureDescriptionPair3 !== "" &&
        picture1afile !== null &&
        picture2afile !== null &&
        picture3afile !== null &&
        picture1bfile !== null &&
        picture2bfile !== null &&
        picture3bfile !== null
          ? setDisabledButton(false)
          : setDisabledButton(true);
      } else if (question.answerCount >= 2) {
        pictureDescription1 !== "" &&
        pictureDescription2 !== "" &&
        pictureDescriptionPair1 !== "" &&
        pictureDescriptionPair2 !== "" &&
        picture1afile !== null &&
        picture2afile !== null &&
        picture1bfile !== null &&
        picture2bfile !== null
          ? setDisabledButton(false)
          : setDisabledButton(true);
      }
    }
  }, [
    question?.questionType,
    picture1afile,
    picture2afile,
    picture3afile,
    picture4afile,
    picture1bfile,
    picture2bfile,
    picture3bfile,
    picture4bfile,
    pictureDescription1,
    pictureDescription2,
    pictureDescription3,
    pictureDescription4,
    pictureDescriptionPair1,
    pictureDescriptionPair2,
    pictureDescriptionPair3,
    pictureDescriptionPair4,
  ]);

  const functionCreateQuestionWithAnswer = async () => {
    const formData = new FormData();
    const convertedFile1 = picture1afile as RcFile;
    const convertedFile2 = picture2afile as RcFile;
    const convertedFile3 = picture3afile as RcFile;
    const convertedFile4 = picture4afile as RcFile;

    const convertedFile5 = picture1bfile as RcFile;
    const convertedFile6 = picture2bfile as RcFile;
    const convertedFile7 = picture3bfile as RcFile;
    const convertedFile8 = picture4bfile as RcFile;

    formData.append("questionId", question.id);
    formData.append("pictureAfile", convertedFile1);
    formData.append("pictureAfile", convertedFile2);
    formData.append("pictureAfile", convertedFile3);
    formData.append("pictureAfile", convertedFile4);

    formData.append("pictureBfile", convertedFile5);
    formData.append("pictureBfile", convertedFile6);
    formData.append("pictureBfile", convertedFile7);
    formData.append("pictureBfile", convertedFile8);

    const answers = [
      {
        pictureDescription: pictureDescription1,
        picturePairDescription: pictureDescriptionPair1,
      },
      {
        pictureDescription: pictureDescription2,
        picturePairDescription: pictureDescriptionPair2,
      },
      {
        pictureDescription: pictureDescription3,
        picturePairDescription: pictureDescriptionPair3,
      },
      {
        pictureDescription: pictureDescription4,
        picturePairDescription: pictureDescriptionPair4,
      },
    ];
    formData.append("answers", JSON.stringify(answers));
    await dispatch(QuestionActions.createQuestionAnswers(formData));
    setChecked(true);
  };

  const beforeUpload1 = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setPicture1afile(null!);
      setImageUrlPicture1afile("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setImageUrlPicture1afile("");
            setPicture1afile(null!);
          }
        };
      };
    }
    return false;
  };

  const beforeUpload2 = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setPicture2afile(null!);
      setImageUrlPicture2afile("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setImageUrlPicture2afile("");
            setPicture2afile(null!);
          }
        };
      };
    }
    return false;
  };

  const beforeUpload3 = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setPicture3afile(null!);
      setImageUrlPicture3afile("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setPicture3afile(null!);
            setImageUrlPicture3afile("");
          }
        };
      };
    }
    return false;
  };

  const beforeUpload4 = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setPicture4afile(null!);
      setImageUrlPicture4afile("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setPicture4afile(null!);
            setImageUrlPicture4afile("");
          }
        };
      };
    }
    return false;
  };

  const beforeUpload5 = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setPicture1bfile(null!);
      setImageUrlPicture1bfile("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setPicture1bfile(null!);
            setImageUrlPicture1bfile("");
          }
        };
      };
    }
    return false;
  };

  const beforeUpload6 = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setPicture2bfile(null!);
      setImageUrlPicture2bfile("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setPicture2bfile(null!);
            setImageUrlPicture2bfile("");
          }
        };
      };
    }
    return false;
  };

  const beforeUpload7 = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setPicture3bfile(null!);
      setImageUrlPicture3bfile("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setPicture3bfile(null!);
            setImageUrlPicture3bfile("");
          }
        };
      };
    }
    return false;
  };

  const beforeUpload8 = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setPicture4bfile(null!);
      setImageUrlPicture4bfile("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setPicture4bfile(null!);
            setImageUrlPicture4bfile("");
          }
        };
      };
    }
    return false;
  };

  const handleChangeImage1: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(
      info,
      setLoadingPicture1afile,
      setPicture1afile,
      setImageUrlPicture1afile
    );
  };

  const handleChangeImage2: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(
      info,
      setLoadingPicture2afile,
      setPicture2afile,
      setImageUrlPicture2afile
    );
  };

  const handleChangeImage3: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(
      info,
      setLoadingPicture3afile,
      setPicture3afile,
      setImageUrlPicture3afile
    );
  };

  const handleChangeImage4: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(
      info,
      setLoadingPicture4afile,
      setPicture4afile,
      setImageUrlPicture4afile
    );
  };

  const handleChangeImage5: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(
      info,
      setLoadingPicture1bfile,
      setPicture1bfile,
      setImageUrlPicture1bfile
    );
  };

  const handleChangeImage6: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(
      info,
      setLoadingPicture2bfile,
      setPicture2bfile,
      setImageUrlPicture2bfile
    );
  };

  const handleChangeImage7: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(
      info,
      setLoadingPicture3bfile,
      setPicture3bfile,
      setImageUrlPicture3bfile
    );
  };

  const handleChangeImage8: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(
      info,
      setLoadingPicture4bfile,
      setPicture4bfile,
      setImageUrlPicture4bfile
    );
  };

  const closeModal = () => {
    setModalVisible(false);
    setPicture1afile(null!);
    setPicture2afile(null!);
    setPicture3afile(null!);
    setPicture4afile(null!);

    setPicture1bfile(null!);
    setPicture2bfile(null!);
    setPicture3bfile(null!);
    setPicture4bfile(null!);

    setImageUrlPicture1afile("");
    setImageUrlPicture2afile("");
    setImageUrlPicture3afile("");
    setImageUrlPicture4afile("");

    setImageUrlPicture1bfile("");
    setImageUrlPicture2bfile("");
    setImageUrlPicture3bfile("");
    setImageUrlPicture4bfile("");

    setPictureDescription1("");
    setPictureDescription2("");
    setPictureDescription3("");
    setPictureDescription4("");

    setPictureDescriptionPair1("");
    setPictureDescriptionPair2("");
    setPictureDescriptionPair3("");
    setPictureDescriptionPair4("");

    setModify(false);
    form.resetFields();
  };

  const functionModifyQuestionWithAnswer = async () => {
    const formData = new FormData();
    const convertedFile1 = picture1afile as RcFile;
    const convertedFile2 = picture2afile as RcFile;
    const convertedFile3 = picture3afile as RcFile;
    const convertedFile4 = picture4afile as RcFile;

    const convertedFile5 = picture1bfile as RcFile;
    const convertedFile6 = picture2bfile as RcFile;
    const convertedFile7 = picture3bfile as RcFile;
    const convertedFile8 = picture4bfile as RcFile;

    formData.append("questionId", question.id);
    const answers = [
      {
        id: propAnswers[1]?.id,
        pairId: propAnswers[0]?.id,
        pictureDescription: pictureDescription1,
        pictureDescriptionPair: pictureDescriptionPair1,
        changed: convertedFile1?.type === undefined ? false : true,
        pairChanged: convertedFile5?.type === undefined ? false : true,
      },
      {
        id: propAnswers[3]?.id,
        pairId: propAnswers[2]?.id,
        pictureDescription: pictureDescription2,
        pictureDescriptionPair: pictureDescriptionPair2,
        changed: convertedFile2?.type === undefined ? false : true,
        pairChanged: convertedFile6?.type === undefined ? false : true,
      },
      {
        id: propAnswers[5]?.id,
        pairId: propAnswers[4]?.id,
        pictureDescription: pictureDescription3,
        pictureDescriptionPair: pictureDescriptionPair3,
        changed: convertedFile3?.type === undefined ? false : true,
        pairChanged: convertedFile7?.type === undefined ? false : true,
      },
      {
        id: propAnswers[7]?.id,
        pairId: propAnswers[6]?.id,
        pictureDescription: pictureDescription4,
        pictureDescriptionPair: pictureDescriptionPair4,
        changed: convertedFile4?.type === undefined ? false : true,
        pairChanged: convertedFile8?.type === undefined ? false : true,
      },
    ];
    formData.append("pictureAfile", convertedFile1);
    formData.append("pictureAfile", convertedFile2);
    formData.append("pictureAfile", convertedFile3);
    formData.append("pictureAfile", convertedFile4);

    formData.append("pictureBfile", convertedFile5);
    formData.append("pictureBfile", convertedFile6);
    formData.append("pictureBfile", convertedFile7);
    formData.append("pictureBfile", convertedFile8);

    formData.append("answers", JSON.stringify(answers));
    await dispatch(QuestionActions.modifyQuestionAnswers(formData));
    setChecked(true);
  };

  useEffect(() => {
    if (modify) {
      setImageUrlPicture1afile(
        process.env.REACT_APP_BASE_URL +
          "/files/content/pictures/" +
          propAnswers[1]?.imageUrl?.substring(
            `${process.env.REACT_APP_BASE_URL}/files/content/pictures/`.length
          )
      );
      setPicture1afile(propAnswers[1]?.imageUrl);

      setImageUrlPicture1bfile(
        process.env.REACT_APP_BASE_URL +
          "/files/content/pictures/" +
          propAnswers[0]?.imageUrl?.substring(
            `${process.env.REACT_APP_BASE_URL}/files/content/pictures/`.length
          )
      );
      setPicture1bfile(propAnswers[0]?.imageUrl);

      setImageUrlPicture2afile(
        process.env.REACT_APP_BASE_URL +
          "/files/content/pictures/" +
          propAnswers[3]?.imageUrl?.substring(
            `${process.env.REACT_APP_BASE_URL}/files/content/pictures/`.length
          )
      );
      setPicture2afile(propAnswers[3]?.imageUrl);

      setImageUrlPicture2bfile(
        process.env.REACT_APP_BASE_URL +
          "/files/content/pictures/" +
          propAnswers[2]?.imageUrl?.substring(
            `${process.env.REACT_APP_BASE_URL}/files/content/pictures/`.length
          )
      );
      setPicture2bfile(propAnswers[2]?.imageUrl);

      setImageUrlPicture3afile(
        process.env.REACT_APP_BASE_URL +
          "/files/content/pictures/" +
          propAnswers[5]?.imageUrl?.substring(
            `${process.env.REACT_APP_BASE_URL}/files/content/pictures/`.length
          )
      );
      setPicture3afile(propAnswers[5]?.imageUrl);

      setImageUrlPicture3bfile(
        process.env.REACT_APP_BASE_URL +
          "/files/content/pictures/" +
          propAnswers[4]?.imageUrl?.substring(
            `${process.env.REACT_APP_BASE_URL}/files/content/pictures/`.length
          )
      );
      setPicture3bfile(propAnswers[4]?.imageUrl);

      setImageUrlPicture4afile(
        process.env.REACT_APP_BASE_URL +
          "/files/content/pictures/" +
          propAnswers[7]?.imageUrl?.substring(
            `${process.env.REACT_APP_BASE_URL}/files/content/pictures/`.length
          )
      );
      setPicture4afile(propAnswers[7]?.imageUrl);

      setImageUrlPicture4bfile(
        process.env.REACT_APP_BASE_URL +
          "/files/content/pictures/" +
          propAnswers[6]?.imageUrl?.substring(
            `${process.env.REACT_APP_BASE_URL}/files/content/pictures/`.length
          )
      );
      setPicture4bfile(propAnswers[6]?.imageUrl);

      setPictureDescription1(propAnswers[1]?.pictureDescription);
      setPictureDescriptionPair1(propAnswers[0]?.pictureDescription);
      setPictureDescription1(propAnswers[3]?.pictureDescription);
      setPictureDescriptionPair1(propAnswers[2]?.pictureDescription);
      setPictureDescription1(propAnswers[5]?.pictureDescription);
      setPictureDescriptionPair1(propAnswers[4]?.pictureDescription);
      setPictureDescription1(propAnswers[7]?.pictureDescription);
      setPictureDescriptionPair1(propAnswers[6]?.pictureDescription);
      form.setFieldsValue({
        description1: propAnswers[1]?.pictureDescription,
        description2: propAnswers[3]?.pictureDescription,
        description3: propAnswers[5]?.pictureDescription,
        description4: propAnswers[7]?.pictureDescription,
        description1Pair: propAnswers[0]?.pictureDescription,
        description2Pair: propAnswers[2]?.pictureDescription,
        description3Pair: propAnswers[4]?.pictureDescription,
        description4Pair: propAnswers[6]?.pictureDescription,
      });
    }
  }, [modify]);

  return (
    <div>
      <Modal
        visible={modalVisible}
        onCancel={() => closeModal()}
        maskClosable={false}
        title={modify ? "Módosítás" : "Létrehozás"}
        forceRender
        centered
        footer={[
          <Button
            key="createPicturePicturePair"
            type="primary"
            disabled={disabledButton}
            onClick={() =>
              modify
                ? functionModifyQuestionWithAnswer()
                : functionCreateQuestionWithAnswer()
            }
          >
            {modify ? "Módosítás" : "Létrehozás"}
          </Button>,
          <Button key="cancel" onClick={() => closeModal()}>
            Mégsem
          </Button>,
        ]}
        width={"100em"}
      >
        <Form
          form={form}
          name="register"
          scrollToFirstError
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 20 }}
        >
          <PicturePictureSchema
            id={"file1"}
            pairId={"file5"}
            beforeUpload1={beforeUpload1}
            handleChangeImage1={handleChangeImage1}
            imageUrlPicture1afile={imageUrlPicture1afile}
            loadingPicture1afile={loadingPicture1afile}
            setPicture1aFile={setPicture1afile}
            setPicture1aImageUrl={setImageUrlPicture1afile}
            beforeUpload2={beforeUpload5}
            handleChangeImage2={handleChangeImage5}
            imageUrlPicture1bfile={imageUrlPicture1bfile}
            loadingPicture1bfile={loadingPicture1bfile}
            setPicture1bFile={setPicture1bfile}
            setPicture1bImageUrl={setImageUrlPicture1bfile}
            title={"Első kép"}
            pairTitle={"Képpár"}
            descriptionId={"description1"}
            descriptionPairId={"description1Pair"}
            pictureDescription={pictureDescription1}
            pictureDescriptionPair={pictureDescriptionPair1}
            setPictureDescription={setPictureDescription1}
            setPictureDescriptionpair={setPictureDescriptionPair1}
          />
          <PicturePictureSchema
            id={"file2"}
            pairId={"file6"}
            beforeUpload1={beforeUpload2}
            handleChangeImage1={handleChangeImage2}
            imageUrlPicture1afile={imageUrlPicture2afile}
            loadingPicture1afile={loadingPicture2afile}
            setPicture1aFile={setPicture2afile}
            setPicture1aImageUrl={setImageUrlPicture2afile}
            beforeUpload2={beforeUpload6}
            handleChangeImage2={handleChangeImage6}
            imageUrlPicture1bfile={imageUrlPicture2bfile}
            loadingPicture1bfile={loadingPicture2bfile}
            setPicture1bFile={setPicture2bfile}
            setPicture1bImageUrl={setImageUrlPicture2bfile}
            title={"Második kép"}
            pairTitle={"Képpár"}
            descriptionId={"description2"}
            descriptionPairId={"description2Pair"}
            pictureDescription={pictureDescription2}
            pictureDescriptionPair={pictureDescriptionPair2}
            setPictureDescription={setPictureDescription2}
            setPictureDescriptionpair={setPictureDescriptionPair2}
          />
          {question?.answerCount >= 3 && (
            <PicturePictureSchema
              id={"file3"}
              pairId={"file7"}
              beforeUpload1={beforeUpload3}
              handleChangeImage1={handleChangeImage3}
              imageUrlPicture1afile={imageUrlPicture3afile}
              loadingPicture1afile={loadingPicture3afile}
              setPicture1aFile={setPicture3afile}
              setPicture1aImageUrl={setImageUrlPicture3afile}
              beforeUpload2={beforeUpload7}
              handleChangeImage2={handleChangeImage7}
              imageUrlPicture1bfile={imageUrlPicture3bfile}
              loadingPicture1bfile={loadingPicture3bfile}
              setPicture1bFile={setPicture3bfile}
              setPicture1bImageUrl={setImageUrlPicture3bfile}
              title={"Harmadik kép"}
              pairTitle={"Képpár"}
              descriptionId={"description3"}
              descriptionPairId={"description3Pair"}
              pictureDescription={pictureDescription3}
              pictureDescriptionPair={pictureDescriptionPair3}
              setPictureDescription={setPictureDescription3}
              setPictureDescriptionpair={setPictureDescriptionPair3}
            />
          )}
          {question?.answerCount === 4 && (
            <PicturePictureSchema
              id={"file4"}
              pairId={"file8"}
              beforeUpload1={beforeUpload4}
              handleChangeImage1={handleChangeImage4}
              imageUrlPicture1afile={imageUrlPicture4afile}
              loadingPicture1afile={loadingPicture4afile}
              setPicture1aFile={setPicture4afile}
              setPicture1aImageUrl={setImageUrlPicture4afile}
              beforeUpload2={beforeUpload8}
              handleChangeImage2={handleChangeImage8}
              imageUrlPicture1bfile={imageUrlPicture4bfile}
              loadingPicture1bfile={loadingPicture4bfile}
              setPicture1bFile={setPicture4bfile}
              setPicture1bImageUrl={setImageUrlPicture4bfile}
              title={"Negyedik kép"}
              pairTitle={"Képpár"}
              descriptionId={"description4"}
              descriptionPairId={"description4Pair"}
              pictureDescription={pictureDescription4}
              pictureDescriptionPair={pictureDescriptionPair4}
              setPictureDescription={setPictureDescription4}
              setPictureDescriptionpair={setPictureDescriptionPair4}
            />
          )}
        </Form>
      </Modal>
    </div>
  );
}
