import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";

export default function WizzardDownloadTemplate({
  choosenFilename,
  next,
  prev,
}: any) {
  return (
    <div style={{ textAlign: "center" }}>
      <h3>Töltsd le a sablont és másold bele a kérdéseidet és válaszaidat</h3>
      <p>(Sablon letöltéséhez kattints a képre.)</p>
      <div style={{ margin: "10px 0 10px 0" }}>
        <Button
          href={`${process.env.REACT_APP_BASE_URL}/api/1.0.0/question/template/${choosenFilename}`}
          download
          type="link"
          style={{ width: "100px", height: "100px", padding: "0" }}
        >
          <DownloadOutlined style={{ fontSize: "100px", cursor: "pointer" }} />
        </Button>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={prev}>Vissza</Button>
        <Button type="primary" onClick={next}>
          Tovább
        </Button>
      </div>
    </div>
  );
}
