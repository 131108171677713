import { Row, Col, Button, Form } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootStore } from "../../store/store";

import "../formating/content.css";

import KeywordCategoryActions from "../../actions/keywordCategory";
import KeywordCategoryTable from "./keywordCategory/table";

export default function KeywordCategories() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propKeywordCategories = useSelector(
    (state: RootStore) => state.keywordCategoryReducer.keywordCategory
  );
  const keywordCategoryStatus = useSelector(
    (state: RootStore) => state.keywordCategoryReducer.status
  );
  const functionRan = useRef(false);
  const [modalVisible, setModalVisible] = useState<boolean>(() => false);
  const [loaded, setLoaded] = useState<boolean>(() => false);
  const [data, setData] = useState<any>(() => []);

  useEffect(() => {
    const fetchData = async () => {
      if (functionRan.current === false) {
        setLoaded(false);
        await dispatch(KeywordCategoryActions.getAllKeywordCategory());
      }
    };
    fetchData();
    if (keywordCategoryStatus) {
      setData(propKeywordCategories);
      setLoaded(true);
    }
    return () => {
      functionRan.current = true;
    };
  });

  const getKeywordCategories = async () => {
    setLoaded(false);
    await dispatch(KeywordCategoryActions.getAllKeywordCategory());
    if (keywordCategoryStatus) {
      if (propKeywordCategories.length > 0) {
        setLoaded(true);
      }
    }
  };

  return (
    <>
      <Row>
        <Col span={24} className="colPadding">
          <h1 className="title">
            <strong>Kulcsszó kategóriák</strong>
          </h1>
          <Row>
            <Row>
              <Col span={30} className="colPadding">
                <Button type="primary" onClick={() => setModalVisible(true)}>
                  Létrehozás
                </Button>
              </Col>
            </Row>
          </Row>
          <Col span={24}>
            <KeywordCategoryTable
              form={form}
              setModalVisible={setModalVisible}
              getKeywordCategories={getKeywordCategories}
              data={data}
              loaded={loaded}
              propKeywordCategories={propKeywordCategories}
              modalVisible={modalVisible}
            />
          </Col>
        </Col>
      </Row>
    </>
  );
}
