import { Button, Form, Input, message, Modal, Transfer } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashBoardActions from "../../actions/dashboard";
import DebounceFunc from "../../constants/debounce";
import { layout } from "../../constants/layout";
import { RootStore } from "../../store/store";

const DashboardModal = ({
  form,
  modalVisible,
  modify,
  closeModal,
  categoryTitle,
  bookTargetKeys,
  setChecked,
  selectedId,
  checked,
  setRefetch,
  nameChange,
  bookSelectedKeys,
  handleChangeTargetBooks,
  handleSelectChangeBook,
}: any) => {
  const dispatch = useDispatch();
  const propSaveStatus = useSelector(
    (state: RootStore) => state.dashBoardReducer.saveStatus
  );
  const propBook = useSelector((state: RootStore) => state.bookReducer.book);
  const [disabled, setDisabled] = useState<boolean>(() => true);
  const functionCreateCategory = async () => {
    const toSend = {
      categoryTitle,
      bookIds: bookTargetKeys,
    };
    await dispatch(DashBoardActions.createCategory(toSend));
    setChecked(true);
  };

  const functioonModifyCategory = async () => {
    const toSend = {
      id: selectedId,
      categoryTitle,
      bookIds: bookTargetKeys,
    };
    await dispatch(DashBoardActions.modifyCategory(toSend));
    setChecked(true);
  };

  useEffect(() => {
    const fetch = () => {
      if (propSaveStatus && checked) {
        message.success("Sikeres mentés");
        setChecked(false);
        closeModal();
        setRefetch(true);
      } else if (!propSaveStatus && checked) {
        setChecked(false);
        setRefetch(false);
        message.error("Hiba a mentés közben!");
      }
    };
    fetch();
  });
  useEffect(() => {
    if (!categoryTitle || bookTargetKeys.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [categoryTitle, bookTargetKeys]);
  return (
    <Modal
      visible={modalVisible}
      title={modify ? "Módosítás" : "Létrehozás"}
      onCancel={closeModal}
      maskClosable={false}
      forceRender
      centered
      footer={[
        <Button
          disabled={disabled}
          key="create"
          type="primary"
          onClick={modify ? functioonModifyCategory : functionCreateCategory}
        >
          {modify ? "Módosítás" : "Létrehozás"}
        </Button>,
        <Button key="cancel" onClick={closeModal}>
          Mégsem
        </Button>,
      ]}
      width="65em"
    >
      <Form
        form={form}
        {...layout}
        name="register"
        onFinish={modify ? functioonModifyCategory : functionCreateCategory}
        scrollToFirstError
      >
        <Form.Item
          name="categoryTitle"
          label="Kategória címe"
          rules={[
            {
              required: true,
              message: "Mező kitöltése kötelező!",
            },
          ]}
        >
          <Input
            name="categoryTitle"
            value={categoryTitle}
            maxLength={255}
            style={{ width: "38.5em" }}
            onChange={(e) => DebounceFunc(e.target.value, nameChange)}
          />
        </Form.Item>
        <Form.Item
          name="book"
          label="Könyvek"
          rules={[
            {
              required: true,
              message: "Mező kitöltése kötelező!",
            },
          ]}
        >
          <Transfer
            style={{ textAlign: "left" }}
            dataSource={propBook}
            render={(item) => `${item.title}`}
            targetKeys={bookTargetKeys}
            selectedKeys={bookSelectedKeys}
            rowKey={(record) => record.id.toString()}
            selectAllLabels={[<div></div>, <div></div>]}
            showSearch
            onChange={(nextTargetKeys: string[]) =>
              handleChangeTargetBooks(nextTargetKeys)
            }
            onSelectChange={(
              sourceSelectedKeys: string[],
              targetSelectedKeys: string[]
            ) =>
              handleSelectChangeBook([
                ...sourceSelectedKeys,
                ...targetSelectedKeys,
              ])
            }
            filterOption={(inputValue, item) =>
              item.title.toLowerCase()!.includes(inputValue.toLowerCase())
            }
            locale={{
              notFoundContent: "Üres",
              selectAll: "Összes kiválasztása",
              selectInvert: "Kiválasztás törlése",
            }}
            listStyle={{
              width: 250,
              height: 300,
              maxWidth: 250,
              maxHeight: 300,
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default DashboardModal;
