import {
  KeywordDispatchTypes,
  KeywordT,
} from "../constants/ActionTypes/keyword-action-types";

export interface initialKeywordState {
  keyword: KeywordT[];
  status: boolean;
  message: string;
  saveStatus: boolean;
}

const defaultState = {
  keyword: [],
  status: false,
  message: "",
  saveStatus: false,
};

const keywordReducer = (
  state: initialKeywordState = defaultState,
  action: KeywordDispatchTypes
): initialKeywordState => {
  switch (action.type) {
    case "KEYWORDS_LOADING":
      return Object.assign({}, state, {
        keyword: [],
        status: false,
        message: "",
      });
    case "KEYWORDS_LOADED":
      return Object.assign({}, state, {
        keyword: action.payload.keywords,
        status: true,
        message: "",
      });
    case "KEYWORDS_ERROR":
      return Object.assign({}, state, {
        keyword: [],
        status: false,
        message: action.payload,
      });
    case "KEYWORD_REGISTER_START":
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case "KEYWORD_REGISTER_SUCCESS":
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case "KEYWORD_REGISTER_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default keywordReducer;
