import { Row, Col, Button, Table, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QuestionActions from "../../actions/question";
import { AnswerT } from "../../constants/ActionTypes/question-action-types";
import { RootStore } from "../../store/store";
import { CheckOutlined } from "@ant-design/icons";
import Text from "./QuestionAnswerComponents/text";
import CheckBox from "./QuestionAnswerComponents/checkbox";
import TrueFalse from "./QuestionAnswerComponents/trueFalse";
import PictureTextPair from "./QuestionAnswerComponents/pictureTextPair";
import PicturePicturePair from "./QuestionAnswerComponents/picturePicturePair";
import TextTextPair from "./QuestionAnswerComponents/textTextPair";
import TextSequence from "./QuestionAnswerComponents/textSequence";
import PictureSequence from "./QuestionAnswerComponents/pictureSequence";
import "../formating/content.css";

export default function Answer({ question }: any) {
  const dispatch = useDispatch();
  const propAnswers = useSelector(
    (state: RootStore) => state.answerReducer.answers
  );
  const propSaveStatus = useSelector(
    (state: RootStore) => state.answerReducer.saveStatus
  );
  const [modalVisible, setModalVisible] = useState<boolean>(() => false);
  const [loaded, setLoaded] = useState<boolean>(() => false);
  const [checked, setChecked] = useState<boolean>(() => false);
  const [modify, setModify] = useState<boolean>(() => false);

  useEffect(() => {
    setLoaded(false);
    const fetch = () => {
      if (question?.id || (checked && propSaveStatus)) {
        dispatch(QuestionActions.getAllAnswerForQuestion(question.id));
        setLoaded(true);
      }
    };
    fetch();
  }, [question, dispatch, checked, propSaveStatus]);

  const sequenceColumns = [
    question?.questionType === 8
      ? {
          title: "Kérdés",
          dataIndex: "description",
          key: "description",
          render: (text: string, record: AnswerT) => {
            return (
              record.imageUrl && (
                <img
                  alt={record.imageUrl}
                  src={
                    process.env.REACT_APP_BASE_URL +
                    "/api/1.0.0/picture/getall/" +
                    record.fileName
                  }
                  className="questionAnswerImage"
                />
              )
            );
          },
        }
      : {
          title: "Kérdés",
          dataIndex: "description",
          key: "description",
        },
    {
      title: "Sorszám",
      dataIndex: "sequenceId",
      key: "sequenceId",
    },
  ];

  const modifyModal = () => {
    setModify(true);
    setModalVisible(true);
  };
  const columns = [];

  (question?.questionType === 0 ||
    question?.questionType === 1 ||
    question?.questionType === 2 ||
    question?.questionType === 4 ||
    question?.questionType === 6) &&
    columns.push({
      title:
        question?.questionType === 4 || question?.questionType === 6
          ? "Szöveg pár"
          : "Válasz",
      key: "description",
      render: (text: string, record: AnswerT) => {
        if (question?.questionType === 6) {
          const d = propAnswers?.filter(
            (pa) => pa.id === record.pairAnswerId && pa.description !== null
          );
          return d[0]?.description;
        } else {
          return record.description;
        }
      },
    });

  (question?.questionType === 1 ||
    question?.questionType === 2 ||
    question?.questionType === 3) &&
    columns.push(
      question?.questionType === 3
        ? {
            title: "Kérdés",
            key: "question",
            render: (text: string, record: AnswerT) => {
              return question?.question;
            },
          }
        : {},
      {
        title: "Helyes válasz",
        key: "isGood",
        render: (text: string, record: AnswerT) => {
          return (
            record.isGood && (
              <div>
                <CheckOutlined />
              </div>
            )
          );
        },
      }
    );

  (question?.questionType === 4 || question?.questionType === 5) &&
    columns.push(
      {
        title: "Kép pár",
        key: "picture",
        render: (text: string, record: AnswerT) => {
          if (record.description && record.pairAnswerId) {
            const pic = propAnswers?.filter(
              (pa) => pa.id === record.pairAnswerId && pa.imageUrl !== null
            );
            return (
              pic[0]?.imageUrl && (
                <img
                  alt={pic[0]?.imageUrl}
                  src={
                    process.env.REACT_APP_BASE_URL +
                    "/api/1.0.0/picture/getall/" +
                    pic[0]?.fileName
                  }
                  className="questionAnswerImage"
                />
              )
            );
          } else if (record.imageUrl && record.pairAnswerId) {
            const pic = propAnswers?.filter(
              (pa) => pa.pairAnswerId === record.id
            );
            return (
              pic[0]?.imageUrl && (
                <img
                  alt={pic[0]?.imageUrl}
                  src={
                    process.env.REACT_APP_BASE_URL +
                    "/api/1.0.0/picture/getall/" +
                    pic[0]?.fileName
                  }
                  className="questionAnswerImage"
                />
              )
            );
          }
        },
      },
      {
        title: "Kép leírás",
        key: "pictureDescription",
        render: (text: string, record: AnswerT) => {
          const pair = propAnswers?.filter(
            (pa) =>
              pa.id === record.pairAnswerId && pa.pictureDescription !== null
          );
          return pair[0]?.pictureDescription;
        },
      }
    );

  question?.questionType === 5 &&
    columns.push(
      {
        title: "Kép pár",
        key: "picturePair",
        render: (text: string, record: AnswerT) => {
          return (
            record.imageUrl && (
              <img
                alt={record.imageUrl}
                src={
                  process.env.REACT_APP_BASE_URL +
                  "/api/1.0.0/picture/getall/" +
                  record.fileName
                }
                className="questionAnswerImage"
              />
            )
          );
        },
      },
      {
        title: "Kép pár leírás",
        key: "pictureDescriptionPair",
        dataIndex: "pictureDescription",
      }
    );

  question?.questionType === 6 &&
    columns.push({
      title: "Szöveg pár",
      key: "description",
      render: (text: string, record: AnswerT) => {
        return record.description;
      },
    });

  question?.questionType === 8 &&
    sequenceColumns.push({
      title: "Kép leírás",
      key: "pictureDescription",
      dataIndex: "pictureDescription",
    });

  return (
    <>
      <Row>
        <Col span={24} className="colPadding">
          <h1 className="title">
            <strong>Kérdések és válaszok</strong>
          </h1>
          <Row>
            <Row>
              <Col span={30} className="colPadding">
                <Space size="middle">
                  {((question?.questionType === 0 ||
                    question?.questionType === 3) &&
                    propAnswers?.length === 0) ||
                  ((question?.questionType === 1 ||
                    question?.questionType === 2 ||
                    question?.questionType === 4 ||
                    question?.questionType === 5 ||
                    question?.questionType === 6 ||
                    question?.questionType === 7 ||
                    question?.questionType === 8) &&
                    propAnswers?.length < question?.answerCount) ? (
                    <Button
                      type="primary"
                      onClick={() => setModalVisible(true)}
                    >
                      Létrehozás
                    </Button>
                  ) : (
                    <Button type="primary" onClick={() => modifyModal()}>
                      Módosítás
                    </Button>
                  )}
                </Space>
              </Col>
            </Row>
          </Row>
          {question?.questionType === 7 || question?.questionType === 8 ? (
            <Table
              columns={sequenceColumns}
              rowKey="id"
              dataSource={propAnswers}
              loading={!loaded && !propAnswers}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
                showSizeChanger: false,
              }}
            />
          ) : (
            <Table
              columns={columns}
              rowKey="id"
              dataSource={
                question?.questionType === 4
                  ? propAnswers?.filter((pa) => pa.description !== null)
                  : question?.questionType === 5 || question?.questionType === 6
                  ? propAnswers?.filter((pa, i) => i % 2 === 0)
                  : propAnswers
              }
              loading={!loaded && !propAnswers}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
                showSizeChanger: false,
              }}
            />
          )}
        </Col>
      </Row>
      {question?.questionType === 0 && modalVisible ? (
        <Text
          question={question}
          modify={modify}
          setModify={setModify}
          setModalVisible={setModalVisible}
          modalVisible={modalVisible}
          checked={checked}
          setChecked={setChecked}
        />
      ) : (question?.questionType === 1 || question?.questionType === 2) &&
        modalVisible ? (
        <CheckBox
          question={question}
          modify={modify}
          setModify={setModify}
          setModalVisible={setModalVisible}
          modalVisible={modalVisible}
          checked={checked}
          setChecked={setChecked}
        />
      ) : question?.questionType === 3 && modalVisible ? (
        <TrueFalse
          question={question}
          modify={modify}
          setModify={setModify}
          setModalVisible={setModalVisible}
          modalVisible={modalVisible}
          checked={checked}
          setChecked={setChecked}
        />
      ) : question?.questionType === 4 && modalVisible ? (
        <PictureTextPair
          question={question}
          modify={modify}
          setModify={setModify}
          setModalVisible={setModalVisible}
          modalVisible={modalVisible}
          checked={checked}
          setChecked={setChecked}
        />
      ) : question?.questionType === 5 && modalVisible ? (
        <PicturePicturePair
          question={question}
          modify={modify}
          setModify={setModify}
          setModalVisible={setModalVisible}
          modalVisible={modalVisible}
          checked={checked}
          setChecked={setChecked}
        />
      ) : question?.questionType === 6 && modalVisible ? (
        <TextTextPair
          question={question}
          modify={modify}
          setModify={setModify}
          setModalVisible={setModalVisible}
          modalVisible={modalVisible}
          checked={checked}
          setChecked={setChecked}
        />
      ) : question?.questionType === 7 && modalVisible ? (
        <TextSequence
          question={question}
          modify={modify}
          setModify={setModify}
          setModalVisible={setModalVisible}
          modalVisible={modalVisible}
          checked={checked}
          setChecked={setChecked}
        />
      ) : question?.questionType === 8 && modalVisible ? (
        <PictureSequence
          question={question}
          modify={modify}
          setModify={setModify}
          setModalVisible={setModalVisible}
          modalVisible={modalVisible}
          checked={checked}
          setChecked={setChecked}
        />
      ) : (
        []
      )}
    </>
  );
}
