import { KEYWORDS_CATEGORY_ACTIONS } from "../../../constants/ActionTypes/ReducerActionTypes/reducer-action-types";

interface InitialState {
  name: string;
  selectedId: number;
}

export const initialState: InitialState = {
  name: "",
  selectedId: null!,
};

type Action =
  | { type: typeof KEYWORDS_CATEGORY_ACTIONS.SET_NAME; name: string }
  | {
      type: typeof KEYWORDS_CATEGORY_ACTIONS.SET_SELECTED_ID;
      selectedId: number;
    };

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case KEYWORDS_CATEGORY_ACTIONS.SET_NAME:
      return { ...state, name: action.name };
    case KEYWORDS_CATEGORY_ACTIONS.SET_SELECTED_ID:
      return { ...state, selectedId: action.selectedId };
    default:
      return state;
  }
};

export default reducer;
