import axios from "axios";
import { Dispatch } from "redux";
import { DashBoardDispatchTypes } from "../constants/ActionTypes/dashboard-action-types";

const getDashboard = (): any => {
  return async (dispatch: Dispatch<DashBoardDispatchTypes>) => {
    dispatch({ type: "DASHBOARDS_LOADING" });
    await axios
      .get("/1.0.0/category/dashboard", {})
      .then((response) => {
        dispatch({
          type: "DASHBOARDS_LOADED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DASHBOARDS_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const createCategory = (params: object): any => {
  return async (dispatch: Dispatch<DashBoardDispatchTypes>) => {
    dispatch({ type: "DASHBOARD_REGISTER_START" });
    await axios
      .post("/1.0.0/category/create", params, {})
      .then((response) => {
        dispatch({
          type: "DASHBOARD_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DASHBOARD_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const modifyCategory = (params: object): any => {
  return async (dispatch: Dispatch<DashBoardDispatchTypes>) => {
    dispatch({ type: "DASHBOARD_REGISTER_START" });
    await axios
      .put("/1.0.0/category/modify/", params, {})
      .then((response) => {
        dispatch({
          type: "DASHBOARD_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DASHBOARD_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const positionUpCategory = (id: number): any => {
  return async (dispatch: Dispatch<DashBoardDispatchTypes>) => {
    dispatch({ type: "DASHBOARD_REGISTER_START" });
    await axios
      .put("/1.0.0/category/up/" + id, {})
      .then((response) => {
        dispatch({
          type: "DASHBOARD_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DASHBOARD_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const positionDownCategory = (id: number): any => {
  return async (dispatch: Dispatch<DashBoardDispatchTypes>) => {
    dispatch({ type: "DASHBOARD_REGISTER_START" });
    await axios
      .put("/1.0.0/category/down/" + id, {})
      .then((response) => {
        dispatch({
          type: "DASHBOARD_REGISTER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DASHBOARD_REGISTER_ERROR",
          payload: err.response.data,
        });
      });
  };
};

const deleteCategory = (id: number): any => {
  return async () => {
    await axios.delete("/1.0.0/category/" + id).catch((err) => {
      console.log(err.message);
    });
  };
};

const DashBoardActions = {
  getDashboard,
  createCategory,
  modifyCategory,
  positionUpCategory,
  positionDownCategory,
  deleteCategory,
};
export default DashBoardActions;
