import {
  KeywordCategoryDispatchTypes,
  KeywordCategoryT,
} from "../constants/ActionTypes/keyword-category-action-types";

export interface initialKeywordCategoryState {
  keywordCategory: KeywordCategoryT[];
  status: boolean;
  message: string;
  saveStatus: boolean;
}

const defaultState = {
  keywordCategory: [],
  status: false,
  message: "",
  saveStatus: false,
};

const keywordCategoryReducer = (
  state: initialKeywordCategoryState = defaultState,
  action: KeywordCategoryDispatchTypes
): initialKeywordCategoryState => {
  switch (action.type) {
    case "KEYWORD_CATEGORIES_LOADING":
      return Object.assign({}, state, {
        keywordCategory: [],
        status: false,
        message: "",
      });
    case "KEYWORD_CATEGORIES_LOADED":
      return Object.assign({}, state, {
        keywordCategory: action.payload,
        status: true,
        message: "",
      });
    case "KEYWORD_CATEGORIES_ERROR":
      return Object.assign({}, state, {
        keywordCategory: [],
        status: false,
        message: action.payload,
      });
    case "KEYWORD_CATEGORIES_REGISTER_START":
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case "KEYWORD_CATEGORIES_REGISTER_SUCCESS":
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case "KEYWORD_CATEGORIES_REGISTER_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default keywordCategoryReducer;
