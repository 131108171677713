import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import loginReducer, { initialLoginState } from "../reducers/login";
import usersReducer, { initialUserState } from "../reducers/user";
import tagReducer, { initialTagState } from "../reducers/tag";
import videoReducer, { initialVideoState } from "../reducers/video";
import pictureReducer, { initialPictureState } from "../reducers/picture";
import audioReducer, { initialAudioState } from "../reducers/audio";
import taskReducer, { initialTaskState } from "../reducers/task";
import bookReducer, { initialBookState } from "../reducers/book";
import keywordReducer, { initialKeywordState } from "../reducers/keyword";
import booktypeReducer, { initialBookTypeState } from "../reducers/booktype";
import pageReducer, { initialPageState } from "../reducers/page";
import webcontentReducer, {
  initialWebContentState,
} from "../reducers/webcontent";
import { LoginDispatchTypes } from "../constants/ActionTypes/login-action-types";
import dashBoardReducer, { initialDashBoardState } from "../reducers/dashboard";
import notificationReducer, {
  initialNotificationState,
} from "../reducers/notification";
import questionReducer, { initialQuestionState } from "../reducers/question";
import answerReducer, { initialAnswerState } from "../reducers/answer";
import keywordCategoryReducer, {
  initialKeywordCategoryState,
} from "../reducers/keywordCategory";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["usersReducer"],
};

type AllStateType = {
  usersReducer: initialUserState;
  tagReducer: initialTagState;
  videoReducer: initialVideoState;
  pictureReducer: initialPictureState;
  audioReducer: initialAudioState;
  taskReducer: initialTaskState;
  bookReducer: initialBookState;
  keywordReducer: initialKeywordState;
  booktypeReducer: initialBookTypeState;
  pageReducer: initialPageState;
  webcontentReducer: initialWebContentState;
  loginReducer: initialLoginState;
  dashBoardReducer: initialDashBoardState;
  notificationReducer: initialNotificationState;
  questionReducer: initialQuestionState;
  answerReducer: initialAnswerState;
  keywordCategoryReducer: initialKeywordCategoryState;
};

const defaultState = {
  usersReducer: undefined!,
  tagReducer: undefined!,
  videoReducer: undefined!,
  pictureReducer: undefined!,
  audioReducer: undefined!,
  taskReducer: undefined!,
  bookReducer: undefined!,
  keywordReducer: undefined!,
  booktypeReducer: undefined!,
  pageReducer: undefined!,
  webcontentReducer: undefined!,
  loginReducer: undefined!,
  dashBoardReducer: undefined!,
  notificationReducer: undefined!,
  questionReducer: undefined!,
  answerReducer: undefined!,
  keywordCategoryReducer: undefined!,
};

const rootReducer = (
  state: AllStateType = defaultState,
  action: LoginDispatchTypes
) => {
  if (action.type === "LOGOUT") {
    state.usersReducer = undefined!;
    state.tagReducer = undefined!;
    state.videoReducer = undefined!;
    state.pictureReducer = undefined!;
    state.audioReducer = undefined!;
    state.taskReducer = undefined!;
    state.bookReducer = undefined!;
    state.keywordReducer = undefined!;
    state.booktypeReducer = undefined!;
    state.pageReducer = undefined!;
    state.webcontentReducer = undefined!;
    state.dashBoardReducer = undefined!;
    state.notificationReducer = undefined!;
    state.questionReducer = undefined!;
    state.answerReducer = undefined!;
    state.keywordCategoryReducer = undefined!;
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  loginReducer,
  usersReducer,
  tagReducer,
  videoReducer,
  pictureReducer,
  audioReducer,
  taskReducer,
  bookReducer,
  keywordReducer,
  booktypeReducer,
  pageReducer,
  webcontentReducer,
  dashBoardReducer,
  notificationReducer,
  questionReducer,
  answerReducer,
  keywordCategoryReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export type RootStore = ReturnType<typeof appReducer>;

export const persistor = persistStore(store);
