import {
  message,
  Row,
  Col,
  Button,
  Popconfirm,
  Form,
  Modal,
  Table,
  Space,
  Transfer,
  InputNumber,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteOutlined,
  EditOutlined,
  ArrowLeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import PageActions from "../../actions/page";
import { RootStore } from "../../store/store";
import { PageT } from "../../constants/ActionTypes/page-action-types";
import { useHistory, useLocation } from "react-router-dom";
import { layout } from "../../constants/layout";
import "../formating/content.css";

export default function Page() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const effectRan = useRef(false);
  const selectorRan = useRef(false);
  const propPage = useSelector((state: RootStore) => state.pageReducer.page);
  const propPageContent = useSelector(
    (state: RootStore) => state.pageReducer.pageWithContent
  );
  const propPageContentStatus = useSelector(
    (state: RootStore) => state.pageReducer.pageContentStatus
  );
  const pageStatus = useSelector(
    (state: RootStore) => state.pageReducer.status
  );
  const propTaskContent = useSelector(
    (state: RootStore) => state.pageReducer.pageWithTaskContent
  );
  const propAudioContent = useSelector(
    (state: RootStore) => state.pageReducer.pageWithAudioContent
  );
  const propPictureContent = useSelector(
    (state: RootStore) => state.pageReducer.pageWithPictureContent
  );
  const propVideoContent = useSelector(
    (state: RootStore) => state.pageReducer.pageWithVideoContent
  );
  const propWebContent = useSelector(
    (state: RootStore) => state.pageReducer.pageWithWebContent
  );
  const location = useLocation<{ bookId: number }>();
  const history = useHistory();
  const pageSaveStatus = useSelector(
    (state: RootStore) => state.pageReducer.saveStatus
  );

  const [pageNumber, setPageNumber] = useState<number>(() => null!);
  const [modalVisible, setModalVisible] = useState<boolean>(() => false);
  const [modify, setModify] = useState<boolean>(() => false);
  const [loaded, setLoaded] = useState<boolean>(() => false);
  const [selectedId, setSelectedId] = useState<number>(() => null!);
  const [checked, setChecked] = useState<boolean>(() => false);
  const [audioTargetKeys, setAudioTargetKeys] = useState<string[]>(() => []);
  const [audioSelectedKeys, setAudioSelectedKeys] = useState<string[]>(
    () => []
  );
  const [pictureTargetKeys, setPictureTargetKeys] = useState<string[]>(
    () => []
  );
  const [pictureSelectedKeys, setPictureSelectedKeys] = useState<string[]>(
    () => []
  );
  const [videoTargetKeys, setVideoTargetKeys] = useState<string[]>(() => []);
  const [videoSelectedKeys, setVideoSelectedKeys] = useState<string[]>(
    () => []
  );
  const [taskTargetKeys, setTaskTargetKeys] = useState<string[]>(() => []);
  const [taskSelectedKeys, setTaskSelectedKeys] = useState<string[]>(() => []);
  const [webContentTargetKeys, setWebContentTargetKeys] = useState<string[]>(
    () => []
  );
  const [webContentSelectedKeys, setWebContentSelectedKeys] = useState<
    string[]
  >(() => []);
  const [bookId, setBookId] = useState<number>(() => null!);
  const [refetch, setRefetch] = useState<boolean>(() => false);
  const [onClick, setOnClick] = useState<boolean>(() => false);
  const [taskVisible, setTaskVisible] = useState<boolean>(() => false);
  const [audioVisible, setAudioVisible] = useState<boolean>(() => false);
  const [pictureVisible, setPictureVisible] = useState<boolean>(() => false);
  const [videoVisible, setVideoVisible] = useState<boolean>(() => false);
  const [webContentVisible, setWebContentVisible] = useState<boolean>(
    () => false
  );
  const [contentLoading, setContentLoading] = useState<boolean>(() => true);
  const [clickOnTask, setClickOnTask] = useState<boolean>(() => false);
  const [clickOnAudio, setClickOnAudio] = useState<boolean>(() => false);
  const [clickOnPicture, setClickOnPicture] = useState<boolean>(() => false);
  const [clickOnVideo, setClickOnVideo] = useState<boolean>(() => false);
  const [clickOnWebContent, setClickOnWebContent] = useState<boolean>(
    () => false
  );
  useEffect(() => {
    const fetchData = async () => {
      if (effectRan.current === false || onClick) {
        try {
          await dispatch(PageActions.getAllPageByBookId(location.state.bookId));
          await dispatch(
            PageActions.getAllPageTask(location.state.bookId.toString())
          );
          await dispatch(
            PageActions.getAllPageAudio(location.state.bookId.toString())
          );

          await dispatch(
            PageActions.getAllPagePicture(location.state.bookId.toString())
          );

          await dispatch(
            PageActions.getAllPageVideo(location.state.bookId.toString())
          );

          await dispatch(
            PageActions.getAllPageWebContent(location.state.bookId.toString())
          );
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetchData();
    setBookId(location.state.bookId);
    return () => {
      effectRan.current = true;
    };
  }, [dispatch, location, checked, pageSaveStatus, bookId, onClick]);
  useEffect(() => {
    if (
      (pageStatus && bookId !== null && selectorRan.current === false) ||
      refetch
    ) {
      return () => {
        setLoaded(true);
        setOnClick(false);
        setRefetch(false);
        selectorRan.current = true;
      };
    }
  }, [pageStatus, bookId, propPage, refetch]);

  const onReset = () => {
    form.resetFields();
  };
  const closeModal = () => {
    setModalVisible(false);
    setModify(false);
    setPageNumber(null!);
    setTaskTargetKeys([]);
    setPictureTargetKeys([]);
    setVideoTargetKeys([]);
    setAudioTargetKeys([]);
    setWebContentTargetKeys([]);
    setTaskVisible(false);
    setAudioVisible(false);
    setPictureVisible(false);
    setVideoVisible(false);
    setWebContentVisible(false);
    onReset();
  };

  const functionCreatePage = async () => {
    const decider = propPage.filter((pp) => pp.pageNumber === pageNumber);
    if (pageNumber > 0 && decider.length === 0) {
      let pictureIds = [];
      for (const pictureId of pictureTargetKeys) {
        pictureIds.push(pictureId.replace(/\s|,/g, ""));
      }
      let taskIds = [];
      for (const taskId of taskTargetKeys) {
        taskIds.push(taskId.replace(/\s|,/g, ""));
      }
      let videoIds = [];
      for (const videoId of videoTargetKeys) {
        videoIds.push(videoId.replace(/\s|,/g, ""));
      }
      let audioIds = [];
      for (const audioId of audioTargetKeys) {
        audioIds.push(audioId.replace(/\s|,/g, ""));
      }
      let webContentIds = [];
      for (const webContentId of webContentTargetKeys) {
        webContentIds.push(webContentId.replace(/\s|,/g, ""));
      }
      const toSend = {
        pageNumber,
        bookId: bookId,
        taskIds: taskIds,
        pictureIds: pictureIds,
        videoIds: videoIds,
        audioIds: audioIds,
        webcontentIds: webContentIds,
      };
      await dispatch(PageActions.createPage(toSend));
      setChecked(true);
    } else {
      message.error(
        "Létezik ilyen számú oldal, vagy az oldalszám nem nagyobb mint 0."
      );
    }
  };

  const functionModifyPage = async () => {
    let pictureIds = [];
    try {
      for (const pictureId of pictureTargetKeys) {
        pictureIds.push(pictureId.replace(/\s|,/g, ""));
      }
    } catch (err) {
      pictureIds.push("0");
    }

    let taskIds = [];
    try {
      for (const taskId of taskTargetKeys) {
        taskIds.push(taskId.replace(/\s|,/g, ""));
      }
    } catch (err) {
      taskIds.push("0");
    }
    let videoIds = [];
    try {
      for (const videoId of videoTargetKeys) {
        videoIds.push(videoId.replace(/\s|,/g, ""));
      }
    } catch (error) {
      videoIds.push("0");
    }
    let audioIds = [];
    try {
      for (const audioId of audioTargetKeys) {
        audioIds.push(audioId.replace(/\s|,/g, ""));
      }
    } catch (error) {
      audioIds.push("0");
    }
    let webContentIds = [];
    try {
      for (const webContentId of webContentTargetKeys) {
        webContentIds.push(webContentId.replace(/\s|,/g, ""));
      }
    } catch (error) {
      webContentIds.push("0");
    }

    const toSend = {
      id: selectedId,
      pageNumber,
      bookId: bookId,
      taskIds: taskIds,
      pictureIds: pictureIds,
      videoIds: videoIds,
      audioIds: audioIds,
      webcontentIds: webContentIds,
    };
    await dispatch(PageActions.modifyPage(toSend));
    setChecked(true);
  };

  useEffect(() => {
    const fetch = () => {
      if (pageSaveStatus && checked) {
        setChecked(false);
        setOnClick(true);
        message.success("Sikeres mentés");
        closeModal();
      } else if (!pageSaveStatus && checked) {
        setOnClick(false);
        setChecked(false);
        message.error("Hiba az feladat mentése közben!");
      }
    };
    fetch();
  }, [pageSaveStatus, checked]);

  const functionDeletePage = async (record: PageT) => {
    setOnClick(true);
    await dispatch(PageActions.deletePage(record.id));
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const showModifyModal = (record: PageT) => {
    form.setFieldsValue({
      pageNumber: record.pageNumber,
    });
    setModalVisible(true);
    setModify(true);
    setSelectedId(record.id);
    setPageNumber(record.pageNumber);
  };

  const handleChangeTargetAudios = (
    nextTargetKeys: string[],
    direction: string,
    moveKeys: string[]
  ) => {
    setAudioTargetKeys(nextTargetKeys);
  };

  const handleSelectChangeAudio = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => {
    setAudioSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleChangeTargetPictures = (
    nextTargetKeys: string[],
    direction: string,
    moveKeys: string[]
  ) => {
    setPictureTargetKeys(nextTargetKeys);
  };

  const handleSelectChangePicture = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => {
    setPictureSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleChangeTargetVideos = (
    nextTargetKeys: string[],
    direction: string,
    moveKeys: string[]
  ) => {
    setVideoTargetKeys(nextTargetKeys);
  };

  const handleSelectChangeVideo = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => {
    setVideoSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleChangeTargetTasks = (
    nextTargetKeys: string[],
    direction: string,
    moveKeys: string[]
  ) => {
    setTaskTargetKeys(nextTargetKeys);
  };

  const handleSelectChangeTask = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => {
    setTaskSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleChangeTargetWebContents = (
    nextTargetKeys: string[],
    direction: string,
    moveKeys: string[]
  ) => {
    setWebContentTargetKeys(nextTargetKeys);
  };

  const handleSelectChangeWebContent = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => {
    setWebContentSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const BackButton = async () => {
    history.push({ pathname: "/raabe-klett/book" });
  };

  const OpenTask = () => {
    dispatch(
      PageActions.getAllPageContent(
        modify ? null! : bookId,
        selectedId,
        "true",
        null!,
        null!,
        null!,
        null!
      )
    );
    setClickOnTask(true);
    setClickOnAudio(false);
    setClickOnPicture(false);
    setClickOnVideo(false);
    setClickOnWebContent(false);
  };

  const OpenAudio = () => {
    dispatch(
      PageActions.getAllPageContent(
        modify ? null! : bookId,
        selectedId,
        null!,
        "true",
        null!,
        null!,
        null!
      )
    );
    setClickOnAudio(true);
    setClickOnTask(false);
    setClickOnPicture(false);
    setClickOnVideo(false);
    setClickOnWebContent(false);
  };

  const OpenPicture = () => {
    dispatch(
      PageActions.getAllPageContent(
        modify ? null! : bookId,
        selectedId,
        null!,
        null!,
        "true",
        null!,
        null!
      )
    );
    setClickOnPicture(true);
    setClickOnAudio(false);
    setClickOnTask(false);
    setClickOnVideo(false);
    setClickOnWebContent(false);
  };

  const OpenVideo = () => {
    dispatch(
      PageActions.getAllPageContent(
        modify ? null! : bookId,
        selectedId,
        null!,
        null!,
        null!,
        "true",
        null!
      )
    );
    setClickOnVideo(true);
    setClickOnPicture(false);
    setClickOnAudio(false);
    setClickOnTask(false);
    setClickOnWebContent(false);
  };
  const OpenWebContent = () => {
    dispatch(
      PageActions.getAllPageContent(
        modify ? null! : bookId,
        selectedId,
        null!,
        null!,
        null!,
        null!,
        "true"
      )
    );
    setClickOnWebContent(true);
    setClickOnVideo(false);
    setClickOnPicture(false);
    setClickOnAudio(false);
    setClickOnTask(false);
  };

  useEffect(() => {
    if (!propPageContentStatus) {
      setContentLoading(true);
    } else {
      setContentLoading(false);
      if (clickOnTask) {
        setTaskVisible(true);
        setAudioVisible(false);
        setPictureVisible(false);
        setVideoVisible(false);
        setWebContentVisible(false);
      }
      if (clickOnAudio) {
        setAudioVisible(true);
        setTaskVisible(false);
        setPictureVisible(false);
        setVideoVisible(false);
        setWebContentVisible(false);
      }
      if (clickOnPicture) {
        setPictureVisible(true);
        setTaskVisible(false);
        setAudioVisible(false);
        setVideoVisible(false);
        setWebContentVisible(false);
      }
      if (clickOnVideo) {
        setVideoVisible(true);
        setTaskVisible(false);
        setAudioVisible(false);
        setPictureVisible(false);
        setWebContentVisible(false);
      }
      if (clickOnWebContent) {
        setWebContentVisible(true);
        setTaskVisible(false);
        setAudioVisible(false);
        setPictureVisible(false);
        setVideoVisible(false);
      }
    }
  }, [propPageContentStatus]);

  const columns = [
    {
      title: "Oldalszám",
      key: "pageNumber",
      dataIndex: "pageNumber",
    },
    {
      title: "Tesztsorok száma",
      key: "tasks",
      render: (text: string, record: PageT) => {
        let counter = "";
        propTaskContent.filter(
          (page) =>
            page.id === record.id && (counter = `${page.task.length} db`)
        );
        return counter;
      },
    },
    {
      title: "Képek száma",
      key: "pictures",
      render: (text: string, record: PageT) => {
        let counter = "";
        propPictureContent.filter(
          (page) =>
            page.id === record.id && (counter = `${page.picture.length} db`)
        );
        return counter;
      },
    },
    {
      title: "Audiók száma",
      key: "audios",
      render: (text: string, record: PageT) => {
        let counter = "";
        propAudioContent.filter(
          (page) =>
            page.id === record.id && (counter = `${page.audio.length} db`)
        );
        return counter;
      },
    },
    {
      title: "Videók száma",
      key: "videos",
      render: (text: string, record: PageT) => {
        let counter = "";
        propVideoContent.filter(
          (page) =>
            page.id === record.id && (counter = `${page.video.length} db`)
        );
        return counter;
      },
    },
    {
      title: "Webkontentek száma",
      key: "webcontents",
      render: (text: string, record: PageT) => {
        let counter = "";
        propWebContent.filter(
          (page) =>
            page.id === record.id && (counter = `${page.webContents.length} db`)
        );
        return counter;
      },
    },
    {
      title: "Módosítás",
      key: "modify",
      render: (text: string, record: PageT) => {
        return (
          <Space size="middle">
            <Button onClick={() => showModifyModal(record)}>
              <EditOutlined />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Törlés",
      key: "delete",
      render: (text: string, record: PageT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan törölni akarod ezt az oldalt?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => functionDeletePage(record)}
            >
              <Button type="primary" danger>
                <DeleteOutlined key="delete" />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    if (propPageContent && modify) {
      setTaskTargetKeys(
        propPageContent.task?.map((t) => t.id.toString())
      );
      setAudioTargetKeys(
        propPageContent.audio?.map((a) => a.id.toString())
      );
      setPictureTargetKeys(
        propPageContent.picture?.map((p) => p.id.toString())
      );
      setVideoTargetKeys(
        propPageContent.video?.map((v) => v.id.toString())
      );
      setWebContentTargetKeys(
        propPageContent.webContents?.map((w) => w.id.toString())
      );
    }
  }, [propPageContent, modify]);

  return (
    <div className="content">
      <Row>
        <Col span={24} className="colPadding">
          <h1 className="title">
            <strong>Oldalak</strong>
          </h1>
          <Row>
            <Row>
              <Col span={30} className="colPadding">
                <Space size={"middle"}>
                  <Button type="primary" onClick={BackButton}>
                    <ArrowLeftOutlined />
                    Vissza
                  </Button>
                  <Button type="primary" onClick={showModal}>
                    Létrehozás
                  </Button>
                </Space>
              </Col>
            </Row>
          </Row>
          <Col span={24}>
            <Table
              columns={columns}
              rowKey="id"
              dataSource={propPage}
              loading={!loaded && !propPage}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
                showSizeChanger: false,
              }}
            />
          </Col>
        </Col>
      </Row>
      <Modal
        visible={modalVisible}
        onCancel={closeModal}
        title={modify ? "Módosítás" : "Létrehozás"}
        maskClosable={false}
        forceRender
        centered
        footer={[
          <Button
            key="createPage"
            type="primary"
            onClick={modify ? functionModifyPage : functionCreatePage}
          >
            {modify ? "Módosítás" : "Létrehozás"}
          </Button>,
          <Button key="cancel" onClick={closeModal}>
            Mégsem
          </Button>,
        ]}
        width="70em"
      >
        <Row>
          <Col span={24} className="colPadding">
            <Form
              form={form}
              {...layout}
              name="register"
              onFinish={modify ? functionModifyPage : functionCreatePage}
              scrollToFirstError
            >
              <Form.Item
                name="pageNumber"
                label="Lapszám"
                rules={[
                  {
                    required: true,
                    message: "Mező kitöltése kötelező!",
                  },
                ]}
              >
                <InputNumber
                  name="pageNumber"
                  min={1}
                  value={pageNumber!}
                  onChange={(e) => setPageNumber(e)}
                />
              </Form.Item>
              <Button
                type="primary"
                onClick={OpenTask}
                style={{
                  marginRight: "1em",
                  marginBottom: "1em",
                  marginLeft: "13.5em",
                }}
              >
                Tesztsorok
              </Button>
              <Button
                type="primary"
                onClick={OpenAudio}
                style={{ marginRight: "1em", marginBottom: "1em" }}
              >
                Audiók
              </Button>
              <Button
                type="primary"
                onClick={OpenPicture}
                style={{ marginRight: "1em", marginBottom: "1em" }}
              >
                Képek
              </Button>
              <Button
                type="primary"
                onClick={OpenVideo}
                style={{ marginRight: "1em", marginBottom: "1em" }}
              >
                Videók
              </Button>
              <Button
                type="primary"
                onClick={OpenWebContent}
                style={{ marginRight: "1em", marginBottom: "1em" }}
              >
                Webkontent
              </Button>
              {taskVisible && (
                <div>
                  {contentLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <Form.Item name="tasks" label="Tesztsorok">
                      <Transfer
                        style={{ textAlign: "left" }}
                        dataSource={propPageContent.book?.tasks}
                        render={(item) => `${item.title}`}
                        targetKeys={taskTargetKeys}
                        selectedKeys={taskSelectedKeys}
                        onChange={handleChangeTargetTasks}
                        onSelectChange={handleSelectChangeTask}
                        rowKey={(record) => record.id.toString()}
                        selectAllLabels={[<div></div>, <div></div>]}
                        showSearch
                        filterOption={(inputValue, item) =>
                          item.title
                            .toLowerCase()!
                            .includes(inputValue.toLowerCase())
                        }
                        locale={{
                          notFoundContent: "Üres",
                          selectAll: "Összes kiválasztása",
                          selectInvert: "Kiválasztás törlése",
                        }}
                        listStyle={{
                          width: 320,
                          height: 350,
                          maxWidth: 320,
                          maxHeight: 350,
                        }}
                      />
                    </Form.Item>
                  )}
                </div>
              )}
              {audioVisible && (
                <div>
                  {contentLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <Form.Item name="audios" label="Audiók">
                      <Transfer
                        style={{ textAlign: "left" }}
                        dataSource={propPageContent.book?.audios}
                        render={(item) => `${item.name}`}
                        targetKeys={audioTargetKeys}
                        selectedKeys={audioSelectedKeys}
                        onChange={handleChangeTargetAudios}
                        onSelectChange={handleSelectChangeAudio}
                        rowKey={(record) => record.id.toString()}
                        selectAllLabels={[<div></div>, <div></div>]}
                        showSearch
                        filterOption={(inputValue, item) =>
                          item.name.toLowerCase()!.includes(inputValue)
                        }
                        locale={{
                          notFoundContent: "Üres",
                          selectAll: "Összes kiválasztása",
                          selectInvert: "Kiválasztás törlése",
                        }}
                        listStyle={{
                          width: 320,
                          height: 350,
                          maxWidth: 320,
                          maxHeight: 350,
                        }}
                      />
                    </Form.Item>
                  )}
                </div>
              )}
              {pictureVisible && (
                <div>
                  {contentLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <Form.Item name="picture" label="Képek">
                      <Transfer
                        style={{ textAlign: "left" }}
                        dataSource={propPageContent.book?.pictures}
                        render={(item) => `${item.name}`}
                        targetKeys={pictureTargetKeys}
                        selectedKeys={pictureSelectedKeys}
                        onChange={handleChangeTargetPictures}
                        onSelectChange={handleSelectChangePicture}
                        rowKey={(record) => record.id.toString()}
                        selectAllLabels={[<div></div>, <div></div>]}
                        showSearch
                        filterOption={(inputValue, item) =>
                          item.name
                            .toLowerCase()!
                            .includes(inputValue.toLowerCase())
                        }
                        locale={{
                          notFoundContent: "Üres",
                          selectAll: "Összes kiválasztása",
                          selectInvert: "Kiválasztás törlése",
                        }}
                        listStyle={{
                          width: 320,
                          height: 350,
                          maxWidth: 320,
                          maxHeight: 350,
                        }}
                      />
                    </Form.Item>
                  )}
                </div>
              )}
              {videoVisible && (
                <div>
                  {contentLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <Form.Item name="video" label="Videók">
                      <Transfer
                        style={{ textAlign: "left" }}
                        dataSource={propPageContent.book?.videos}
                        render={(item) => `${item.name}`}
                        targetKeys={videoTargetKeys}
                        selectedKeys={videoSelectedKeys}
                        onChange={handleChangeTargetVideos}
                        onSelectChange={handleSelectChangeVideo}
                        rowKey={(record) => record.id.toString()}
                        selectAllLabels={[<div></div>, <div></div>]}
                        showSearch
                        filterOption={(inputValue, item) =>
                          item.name
                            .toLowerCase()!
                            .includes(inputValue.toLowerCase())
                        }
                        locale={{
                          notFoundContent: "Üres",
                          selectAll: "Összes kiválasztása",
                          selectInvert: "Kiválasztás törlése",
                        }}
                        listStyle={{
                          width: 320,
                          height: 350,
                          maxWidth: 320,
                          maxHeight: 350,
                        }}
                      />
                    </Form.Item>
                  )}
                </div>
              )}
              {webContentVisible && (
                <div>
                  {contentLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <Form.Item name="webContent" label="Webkontent">
                      <Transfer
                        style={{ textAlign: "left" }}
                        dataSource={propPageContent.book?.webcontents}
                        render={(item) => `${item.name}`}
                        targetKeys={webContentTargetKeys}
                        selectedKeys={webContentSelectedKeys}
                        onChange={handleChangeTargetWebContents}
                        onSelectChange={handleSelectChangeWebContent}
                        rowKey={(record) => record.id.toString()}
                        selectAllLabels={[<div></div>, <div></div>]}
                        showSearch
                        filterOption={(inputValue, item) =>
                          item.name
                            .toLowerCase()!
                            .includes(inputValue.toLowerCase())
                        }
                        locale={{
                          notFoundContent: "Üres",
                          selectAll: "Összes kiválasztása",
                          selectInvert: "Kiválasztás törlése",
                        }}
                        listStyle={{
                          width: 320,
                          height: 350,
                          maxWidth: 320,
                          maxHeight: 350,
                        }}
                      />
                    </Form.Item>
                  )}
                </div>
              )}
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
