import { ADMIN_ACTIONS } from "../../constants/ActionTypes/ReducerActionTypes/reducer-action-types";

interface InitialState {
  email: string;
  password: string;
  name: string;
  loaded: boolean;
  checked: boolean;
  isDelete: boolean;
}

export const initialState: InitialState = {
  email: "",
  password: "",
  name: "",
  loaded: false,
  checked: false,
  isDelete: false,
};

type Action =
  | { type: typeof ADMIN_ACTIONS.SET_EMAIL; email: string }
  | { type: typeof ADMIN_ACTIONS.SET_PASSWORD; password: string }
  | { type: typeof ADMIN_ACTIONS.SET_NAME; name: string }
  | { type: typeof ADMIN_ACTIONS.SET_LOADED; loaded: boolean }
  | { type: typeof ADMIN_ACTIONS.SET_CHECKED; checked: boolean }
  | { type: typeof ADMIN_ACTIONS.SET_IS_DELETE; isDelete: boolean };

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ADMIN_ACTIONS.SET_EMAIL:
      return { ...state, email: action.email };
    case ADMIN_ACTIONS.SET_PASSWORD:
      return { ...state, password: action.password };
    case ADMIN_ACTIONS.SET_NAME:
      return { ...state, name: action.name };
    case ADMIN_ACTIONS.SET_LOADED:
      return { ...state, loaded: action.loaded };
    case ADMIN_ACTIONS.SET_CHECKED:
      return { ...state, checked: action.checked };
    case ADMIN_ACTIONS.SET_IS_DELETE:
      return { ...state, isDelete: action.isDelete };
    default:
      return state;
  }
};

export default reducer;
