import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { RootStore } from "../../store/store";

export default function LoginRedirect() {
  const status = useSelector((state: RootStore) => state.loginReducer.status);
  const history = useHistory();
  const location = useLocation();
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get("token");
      if (token) {
        if (status) {
          history.push("/raabe-klett");
        } else {
          history.push("/");
          message.error("Sikertelen átirányítás", 5);
        }
      } else {
        history.push("/");
        message.error("Sikertelen átirányítás", 5);
      }
    }
    return () => {
      effectRan.current = true;
    };
  }, [history, location.search, status]);
  return (
    <div>
      <div>Átirányítás folyamatban</div>
    </div>
  );
}
