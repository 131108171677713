import React, { useCallback, useEffect, useState } from "react";
import Body from "./body";
import { Button, Layout, Menu, Tag } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
  VideoCameraOutlined,
  CameraOutlined,
  SolutionOutlined,
  AudioOutlined,
  OrderedListOutlined,
  ReconciliationOutlined,
  BookOutlined,
  NotificationOutlined,
  FileWordOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import "./main.scss";
import { useDispatch, useSelector } from "react-redux";
import LoginActions from "../actions/login";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import HttpService from "../services/http";
import socketIOClient, { Socket } from "socket.io-client";
import TagActions from "../actions/tag";
import SubMenu from "antd/lib/menu/SubMenu";
import type { MenuProps } from "antd";
import { RootStore } from "../store/store";
import { userRoles } from "../constants/roles";
import jwtDecode from "jwt-decode";

const { Header, Sider, Content } = Layout;

export default function Main() {
  const token = useSelector((state: RootStore) => state.loginReducer.token);

  const message = useSelector((state: RootStore) => state.tagReducer.message);
  const visible = useSelector((state: RootStore) => state.tagReducer.visible);
  const user = useSelector((state: RootStore) => state.loginReducer.user);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [selectedKey, setSelectedKey] = useState<string>(() =>
    user.groups.map((group) => group.id).includes(1) ? "10" : "6"
  );
  const [stateSocket, setSocket] = useState<Socket>(null!);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  HttpService.setAxiosDefaults(token);

  const defaultSelectedKey = useCallback(() => {
    switch (location.pathname) {
      case "/raabe-klett/admins":
        return "10";
      case "/raabe-klett/book":
        return "6";
      case "/raabe-klett/task":
        return "5";
      case "/raabe-klett/video":
        return "2";
      case "/raabe-klett/picture":
        return "3";
      case "/raabe-klett/audio":
        return "4";
      case "/raabe-klett/webcontent":
        return "9";
      case "/raabe-klett/dashboard":
        return "7";
      case "/raabe-klett/notification":
        return "8";
      default:
        return "";
    }
  }, [location.pathname]);

  useEffect(() => {
    setSelectedKey(defaultSelectedKey());
    const socket = socketIOClient(`${process.env.REACT_APP_BASE_URL}`, {
      transports: ["websocket"],
      query: { token: token.split(" ")[1] },
      path: "/api/socket.io",
      secure: true,
    });
    setSocket(socket);
    socket.io.connect();
  }, [token, defaultSelectedKey]);

  const handleSelect: MenuProps["onClick"] = async (event) => {
    setSelectedKey(event.key);
  };

  const logoutAction = useCallback(() => {
    HttpService.clearToken();
    if (stateSocket) {
      stateSocket.disconnect();
    }
    dispatch(LoginActions.logout());
    history.push("/login");
  }, [dispatch, history, stateSocket]);

  useEffect(() => {
    const decodedToken: { exp: number } = jwtDecode(token!);
    const expirationTime = decodedToken.exp * 1000;
    const currentTime = Date.now();
    if (expirationTime < currentTime) {
      logoutAction();
    }
  }, [token, logoutAction]);

  const handleMenuClick: MenuProps["onClick"] = async (event) => {
    switch (event.key) {
      case "logout":
        logoutAction();
        break;
      default:
        break;
    }
  };

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const theOpenKeys = (event: string[]) => {
    if (openKeys.length === 0) {
      setOpenKeys(event);
    } else {
      const notInculded = event.filter(
        (element: string) => element !== openKeys[0]
      );
      setOpenKeys(notInculded);
    }
  };

  const hasRight = (rights: number[]) => {
    let allow = false;
    user.groups.forEach((group) => {
      if (rights.includes(group.id)) {
        allow = true;
      }
    });
    return allow;
  };

  return (
    <div style={{ height: "100vh" }}>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          className={!collapsed ? "sider" : "collapsed"}
          style={{
            background: "#151917",
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            // left: 0,
            // top: 0,
            // bottom: 0,
          }}
        >
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[selectedKey]}
            onSelect={handleSelect}
            style={{
              background: "#151917",
            }}
            openKeys={openKeys}
            onOpenChange={theOpenKeys}
            subMenuOpenDelay={0}
            forceSubMenuRender
            onClick={handleMenuClick}
          >
            {hasRight([userRoles.SUPER_ADMIN]) && (
              <Menu.Item key="10" icon={<UsergroupAddOutlined />}>
                <NavLink to="/raabe-klett/admins" className="link-color">
                  Felhasználókezelés
                </NavLink>
              </Menu.Item>
            )}
            <Menu.Item key="6" icon={<BookOutlined />}>
              <NavLink to="/raabe-klett/book" className="link-color">
                Könyvek
              </NavLink>
            </Menu.Item>
            <SubMenu icon={<SolutionOutlined />} title="Kontentek">
              <Menu.Item key="5" icon={<OrderedListOutlined />}>
                <NavLink to="/raabe-klett/task" className="link-color">
                  Tesztsorok
                </NavLink>
              </Menu.Item>
              <Menu.Item key="2" icon={<VideoCameraOutlined />}>
                <NavLink to="/raabe-klett/video" className="link-color">
                  Videók
                </NavLink>
              </Menu.Item>
              <Menu.Item key="3" icon={<CameraOutlined />}>
                <NavLink to="/raabe-klett/picture" className="link-color">
                  Képek
                </NavLink>
              </Menu.Item>
              <Menu.Item key="4" icon={<AudioOutlined />}>
                <NavLink to="/raabe-klett/audio" className="link-color">
                  Audiók
                </NavLink>
              </Menu.Item>
              <Menu.Item key="9" icon={<FileWordOutlined />}>
                <NavLink to="/raabe-klett/webcontent" className="link-color">
                  Webkontentek
                </NavLink>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="7" icon={<ReconciliationOutlined />}>
              <NavLink to="/raabe-klett/dashboard" className="link-color">
                Dashboard
              </NavLink>
            </Menu.Item>
            <Menu.Item key="8" icon={<NotificationOutlined />}>
              <NavLink to="/raabe-klett/notification" className="link-color">
                Üzenetek
              </NavLink>
            </Menu.Item>
            <Menu.Item key="logout" icon={<LogoutOutlined />}>
              Kijelentkezés
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              padding: "0 0 0 26px",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ display: "flex" }}>
              <div>
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: toggle,
                  }
                )}
              </div>
            </div>
            <div style={{ width: "65vw", height: "auto", paddingTop: "15px" }}>
              <Tag
                closable
                onClose={dispatch(TagActions.closeTag())}
                visible={visible}
                style={{
                  color: "#ffffff",
                  backgroundColor: "#ADADAD",
                  fontSize: "1rem",
                  maxWidth: "100%",
                  whiteSpace: "unset",
                  position: "relative",
                  paddingRight: "25px",
                }}
              >
                {message}
              </Tag>
            </div>
            <div
              style={{
                padding: "1em",
                marginRight: "1em",
                position: "absolute",
                right: "0em",
                zIndex: "1",
              }}
            >
              <Button
                size="large"
                onClick={() => history.push("/raabe-klett/profile")}
              >
                <UserOutlined />
              </Button>
            </div>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "0px 0px",
              paddingLeft: 24,
            }}
          >
            <Body socket={stateSocket} />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
