import {
  DashBoardDispatchTypes,
  DashBoardT,
} from "../constants/ActionTypes/dashboard-action-types";

export interface initialDashBoardState {
  category: DashBoardT[];
  status: boolean;
  message: string;
  saveStatus: boolean;
}

const defaultState: initialDashBoardState = {
  category: [],
  status: false,
  message: "",
  saveStatus: false,
};

const dashBoardReducer = (
  state: initialDashBoardState = defaultState,
  action: DashBoardDispatchTypes
): initialDashBoardState => {
  switch (action.type) {
    case "DASHBOARDS_LOADING":
      return Object.assign({}, state, {
        category: [],
        status: false,
        message: "",
      });
    case "DASHBOARDS_LOADED":
      return Object.assign({}, state, {
        category: action.payload.categories,
        status: true,
        message: "",
      });
    case "DASHBOARDS_ERROR":
      return Object.assign({}, state, {
        category: [],
        status: false,
        message: action.payload,
      });
    case "DASHBOARD_REGISTER_START":
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case "DASHBOARD_REGISTER_SUCCESS":
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case "DASHBOARD_REGISTER_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default dashBoardReducer;
