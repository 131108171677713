import { Row, Col, Button, Form, Modal, Input, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PictureActions from "../../../actions/picture";
import {
  RestOutlined,
  RetweetOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { RootStore } from "../../../store/store";
import { PictureT } from "../../../constants/ActionTypes/picture-action-types";
import BookActions from "../../../actions/book";

import "../../formating/content.css";
import DeletedPictures from "./deletedPictures";
import useWindowDimensions from "../../../constants/sizecheck";

import PicturePaginator from "./paginator";
import PictureLoader from "./pictureLoader";

export default function Picture() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const effectRan = useRef(false);
  const propPictures = useSelector(
    (state: RootStore) => state.pictureReducer.picture
  );
  const status = useSelector((state: RootStore) => state.pictureReducer.status);

  const [modalVisible, setModalVisible] = useState<boolean>(() => false);
  const [modify, setModify] = useState<boolean>(() => false);
  const [loaded, setLoaded] = useState<boolean>(() => false);
  const [bookSearch, setBookSearch] = useState<string>(() => "");
  const [filteredPictures, setFilteredPictures] = useState<PictureT[]>(
    () => []
  );
  const [onSearch, setOnSerach] = useState<boolean>(() => false);
  const [current, setCurrent] = useState<number>(() => 1);
  const [showDeletedModal, setShowDeletedModal] = useState<boolean>(
    () => false
  );
  const { width } = useWindowDimensions();
  const [previewModal, setPreviewModal] = useState<boolean>(() => false);
  const [previewImageUrl, setPreviewImageUrl] = useState<string>(() => "");

  const [picturesearch, setPicturesearch] = useState<string>(() => "");
  const [onPictureNameSearch, setOnPictureNameSearch] = useState<boolean>(
    () => false
  );
  useEffect(() => {
    const fetchData = async () => {
      if (effectRan.current === false) {
        try {
          await dispatch(PictureActions.getAllPicture());
          await dispatch(BookActions.getAllBook(true));
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetchData();
    if (status) {
      setLoaded(true);
    }
    return () => {
      effectRan.current = true;
    };
  }, [dispatch, status]);

  const getPictures = async () => {
    setLoaded(false);
    try {
      await dispatch(PictureActions.getAllPicture());
      if (status) {
        if (propPictures.length > 0) {
          setLoaded(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const closeDeletedPicturesModal = async () => {
    setShowDeletedModal(false);
    await getPictures();
  };

  const searchPictures = () => {
    if (bookSearch.length > 0) {
      setOnSerach(true);
      if (picturesearch.length > 0 && onPictureNameSearch === true) {
        const filter = filteredPictures.filter((picture) =>
          picture.book[picture.book.length - 1].title
            .toLowerCase()
            .includes(bookSearch.toLowerCase())
        );
        setFilteredPictures(filter);
      } else {
        const filter = propPictures.filter((picture) =>
          picture.book[picture.book.length - 1]?.title
            .toLowerCase()
            .includes(bookSearch.toLocaleLowerCase())
        );
        setFilteredPictures(filter);
      }
    }
  };

  const searchPictureNames = () => {
    if (picturesearch.length > 0) {
      setOnPictureNameSearch(true);
      if (bookSearch.length > 0 && onSearch === true) {
        const filter = filteredPictures.filter((picture) =>
          picture.name.toLowerCase().includes(picturesearch.toLowerCase())
        );
        setFilteredPictures(filter);
      } else {
        const filter = propPictures.filter((picture) =>
          picture.name.toLowerCase().includes(picturesearch.toLowerCase())
        );
        setFilteredPictures(filter);
      }
    }
  };

  const resetFilter = () => {
    setOnSerach(false);
    setBookSearch("");
    if (picturesearch.length > 0) {
      const filter = propPictures.filter((picture) =>
        picture.name.toLowerCase().includes(picturesearch.toLowerCase())
      );
      setFilteredPictures(filter);
    } else {
      setFilteredPictures([]);
    }
  };

  const resetPictureNameFilter = () => {
    setOnPictureNameSearch(false);
    setPicturesearch("");
    if (bookSearch.length > 0) {
      const filter = propPictures.filter((picture) =>
        picture.book[picture.book.length - 1]?.title
          .toLowerCase()
          .includes(bookSearch.toLocaleLowerCase())
      );
      setFilteredPictures(filter);
    } else {
      setFilteredPictures([]);
    }
  };

  return (
    <div className="content">
      <Row>
        <Col span={24} className="colPadding">
          <h1 className="title">
            <strong>Képek</strong>
          </h1>
          <Row>
            <Row>
              <Col span={30} className="colPadding">
                <Button type="primary" onClick={() => setModalVisible(true)}>
                  Létrehozás
                </Button>
              </Col>
            </Row>
            <div className="deletedModal">
              <Button onClick={() => setShowDeletedModal(true)}>
                <RestOutlined />
              </Button>
            </div>
          </Row>
          <Space className="colPadding">
            Könyv alapú keresés:
            <Input
              name="booksearch"
              value={bookSearch}
              onChange={(e) => setBookSearch(e.target.value)}
            />
            {!onSearch ? (
              <Button type="primary" size="small" onClick={searchPictures}>
                <SearchOutlined />
              </Button>
            ) : (
              <Button type="primary" size="small" onClick={resetFilter}>
                <RetweetOutlined />
              </Button>
            )}
          </Space>
          <Space className="colPadding">
            Keresés név alapján:
            <Input
              name="picturesearch"
              value={picturesearch}
              onChange={(e) => setPicturesearch(e.target.value)}
            />
            {!onPictureNameSearch ? (
              <Button type="primary" size="small" onClick={searchPictureNames}>
                <SearchOutlined />
              </Button>
            ) : (
              <Button
                type="primary"
                size="small"
                onClick={resetPictureNameFilter}
              >
                <RetweetOutlined />
              </Button>
            )}
          </Space>
          <PictureLoader
            loaded={loaded}
            propPictures={propPictures}
            onSearch={onSearch}
            onPictureNameSearch={onPictureNameSearch}
            width={width}
            filteredPictures={filteredPictures}
            current={current}
            getPictures={getPictures}
            form={form}
            setModalVisible={setModalVisible}
            setModify={setModify}
            setPreviewImageUrl={setPreviewImageUrl}
            setPreviewModal={setPreviewModal}
            modalVisible={modalVisible}
            modify={modify}
          />
        </Col>
      </Row>
      <div className="picturePaginator">
        <PicturePaginator
          onSearch={onSearch}
          onPictureNameSearch={onPictureNameSearch}
          filteredPictures={filteredPictures}
          propPictures={propPictures}
          setCurrent={setCurrent}
          width={width}
        />
      </div>

      <Modal
        visible={showDeletedModal}
        onCancel={closeDeletedPicturesModal}
        maskClosable={true}
        forceRender
        footer={false}
        width={"87.5vw"}
        bodyStyle={{ height: "89.5vh" }}
        style={{
          position: "absolute",
          right: 0,
          bottom: "0em",
          marginTop: "0em",
        }}
      >
        <DeletedPictures showDeletedModal={showDeletedModal} />
      </Modal>
      <Modal
        visible={previewModal}
        onCancel={() => setPreviewModal(false)}
        maskClosable={true}
        centered
        forceRender
        footer={false}
        bodyStyle={{ maxWidth: "80em" }}
        width={"75em"}
      >
        <div className="colPadding">
          <img
            className="picturePreview"
            alt={previewImageUrl}
            src={
              process.env.REACT_APP_BASE_URL +
              "/api/1.0.0/picture/getall/" +
              previewImageUrl
            }
          />
        </div>

        <div className="colPadding">
          <Button
            style={{
              display: "block",
              marginRight: "auto",
              marginLeft: "auto",
              position: "relative",
            }}
            onClick={() => setPreviewModal(false)}
          >
            Vissza
          </Button>
        </div>
      </Modal>
    </div>
  );
}
