import "./App.css";
import axios from "axios";
import { Suspense } from "react";
import { Spin } from "antd";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import ProtectedRoute from "./components/protectedRoute/protected-route";
import LoginRedirect from "./components/Login/loginRedirect";
import Main from "./skeleton/main";
import LoginMain from "./components/Login/loginMain";
import ConnectedForgotPasswordConfirm from "./components/Login/forgotpasswordConfirm";
import SuccessPage from "./components/Login/successpage";

export default function App() {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL + "/api";
  return (
    <Suspense fallback={<Spin spinning={true} />}>
      <div style={{ height: "100%" }}>
        <Helmet>
          <title>{process.env.REACT_APP_TITLE}</title>
        </Helmet>
        <Router>
          <Switch>
            <Route exact path="/login" component={LoginMain} />
            <Route
              exact
              path="/forgot-password-confirm"
              component={ConnectedForgotPasswordConfirm}
            />
            <Route exact path="/success-page" component={SuccessPage} />
            <Route exact path="/login-redirect" component={LoginRedirect} />
            <ProtectedRoute path="/raabe-klett" component={Main} />
            <Redirect from="**" to="/login" />
          </Switch>
        </Router>
      </div>
    </Suspense>
  );
}
