import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Form, Select, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect } from "react";
import CharacterCounter from "../../../../constants/characterCounter";
import DebounceFunc from "../../../../constants/debounce";
import { removePictureFile } from "../../../../constants/pictureUpload";

export default function PictureSequenceSchema({
  id,
  sequenceId,
  beforeUpload,
  handleChangeImage,
  imageUrlPictureFile,
  loadingPictureFile,
  sequnce,
  setSequence,
  arr,
  title,
  selectedSequences,
  forceRefresh,
  setForceRefresh,
  descriptionId,
  pictureDescription,
  setPictureDescription,
  setPictureFile,
  setPictureImageUrl,
}: any) {
  useEffect(() => {
    if (forceRefresh) {
      setForceRefresh(false);
    }
  }, [forceRefresh]);

  return (
    <Form.Item
      name="pairing1"
      style={{
        padding: "0.5em",
        position: "relative",
        left: "15em",
      }}
      rules={[
        {
          required: true,
          message: "",
        },
      ]}
    >
      <Form.Item
        name={id}
        label={title}
        rules={[
          {
            required: true,
            message: "Kérem adjon meg egy fájlt!",
          },
        ]}
        style={{
          display: "inline-block",
          width: "calc(50% - 4em)",
        }}
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          beforeUpload={beforeUpload}
          onChange={handleChangeImage}
          accept=".png,.jpg,.jpeg,.gif"
        >
          {imageUrlPictureFile ? (
            <img
              src={imageUrlPictureFile}
              alt="avatar"
              style={{ width: "100%" }}
            />
          ) : (
            <div>
              {loadingPictureFile ? <LoadingOutlined /> : <PlusOutlined />}
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
        <Button
          style={{ position: "absolute", left: "9em", top: "2.5em" }}
          size="small"
          danger
          type="primary"
          disabled={!imageUrlPictureFile}
          onClick={() => removePictureFile(setPictureFile, setPictureImageUrl)}
        >
          <DeleteOutlined />
        </Button>
      </Form.Item>
      <Form.Item
        name={descriptionId}
        label={"Képleírás"}
        style={{
          display: "inline-block",
          width: "15em",
          marginLeft: "-5em",
        }}
        rules={[
          {
            required: true,
            message: "Mező kitöltése kötelező!",
          },
        ]}
      >
        <TextArea
          name={id}
          value={pictureDescription}
          maxLength={100}
          onChange={(e) => setPictureDescription(e.target.value)}
        />
        <CharacterCounter max={100} taken={pictureDescription.length} />
      </Form.Item>
      <Form.Item
        name={sequenceId}
        style={{
          display: "inline-block",
          width: "calc(20% - 4em)",
          margin: "2em 4em",
        }}
      >
        <Select
          style={{ width: "5em", marginTop: "1em" }}
          value={sequnce}
          onChange={(event) => setSequence(event)}
        >
          {arr?.map((v: number) => {
            return (
              <Select.Option
                key={v}
                value={v}
                style={
                  selectedSequences?.includes(v)
                    ? { backgroundColor: "#e6e6e6" }
                    : { backgroundColor: "white" }
                }
              >
                {v}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </Form.Item>
  );
}
