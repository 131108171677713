import { Row, Col, Button, Form } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootStore } from "../../store/store";
import DashBoardActions from "../../actions/dashboard";
import BookActions from "../../actions/book";
import "../formating/content.css";
import DashboardTable from "./table";

export default function DashBoard() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const effectRan = useRef(false);
  const propDashBoard = useSelector(
    (state: RootStore) => state.dashBoardReducer.category
  );
  const propStatus = useSelector(
    (state: RootStore) => state.dashBoardReducer.status
  );
  const [modalVisible, setModalVisible] = useState<boolean>(() => false);
  const [loaded, setLoaded] = useState<boolean>(() => false);
  const [refetch, setRefetch] = useState<boolean>(() => false);
  
  useEffect(() => {
    const fetchData = async () => {
      if (effectRan.current === false) {
        await dispatch(DashBoardActions.getDashboard());
        await dispatch(BookActions.getAllBook(true));
      }
      if (refetch) {
        await dispatch(DashBoardActions.getDashboard());
      }
    };
    fetchData();
    if (propStatus) {
      setLoaded(true);
      setRefetch(false);
    }
    return () => {
      effectRan.current = true;
    };
  }, [dispatch, propStatus, refetch]);

  return (
    <div className="content">
      <Row>
        <Col span={24} className="colPadding">
          <h1 className="title">
            <strong>Dashboard</strong>
          </h1>
          <Row>
            <Row>
              <Col span={30} className="colPadding">
                <Button type="primary" onClick={() => setModalVisible(true)}>
                  Létrehozás
                </Button>
              </Col>
            </Row>
          </Row>
          <Col span={24}>
            <DashboardTable
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              form={form}
              propDashBoard={propDashBoard}
              loaded={loaded}
              setRefetch={setRefetch}
            />
          </Col>
        </Col>
      </Row>
    </div>
  );
}
