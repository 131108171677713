import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, Table } from "antd";
import { useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import BookTypeActions from "../../actions/booktype";
import { BookTypeT } from "../../constants/ActionTypes/book-type-action-types";
import { useNameChange, useSelectedIdChange } from "./booktypeUtils";
import BookTypeModal from "./modal";
import reducer, { initialState } from "./reducer";

const BookTypeTable = ({
  form,
  propBookType,
  loaded,
  getBookTypes,
  modalVisible,
  setModalVisible,
}: any) => {
  const dispatch = useDispatch();
  const [modify, setModify] = useState<boolean>(() => false);
  const [state, reducerDispatch] = useReducer(reducer, initialState);
  const selectedIdChange = useSelectedIdChange(reducerDispatch);
  const nameChange = useNameChange(reducerDispatch);
  const { name, selectedId } = state;
  const showModifyModal = (record: BookTypeT) => {
    form.setFieldsValue({
      name: record.name,
    });
    setModalVisible(true);
    setModify(true);
    selectedIdChange(record.id);
    nameChange(record.name);
  };

  const functionDeleteBookType = async (record: BookTypeT) => {
    await dispatch(BookTypeActions.deleteBookType(record.id));
    getBookTypes();
  };

  const columns = [
    {
      title: "Könyvtípus megnevezése",
      key: "bookTypeName",
      dataIndex: "name",
    },
    {
      title: "Módosítás",
      key: "modify",
      render: (text: string, record: BookTypeT) => {
        return (
          <Space size="middle">
            <Button onClick={() => showModifyModal(record)}>
              <EditOutlined />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Törlés",
      key: "delete",
      render: (text: string, record: BookTypeT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan törölni akarod ezt a könyvtípust?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => functionDeleteBookType(record)}
            >
              <Button type="primary" danger>
                <DeleteOutlined key="delete" />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={propBookType}
        loading={!loaded && !propBookType}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          showSizeChanger: false,
        }}
      />
      <BookTypeModal
        modalVisible={modalVisible}
        modify={modify}
        selectedId={selectedId}
        form={form}
        name={name}
        nameChange={nameChange}
        setModify={setModify}
        setModalVisible={setModalVisible}
        getBookTypes={getBookTypes}
      />
    </div>
  );
};
export default BookTypeTable;
