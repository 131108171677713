import {
  AnswerT,
  QuestionDispatchTypes,
} from "../constants/ActionTypes/question-action-types";

export interface initialAnswerState {
  answers: AnswerT[];
  status: boolean;
  message: string;
  saveStatus: boolean;
}

const defaultState = {
  answers: [],
  status: false,
  message: "",
  saveStatus: false,
};
const answerReducer = (
  state: initialAnswerState = defaultState,
  action: QuestionDispatchTypes
): initialAnswerState => {
  switch (action.type) {
    case "QUESTION_ANSWES_LOADING":
      return Object.assign({}, state, {
        answers: [],
        status: false,
        message: "",
      });
    case "QUESTION_ANSWES_LOADED":
      return Object.assign({}, state, {
        answers: action.payload,
        status: true,
        message: "",
      });
    case "QUESTION_ANSWES_ERROR":
      return Object.assign({}, state, {
        answers: [],
        status: false,
        message: action.payload,
      });
    case "QUESTION_ANSWES_REGISTER_START":
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case "QUESTION_ANSWES_REGISTER_SUCCESS":
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case "QUESTION_ANSWES_REGISTER_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default answerReducer;
