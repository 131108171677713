import { useEffect, useState } from "react";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import type { UploadChangeParam } from "antd/es/upload";
import { Button, Form, message, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import QuestionActions from "../../../actions/question";
import { RootStore } from "../../../store/store";
import PictureTextSchema from "./schemas/pictureTextSchema";
import { handleChange } from "../../../constants/pictureUpload";

export default function PictureTextPair({
  question,
  modify,
  setModify,
  setModalVisible,
  modalVisible,
  checked,
  setChecked,
}: any) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propAnswers = useSelector(
    (state: RootStore) => state.answerReducer.answers
  );
  const propSaveStatus = useSelector(
    (state: RootStore) => state.answerReducer.saveStatus
  );
  const [picture1afile, setPicture1afile] = useState<UploadFile | any>(
    () => null!
  );
  const [loadingPicture1afile, setLoadingPicture1afile] = useState<boolean>(
    () => false
  );
  const [imageUrlPicture1afile, setImageUrlPicture1afile] = useState<string>(
    () => ""
  );
  const [picture2afile, setPicture2afile] = useState<UploadFile | any>(
    () => null!
  );
  const [loadingPicture2afile, setLoadingPicture2afile] = useState<boolean>(
    () => false
  );
  const [imageUrlPicture2afile, setImageUrlPicture2afile] = useState<string>(
    () => ""
  );
  const [picture3afile, setPicture3afile] = useState<UploadFile | any>(
    () => null!
  );
  const [loadingPicture3afile, setLoadingPicture3afile] = useState<boolean>(
    () => false
  );
  const [imageUrlPicture3afile, setImageUrlPicture3afile] = useState<string>(
    () => ""
  );
  const [picture4afile, setPicture4afile] = useState<UploadFile | any>(
    () => null!
  );
  const [loadingPicture4afile, setLoadingPicture4afile] = useState<boolean>(
    () => false
  );
  const [imageUrlPicture4afile, setImageUrlPicture4afile] = useState<string>(
    () => ""
  );

  const [pairAnswerPair1, setPairAnswerPair1] = useState<string>(() => "");
  const [pairAnswerPair2, setPairAnswerPair2] = useState<string>(() => "");
  const [pairAnswerPair3, setPairAnswerPair3] = useState<string>(() => "");
  const [pairAnswerPair4, setPairAnswerPair4] = useState<string>(() => "");

  const [pictureDescription1, setPictureDescription1] = useState<string>(
    () => ""
  );
  const [pictureDescription2, setPictureDescription2] = useState<string>(
    () => ""
  );
  const [pictureDescription3, setPictureDescription3] = useState<string>(
    () => ""
  );
  const [pictureDescription4, setPictureDescription4] = useState<string>(
    () => ""
  );

  const [disabledButton, setDisabledButton] = useState<boolean>(() => true);

  const functionCreateQuestionWithAnswer = async () => {
    const formData = new FormData();
    const convertedFile1 = picture1afile as RcFile;
    const convertedFile2 = picture2afile as RcFile;
    const convertedFile3 = picture3afile as RcFile;
    const convertedFile4 = picture4afile as RcFile;

    formData.append("questionId", question.id);
    const answers = [
      {
        pairAnswerPair: pairAnswerPair1,
        pictureDescription: pictureDescription1,
      },
      {
        pairAnswerPair: pairAnswerPair2,
        pictureDescription: pictureDescription2,
      },
      {
        pairAnswerPair: pairAnswerPair3,
        pictureDescription: pictureDescription3,
      },
      {
        pairAnswerPair: pairAnswerPair4,
        pictureDescription: pictureDescription4,
      },
    ];

    formData.append("pictureAfile", convertedFile1);
    formData.append("pictureAfile", convertedFile2);
    formData.append("pictureAfile", convertedFile3);
    formData.append("pictureAfile", convertedFile4);

    formData.append("answers", JSON.stringify(answers));
    await dispatch(QuestionActions.createQuestionAnswers(formData));
    setChecked(true);
  };

  useEffect(() => {
    const fetch = () => {
      if (propSaveStatus && checked) {
        message.success("Sikeres mentés");
        setChecked(false);
        setModalVisible(false);
      } else if (!propSaveStatus && checked) {
        setChecked(false);
        message.error("Hiba az feladat mentése közben!");
      }
    };
    fetch();
  }, [checked]);

  useEffect(() => {
    if (question?.answerCount === 4) {
      pairAnswerPair1 !== "" &&
      pairAnswerPair2 !== "" &&
      pairAnswerPair3 !== "" &&
      pairAnswerPair4 !== "" &&
      pictureDescription1 !== "" &&
      pictureDescription2 !== "" &&
      pictureDescription3 !== "" &&
      pictureDescription4 !== "" &&
      picture1afile !== null &&
      picture2afile !== null &&
      picture3afile !== null &&
      picture4afile !== null
        ? setDisabledButton(false)
        : setDisabledButton(true);
    } else if (question?.answerCount === 3) {
      pairAnswerPair1 !== "" &&
      pairAnswerPair2 !== "" &&
      pairAnswerPair3 !== "" &&
      pictureDescription1 !== "" &&
      pictureDescription2 !== "" &&
      pictureDescription3 !== "" &&
      picture1afile !== null &&
      picture2afile !== null &&
      picture3afile !== null
        ? setDisabledButton(false)
        : setDisabledButton(true);
    } else if (question?.answerCount === 2) {
      pairAnswerPair1 !== "" &&
      pairAnswerPair2 !== "" &&
      pictureDescription1 !== "" &&
      pictureDescription2 !== "" &&
      picture1afile !== null &&
      picture2afile !== null
        ? setDisabledButton(false)
        : setDisabledButton(true);
    }
  }, [
    question?.questionType,
    pairAnswerPair1,
    pairAnswerPair2,
    pairAnswerPair3,
    pairAnswerPair4,
    pictureDescription1,
    pictureDescription2,
    pictureDescription3,
    pictureDescription4,
    picture1afile,
    picture2afile,
    picture3afile,
    picture4afile,
  ]);

  const beforeUpload1 = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setPicture1afile(null!);
      setImageUrlPicture1afile("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setImageUrlPicture1afile("");
            setPicture1afile(null!);
          }
        };
      };
    }
    return false;
  };

  const beforeUpload2 = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setPicture2afile(null!);
      setImageUrlPicture2afile("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setImageUrlPicture2afile("");
            setPicture2afile(null!);
          }
        };
      };
    }
    return false;
  };

  const beforeUpload3 = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setPicture3afile(null!);
      setImageUrlPicture3afile("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setPicture3afile(null!);
            setImageUrlPicture3afile("");
          }
        };
      };
    }
    return false;
  };

  const beforeUpload4 = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setPicture4afile(null!);
      setImageUrlPicture4afile("");
      message.error("Túl nagy file (max 5MB)");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          const height = image.naturalHeight;
          const width = image.naturalWidth;
          if (height > 1920 || width > 1920) {
            message.error("A kép felbontása túl nagy, max fullHD");
            setPicture4afile(null!);
            setImageUrlPicture4afile("");
          }
        };
      };
    }
    return false;
  };

  const handleChangeImage1: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(
      info,
      setLoadingPicture1afile,
      setPicture1afile,
      setImageUrlPicture1afile
    );
  };

  const handleChangeImage2: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(
      info,
      setLoadingPicture2afile,
      setPicture2afile,
      setImageUrlPicture2afile
    );
  };

  const handleChangeImage3: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(
      info,
      setLoadingPicture3afile,
      setPicture3afile,
      setImageUrlPicture3afile
    );
  };

  const handleChangeImage4: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(
      info,
      setLoadingPicture4afile,
      setPicture4afile,
      setImageUrlPicture4afile
    );
  };

  const closeModal = () => {
    setModalVisible(false);
    setPicture1afile(null!);
    setPicture2afile(null!);
    setPicture3afile(null!);
    setPicture4afile(null!);

    setPairAnswerPair1("");
    setPairAnswerPair2("");
    setPairAnswerPair3("");
    setPairAnswerPair4("");

    setPictureDescription1("");
    setPictureDescription2("");
    setPictureDescription3("");
    setPictureDescription4("");

    setImageUrlPicture1afile("");
    setImageUrlPicture2afile("");
    setImageUrlPicture3afile("");
    setImageUrlPicture4afile("");

    setModify(false);

    form.resetFields();
  };

  const functionModifyQuestionWithAnswer = async () => {
    const formData = new FormData();
    const convertedFile1 = picture1afile as RcFile;
    const convertedFile2 = picture2afile as RcFile;
    const convertedFile3 = picture3afile as RcFile;
    const convertedFile4 = picture4afile as RcFile;

    formData.append("questionId", question.id);
    const answers = [
      {
        pairAnswerPair: pairAnswerPair1,
        id: propAnswers[0]?.id,
        pictureId: propAnswers[1]?.id,
        pictureDescription: pictureDescription1,
        changed: convertedFile1?.type === undefined ? false : true,
      },
      {
        pairAnswerPair: pairAnswerPair2,
        id: propAnswers[2]?.id,
        pictureId: propAnswers[3]?.id,
        pictureDescription: pictureDescription2,
        changed: convertedFile2?.type === undefined ? false : true,
      },
      {
        pairAnswerPair: pairAnswerPair3,
        id: propAnswers[4]?.id,
        pictureId: propAnswers[5]?.id,
        pictureDescription: pictureDescription3,
        changed: convertedFile3?.type === undefined ? false : true,
      },
      {
        pairAnswerPair: pairAnswerPair4,
        id: propAnswers[6]?.id,
        pictureId: propAnswers[7]?.id,
        pictureDescription: pictureDescription4,
        changed: convertedFile4?.type === undefined ? false : true,
      },
    ];
    formData.append("pictureAfile", convertedFile1);
    formData.append("pictureAfile", convertedFile2);
    formData.append("pictureAfile", convertedFile3);
    formData.append("pictureAfile", convertedFile4);

    formData.append("answers", JSON.stringify(answers));
    await dispatch(QuestionActions.modifyQuestionAnswers(formData));
    setChecked(true);
  };

  useEffect(() => {
    if (modify) {
      setPairAnswerPair1(propAnswers[0]?.description);
      setPairAnswerPair2(propAnswers[2]?.description);
      setPairAnswerPair3(propAnswers[4]?.description);
      setPairAnswerPair4(propAnswers[6]?.description);

      setPictureDescription1(propAnswers[1]?.pictureDescription);
      setPictureDescription2(propAnswers[3]?.pictureDescription);
      setPictureDescription3(propAnswers[5]?.pictureDescription);
      setPictureDescription4(propAnswers[7]?.pictureDescription);

      setImageUrlPicture1afile(
        process.env.REACT_APP_BASE_URL +
          "/files/content/pictures/" +
          propAnswers[1]?.imageUrl?.substring(`${process.env.REACT_APP_BASE_URL}/files/content/pictures/`.length)
      );
      setPicture1afile(propAnswers[1]?.imageUrl);

      setImageUrlPicture2afile(
        process.env.REACT_APP_BASE_URL +
          "/files/content/pictures/" +
          propAnswers[3]?.imageUrl?.substring(`${process.env.REACT_APP_BASE_URL}/files/content/pictures/`.length)
      );
      setPicture2afile(propAnswers[3]?.imageUrl);

      setImageUrlPicture3afile(
        process.env.REACT_APP_BASE_URL +
          "/files/content/pictures/" +
          propAnswers[5]?.imageUrl?.substring(`${process.env.REACT_APP_BASE_URL}/files/content/pictures/`.length)
      );
      setPicture3afile(propAnswers[5]?.imageUrl);

      setImageUrlPicture4afile(
        process.env.REACT_APP_BASE_URL +
          "/files/content/pictures/" +
          propAnswers[7]?.imageUrl?.substring(`${process.env.REACT_APP_BASE_URL}/files/content/pictures/`.length)
      );
      setPicture4afile(propAnswers[7]?.imageUrl);

      form.setFieldsValue({
        answer1: propAnswers[0]?.description,
        answer2: propAnswers[2]?.description,
        answer3: propAnswers[4]?.description,
        answer4: propAnswers[6]?.description,
        pictureDescription1: propAnswers[1]?.pictureDescription,
        pictureDescription2: propAnswers[3]?.pictureDescription,
        pictureDescription3: propAnswers[5]?.pictureDescription,
        pictureDescription4: propAnswers[7]?.pictureDescription,
      });
    }
  }, [modify]);

  return (
    <div>
      <Modal
        visible={modalVisible}
        onCancel={() => closeModal()}
        maskClosable={false}
        title={modify ? "Módosítás" : "Létrehozás"}
        forceRender
        centered
        footer={[
          <Button
            key="createPictureTextPair"
            type="primary"
            disabled={disabledButton}
            onClick={() =>
              modify
                ? functionModifyQuestionWithAnswer()
                : functionCreateQuestionWithAnswer()
            }
          >
            {modify ? "Módosítás" : "Létrehozás"}
          </Button>,
          <Button key="cancel" onClick={() => closeModal()}>
            Mégsem
          </Button>,
        ]}
        width={"90em"}
      >
        <Form
          form={form}
          name="register"
          scrollToFirstError
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 20 }}
        >
          <PictureTextSchema
            id={"answer1"}
            pairId={"file1"}
            pairAnswerPair={pairAnswerPair1}
            setPairAnswerPair={setPairAnswerPair1}
            beforeUpload={beforeUpload1}
            handleChangeImage={handleChangeImage1}
            imageUrlPictureFile={imageUrlPicture1afile}
            loadingPictureFile={loadingPicture1afile}
            title={"Első pár"}
            descriptionId={"pictureDescription1"}
            pictureDescription={pictureDescription1}
            setPictureDescription={setPictureDescription1}
            setPictureFile={setPicture1afile}
            setPictureImageUrl={setImageUrlPicture1afile}
          />
          <PictureTextSchema
            id={"answer2"}
            pairId={"file2"}
            pairAnswerPair={pairAnswerPair2}
            setPairAnswerPair={setPairAnswerPair2}
            beforeUpload={beforeUpload2}
            handleChangeImage={handleChangeImage2}
            imageUrlPictureFile={imageUrlPicture2afile}
            loadingPictureFile={loadingPicture2afile}
            title={"Második pár"}
            descriptionId={"pictureDescription2"}
            pictureDescription={pictureDescription2}
            setPictureDescription={setPictureDescription2}
            setPictureFile={setPicture2afile}
            setPictureImageUrl={setImageUrlPicture2afile}
          />
          {question?.answerCount >= 3 && (
            <PictureTextSchema
              id={"answer3"}
              pairId={"file3"}
              pairAnswerPair={pairAnswerPair3}
              setPairAnswerPair={setPairAnswerPair3}
              beforeUpload={beforeUpload3}
              handleChangeImage={handleChangeImage3}
              imageUrlPictureFile={imageUrlPicture3afile}
              loadingPictureFile={loadingPicture3afile}
              title={"Harmadik pár"}
              descriptionId={"pictureDescription3"}
              pictureDescription={pictureDescription3}
              setPictureDescription={setPictureDescription3}
              setPictureFile={setPicture3afile}
              setPictureImageUrl={setImageUrlPicture3afile}
            />
          )}
          {question?.answerCount === 4 && (
            <PictureTextSchema
              id={"answer4"}
              pairId={"file4"}
              pairAnswerPair={pairAnswerPair4}
              setPairAnswerPair={setPairAnswerPair4}
              beforeUpload={beforeUpload4}
              handleChangeImage={handleChangeImage4}
              imageUrlPictureFile={imageUrlPicture4afile}
              loadingPictureFile={loadingPicture4afile}
              title={"Negyedik pár"}
              descriptionId={"pictureDescription4"}
              pictureDescription={pictureDescription4}
              setPictureDescription={setPictureDescription4}
              setPictureFile={setPicture4afile}
              setPictureImageUrl={setImageUrlPicture4afile}
            />
          )}
        </Form>
      </Modal>
    </div>
  );
}
