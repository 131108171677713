import { Button, Form, Input, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BookTypeActions from "../../actions/booktype";
import DebounceFunc from "../../constants/debounce";
import { layout } from "../../constants/layout";
import { RootStore } from "../../store/store";

const BookTypeModal = ({
  modalVisible,
  modify,
  selectedId,
  form,
  name,
  nameChange,
  setModify,
  setModalVisible,
  getBookTypes,
}: any) => {
  const dispatch = useDispatch();
  const bookTypeSaveStatus = useSelector(
    (state: RootStore) => state.booktypeReducer.saveStatus
  );
  const [disabled, setDisabled] = useState<boolean>(() => true);
  const [checked, setChecked] = useState<boolean>(() => false);

  const functionCreateBookType = async () => {
    const toSend = {
      name: name,
    };
    await dispatch(BookTypeActions.createBookType(toSend));
    setChecked(true);
  };

  const functionModifyBookType = async () => {
    const toSend = {
      id: selectedId,
      name,
    };
    await dispatch(BookTypeActions.modifyBookType(toSend));
    setChecked(true);
  };

  useEffect(() => {
    if (!name) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [name]);

  useEffect(() => {
    const fetch = () => {
      if (bookTypeSaveStatus && checked) {
        setChecked(false);
        message.success("Sikeres mentés");
      } else if (!bookTypeSaveStatus && checked) {
        setChecked(false);
        message.error("Hiba az feladat mentése közben!");
      }
    };
    fetch();
    return () => {
      if (bookTypeSaveStatus && checked) {
        getBookTypes();
        closeModal();
      }
    };
  });

  const onReset = () => {
    form.resetFields();
  };

  const closeModal = () => {
    setModalVisible(false);
    setModify(false);
    nameChange("");
    onReset();
  };

  return (
    <Modal
      visible={modalVisible}
      onCancel={closeModal}
      title={modify ? "Módosítás" : "Létrehozás"}
      maskClosable={false}
      forceRender
      centered
      footer={[
        <Button
          disabled={disabled}
          key="createTask"
          type="primary"
          onClick={modify ? functionModifyBookType : functionCreateBookType}
        >
          {modify ? "Módosítás" : "Létrehozás"}
        </Button>,
        <Button key="cancel" onClick={closeModal}>
          Mégsem
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        name="register"
        onFinish={modify ? functionModifyBookType : functionCreateBookType}
        scrollToFirstError
      >
        <Form.Item
          name="name"
          label="Megnevezés"
          rules={[
            {
              required: true,
              message: "Mező kitöltése kötelező!",
            },
          ]}
        >
          <Input
            name="name"
            value={name}
            maxLength={255}
            onChange={(e) => DebounceFunc(e.target.value, nameChange)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BookTypeModal;
