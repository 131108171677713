const MissingRight = () => {
  return (
    <div>
      <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
        <strong>Nincs joga ehhez a művelethez!</strong>
      </h1>
    </div>
  );
};

export default MissingRight;
