import { useCallback } from "react";
import { BOOK_ACTIONS } from "../../constants/ActionTypes/ReducerActionTypes/reducer-action-types";
import { initialState } from "./reducer";
export type BookState = typeof initialState;

export const useNameChange = (dispatch: any) => {
  const nameChange = useCallback(
    (value: string) => {
      dispatch({ type: BOOK_ACTIONS.SET_NAME, name: value });
    },
    [dispatch]
  );

  return nameChange;
};

export const useDescriptionChange = (dispatch: any) => {
  const descriptionChange = useCallback(
    (value: string) => {
      dispatch({ type: BOOK_ACTIONS.SET_DESCRIPTION, description: value });
    },
    [dispatch]
  );

  return descriptionChange;
};

export const useCreatorsChange = (dispatch: any) => {
  const creatorsChange = useCallback(
    (value: string) => {
      dispatch({ type: BOOK_ACTIONS.SET_CREATORS, creators: value });
    },
    [dispatch]
  );

  return creatorsChange;
};

export const useBarcodeChange = (dispatch: any) => {
  const barcodeChange = useCallback(
    (value: string) => {
      dispatch({ type: BOOK_ACTIONS.SET_BARCODE, barcode: value });
    },
    [dispatch]
  );

  return barcodeChange;
};

export const useImageUrlChange = (dispatch: any) => {
  const imageUrlChange = useCallback(
    (value: string) => {
      dispatch({ type: BOOK_ACTIONS.SET_IMAGE_URL, imageUrl: value });
    },
    [dispatch]
  );

  return imageUrlChange;
};

export const useSearchedColumnChange = (dispatch: any) => {
  const searchedColumnChange = useCallback(
    (value: string) => {
      dispatch({
        type: BOOK_ACTIONS.SET_SEARCHED_COLUMN,
        searchedColumn: value,
      });
    },
    [dispatch]
  );

  return searchedColumnChange;
};

export const usePurchaseUrlChange = (dispatch: any) => {
  const purchaseUrlChange = useCallback(
    (value: string) => {
      dispatch({
        type: BOOK_ACTIONS.SET_PURCHASE_URL,
        purchaseUrl: value,
      });
    },
    [dispatch]
  );

  return purchaseUrlChange;
};

export const useSelectedIdChange = (dispatch: any) => {
  const selectedIdChange = useCallback(
    (value: number) => {
      dispatch({
        type: BOOK_ACTIONS.SET_SELECTED_ID,
        selectedId: value,
      });
    },
    [dispatch]
  );

  return selectedIdChange;
};

export const useBookTypeIdChange = (dispatch: any) => {
  const booktypeIdChange = useCallback(
    (value: number) => {
      dispatch({
        type: BOOK_ACTIONS.SET_BOOK_TYPE_ID,
        booktypeId: value,
      });
    },
    [dispatch]
  );

  return booktypeIdChange;
};

export const useKeywordsTargetKeysChange = (dispatch: any) => {
  const keywordsTargetKeysChange = useCallback(
    (value: string[]) => {
      dispatch({
        type: BOOK_ACTIONS.SET_KEYWORDS_TARGET_KEYS,
        keywordsTargetKeys: value,
      });
    },
    [dispatch]
  );

  return keywordsTargetKeysChange;
};

export const useKeywordsSelectedKeysChange = (dispatch: any) => {
  const keywordsSelectedKeysChange = useCallback(
    (value: string[]) => {
      dispatch({
        type: BOOK_ACTIONS.SET_KEYWORDS_SELECTED_KEYS,
        keywordsSelectedKeys: value,
      });
    },
    [dispatch]
  );

  return keywordsSelectedKeysChange;
};

export const useFileChange = (dispatch: any) => {
  const fileChange = useCallback(
    (value: any) => {
      dispatch({
        type: BOOK_ACTIONS.SET_FILE,
        file: value,
      });
    },
    [dispatch]
  );

  return fileChange;
};
