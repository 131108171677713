import { Button, Form, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QuestionActions from "../../../actions/question";
import { RootStore } from "../../../store/store";
import { usePrevious } from "./pictureSequence";
import TextSequenceSchema from "./schemas/textSequenceSchema";

export default function TextSequence({
  question,
  modify,
  setModify,
  setModalVisible,
  modalVisible,
  checked,
  setChecked,
}: any) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propAnswers = useSelector(
    (state: RootStore) => state.answerReducer.answers
  );
  const propSaveStatus = useSelector(
    (state: RootStore) => state.answerReducer.saveStatus
  );
  const [firstSequnce, setFirstSequence] = useState<number>(() => null!);
  const [secoundSequnce, setSecoundSequence] = useState<number>(() => null!);
  const [thirdSequnce, setThirdSequence] = useState<number>(() => null!);
  const [fourthSequnce, setFourthSequence] = useState<number>(() => null!);
  const [fifthSequnce, setFifthSequence] = useState<number>(() => null!);
  const [sixthSequnce, setSixthSequence] = useState<number>(() => null!);
  const [seventhSequnce, setSeventhSequence] = useState<number>(() => null!);
  const [eighthSequnce, setEighthSequence] = useState<number>(() => null!);

  const [arr, setArray] = useState<number[]>(() => []);
  const [pairAnswerPair1, setPairAnswerPair1] = useState<string>(() => "");
  const [pairAnswerPair2, setPairAnswerPair2] = useState<string>(() => "");
  const [pairAnswerPair3, setPairAnswerPair3] = useState<string>(() => "");
  const [pairAnswerPair4, setPairAnswerPair4] = useState<string>(() => "");
  const [pairAnswerPair5, setPairAnswerPair5] = useState<string>(() => "");
  const [pairAnswerPair6, setPairAnswerPair6] = useState<string>(() => "");
  const [pairAnswerPair7, setPairAnswerPair7] = useState<string>(() => "");
  const [pairAnswerPair8, setPairAnswerPair8] = useState<string>(() => "");
  const [disabledButton, setDisabledButton] = useState<boolean>(() => true);

  const [selectedSequences, setSelectedSequences] = useState<number[]>(
    () => []
  );
  const firstPrevCount = usePrevious(firstSequnce);
  const secoundPrevCount = usePrevious(secoundSequnce);
  const thirdPrevCount = usePrevious(thirdSequnce);
  const fourthPrevCount = usePrevious(fourthSequnce);
  const fifthPrevCount = usePrevious(fourthSequnce);
  const sixthPrevCount = usePrevious(fourthSequnce);
  const seventhPrevCount = usePrevious(fourthSequnce);
  const eighthPrevCount = usePrevious(fourthSequnce);

  const [forceRefresh, setForceRefresh] = useState<boolean>(() => false);

  const removeElements = (value: any) => {
    for (let i = 0; i < selectedSequences.length; i++) {
      if (selectedSequences[i] === value) {
        selectedSequences.splice(i, 1);
        setSelectedSequences(selectedSequences);
      }
    }
  };

  useEffect(() => {
    if (firstSequnce !== null) {
      setSelectedSequences((pre) => [firstSequnce, ...pre]);
      if (
        firstSequnce === secoundSequnce ||
        firstSequnce === thirdSequnce ||
        firstSequnce === fourthSequnce ||
        firstSequnce === fifthSequnce ||
        firstSequnce === sixthSequnce ||
        firstSequnce === seventhSequnce ||
        firstSequnce === eighthSequnce
      ) {
        removeElements(firstPrevCount);
        setForceRefresh(true);
      }
    }
  }, [firstSequnce]);

  useEffect(() => {
    if (secoundSequnce !== null) {
      setSelectedSequences((pre) => [secoundSequnce, ...pre]);
      if (
        secoundSequnce === firstSequnce ||
        secoundSequnce === thirdSequnce ||
        secoundSequnce === fourthSequnce ||
        secoundSequnce === fifthSequnce ||
        secoundSequnce === sixthSequnce ||
        secoundSequnce === seventhSequnce ||
        secoundSequnce === eighthSequnce
      ) {
        removeElements(secoundPrevCount);
        setForceRefresh(true);
      }
    }
  }, [secoundSequnce]);

  useEffect(() => {
    if (thirdSequnce !== null) {
      setSelectedSequences((pre) => [thirdSequnce, ...pre]);
      if (
        thirdSequnce === firstSequnce ||
        thirdSequnce === secoundSequnce ||
        thirdSequnce === fourthSequnce ||
        thirdSequnce === fifthSequnce ||
        thirdSequnce === sixthSequnce ||
        thirdSequnce === seventhSequnce ||
        thirdSequnce === eighthSequnce
      ) {
        removeElements(thirdPrevCount);
        setForceRefresh(true);
      }
    }
  }, [thirdSequnce]);

  useEffect(() => {
    if (fourthSequnce !== null) {
      setSelectedSequences((pre) => [fourthSequnce, ...pre]);
      if (
        fourthSequnce === firstSequnce ||
        fourthSequnce === secoundSequnce ||
        fourthSequnce === thirdSequnce ||
        fourthSequnce === fifthSequnce ||
        fourthSequnce === sixthSequnce ||
        fourthSequnce === seventhSequnce ||
        fourthSequnce === eighthSequnce
      ) {
        removeElements(fourthPrevCount);
        setForceRefresh(true);
      }
    }
  }, [fourthSequnce]);

  useEffect(() => {
    if (fifthSequnce !== null) {
      setSelectedSequences((pre) => [fifthSequnce, ...pre]);
      if (
        fifthSequnce === firstSequnce ||
        fifthSequnce === secoundSequnce ||
        fifthSequnce === thirdSequnce ||
        fifthSequnce === fourthSequnce ||
        fifthSequnce === sixthSequnce ||
        fifthSequnce === seventhSequnce ||
        fifthSequnce === eighthSequnce
      ) {
        removeElements(fifthPrevCount);
        setForceRefresh(true);
      }
    }
  }, [fifthSequnce]);

  useEffect(() => {
    if (sixthSequnce !== null) {
      setSelectedSequences((pre) => [sixthSequnce, ...pre]);
      if (
        sixthSequnce === firstSequnce ||
        sixthSequnce === secoundSequnce ||
        sixthSequnce === thirdSequnce ||
        sixthSequnce === fourthSequnce ||
        sixthSequnce === fifthSequnce ||
        sixthSequnce === seventhSequnce ||
        sixthSequnce === eighthSequnce
      ) {
        removeElements(sixthPrevCount);
        setForceRefresh(true);
      }
    }
  }, [sixthSequnce]);

  useEffect(() => {
    if (seventhSequnce !== null) {
      setSelectedSequences((pre) => [seventhSequnce, ...pre]);
      if (
        seventhSequnce === firstSequnce ||
        seventhSequnce === secoundSequnce ||
        seventhSequnce === thirdSequnce ||
        seventhSequnce === fourthSequnce ||
        seventhSequnce === fifthSequnce ||
        seventhSequnce === sixthSequnce ||
        seventhSequnce === eighthSequnce
      ) {
        removeElements(seventhPrevCount);
        setForceRefresh(true);
      }
    }
  }, [seventhSequnce]);

  useEffect(() => {
    if (eighthSequnce !== null) {
      setSelectedSequences((pre) => [eighthSequnce, ...pre]);
      if (
        eighthSequnce === firstSequnce ||
        eighthSequnce === secoundSequnce ||
        eighthSequnce === thirdSequnce ||
        eighthSequnce === fourthSequnce ||
        eighthSequnce === fifthSequnce ||
        eighthSequnce === sixthSequnce ||
        eighthSequnce === seventhSequnce
      ) {
        removeElements(eighthPrevCount);
        setForceRefresh(true);
      }
    }
  }, [eighthSequnce]);

  useEffect(() => {
    setArray(Array.from({ length: question?.answerCount }, (_, i) => i + 1));
  }, [question?.answerCount]);

  useEffect(() => {
    const fetch = () => {
      if (propSaveStatus && checked) {
        message.success("Sikeres mentés");
        setChecked(false);
        setModalVisible(false);
      } else if (!propSaveStatus && checked) {
        setChecked(false);
        message.error("Hiba az feladat mentése közben!");
      }
    };
    fetch();
  }, [checked]);

  const functionCreateQuestionWithAnswer = async () => {
    const formData = new FormData();
    formData.append("questionId", question.id);
    const answers = [
      {
        description: pairAnswerPair1,
        sequenceId: firstSequnce,
      },
      {
        description: pairAnswerPair2,
        sequenceId: secoundSequnce,
      },
      {
        description: pairAnswerPair3,
        sequenceId: thirdSequnce,
      },
      {
        description: pairAnswerPair4,
        sequenceId: fourthSequnce,
      },
      {
        description: pairAnswerPair5,
        sequenceId: fifthSequnce,
      },
      {
        description: pairAnswerPair6,
        sequenceId: sixthSequnce,
      },
      {
        description: pairAnswerPair7,
        sequenceId: seventhSequnce,
      },
      {
        description: pairAnswerPair8,
        sequenceId: eighthSequnce,
      },
    ];

    formData.append("answers", JSON.stringify(answers));
    await dispatch(QuestionActions.createQuestionAnswers(formData));
    setChecked(true);
  };

  const closeModal = () => {
    setModalVisible(false);

    setPairAnswerPair1("");
    setPairAnswerPair2("");
    setPairAnswerPair3("");
    setPairAnswerPair4("");
    setPairAnswerPair5("");
    setPairAnswerPair6("");
    setPairAnswerPair7("");
    setPairAnswerPair8("");

    setFirstSequence(null!);
    setSecoundSequence(null!);
    setThirdSequence(null!);
    setFourthSequence(null!);
    setFifthSequence(null!);
    setSixthSequence(null!);
    setSeventhSequence(null!);
    setEighthSequence(null!);

    setModify(false);
    form.resetFields();
  };

  const functionModifyQuestionWithAnswer = async () => {
    const formData = new FormData();
    formData.append("questionId", question.id);
    const answers = [
      {
        id: propAnswers[0]?.id,
        description: pairAnswerPair1,
        sequenceId: firstSequnce,
      },
      {
        id: propAnswers[1]?.id,
        description: pairAnswerPair2,
        sequenceId: secoundSequnce,
      },
      {
        id: propAnswers[2]?.id,
        description: pairAnswerPair3,
        sequenceId: thirdSequnce,
      },
      {
        id: propAnswers[3]?.id,
        description: pairAnswerPair4,
        sequenceId: fourthSequnce,
      },
      {
        id: propAnswers[4]?.id,
        description: pairAnswerPair5,
        sequenceId: fifthSequnce,
      },
      {
        id: propAnswers[5]?.id,
        description: pairAnswerPair6,
        sequenceId: sixthSequnce,
      },
      {
        id: propAnswers[6]?.id,
        description: pairAnswerPair7,
        sequenceId: seventhSequnce,
      },
      {
        id: propAnswers[7]?.id,
        description: pairAnswerPair8,
        sequenceId: eighthSequnce,
      },
    ];

    formData.append("answers", JSON.stringify(answers));
    await dispatch(QuestionActions.modifyQuestionAnswers(formData));
    setChecked(true);
  };

  useEffect(() => {
    if (modify) {
      setPairAnswerPair1(propAnswers[0]?.description);
      setPairAnswerPair2(propAnswers[1]?.description);
      setPairAnswerPair3(propAnswers[2]?.description);
      setPairAnswerPair4(propAnswers[3]?.description);
      setPairAnswerPair5(propAnswers[4]?.description);
      setPairAnswerPair6(propAnswers[5]?.description);
      setPairAnswerPair7(propAnswers[6]?.description);
      setPairAnswerPair8(propAnswers[7]?.description);
      setFirstSequence(propAnswers[0]?.sequenceId);
      setSecoundSequence(propAnswers[1]?.sequenceId);
      setThirdSequence(propAnswers[2]?.sequenceId);
      setFourthSequence(propAnswers[3]?.sequenceId);
      setFifthSequence(propAnswers[4]?.sequenceId);
      setSixthSequence(propAnswers[5]?.sequenceId);
      setSeventhSequence(propAnswers[6]?.sequenceId);
      setEighthSequence(propAnswers[7]?.sequenceId);
      form.setFieldsValue({
        answer1: propAnswers[0]?.description,
        answer2: propAnswers[1]?.description,
        answer3: propAnswers[2]?.description,
        answer4: propAnswers[3]?.description,
        answer5: propAnswers[4]?.description,
        answer6: propAnswers[5]?.description,
        answer7: propAnswers[6]?.description,
        answer8: propAnswers[7]?.description,
        sequence1: propAnswers[0]?.sequenceId,
        sequence2: propAnswers[1]?.sequenceId,
        sequence3: propAnswers[2]?.sequenceId,
        sequence4: propAnswers[3]?.sequenceId,
        sequence5: propAnswers[4]?.sequenceId,
        sequence6: propAnswers[5]?.sequenceId,
        sequence7: propAnswers[6]?.sequenceId,
        sequence8: propAnswers[7]?.sequenceId,
      });
    }
  }, [modify]);

  useEffect(() => {
    if (question.answerCount === 8) {
      pairAnswerPair1 !== "" &&
      pairAnswerPair2 !== "" &&
      pairAnswerPair3 !== "" &&
      pairAnswerPair4 !== "" &&
      pairAnswerPair5 !== "" &&
      pairAnswerPair6 !== "" &&
      pairAnswerPair7 !== "" &&
      pairAnswerPair8 !== "" &&
      selectedSequences.includes(1) &&
      selectedSequences.includes(2) &&
      selectedSequences.includes(3) &&
      selectedSequences.includes(4) &&
      selectedSequences.includes(5) &&
      selectedSequences.includes(6) &&
      selectedSequences.includes(7) &&
      selectedSequences.includes(8)
        ? setDisabledButton(false)
        : setDisabledButton(true);
    } else if (question.answerCount === 7) {
      pairAnswerPair1 !== "" &&
      pairAnswerPair2 !== "" &&
      pairAnswerPair3 !== "" &&
      pairAnswerPair4 !== "" &&
      pairAnswerPair5 !== "" &&
      pairAnswerPair6 !== "" &&
      pairAnswerPair7 !== "" &&
      selectedSequences.includes(1) &&
      selectedSequences.includes(2) &&
      selectedSequences.includes(3) &&
      selectedSequences.includes(4) &&
      selectedSequences.includes(5) &&
      selectedSequences.includes(6) &&
      selectedSequences.includes(7)
        ? setDisabledButton(false)
        : setDisabledButton(true);
    } else if (question.answerCount === 6) {
      pairAnswerPair1 !== "" &&
      pairAnswerPair2 !== "" &&
      pairAnswerPair3 !== "" &&
      pairAnswerPair4 !== "" &&
      pairAnswerPair5 !== "" &&
      pairAnswerPair6 !== "" &&
      selectedSequences.includes(1) &&
      selectedSequences.includes(2) &&
      selectedSequences.includes(3) &&
      selectedSequences.includes(4) &&
      selectedSequences.includes(5) &&
      selectedSequences.includes(6)
        ? setDisabledButton(false)
        : setDisabledButton(true);
    } else if (question.answerCount === 5) {
      pairAnswerPair1 !== "" &&
      pairAnswerPair2 !== "" &&
      pairAnswerPair3 !== "" &&
      pairAnswerPair4 !== "" &&
      pairAnswerPair5 !== "" &&
      selectedSequences.includes(1) &&
      selectedSequences.includes(2) &&
      selectedSequences.includes(3) &&
      selectedSequences.includes(4) &&
      selectedSequences.includes(5)
        ? setDisabledButton(false)
        : setDisabledButton(true);
    } else if (question.answerCount === 4) {
      pairAnswerPair1 !== "" &&
      pairAnswerPair2 !== "" &&
      pairAnswerPair3 !== "" &&
      pairAnswerPair4 !== "" &&
      selectedSequences.includes(1) &&
      selectedSequences.includes(2) &&
      selectedSequences.includes(3) &&
      selectedSequences.includes(4)
        ? setDisabledButton(false)
        : setDisabledButton(true);
    } else if (question.answerCount === 3) {
      pairAnswerPair1 !== "" &&
      pairAnswerPair2 !== "" &&
      pairAnswerPair3 !== "" &&
      selectedSequences.includes(1) &&
      selectedSequences.includes(2) &&
      selectedSequences.includes(3)
        ? setDisabledButton(false)
        : setDisabledButton(true);
    }
  }, [
    question?.questionType,
    pairAnswerPair1,
    pairAnswerPair2,
    pairAnswerPair3,
    pairAnswerPair4,
    pairAnswerPair5,
    pairAnswerPair6,
    pairAnswerPair7,
    pairAnswerPair8,
    firstSequnce,
    secoundSequnce,
    thirdSequnce,
    fourthSequnce,
    fifthSequnce,
    sixthSequnce,
    seventhSequnce,
    eighthSequnce,
    selectedSequences,
  ]);

  return (
    <div>
      <Modal
        visible={modalVisible}
        onCancel={() => closeModal()}
        maskClosable={false}
        title={modify ? "Módosítás" : "Létrehozás"}
        forceRender
        centered
        footer={[
          <Button
            key="createTextSequence"
            type="primary"
            disabled={disabledButton}
            onClick={() =>
              modify
                ? functionModifyQuestionWithAnswer()
                : functionCreateQuestionWithAnswer()
            }
          >
            {modify ? "Módosítás" : "Létrehozás"}
          </Button>,
          <Button key="cancel" onClick={() => closeModal()}>
            Mégsem
          </Button>,
        ]}
        width={"70em"}
      >
        <Form
          form={form}
          name="register"
          scrollToFirstError
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 20 }}
        >
          <TextSequenceSchema
            id={"answer1"}
            pairAnswerPair={pairAnswerPair1}
            setPairAnswerPair={setPairAnswerPair1}
            sequenceId={"sequence1"}
            sequnce={firstSequnce}
            setSequence={setFirstSequence}
            arr={arr}
            title={"Első szöveg"}
            selectedSequences={selectedSequences}
            forceRefresh={forceRefresh}
            setForceRefresh={setForceRefresh}
          />
          <TextSequenceSchema
            id={"answer2"}
            pairAnswerPair={pairAnswerPair2}
            setPairAnswerPair={setPairAnswerPair2}
            sequenceId={"sequence2"}
            sequnce={secoundSequnce}
            setSequence={setSecoundSequence}
            arr={arr}
            title={"Második szöveg"}
            selectedSequences={selectedSequences}
            forceRefresh={forceRefresh}
            setForceRefresh={setForceRefresh}
          />
          {question.answerCount >= 3 && (
            <TextSequenceSchema
              id={"answer3"}
              pairAnswerPair={pairAnswerPair3}
              setPairAnswerPair={setPairAnswerPair3}
              sequenceId={"sequence3"}
              sequnce={thirdSequnce}
              setSequence={setThirdSequence}
              arr={arr}
              title={"Harmadik szöveg"}
              selectedSequences={selectedSequences}
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
            />
          )}
          {question.answerCount >= 4 && (
            <TextSequenceSchema
              id={"answer4"}
              pairAnswerPair={pairAnswerPair4}
              setPairAnswerPair={setPairAnswerPair4}
              sequenceId={"sequence4"}
              sequnce={fourthSequnce}
              setSequence={setFourthSequence}
              arr={arr}
              title={"Negyedik szöveg"}
              selectedSequences={selectedSequences}
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
            />
          )}
          {question.answerCount >= 5 && (
            <TextSequenceSchema
              id={"answer5"}
              pairAnswerPair={pairAnswerPair5}
              setPairAnswerPair={setPairAnswerPair5}
              sequenceId={"sequence5"}
              sequnce={fifthSequnce}
              setSequence={setFifthSequence}
              arr={arr}
              title={"Ötödik szöveg"}
              selectedSequences={selectedSequences}
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
            />
          )}
          {question.answerCount >= 6 && (
            <TextSequenceSchema
              id={"answer6"}
              pairAnswerPair={pairAnswerPair6}
              setPairAnswerPair={setPairAnswerPair6}
              sequenceId={"sequence6"}
              sequnce={sixthSequnce}
              setSequence={setSixthSequence}
              arr={arr}
              title={"Hatodik szöveg"}
              selectedSequences={selectedSequences}
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
            />
          )}
          {question.answerCount >= 7 && (
            <TextSequenceSchema
              id={"answer7"}
              pairAnswerPair={pairAnswerPair7}
              setPairAnswerPair={setPairAnswerPair7}
              sequenceId={"sequence7"}
              sequnce={seventhSequnce}
              setSequence={setSeventhSequence}
              arr={arr}
              title={"Hetedik szöveg"}
              selectedSequences={selectedSequences}
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
            />
          )}
          {question.answerCount === 8 && (
            <TextSequenceSchema
              id={"answer8"}
              pairAnswerPair={pairAnswerPair8}
              setPairAnswerPair={setPairAnswerPair8}
              sequenceId={"sequence8"}
              sequnce={eighthSequnce}
              setSequence={setEighthSequence}
              arr={arr}
              title={"Nyolcadik szöveg"}
              selectedSequences={selectedSequences}
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
            />
          )}
        </Form>
      </Modal>
    </div>
  );
}
