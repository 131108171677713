import {
  LoadingOutlined,
  PictureOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { Button, Card, Modal, Pagination, Popconfirm, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PictureActions from "../../../actions/picture";
import { PictureT } from "../../../constants/ActionTypes/picture-action-types";
import useWindowDimensions from "../../../constants/sizecheck";
import { RootStore } from "../../../store/store";
import "../../formating/content.css";

export default function DeletedPictures({ showDeletedModal }: any) {
  const dispatch = useDispatch();
  const propPictures = useSelector(
    (state: RootStore) => state.pictureReducer.deletedPictures
  );
  const propStatus = useSelector(
    (state: RootStore) => state.pictureReducer.status
  );
  const [loaded, setLoaded] = useState<boolean>(() => false);
  const [current, setCurrent] = useState<number>(() => 1);
  const { width } = useWindowDimensions();
  const [previewModal, setPreviewModal] = useState<boolean>(() => false);
  const [previewImageUrl, setPreviewImageUrl] = useState<string>(() => "");

  useEffect(() => {
    const fetch = async () => {
      if (showDeletedModal) {
        try {
          await dispatch(PictureActions.getAllDeletedPicture());
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetch();
    if (propStatus) {
      setLoaded(true);
    }
  }, [dispatch, showDeletedModal]);

  const getDeletedVideos = async () => {
    setLoaded(false);
    try {
      await dispatch(PictureActions.getAllDeletedPicture());
      if (propStatus) {
        setLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const restoreDeletedVideo = async (record: PictureT) => {
    await dispatch(PictureActions.restorePicture(record.id.toString()));
    await getDeletedVideos();
  };

  const showPreviewModal = (picUrl: string) => {
    setPreviewImageUrl(picUrl);
    setPreviewModal(true);
  };

  const renderPhotos = (source: PictureT[]) => {
    return source.map((photo: PictureT) => {
      return (
        <div className="pictureCardStyle">
          <Card
            cover={
              <img
                className="deletedPictureStyle"
                alt={photo.name}
                src={
                  process.env.REACT_APP_BASE_URL +
                  "/api/1.0.0/picture/getall/" +
                  photo.filename
                }
              />
            }
            bordered={false}
            actions={[
              <PictureOutlined
                key="pre"
                onClick={() => showPreviewModal(photo.filename)}
              />,
              <Popconfirm
                title="Biztosan törölni akarod ezt a képet?"
                okText="Igen"
                cancelText="Mégsem"
                onConfirm={() => restoreDeletedVideo(photo)}
              >
                <RedoOutlined />,
              </Popconfirm>,
            ]}
          >
            <Meta title={photo.name} />
          </Card>
        </div>
      );
    });
  };

  return (
    <div>
      <h1 className="title">
        <strong>Törölt képek</strong>
      </h1>
      <Row>
        {loaded && propPictures ? (
          renderPhotos(
            width <= 2560 && width > 2380
              ? propPictures.slice(
                  current === 1 ? 0 : 30 * current - 30,
                  30 * current
                )
              : width <= 2380 && width > 2150
              ? propPictures.slice(
                  current === 1 ? 0 : 27 * current - 27,
                  27 * current
                )
              : width <= 2150 && width > 1920
              ? propPictures.slice(
                  current === 1 ? 0 : 24 * current - 24,
                  24 * current
                )
              : width <= 1920 && width > 1903
              ? propPictures.slice(
                  current === 1 ? 0 : 21 * current - 21,
                  21 * current
                )
              : width <= 1903 && width >= 1673
              ? propPictures.slice(
                  current === 1 ? 0 : 18 * current - 18,
                  18 * current
                )
              : width < 1673 && width >= 1445
              ? propPictures.slice(
                  current === 1 ? 0 : 15 * current - 15,
                  15 * current
                )
              : width < 1445 && width >= 1213
              ? propPictures.slice(
                  current === 1 ? 0 : 12 * current - 12,
                  12 * current
                )
              : width < 1213 && width >= 983
              ? propPictures.slice(
                  current === 1 ? 0 : 9 * current - 9,
                  9 * current
                )
              : width < 983 && width >= 753
              ? propPictures.slice(
                  current === 1 ? 0 : 6 * current - 6,
                  6 * current
                )
              : propPictures.slice(
                  current === 1 ? 0 : 3 * current - 3,
                  3 * current
                )
          )
        ) : (
          <div className="loadingButton">
            <LoadingOutlined style={{ fontSize: "10em" }} />
          </div>
        )}
      </Row>
      <div className="picturePaginator">
        <Pagination
          total={propPictures.length}
          onChange={(e) => setCurrent(e)}
          showSizeChanger={false}
          pageSize={18}
        />
      </div>
      <Modal
        visible={previewModal}
        onCancel={() => setPreviewModal(false)}
        maskClosable={true}
        centered
        forceRender
        footer={false}
        bodyStyle={{ maxWidth: "80em" }}
        width={"75em"}
      >
        <div className="colPadding">
          <img
            className="picturePreview"
            alt={previewImageUrl}
            src={
              process.env.REACT_APP_BASE_URL +
              "/api/1.0.0/picture/getall/" +
              previewImageUrl
            }
          />
        </div>

        <div className="colPadding">
          <Button
            style={{
              display: "block",
              marginRight: "auto",
              marginLeft: "auto",
              position: "relative",
            }}
            onClick={() => setPreviewModal(false)}
          >
            Vissza
          </Button>
        </div>
      </Modal>
    </div>
  );
}
