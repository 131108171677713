import { useCallback } from "react";
import { DASBOARD_ACTIONS } from "../../constants/ActionTypes/ReducerActionTypes/reducer-action-types";
import { initialState } from "./reducer";
export type AdminState = typeof initialState;

export const useNameChange = (dispatch: any) => {
  const nameChange = useCallback(
    (value: string) => {
      dispatch({
        type: DASBOARD_ACTIONS.SET_CATEGORY_TITLE,
        categoryTitle: value,
      });
    },
    [dispatch]
  );

  return nameChange;
};

export const useSelectedIdChange = (dispatch: any) => {
  const selectedIdChange = useCallback(
    (value: number) => {
      dispatch({
        type: DASBOARD_ACTIONS.SET_SELECTED_ID,
        selectedId: value,
      });
    },
    [dispatch]
  );

  return selectedIdChange;
};

export const useDashboardTargetKeysChange = (dispatch: any) => {
  const dashboardTargetKeysChange = useCallback(
    (value: string[]) => {
      dispatch({
        type: DASBOARD_ACTIONS.SET_CATEGORY_TARGET_KEYS,
        dashboardTargetKeys: value,
      });
    },
    [dispatch]
  );

  return dashboardTargetKeysChange;
};

export const useDashboardSelectedKeysChange = (dispatch: any) => {
  const dashboardSelectedKeysChange = useCallback(
    (value: string[] | any) => {
      dispatch({
        type: DASBOARD_ACTIONS.SET_CATEGORY_SELECTED_KEYS,
        dashboardSelectedKeys: value,
      });
    },
    [dispatch]
  );

  return dashboardSelectedKeysChange;
};
