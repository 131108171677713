import { Button, Col, Form, Input, message, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import KeywordCategoryActions from "../../../actions/keywordCategory";
import DebounceFunc from "../../../constants/debounce";
import { layout } from "../../../constants/layout";
import { RootStore } from "../../../store/store";

const KeywordCategoryModal = ({
  form,
  modalVisible,
  setModalVisible,
  setModify,
  nameChange,
  name,
  selectedId,
  getKeywordCategories,
  modify,
}: any) => {
  const keywordCategorySaveStatus = useSelector(
    (state: RootStore) => state.keywordCategoryReducer.saveStatus
  );
  const dispatch = useDispatch();
  const [checked, setChecked] = useState<boolean>(() => false);
  const [disabled, setDisabled] = useState<boolean>(() => true);
  const onReset = () => {
    form.resetFields();
  };
  const closeModal = () => {
    setModalVisible(false);
    setModify(false);
    nameChange("");
    onReset();
  };

  const functionCreateKeywordCategory = async () => {
    const toSend = {
      name: name,
    };
    await dispatch(KeywordCategoryActions.createKeywordCategory(toSend));
    setChecked(true);
  };

  const functionModifyKeywordCategory = async () => {
    const toSend = {
      id: selectedId,
      name,
    };
    await dispatch(KeywordCategoryActions.modifyKeywordCategory(toSend));
    setChecked(true);
  };

  useEffect(() => {
    const fetch = () => {
      if (keywordCategorySaveStatus && checked) {
        setChecked(false);
        message.success("Sikeres mentés");
      } else if (!keywordCategorySaveStatus && checked) {
        setChecked(false);
        message.error("Hiba az feladat mentése közben!");
      }
    };
    fetch();
    return () => {
      if (keywordCategorySaveStatus && checked) {
        getKeywordCategories();
        closeModal();
      }
    };
  });

  useEffect(() => {
    if (!name) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [name]);

  return (
    <Modal
      visible={modalVisible}
      onCancel={closeModal}
      title={modify ? "Módosítás" : "Létrehozás"}
      maskClosable={false}
      forceRender
      centered
      footer={[
        <Button
          disabled={disabled}
          key="createTask"
          type="primary"
          onClick={
            modify
              ? functionModifyKeywordCategory
              : functionCreateKeywordCategory
          }
        >
          {modify ? "Módosítás" : "Létrehozás"}
        </Button>,
        <Button key="cancel" onClick={closeModal}>
          Mégsem
        </Button>,
      ]}
      width="45em"
    >
      <Row>
        <Col span={24} className="colPadding">
          <Form
            {...layout}
            name="register"
            onFinish={
              modify
                ? functionModifyKeywordCategory
                : functionCreateKeywordCategory
            }
            scrollToFirstError
            form={form}
          >
            <Form.Item
              name="name"
              label="Megnevezés"
              rules={[
                {
                  required: true,
                  message: "Mező kitöltése kötelező!",
                },
              ]}
            >
              <Input
                name="name"
                value={name}
                maxLength={255}
                onChange={(e) => DebounceFunc(e.target.value, nameChange)}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
export default KeywordCategoryModal;
