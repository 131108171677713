import { Button, Form, Input, message } from "antd";
import Axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserT } from "../../constants/ActionTypes/login-action-types";
import useWindowDimensions from "../../constants/sizecheck";
import "../formating/content.css";
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 10,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};
function ConnectedForgotPasswordConfirm() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(() => true);
  const [success, setSuccess] = useState(() => false);
  const [user, setUser] = useState<UserT>(() => null!);
  const [password, setPassword] = useState(() => "");
  const [passwordCheck, setPasswordCheck] = useState(() => "");
  const functionRan = useRef(false);
  const history = useHistory();
  const [buttonDisabler, setButtonDisabler] = useState(() => true);
  const { width } = useWindowDimensions();
  const [answer, setAnswer] = useState("");

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const lng = queryParams.get("lng");

  useEffect(() => {
    const fetch = async () => {
      if (functionRan.current === false) {
        await Axios.get("/1.0.0/users/forgot-password-confirm", {
          params: { token },
        })
          .then((response) => {
            setUser(response.data);
            setLoading(false);
            setSuccess(true);
          })
          .catch((err) => {
            setLoading(false);
            setSuccess(false);
          });
      }
    };
    fetch();
    return () => {
      functionRan.current = true;
    };
  }, [token]);

  useEffect(() => {
    setButtonDisabler(true);
    if (
      /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d))/.test(password) &&
      /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d))/.test(passwordCheck) &&
      password.length > 0 &&
      passwordCheck.length > 0 &&
      password === passwordCheck
    ) {
      setAnswer("");
      setButtonDisabler(false);
    }
    if (
      password.length > 0 &&
      passwordCheck.length > 0 &&
      password !== passwordCheck
    ) {
      setButtonDisabler(true);
      setAnswer("Nem egyezik meg a két jelszó!");
    }

    if (
      password.length > 0 &&
      passwordCheck.length > 0 &&
      password === passwordCheck
    ) {
      setAnswer("");
    }
  }, [password, passwordCheck]);

  const modifyUserPassword = async () => {
    setButtonDisabler(true);
    const userParams = {
      id: user.id,
      password: password,
      language: lng?.toString(),
    };
    Axios.put("/1.0.0/users/modify-password", userParams, {
      params: { token },
    })
      .then((response) => {
        history.push(`/success-page`);
      })
      .catch((err) => {
        message.error(
          "Lejárt, vagy nem létező token, kérjen új jelszóemlékeztetőt!",
          3
        );
      });
  };

  return (
    <div>
      {loading ? (
        "Kérem várjon..."
      ) : !loading && success ? (
        <div>
          <Form
            form={form}
            {...formItemLayout}
            name="register"
            onFinish={modifyUserPassword}
            scrollToFirstError
            style={
              width >= 576
                ? { paddingTop: "2em", marginRight: "2em" }
                : { paddingTop: "2em", marginLeft: "3em", marginRight: "3em" }
            }
          >
            <Form.Item name="name" label={"Név: "}>
              <Input
                style={width >= 576 ? { maxWidth: "20vw" } : { width: "100%" }}
                name="name"
                placeholder={user.name}
                disabled={true}
              />
            </Form.Item>

            <Form.Item name="email" label={"E-mail cím: "}>
              <Input
                style={width >= 576 ? { maxWidth: "20vw" } : { width: "100%" }}
                name="email"
                placeholder={user.email}
                disabled={true}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={"Jelszó: "}
              rules={[
                {
                  required: true,
                  message: "Mező kitöltése kötelező!",
                },
                {
                  pattern: /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d))/,
                  message:
                    "A jelszónak tartalmaznia kell kis- és nagybetűt és számot!",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                autoComplete="new-password"
                style={width >= 576 ? { maxWidth: "20vw" } : { width: "100%" }}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="passwordcheck"
              label={"Jelszó megerősítés: "}
              rules={[
                {
                  required: true,
                  message: "Mező kitöltése kötelező!",
                },
              ]}
            >
              <Input.Password
                autoComplete="new-password"
                onChange={(e) => setPasswordCheck(e.target.value)}
                value={passwordCheck}
                style={width >= 576 ? { maxWidth: "20vw" } : { width: "100%" }}
                name="passwordcheck"
                type="password"
              />
              <div style={{ color: "red" }}>{answer}</div>
            </Form.Item>
          </Form>
          <div style={{ textAlign: "center" }}>
            <Button
              disabled={buttonDisabler}
              type="primary"
              htmlType="submit"
              onClick={() => modifyUserPassword()}
              style={{ display: "inline-block" }}
            >
              Mentés
            </Button>
          </div>
        </div>
      ) : (
        <div className="forgotpasswordErrorMessage">
          Lejárt, vagy nem létező token, kérjen új jelszóemlékeztetőt!
        </div>
      )}
    </div>
  );
}

export default ConnectedForgotPasswordConfirm;
