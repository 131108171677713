import { AudioT } from "../../../constants/ActionTypes/audio-action-types";
import type { UploadFile } from "antd/es/upload/interface";
import { AUDIO_ACTIONS } from "../../../constants/ActionTypes/ReducerActionTypes/reducer-action-types";
interface InitialState {
  file: UploadFile | any;
  name: string;
  selectedId: number;
  imageUrl: string;
  bookId: number;
  bookSearch: string;
  searchColumn: string;
  filteredAudios: AudioT[];
  bookTargetKeys: string[];
  bookSelectedKeys: string[];
}

export const initialState: InitialState = {
  file: null,
  name: "",
  selectedId: null!,
  imageUrl: "",
  bookId: null!,
  bookSearch: "",
  searchColumn: "",
  filteredAudios: [],
  bookTargetKeys: [],
  bookSelectedKeys: [],
};

type Action =
  | { type: typeof AUDIO_ACTIONS.SET_FILE; file: UploadFile | any }
  | { type: typeof AUDIO_ACTIONS.SET_NAME; name: string }
  | { type: typeof AUDIO_ACTIONS.SET_SELECTED_ID; selectedId: number }
  | { type: typeof AUDIO_ACTIONS.SET_IMAGE_URL; imageUrl: string }
  | { type: typeof AUDIO_ACTIONS.SET_BOOK_ID; bookId: number }
  | { type: typeof AUDIO_ACTIONS.SET_BOOK_SEARCH; bookSearch: string }
  | { type: typeof AUDIO_ACTIONS.SET_SEARCHED_COLUMN; searchColumn: string }
  | { type: typeof AUDIO_ACTIONS.SET_FILETED_AUDIOS; filteredAudios: AudioT[] }
  | {
      type: typeof AUDIO_ACTIONS.SET_BOOK_TARGET_KEYS;
      bookTargetKeys: string[];
    }
  | {
      type: typeof AUDIO_ACTIONS.SET_BOOK_SELECTED_KEYS;
      bookSelectedKeys: string[];
    };

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case AUDIO_ACTIONS.SET_FILE:
      return { ...state, file: action.file };
    case AUDIO_ACTIONS.SET_NAME:
      return { ...state, name: action.name };
    case AUDIO_ACTIONS.SET_SELECTED_ID:
      return { ...state, selectedId: action.selectedId };
    case AUDIO_ACTIONS.SET_IMAGE_URL:
      return { ...state, imageUrl: action.imageUrl };
    case AUDIO_ACTIONS.SET_BOOK_ID:
      return { ...state, bookId: action.bookId };
    case AUDIO_ACTIONS.SET_BOOK_SEARCH:
      return { ...state, bookSearch: action.bookSearch };
    case AUDIO_ACTIONS.SET_SEARCHED_COLUMN:
      return { ...state, searchColumn: action.searchColumn };
    case AUDIO_ACTIONS.SET_FILETED_AUDIOS:
      return { ...state, filteredAudios: action.filteredAudios };
    case AUDIO_ACTIONS.SET_BOOK_TARGET_KEYS:
      return { ...state, bookTargetKeys: action.bookTargetKeys };
    case AUDIO_ACTIONS.SET_BOOK_SELECTED_KEYS:
      return { ...state, bookSelectedKeys: action.bookSelectedKeys };
    default:
      return state;
  }
};

export default reducer;
