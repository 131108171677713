import { useCallback } from "react";
import { AUDIO_ACTIONS } from "../../../constants/ActionTypes/ReducerActionTypes/reducer-action-types";
import { initialState } from "./reducer";
import type { UploadFile } from "antd/es/upload/interface";
import { AudioT } from "../../../constants/ActionTypes/audio-action-types";
export type BookState = typeof initialState;

export const useFileChange = (dispatch: any) => {
  const fileChange = useCallback(
    (value: UploadFile | any) => {
      dispatch({
        type: AUDIO_ACTIONS.SET_FILE,
        file: value,
      });
    },
    [dispatch]
  );

  return fileChange;
};

export const useNameChange = (dispatch: any) => {
  const nameChange = useCallback(
    (value: string) => {
      dispatch({ type: AUDIO_ACTIONS.SET_NAME, name: value });
    },
    [dispatch]
  );

  return nameChange;
};

export const useSelectedIdChange = (dispatch: any) => {
  const selectedIdChange = useCallback(
    (value: number) => {
      dispatch({
        type: AUDIO_ACTIONS.SET_SELECTED_ID,
        selectedId: value,
      });
    },
    [dispatch]
  );

  return selectedIdChange;
};

export const useImageUrlChange = (dispatch: any) => {
  const imageUrlChange = useCallback(
    (value: string) => {
      dispatch({ type: AUDIO_ACTIONS.SET_IMAGE_URL, imageUrl: value });
    },
    [dispatch]
  );

  return imageUrlChange;
};

export const useBookIdChange = (dispatch: any) => {
  const bookIdChange = useCallback(
    (value: number) => {
      dispatch({
        type: AUDIO_ACTIONS.SET_BOOK_ID,
        bookId: value,
      });
    },
    [dispatch]
  );

  return bookIdChange;
};

export const useBookSearchedColumnChange = (dispatch: any) => {
  const bookSearchColumnChange = useCallback(
    (value: string) => {
      dispatch({
        type: AUDIO_ACTIONS.SET_BOOK_SEARCH,
        bookSearch: value,
      });
    },
    [dispatch]
  );

  return bookSearchColumnChange;
};

export const useSearchedColumnChange = (dispatch: any) => {
  const searchColumnChange = useCallback(
    (value: string) => {
      dispatch({
        type: AUDIO_ACTIONS.SET_SEARCHED_COLUMN,
        searchColumn: value,
      });
    },
    [dispatch]
  );

  return searchColumnChange;
};

export const useFilteredAudiosColumnChange = (dispatch: any) => {
  const filteredAudiosColumnChange = useCallback(
    (value: AudioT[]) => {
      dispatch({
        type: AUDIO_ACTIONS.SET_FILETED_AUDIOS,
        filteredAudios: value,
      });
    },
    [dispatch]
  );

  return filteredAudiosColumnChange;
};

export const useBookTargetKeysChange = (dispatch: any) => {
  const bookTargetKeysChange = useCallback(
    (value: string[]) => {
      dispatch({
        type: AUDIO_ACTIONS.SET_BOOK_TARGET_KEYS,
        bookTargetKeys: value,
      });
    },
    [dispatch]
  );

  return bookTargetKeysChange;
};

export const useBookSelectedKeysChange = (dispatch: any) => {
  const bookSelectedKeysChange = useCallback(
    (value: string[]) => {
      dispatch({
        type: AUDIO_ACTIONS.SET_BOOK_SELECTED_KEYS,
        bookSelectedKeys: value,
      });
    },
    [dispatch]
  );

  return bookSelectedKeysChange;
};
