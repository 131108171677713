import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, Table } from "antd";
import { useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import KeywordActions from "../../actions/keyword";
import { KeywordT } from "../../constants/ActionTypes/keyword-action-types";
import {
  useNameChange,
  useSelectedIdChange,
  useTypeChange,
} from "./keywordUtils";
import KeywordModal from "./modal";
import reducer, { initialState } from "./reducer";

const KeywordTable = ({
  form,
  setModalVisible,
  getKeywords,
  propKeyword,
  loaded,
  modalVisible,
}: any) => {
  const dispatch = useDispatch();
  const [state, reducerDispatch] = useReducer(reducer, initialState);
  const { name, keywordType, selectedId } = state;
  const nameChange = useNameChange(reducerDispatch);
  const selectedIdChange = useSelectedIdChange(reducerDispatch);
  const typeChange = useTypeChange(reducerDispatch);
  const [modify, setModify] = useState<boolean>(() => false);

  const functionDeleteKeyword = async (record: KeywordT) => {
    await dispatch(KeywordActions.deleteKeyword(record.id));
    getKeywords();
  };

  const showModifyModal = (record: KeywordT) => {
    form.setFieldsValue({
      name: record.name,
      type: record.type,
    });
    setModalVisible(true);
    setModify(true);
    selectedIdChange(record.id);
    nameChange(record.name);
    typeChange(record.type);
  };

  const columns = [
    {
      title: "Kulcsszó",
      key: "keyword",
      dataIndex: "name",
    },
    {
      title: "Típus",
      key: "type",
      dataIndex: "type",
    },
    {
      title: "Módosítás",
      key: "modify",
      render: (text: string, record: KeywordT) => {
        return (
          <Space size="middle">
            <Button onClick={() => showModifyModal(record)}>
              <EditOutlined />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Törlés",
      key: "delete",
      render: (text: string, record: KeywordT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan törölni akarod ezt a kulcsszót?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => functionDeleteKeyword(record)}
            >
              <Button type="primary" danger>
                <DeleteOutlined key="delete" />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={propKeyword}
        loading={!loaded && !propKeyword}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          showSizeChanger: false,
        }}
      />
      <KeywordModal
        form={form}
        modalVisible={modalVisible}
        getKeywords={getKeywords}
        setModalVisible={setModalVisible}
        setModify={setModify}
        name={name}
        type={keywordType}
        nameChange={nameChange}
        typeChange={typeChange}
        selectedId={selectedId}
        modify={modify}
      />
    </div>
  );
};
export default KeywordTable;
