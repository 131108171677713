import AdminUsers from "../components/Admins/admins";
import Book from "../components/Book/book";
import DashBoard from "../components/Dashboard/dashboard";
import Audio from "../components/Media/audio/audio";
import Picture from "../components/Media/picture/picture";
import Video from "../components/Media/video/video";
import Notification from "../components/Notifications/notification";
import Page from "../components/Page/page";
import Task from "../components/Task/task";
import Profile from "../components/user/profile";
import Webcontent from "../components/WebContent/webcontent";

export const routes = [
  {
    path: "/raabe-klett/profile",
    component: Profile,
  },
  {
    path: "/raabe-klett/video",
    component: Video,
  },
  {
    path: "/raabe-klett/picture",
    component: Picture,
  },
  {
    path: "/raabe-klett/audio",
    component: Audio,
  },
  {
    path: "/raabe-klett/task",
    component: Task,
  },
  {
    path: "/raabe-klett/book",
    component: Book,
  },
  {
    path: "/raabe-klett/page",
    component: Page,
  },
  {
    path: "/raabe-klett/dashboard",
    component: DashBoard,
  },
  {
    path: "/raabe-klett/notification",
    component: Notification,
  },
  {
    path: "/raabe-klett/webcontent",
    component: Webcontent,
  },
  {
    path: "/raabe-klett/admins",
    component: AdminUsers,
  }
];
