import axios from "axios";
import { Dispatch } from "redux";
import { LoginDispatchTypes } from "../constants/ActionTypes/login-action-types";
import HttpService from "../services/http";

const login = (email: string, password: string): any => {
  return async (dispatch: Dispatch<LoginDispatchTypes>) => {
    dispatch({ type: "TOKEN_LOADING" });
    await axios
      .post("/1.0.0/users/frontend-login", {
        email,
        password,
      })
      .then((response) => {
        const tokenToSave = "Bearer " + response.data.token.accessToken;
        HttpService.setAxiosDefaults(tokenToSave);
        dispatch({
          type: "TOKEN_LOADED",
          payload: { token: tokenToSave, user: response.data.user },
        });
      })
      .catch((err) => {
        dispatch({
          type: "TOKEN_ERROR",
          payload: err.response?.data,
        });
        console.log(err.message);
      });
  };
};

const refreshUser = (userId: number): any => {
  return async (dispatch: Dispatch<LoginDispatchTypes>) => {
    await axios
      .get("/1.0.0/users/refresh-user", {
        params: { userId: userId },
      })
      .then((response) => {
        const tokenToSave = "Bearer " + response.data.token.accessToken;
        HttpService.setAxiosDefaults(tokenToSave);
        dispatch({
          type: "TOKEN_LOADED",
          payload: { token: tokenToSave, user: response.data.user },
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
};

const logout = (): any => {
  return (dispatch: Dispatch<LoginDispatchTypes>) => {
    dispatch({ type: "LOGOUT" });
  };
};

const LoginActions = {
  login,
  refreshUser,
  logout,
};

export default LoginActions;
