import { Row, Col, Button, Form } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BookTypeActions from "../../actions/booktype";
import { RootStore } from "../../store/store";
import "../formating/content.css";
import BookTypeTable from "./table";

export default function BookType() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propBookType = useSelector(
    (state: RootStore) => state.booktypeReducer.booktype
  );
  const bookTypeStatus = useSelector(
    (state: RootStore) => state.booktypeReducer.status
  );

  const [modalVisible, setModalVisible] = useState<boolean>(() => false);
  const [loaded, setLoaded] = useState<boolean>(() => false);

  const getBookTypes = async () => {
    setLoaded(false);
    try {
      await dispatch(BookTypeActions.getAllBookType());
      setTimeout(() => {
        if (bookTypeStatus) {
          if (propBookType.length > 0) {
            setLoaded(true);
          }
        }
      }, 100);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Row>
        <Col span={24} className="colPadding">
          <h1 className="title">
            <strong>Könyvtípusok</strong>
          </h1>
          <Row>
            <Row>
              <Col span={30} className="colPadding">
                <Button type="primary" onClick={() => setModalVisible(true)}>
                  Létrehozás
                </Button>
              </Col>
            </Row>
          </Row>
          <Col span={24}>
            <BookTypeTable
              form={form}
              propBookType={propBookType}
              loaded={loaded}
              getBookTypes={getBookTypes}
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
            />
          </Col>
        </Col>
      </Row>
    </div>
  );
}
