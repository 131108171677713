import { NOTIFICATION_ACTIONS } from "../../constants/ActionTypes/ReducerActionTypes/reducer-action-types";
import type { UploadFile } from "antd/es/upload/interface";
interface InitialState {
  bookId: number;
  title: string;
  description: string;
  imageUrl: string;
  file: UploadFile | any;
}

export const initialState: InitialState = {
  bookId: null!,
  title: "",
  description: "",
  imageUrl: "",
  file: null,
};

type Action =
  | {
      type: typeof NOTIFICATION_ACTIONS.SET_BOOK_ID;
      bookId: number;
    }
  | { type: typeof NOTIFICATION_ACTIONS.SET_TITLE; title: string }
  | {
      type: typeof NOTIFICATION_ACTIONS.SET_DESCRIPTION;
      description: string;
    }
  | {
      type: typeof NOTIFICATION_ACTIONS.SET_IMAGE_URL;
      imageUrl: string;
    }
  | {
      type: typeof NOTIFICATION_ACTIONS.SET_FILE;
      file: UploadFile | any;
    };

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case NOTIFICATION_ACTIONS.SET_BOOK_ID:
      return { ...state, bookId: action.bookId };
    case NOTIFICATION_ACTIONS.SET_TITLE:
      return { ...state, title: action.title };
    case NOTIFICATION_ACTIONS.SET_DESCRIPTION:
      return { ...state, description: action.description };
    case NOTIFICATION_ACTIONS.SET_IMAGE_URL:
      return { ...state, imageUrl: action.imageUrl };
    case NOTIFICATION_ACTIONS.SET_FILE:
      return { ...state, file: action.file };
    default:
      return state;
  }
};

export default reducer;
