import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Form, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import CharacterCounter from "../../../../constants/characterCounter";
import DebounceFunc from "../../../../constants/debounce";
import { removePictureFile } from "../../../../constants/pictureUpload";

export default function PicturePictureSchema({
  id,
  pairId,
  beforeUpload1,
  handleChangeImage1,
  imageUrlPicture1afile,
  loadingPicture1afile,
  setPicture1aFile,
  setPicture1aImageUrl,
  beforeUpload2,
  handleChangeImage2,
  imageUrlPicture1bfile,
  loadingPicture1bfile,
  setPicture1bFile,
  setPicture1bImageUrl,
  title,
  descriptionId,
  descriptionPairId,
  pictureDescription,
  pictureDescriptionPair,
  setPictureDescription,
  setPictureDescriptionpair,
  pairTitle,
}: any) {
  return (
    <Form.Item
      name="pairing1"
      // label={title}
      style={{
        position: "relative",
        padding: "0.5em",
        left: "15em",
      }}
      rules={[
        {
          required: true,
          message: "",
        },
      ]}
    >
      <Form.Item
        name={id}
        label={title}
        rules={[
          {
            required: true,
            message: "Kérem adjon meg egy fájlt!",
          },
        ]}
        style={{
          display: "inline-block",
          width: "calc(15% - 4em)",
          marginRight: "7em",
        }}
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          beforeUpload={beforeUpload1}
          onChange={handleChangeImage1}
          accept=".png,.jpg,.jpeg,.gif"
        >
          {imageUrlPicture1afile ? (
            <img
              src={imageUrlPicture1afile}
              alt="avatar"
              style={{ width: "100%" }}
            />
          ) : (
            <div>
              {loadingPicture1afile ? <LoadingOutlined /> : <PlusOutlined />}
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
        <Button
          style={{ position: "absolute", left: "9em", top: "2.5em" }}
          size="small"
          danger
          type="primary"
          disabled={!imageUrlPicture1afile}
          onClick={() =>
            removePictureFile(setPicture1aFile, setPicture1aImageUrl)
          }
        >
          <DeleteOutlined />
        </Button>
      </Form.Item>
      <Form.Item
        label={"Kép leírása"}
        name={descriptionId}
        style={{
          display: "inline-block",
          width: "20em",
        }}
        rules={[
          {
            required: true,
            message: "Mező kitöltése kötelező!",
          },
        ]}
      >
        <TextArea
          name={id}
          value={pictureDescription}
          maxLength={100}
          onChange={(e) => setPictureDescription(e.target.value)}
        />
        <CharacterCounter max={100} taken={pictureDescription.length} />
      </Form.Item>
      <Form.Item
        name={pairId}
        label={pairTitle}
        style={{
          display: "inline-block",
          width: "calc(25% - 4em)",
          margin: "0 4em",
        }}
        rules={[
          {
            required: true,
            message: "Mező kitöltése kötelező!",
          },
        ]}
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          beforeUpload={beforeUpload2}
          onChange={handleChangeImage2}
          accept=".png,.jpg,.jpeg,.gif"
        >
          {imageUrlPicture1bfile ? (
            <img
              src={imageUrlPicture1bfile}
              alt="avatar"
              style={{ width: "100%" }}
            />
          ) : (
            <div>
              {loadingPicture1bfile ? <LoadingOutlined /> : <PlusOutlined />}
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
        <Form.Item
          name={descriptionPairId}
          label={"Kép leírása"}
          style={{
            display: "inline-block",
            width: "20em",
            marginLeft: "14em",
            marginTop: "-10em",
          }}
          rules={[
            {
              required: true,
              message: "Mező kitöltése kötelező!",
            },
          ]}
        >
          <TextArea
            name={id}
            value={pictureDescriptionPair}
            maxLength={255}
            onChange={(e) => setPictureDescriptionpair(e.target.value)}
          />
          <CharacterCounter max={100} taken={pictureDescriptionPair.length} />
        </Form.Item>
        <Button
          style={{
            position: "absolute",
            left: "9em",
            top: "2.5em",
          }}
          size="small"
          danger
          type="primary"
          disabled={!imageUrlPicture1bfile}
          onClick={() =>
            removePictureFile(setPicture1bFile, setPicture1bImageUrl)
          }
        >
          <DeleteOutlined />
        </Button>
      </Form.Item>
    </Form.Item>
  );
}
