import {
  message,
  Row,
  Col,
  Button,
  Popconfirm,
  Form,
  Modal,
  Input,
  Table,
  Space,
  Upload,
  Select,
  Transfer,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  RestOutlined,
  RetweetOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import WebContentActions from "../../actions/webcontent";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import type { UploadChangeParam } from "antd/es/upload";
import { RootStore } from "../../store/store";
import { WebContentT } from "../../constants/ActionTypes/webcontent-action-types";
import pic from "../../static/46532.png";
import BookActions from "../../actions/book";
import { BookT } from "../../constants/ActionTypes/book-action-types";
import { layout } from "../../constants/layout";
import DebounceFunc from "../../constants/debounce";
import "../formating/content.css";
import DeletedWebcontent from "./deletedWebcontent";
import {
  handleChange,
  removePictureFile,
  UploadButton,
} from "../../constants/pictureUpload";
import type { InputRef } from "antd";
import type { ColumnType } from "antd/es/table";

export const downloadContent = async (record: WebContentT) => {
  message.info("A letöltés folyamatban, kérem várjon!", 3);
  fetch(
    `${
      process.env.REACT_APP_BASE_URL
    }/api/1.0.0/webcontent/download/${record.url?.substring(
      `${process.env.REACT_APP_BASE_URL}/files/content/webcontents/`.length
    )}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/pdf" },
    }
  )
    .then((response) => response.blob())
    .then((blob) => {
      const newUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = newUrl;
      link.setAttribute(
        "download",
        record.originalName ? record.originalName : "file.pdf"
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    })
    .catch((err) => {
      console.log(err);
      message.error("Hiba a letöltés közben!");
    });
};

export default function Webcontent() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propWebContent = useSelector(
    (state: RootStore) => state.webcontentReducer.webcontent
  );
  const status = useSelector(
    (state: RootStore) => state.webcontentReducer.status
  );
  const saveStatus = useSelector(
    (state: RootStore) => state.webcontentReducer.saveStatus
  );
  const propBooks = useSelector((state: RootStore) => state.bookReducer.book);
  const [bookId, setBookId] = useState<number>(() => null!);
  const functionRan = useRef(false);
  const [name, setName] = useState<string>(() => "");
  const [file, setFile] = useState<UploadFile>(() => null!);
  const [modalVisible, setModalVisible] = useState<boolean>(() => false);
  const [modify, setModify] = useState<boolean>(() => false);
  const [loaded, setLoaded] = useState<boolean>(() => false);
  const [selectedId, setSelectedId] = useState<number>(() => null!);
  const [checked, SetChecked] = useState<boolean>(() => false);
  const [loading, setLoading] = useState<boolean>(() => false);
  const [webcontentUrl, setWebcontentUrl] = useState<string>(() => "");
  const [bookSearch, setBookSearch] = useState<string>(() => "");
  const [filteredWebContents, setFilteredWebContents] = useState<WebContentT[]>(
    () => []
  );
  const [onSearch, setOnSerach] = useState<boolean>(() => false);
  const [disabled, setDisabled] = useState<boolean>(() => true);
  const [showDeletedModal, setShowDeletedModal] = useState<boolean>(
    () => false
  );
  const [booksTargetKeys, setBooksTargetKeys] = useState<string[]>(() => []);
  const [booksSelectedKeys, setBooksSelectedKeys] = useState<string[]>(
    () => []
  );
  const [searchedColumn, setSearchedColumn] = useState(() => "");
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (functionRan.current === false) {
        try {
          await dispatch(WebContentActions.getAllWebcontent());
          await dispatch(BookActions.getAllBook(true));
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetchData();
    return () => {
      if (status) {
        setLoaded(true);
      }
      functionRan.current = true;
    };
  }, [dispatch, propWebContent, status]);

  const getWebContents = async () => {
    setLoaded(false);
    try {
      await dispatch(WebContentActions.getAllWebcontent());
      if (status) {
        if (propWebContent?.length > 0) {
          setLoaded(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const closeModal = () => {
    setModalVisible(false);
    setModify(false);
    setName("");
    setWebcontentUrl("");
    onReset();
    removePictureFile(setFile);
    setBooksTargetKeys([]);
    setBookId(null!);
  };

  const functionCreateWebContent = async () => {
    const formData = new FormData();
    if (file) {
      const convertedFile = file as RcFile;
      formData.append("file", convertedFile);
    } else {
      formData.append("webcontentUrl", webcontentUrl);
    }
    formData.append("name", name);
    formData.append("bookId", bookId.toString());
    await dispatch(WebContentActions.uploadWebcontent(formData));
    SetChecked(true);
  };

  const functionModifyWebContent = async () => {
    const formData = new FormData();
    if (file) {
      const convertedFile = file as RcFile;
      formData.append("file", convertedFile);
    } else {
      formData.append("webcontentUrl", webcontentUrl);
    }
    formData.append("id", selectedId.toString().replace(/\s|,/g, ""));
    formData.append("name", name);
    formData.append("bookIds", JSON.stringify(booksTargetKeys));
    await dispatch(WebContentActions.modifyWebcontent(formData));
    SetChecked(true);
  };

  useEffect(() => {
    const fetch = () => {
      if (saveStatus && checked) {
        message.success("Sikeres mentés");
        SetChecked(false);
      } else if (!saveStatus && checked) {
        SetChecked(false);
        message.error("Hiba az feladat mentése közben!");
      }
    };
    fetch();
    return () => {
      if (saveStatus && checked) {
        getWebContents();
        closeModal();
      }
    };
  });

  const functionDeleteWebContent = async (record: WebContentT) => {
    await dispatch(WebContentActions.deleteWebcontent(record.id));
    getWebContents();
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const showModifyModal = (record: WebContentT) => {
    form.setFieldsValue({
      name: record?.name,
      webc: !record.url.includes(`${process.env.REACT_APP_BASE_URL}`)
        ? record.url
        : "",
      book: record.book,
    });
    setModalVisible(true);
    setModify(true);
    setSelectedId(record.id);
    setName(record.name);
    setBookId(record.book[record.book.length - 1]?.id);
    if (!record.url.includes(`${process.env.REACT_APP_BASE_URL}`)) {
      setWebcontentUrl(record.url);
    } else {
      let url = {
        uid: "-1",
        name: `${record.url}`,
        url: `${record.url}`,
      };
      setFile(url);
    }
    setBooksTargetKeys(record.book?.map((b: BookT) => b.id.toString()));
  };

  const beforeUpload = async (file: RcFile) => {
    if (file.size / 1024 / 1024 > 5) {
      setFile(null!);
      message.error("Túl nagy file (max 5MB)");
    }

    return false;
  };
  const handleChangeFile: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    handleChange(info, setLoading, setFile);
  };

  type DataIndex = keyof WebContentT;

  const handleSearch = (
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    title: string
  ): ColumnType<WebContentT> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="searchPadding">
        <Input
          ref={searchInput}
          placeholder={`Keress ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm, dataIndex)}
          className="searchInput"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            className="searchButton"
          >
            Keresés
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              handleSearch(confirm, dataIndex);
            }}
            size="small"
            className="searchButton"
          >
            Visszaállítás
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const columns = [
    {
      title: "Webkontent megnevezés",
      key: "name",
      dataIndex: "name",
      ...getColumnSearchProps("name", "megnevezést"),
      sorter: (a: WebContentT, b: WebContentT) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Eredeti név",
      key: "originalName",
      dataIndex: "originalName",
      ...getColumnSearchProps("originalName", "eredeti nevet"),
    },
    // NINCS ELDÖNTVE HOGY KELL E NEKIK
    // {
    //   title: "Webkontent mérete",
    //   key: "fileSize",
    //   render: (text: string, record: WebContentT) => {
    //     return `${record.fileSize} KB`;
    //   },
    // },
    {
      title: "Könyv",
      key: "fileSize",
      render: (text: string, record: WebContentT) => {
        const arr = record.book
          ?.map((b) => {
            if (!b.deleted) {
              return b.title + ", ";
            } else {
              return null;
            }
          })
          .filter((e) => e !== null);

        if (arr.length > 4) {
          return arr.slice(0, 4) + "...";
        } else {
          return arr;
        }
      },
    },
    {
      title: "Létrehozva",
      key: "created",
      render: (text: string, record: WebContentT) => {
        return new Date(record.created).toLocaleDateString();
      },
    },
    {
      title: "Letöltés",
      key: "download",
      render: (text: string, record: WebContentT) => {
        if (record.url.includes(".pdf")) {
          return (
            <Space size="middle">
              <Button onClick={() => downloadContent(record)}>
                <DownloadOutlined />
              </Button>
            </Space>
          );
        } else {
          return [];
        }
      },
    },
    {
      title: "Módosítás",
      key: "modify",
      render: (text: string, record: WebContentT) => {
        return (
          <Space size="middle">
            <Button onClick={() => showModifyModal(record)}>
              <EditOutlined />
            </Button>
          </Space>
        );
      },
    },

    {
      title: "Törlés",
      key: "delete",
      render: (text: string, record: WebContentT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan törölni akarod ezt a kontentet?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => functionDeleteWebContent(record)}
            >
              <Button type="primary" danger>
                <DeleteOutlined key="delete" />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const searchWebContents = () => {
    if (bookSearch.length > 0) {
      setOnSerach(true);
      const filter = propWebContent.filter((webContent) =>
        webContent.book[webContent.book.length - 1]?.title
          .toLowerCase()
          .includes(bookSearch.toLocaleLowerCase())
      );
      setFilteredWebContents(filter);
    }
  };

  const resetFilter = () => {
    setOnSerach(false);
    setBookSearch("");
    setFilteredWebContents([]);
  };

  const closeDeletedWebContentsModal = async () => {
    setShowDeletedModal(false);
    await getWebContents();
  };

  useEffect(() => {
    if (file ? !name || !file || !bookId : !name || !webcontentUrl || !bookId) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [name, file, bookId, webcontentUrl]);

  return (
    <div className="content">
      <Row>
        <Col span={24} className="colPadding">
          <h1 className="title">
            <strong>Webkontentek</strong>
          </h1>
          <Row>
            <Row>
              <Col span={30} className="colPadding">
                <Space size="middle">
                  <Button type="primary" onClick={showModal}>
                    Létrehozás
                  </Button>
                </Space>
              </Col>
            </Row>
            <div className="recicleBin">
              <Button onClick={() => setShowDeletedModal(true)}>
                <RestOutlined />
              </Button>
            </div>
          </Row>
          <Space className="colPadding">
            Könyv alapú keresés:
            <Input
              name="booksearch"
              value={bookSearch}
              onChange={(e) => setBookSearch(e.target.value)}
            />
            {!onSearch ? (
              <Button type="primary" size="small" onClick={searchWebContents}>
                <SearchOutlined />
              </Button>
            ) : (
              <Button type="primary" size="small" onClick={resetFilter}>
                <RetweetOutlined />
              </Button>
            )}
          </Space>
          <Col span={24}>
            <Table
              columns={columns}
              rowKey="id"
              dataSource={onSearch ? filteredWebContents : propWebContent}
              loading={!loaded && !propWebContent}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
                showSizeChanger: false,
              }}
            />
          </Col>
        </Col>
      </Row>
      <Modal
        visible={modalVisible}
        onCancel={closeModal}
        title={modify ? "Módosítás" : "Létrehozás"}
        maskClosable={false}
        forceRender
        centered
        footer={[
          <Button
            disabled={disabled}
            key="createWebContent"
            type="primary"
            onClick={
              modify ? functionModifyWebContent : functionCreateWebContent
            }
          >
            {modify ? "Módosítás" : "Létrehozás"}
          </Button>,
          <Button key="cancel" onClick={closeModal}>
            Mégsem
          </Button>,
        ]}
        width={"55em"}
      >
        <Row>
          <Col span={24} className="colPadding">
            <Form
              form={form}
              {...layout}
              name="register"
              onFinish={
                modify ? functionModifyWebContent : functionCreateWebContent
              }
              scrollToFirstError
            >
              <Form.Item
                name="name"
                label="Megnevezés"
                rules={[
                  {
                    required: true,
                    message: "Mező kitöltése kötelező!",
                  },
                ]}
              >
                <Input
                  name="name"
                  value={name}
                  maxLength={255}
                  onChange={(e) => DebounceFunc(e.target.value, setName)}
                />
              </Form.Item>
              {(file || !webcontentUrl) && (
                <Form.Item
                  name="file"
                  label="Filefeltöltés"
                  rules={[
                    {
                      required: true,
                      message: "Kérem adjon meg egy fájlt!",
                    },
                  ]}
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    accept=".pdf"
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    beforeUpload={beforeUpload}
                    onChange={handleChangeFile}
                    onRemove={() => removePictureFile(setFile)}
                  >
                    {file ? (
                      <img src={pic} alt="avatar" className="coverPicPreview" />
                    ) : (
                      UploadButton(loading)
                    )}
                  </Upload>
                  <Button
                    style={{ position: "absolute", left: "9em", top: "2.5em" }}
                    size="small"
                    danger
                    type="primary"
                    disabled={!file}
                    onClick={() => removePictureFile(setFile)}
                  >
                    <DeleteOutlined />
                  </Button>
                </Form.Item>
              )}
              {(!file || webcontentUrl) && (
                <Form.Item
                  name="webc"
                  label="Konent url"
                  rules={[
                    {
                      required: true,
                      message: "Mező kitöltése kötelező!",
                    },
                  ]}
                >
                  <Input
                    name="webc"
                    value={webcontentUrl}
                    maxLength={255}
                    onChange={(e) => setWebcontentUrl(e.target.value)}
                  />
                </Form.Item>
              )}
              <Form.Item
                name="book"
                label="Könyv"
                rules={[
                  {
                    required: true,
                    message: "Mező kitöltése kötelező!",
                  },
                ]}
              >
                {modify ? (
                  <Transfer
                    style={{ textAlign: "left" }}
                    dataSource={propBooks}
                    render={(item) => `${item.title}`}
                    targetKeys={booksTargetKeys}
                    selectedKeys={booksSelectedKeys}
                    showSearch
                    onChange={(nextTargetKeys: string[]) =>
                      setBooksTargetKeys(nextTargetKeys)
                    }
                    onSelectChange={(
                      sourceSelectedKeys: string[],
                      targetSelectedKeys: string[]
                    ) =>
                      setBooksSelectedKeys([
                        ...sourceSelectedKeys,
                        ...targetSelectedKeys,
                      ])
                    }
                    rowKey={(record) => record.id.toString()}
                    selectAllLabels={[<div></div>, <div></div>]}
                    filterOption={(inputValue, item) =>
                      item.title
                        .toLowerCase()!
                        .includes(inputValue.toLowerCase())
                    }
                    locale={{
                      notFoundContent: "Üres",
                      selectAll: "Összes kiválasztása",
                      selectInvert: "Kiválasztás törlése",
                    }}
                    listStyle={{
                      width: 240,
                      height: 300,
                    }}
                  />
                ) : (
                  <Select
                    style={{ width: "30em" }}
                    value={bookId}
                    onChange={(event) => setBookId(event)}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={propBooks.map((book: BookT) => ({
                      value: book.id,
                      label: book.title,
                    }))}
                  />
                )}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={showDeletedModal}
        onCancel={closeDeletedWebContentsModal}
        maskClosable={true}
        forceRender
        footer={false}
        width={"87.5vw"}
        bodyStyle={{ height: "89.5vh" }}
        style={{
          position: "absolute",
          right: 0,
          bottom: "0em",
          marginTop: "0em",
        }}
      >
        <DeletedWebcontent showDeletedModal={showDeletedModal} />
      </Modal>
    </div>
  );
}
